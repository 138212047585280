.contact_area {
	position: relative;
	background: #fff;
	overflow: hidden;

	.section_title {
		margin-bottom: 3.35rem;
	}
}

.map_main {
	position: static;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	padding-left: 15px;
}

.map {
	height: 100%;
	width: 100%;
}

.contact_content {
	padding: 5rem 2.89rem;
	background: #f8f8f8;
	margin-right: 15px;
	padding-right: 15px;
}

.form_group {
	position: relative;
	margin-bottom: 20px;

	&.half {

		width: 50%;
		display: inline-block;
		margin: 0 -0.139rem 1.12rem;
		padding: 0 0.84rem;

		&:first-child {
			padding-left: 0;
		}

		&:nth-child(2) {
			padding-right: 0;

			input[required] + label {
				left: 1.67rem;
			}
		}
	}

	input {
		width: 100%;
		height: 2.78rem;
		border: none;
		padding: 0 0.84rem;
		box-shadow: none;
		color: #9f9f9f;
	}

	input[required] + label {
		color: #999;
		position: absolute;
		left: 0.84rem;
		top: 0.62rem;
		font-size: 0.84rem;
		color: #9f9f9f;
		pointer-events: none;

		&:after {
		    content:'*';
		    color: $main_theme_color;
			font-size: 0.84rem;
		}
	}

	input[required]:invalid + label {
	    display: inline-block;
	    opacity: 1;
	}

	input[required]:valid + label {
	    opacity: 0;
	}

	textarea {
		height: 7.56rem;
		border: none;
		padding: 0.84rem;
		background: #fff;
		resize: none;
		font-size: 0.84rem;
		color: #9f9f9f;

		&::placeholder {
			opacity: 1;
		}
	}

	&:last-child {
		margin-bottom: 0;
		text-align: center;
	}

	.btn_wrapper{
		margin-top: 0;
	}
}
.contact_form p {
	margin-bottom: 0;
}

#contact .btn_wrapper {
	text-align: left;
}
#contact.contact_area.section_padding {
	position: relative;
	z-index:1;
	padding-top: 0;
}
.no_padding{
	padding: 0;
}