/*--------------------------------------------------------------
  ##  Fontfamily
  --------------------------------------------------------------*/
$font_primary: 'Roboto', sans-serif;
$font_heading: 'Poppins', sans-serif;

/*--------------------------------------------------------------
  ##  Themecolor
  --------------------------------------------------------------*/
$main_theme_color: #f99a00;
$main_theme_bg: #f6fbfe;
$main_theme_color_light: #ffc000;
$secondary_theme_color: #f8f8f8;
$text_main: #383838;
$text_body: #595858;
$text_body_main: #666;
$text_sub_title: #aeadad;
$sub_section_title: #7b7b7a;
$text_footer_bottom: #e1e1e0;
$text_footer: #bebebe;
$toolbar_bg: #363636;
$toolbar_color: #e5e2e2;
$product_title_color: #524a1f;
$footer_two_bg: #f7f7f7;
$box_heading:#505253;
$home5_border: #2d2d2d;
$home5_secondary: #424e5a;
$home6_secondary: #333333;
$footer_common : #9b9b9b;

// new
$home6_primary: #45D638;
$home6_primary2: #5830B4;
$home6_primary3: #D01BD0;
$home5_primary: #3db5e6;
$home5_black: #121212;
$text_white: #ffffff;
$text_inner45: #454545;
$text_inner54: #545454;
$text_inner74: #7474BA;