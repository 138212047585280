.features_area_home6_all {
	display: flex;
	flex-wrap: wrap;
}

.features_area_home6.section_padding {
	padding-bottom: 0;
}

.single_feature_home6 {
	width: calc(50% - 20px);
	background: #fff;
	border: 1px solid #efefef;
	-webkit-box-shadow: 22px 32px 70px rgba(0, 0, 0, 0.15);
	box-shadow: 22px 32px 70px rgba(0, 0, 0, 0.15);
	margin-bottom: 50px;

	&:nth-child(odd){
		z-index: 9;

		.after {
			position: absolute;
			left: 30%;
			top: -10%;
			height: 100%;
			width: 100%;
		}

		.before {
			position: absolute;
			left: 40%;
			bottom: -27%;
			height: 100%;
			width: 100%;
		}
	}

	&:first-child {
		.after {
			top: -43%;
			left: 28%;
		}
	}

	.single_feature_home6_bottom_icon {
		position: absolute;
		right: -35px;
		bottom: -58px;

		i {
			font-size: 210px;
			opacity: 0.1;
			color: #a5a5a5;
		}
	}
}
.grid-item.single_feature_home6:nth-child(2) {
	margin-top: 449px;
}
.grid-item.single_feature_home6:nth-child(2n) {
	margin-left: 20px;
	z-index: 99;
}
.grid-item.single_feature_home6:nth-child(2n-1) {
	margin-right: 20px;
}
.grid.features_area_home6_all {
	width: calc(100% - 100px);
	margin-left: 50px;
	margin-top: -80px;

}

.single_feature_home6_content {
	position: relative;
	padding: 134px 90px 110px 108px;
	overflow: hidden;
	background: #fff;
	z-index: 999;

	&:hover {
		.single_feature_home6_icon i {
			color: #ebebeb;
		}

		.single_feature_home6_desc h3 {

			&:before {
				background: $home6_primary;
			}
		}
	}

	.single_feature_home6_icon {
		position: absolute;
		left: 81px;
		top: 116px;

		i {
			color: $home6_primary;
			font-size: 26px;
			transition: 0.5s;
		}
	}
}

.single_feature_home6_desc {
	padding: 20px;

	h3 {
		font-size: 24px;
		font-weight: 600;
		letter-spacing: 2.9px;
		text-align: left;
		color: $home5_black;
		font-family: "Poppins", sans-serif;
		text-transform: capitalize;
		margin-bottom: 0;
		padding-bottom: 25px;
		margin-bottom: 25px;
		position: relative;


		&:before {
			position: absolute;
			left: 5px;
			top: -50%;
			width: 15px;
			height: 146px;
			content: "";
			transform: rotate(45deg);
			transform-origin: center;
			background: #ebebeb;
			z-index: -1;
			transition: 0.5s;
		}

		&:after {
			position: absolute;
			content: "";
			left: 0;
			bottom: 0;
			width: 10px;
			height: 2px;
			background: $home6_primary;
		}
	}

	p {
		font-size: 20px;
		font-weight: 300;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.8;
		letter-spacing: normal;
		text-align: left;
		color: $text_inner45;
	}
}
.features_area_home6 {
	padding: 80px 0 0px;
	margin-bottom: -40px;

	.section_title_four {
		margin-bottom: 49px;

		h2 {
			font-family: Poppins;
			font-size: 60px;
			font-weight: 600;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.67;
			letter-spacing: normal;
			text-align: center;
		}
	}
}

@media (min-width: 1200px) and (max-width: 1499px){

	.single_feature_home6:last-child .before {
		display: none;
	}
	.single_feature_home6_content {
		padding: 75px;
	}

	.single_feature_home6_content .single_feature_home6_icon {
		left: 60px;
		top: 70px;
	}
}

@media (min-width: 992px) and (max-width:1199px){
	.single_feature_home6_content {
		padding: 40px 30px 40px 40px;
	}
	.single_feature_home6_content .single_feature_home6_icon {
		left: 30px;
		top: 30px;
	}

	.grid-item.single_feature_home6:nth-child(2) {
		margin-top: 300px;
	}
	.single_feature_home6:last-child .before {
		display: none;
	}
	.features_area_home6 .section_title_four h2 {
		box-shadow: none;
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	.grid.features_area_home6_all {
		width: 100%;
		margin-left: 0;
	}

	.single_feature_home6_content {
	    padding: 68px 15px 60px 15px;
	}

	.single_feature_home6_content .single_feature_home6_icon {
		left: 15px;
		top: 50px;
	}

	.grid-item.single_feature_home6:nth-child(2) {
		margin-top: 300px;
	}
	.single_feature_home6:nth-child(2n+1) .after {
		left: 36%;
		top: 0;
	}
	.single_feature_home6:first-child .after {
		top: -15%;
		left: 0;
	}
	.single_feature_home6:last-child .before {
		display: none;
	}
	.features_area_home6 .section_title_four h2 {
		box-shadow: none !important;
	}
	.features_area_home6 {
		padding: 60px 0 0px;
		margin-bottom: -80px;
	}
}

@media only screen and (max-width: 767px) {
	.features_area_home6 .section_title_four h2 {
		line-height: normal;
		padding: 0;
		font-size: 24px;
		box-shadow: none;
	}
	.features_area_home6 {
		padding: 70px 0 0px;
		margin-bottom: -50px;
	}
	.single_feature_home6_content {
		padding: 30px 20px;
	}

	.single_feature_home6_desc p{
		font-size: 19px;
	}

	.single_feature_home6 {
		width: 100%;
	}

	.features_area_home6.section_padding {
		padding-bottom: 0;
	}

	.grid.features_area_home6_all {
		width: 100%;
		margin-left: 0;
		margin-top: 0px;
	}

	.grid-item.single_feature_home6:nth-child(2n) {
		margin-left: 0;
	}

	.single_feature_home6_desc {
		padding: 0;
	}
	.single_feature_home6 .after,
	.single_feature_home6 .before {
		display: none;
	}

	.grid-item.single_feature_home6 {
		opacity: 1;
	}
	.grid-item.single_feature_home6:nth-child(2) {
		margin-top: 0;
	}

	.single_feature_home6_content .single_feature_home6_icon {
		position: static;
		margin-bottom: 10px;
	}

	.single_feature_home6_desc h3 {
		font-size: 20px;
		letter-spacing: normal;

		&:before {
			display: none;
		}
	}
}

