.top_feature_area{
	background: $main_theme_color;
	padding: 30px 0;
}
.top_feature_border {
	border: 1px solid rgba(255,255,255,0.3);
	padding: 30px;
}
.single_top_feature{
	color: #fff;
	margin-top: 0px;
	span {
		font-size:2.0rem;
		color: #fff;
	}
	.tf_icon{
		display: block;
		text-align: left;
		height: auto;
		margin-bottom: 15px;
	}
	.tf_content{
		h5{
			color:#fff;
			margin-bottom: 0;
			margin-bottom:5px;
		}
		p {
			color: #fff;
			font-size: 18px;
			margin-bottom: 0;
		}
	}
}
.tf_image img {
	max-width: 100%;
}
