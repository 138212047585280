.hdr_area_four {

	.top_toolbar_new {
		background: $home6_primary2;
		position: relative;

		.toolbar_left p {
			color: $text_white;

			span {
				color: $text_white;
			}
		}

		#count_down span {
			background: $home6_primary;
			margin-top: 0;
			line-height: 45px;
		}

		.toolbar_right ul li {

			color: $text_white;

			> i {
				color: $text_white;

				&:hover {
					color: $home6_primary;
				}
			}

			> a {
				color: $text_white;

				&:hover {
					color: $home6_primary;
				}
			}
		}
	}

	.main_menu > ul > li {

		> a {
			font-size: 14px;
			color: $home6_primary2;
			position: relative;
			padding:0 19px;
			transition: 0.3s;

			&:hover, &.current_page_item {
				background: transparent;
				color: $home6_primary3;

				&:after {
					color: $home6_primary3;
					left: 0;
				}
			}

			&:after {
				position: absolute;
				left: 6px;
				top: 0;
				content: "/";
				transform: rotate(20deg);
				transition: 0.3s;
			}
		}
	}

	.header_nav {
		background: #f5f5f5;
	}
}

.hdr_btn_wrapper {
	padding: 26px 0;

	a {
		padding: 0;
		font-size: 18px;
		font-weight: 700;
		text-transform: uppercase;
		color: $home6_primary3;
		letter-spacing: 2px;
		vertical-align: middle;
		transition: 0.3s;

		&:hover {

			i {
				color: $home6_primary;
			}
		}

		i {
			font-size: 20px;
			vertical-align: middle;
			transition: 0.3s;
			margin-top: -2px;
		}
	}
}

@media (min-width: 1200px) and (max-width: 1499px) {
	.hdr_btn_wrapper a {
		font-size: 16px;
	}

	.hdr_area_four .main_menu > ul > li > a {
		padding: 0 20px;
		padding-right: 5px;
	}
}