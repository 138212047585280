.supplement_page_top_left {
	position: relative;

	&:before {
		position: absolute;
		left: 44px;
		top: -27%;
		width: 27px;
		height: 86%;
		content: "";
		background: $home6_primary;
		transform: rotate(45deg);
		z-index: -1;
	}

	.section_title_four {
		position: absolute;
		left: 0;
		top: -42px;
	}
}
.supplement_page_top_right {
	padding-left: 0px;

	h3 {
		font-size: 24px;
		font-weight: 500;
		font-style: normal;
		font-stretch: normal;
		line-height: 30px;
		letter-spacing: 2.9px;
		text-align: left;
		color: #222;
		font-family: "Poppins", sans-serif;
		text-transform: inherit;
		margin-bottom: 48px;
	}

	p {
		font-size: 20px;
		font-size: 20px;
		font-weight: 300;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.5;
		letter-spacing: normal;
		text-align: left;
		color: $text_inner45;
		padding-bottom: 52px;
		margin-bottom: 0;
	}
}

.supplement_page_right_slider {
	padding: 90px 0px 0px;

	.owl-nav {
		padding-left: 0px;
		margin-left: 2px;
	}

	.owl-prev,
	.owl-next {
		width: 40px;
		height: 40px;
		border: 3px solid #000 !important;
		display: inline-block;
		line-height: 32px;
		border-radius: 0;

		&:hover {
			i {
				color: $home6_primary;
			}
		}

		i {
			line-height : 33px;
			font-size: 15px;
		}
	}

	.owl-next {
		margin-left: -3px;
	}

	.supplement_page_top_left_img {
		padding-right: 50px;
	}
}

.supplement_page_top {
	padding: 140px 0;
}

@media (min-width: 1200px) and (max-width: 1499px) {
	.supplement_page_right_slider {
		padding: 35px 0px 0px;
	}
	.home5_recent_view_cart a i {
		margin-right: 0;
	}
	.home5_recent_view_cart a {
		font-size: 10px;
	}
}

@media (min-width: 992px) and (max-width: 1199px) {
	.section_title_four h2 {
		font-size: 40px;
	}
	.supplement_page_right_slider {
		padding: 0;
	}
	.supplement_page_right_slider .owl-nav {
		margin-left: 10px;
	}

	.supplement_page_top_left::before {
		left: 60px;
		top: -33%;
	}
}

@media (min-width: 768px) and (max-width: 991px){

	.section_title_four h2 {
		font-size: 30px;
		padding: 13px 33px;
		box-shadow: -1px -1px 5px rgba(0,0,0,0.05);
	}
	.supplement_page_right_slider {
		padding: 0;
	}
	.supplement_page_top_right h3 {
		font-size: 22px;
		letter-spacing: 2px;
	}
	.supplement_page_right_slider .owl-nav {
		margin-left: 12px;
	}

	.supplement_page_top {
		padding: 100px 0 100px;
	}

	.supplement_page_top_left:before {
	    display: none;
	}
	.supplement_page_top_left .section_title_four {
		position: static;
	}
}

@media only screen and (max-width: 767px) {
	.supplement_page_top {
		padding: 124px 0 53px;
	}
	.supplement_page_right_slider {
		padding: 50px 0px 0px;
	}
	.supplement_page_right_slider .owl-nav {
		margin-left: 10px;
	}

	.supplement_page_top_left::before {
		display: none;
	}
}

