.video_three_area {
	background: rgb(240,107,0);
	background: -moz-linear-gradient(right, rgba(240,107,0,1) 0%, rgba(240,158,0,1) 100%);
	background: -webkit-linear-gradient(right, rgba(240,107,0,1) 0%,rgba(240,158,0,1) 100%);
	background: linear-gradient(to left, rgba(240,107,0,1) 0%,rgba(240,158,0,1) 100%);
	font-family: $font_primary;

	.section_title_two {
		margin-bottom: 85px;

		h2 {
			color: #fff;
		}

		p {
			color: #fff;
		}
	}
}
.video_three_area_bg {
	padding: 100px 0;
	background-size: cover;
	background-repeat: no-repeat;
	text-align: center;

	.video-btn.pop-up {
		padding: 0;
	}
}

.vido_three_inner {
	position: relative;
	display: block;
	width: 220px;
	height: 112px;
	background: url(../../media/images/banner-two/video-three-ppup.png) no-repeat scroll 0 0/cover;
	border-radius: 6px;
	background-color: #cd4526;
  	background-blend-mode: multiply;
  	margin-bottom: 10px;

	.video_three_play {
		position: absolute;
		left: 50%;
		-webkit-transform: translate(-50%);
		-ms-transform: translate(-50%);
		transform: translate(-50%);
		top: 11px;
	}
}

.video-btn.pop-up p {
	font-size: 16px;
	font-weight: 500;
	color: $home5_black;
	opacity: 0.7;
	border-bottom: 1px solid rgba(0,0,0,0.7);
	display: inline-block;
	margin-bottom: 0;
	opacity: 0.7;
}

@supports (-ms-ime-align:auto) {
    .vido_three_inner {
        background: url(../../media/images/banner-two/video-three-ppup-edge.png) no-repeat scroll 0 0/cover;
        position: relative;
        border-radius: 6px;
        -webkit-border-radius: 6px;

        &:after {
        	position:absolute;
        	left:0;
        	top:0;
        	content: "";
        	width:100%;
        	height: 100%;
        	background: rgba(117,18,7,0.8);
        }
    }
}