.single_pkg_plan {
	text-align: center;
	position: relative;
	height: 492px;
	margin-bottom: 0;
	margin-top: 0;
}

.single_pkg_plan figure {
  margin-bottom: 0;
}

.single_pkg_plan:hover .single_pkg_img::before{
	top: 0;
}


.single_pkg_plan span {
	font-size: 36px;
	color: $home5_black;
	font-weight: 400;
	position: relative;
	display: inline-block;
	margin-bottom: 1.38rem;
}


.single_pkg_img {
  height: 270px;

  img {
  	height: 100%;
  }
}

.single_pkg_bottom {
	background: #f8f8f8;
	padding: 60px 15px;
	bottom: 0;
	position: absolute;
	width: 100%;
	transition: 0.8s;
}

.single_pkg_bottom p {
  margin-bottom: 1.8rem;
}

.single_pkg_bottom h6 {
	padding-bottom: 0;
	font-size: 16px;
	font-weight: 400;
	height: 0;
	transform: scaleY(0);
	transform-origin: bottom left;
	transition:0.8s;
	color: $main_theme_color;

	&:after {
		position: absolute;
		left: 50%;
		height: 3px;
		background: $home5_black;
		bottom: 20px;
		content: "";
		width: 30px;
		-webkit-transform: translateX(-50%);
		-ms-transform: translateX(-50%);
		transform: translateX(-50%);
	}
}

.single_pkg_plan:hover .single_pkg_bottom h6 {
	height: 83px;
	transform: scaleY(1);
}

/*--------------------------------------------------------------
  ##  Advertise
  --------------------------------------------------------------*/
.advertise_area .vigo_container_one {
  background: #eff8ff;
  padding: 28px;
}

.advertise_area .row.background_white {
  background: #fff;
  margin: 0;
  border-radius: 10px;
}

.advertise_text_right {
  float: right;
  margin-top: 18px;
}

.advertise_text_left {
  display: inline-block;
}

.advertise_text_left span {
  color: $main_theme_color;
}

.advertise_all {
  display: table;
  width: 100%;
  height: 100%;
}

.advertise_cell {
  display: table-cell;
  vertical-align: middle;
  height: 100%;
}

.advertise_area {
  padding-bottom: 110px;
}

.advertise_text_left p {
  margin-bottom: 0;
}