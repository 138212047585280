.supplement_select_item {
	display: flex;
	justify-content: space-between;
	padding: 70px 0;
	padding-left: 46px;
	align-items: center;

	.quantity {
		margin-bottom: 12px;
	}
}
.supplement_select_area {
	padding-top: 120px;
}
.supplement_select_content {
	background: #f9f9f9 url(../../media/images/home3/guy.png) no-repeat scroll left 42px bottom/contain;
	position: relative;
	z-index: 1;
	border: 1px solid #fff;

	.supplement_select_item_bg {
		position: absolute;
		right: 0;
		top: 0;
		width: auto;
		height: auto;
		z-index: -1;
	}
}

.section_title_three {
	margin-bottom: 40px;

	h2{
		font-size: 36px;
		font-weight: bold;
		font-style: normal;
		font-stretch: normal;
		line-height: 36px;
		letter-spacing: normal;
		text-align: left;
		color: $home5_primary;
		margin-bottom: 16px;
		text-transform: uppercase;

	}

	p {
		font-size: 20px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.5;
		letter-spacing: normal;
		text-align: left;
		color: $home5_secondary;
		margin-bottom: 0;
		width: 75%;
	}
}
.supplement_select_area {

 	.section_title_three  {
 		margin-bottom: 31px;
 	}

	.product-cart {
		padding-bottom: 0;
		border-bottom: 0px;
	}

	.quantity .looking {
		border-radius: 50%;
		width: 26px;
		height: 26px;
		line-height: 23px;
		border: 0px;
		margin-left: 5px;

		&:hover {
			background: $home5_primary;
		}
	}

    .woocommerce-Price-amount {
		font-size: 36px;
		font-weight: bold;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.92;
		letter-spacing: normal;
		text-align: left;
		color: $home5_black;
		margin-bottom: 9px;

		span {
			font-size: 14px;
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			line-height: 4.93;
			letter-spacing: normal;
			text-align: left;
			color: $home5_secondary;
		}
	}

	.btn_two {
		border: 2px solid $home5_primary;
		color: $home5_primary;
		background: #fff;
		letter-spacing: 1.9px;

		&:hover {
			color: #fff;
			background: $home5_primary;
		}
	}
}

@media (min-width: 992px) and (max-width: 1199px) {
	.supplement_select_content {
		background: #f9f9f9;
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	.supplement_select_item_product {
		display: none;
	}

	.supplement_select_content {
		background: #f1f1f1;
	}

	.supplement_select_item_detail {
		width: 68%;
	}

	.supplement_select_area {
		padding-top: 100px;
	}
}
@media (min-width: 576px) and (max-width: 767px) {
	.supplement_select_content .supplement_select_item_bg {
		display: none;
	}
	.supplement_select_content {
		background: #f1f1f1;
	}
	.section_title_three p {
		width: 100%;
	}
	.supplement_select_area {
		padding-top: 80px;
	}
	.supplement_benefit_item {
		width: calc(50% - 1px);
		margin-bottom: 1px;
	}
}
@media only screen and (max-width: 575px) {
	.supplement_select_item {
		padding: 30px;
		padding-left: 30px;
		background: #f1f1f1;
	}
	.supplement_select_item_product {
		display: none;
	}

	.supplement_select_content .supplement_select_item_bg {
		display: none;
	}
	.section_title_three h2 {
		font-size: 27px;
	}
	.section_title_three p {
		width: 100%;
	}
	.supplement_select_content {
		background: transparent;
		border: 1px solid transparent;
	}
	.supplement_at_a_glance.section_padding_two {
		position: relative;
		z-index: 1;
	}

	.supplement_at_item_img {
		display: none;
	}

	.supplement_select_area {
		padding: 50px 0 80px;
	}
}

@media (min-width: 480px) and (max-width: 575px) {
	.supplement_select_area {
		padding: 50px 0 80px;
	}
}