.supplement_more {
	position: relative;
	z-index: 1

	&:after {
		position: absolute;
		content: "";
		left: 15px;
		top: 0;
		width: calc(100% - 30px);
		height: 200px;
		background:#ffffff url(../../media/images/home6/supp-more.jpg) no-repeat;
		z-index: -1;
	}

	.sn_related_product {
		background: #fff;
		margin-right: 30px;
		box-shadow: 22px 32px 70px rgba(0,0,0,0.15);

		&:last-child {
			margin-right: 0;
		}
	}
	.section_title_four h2 {
		margin-top: -53px;
	}

	.supplement_more_related_products {
		display: flex;
		flex-wrap: initial;
	}

	.sn_pd_rating{

		a i {
			color: $home6_primary3;
		}
	}
}

@media (min-width: 992px) and (max-width: 1199px) {
	.supplement_more .sn_related_product {
		margin-right: 5px;
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	.supplement_more .sn_related_product {
		width: 28%;
		margin-bottom: 20px;
	}

	.supplement_more .supplement_more_related_products {
		flex-wrap: wrap;
	}

	.supplement_more .sn_related_product {
		width: calc(33% - 28px);
		margin-bottom: 30px;
		margin-left: 15px;
		margin-right: 15px;
	}
}

@media only screen and (max-width: 767px) {
	.supplement_more .supplement_more_related_products {
		flex-wrap: wrap;
	}
	.supplement_more .sn_related_product {
		width: 44%;
		margin-bottom: 20px;
	}

	.supplement_more .sn_related_product {
		width: 48%;
		margin-right: 0;
	}
	.supplement_more .supplement_more_related_products {
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		justify-content: space-between;
	}

}

@media (max-width: 575px) {
	.supplement_more .sn_related_product {
		width: 47%;
		margin-right: 0;
	}
	.supplement_more .sn_related_product:last-child {
		margin-right: auto;
	}
}

@media only screen and (max-width: 479px) {
	.supplement_more .sn_related_product {
		margin: 0 auto 20px;
		width: 100%;
	}
}