.package_plan_two {

	p,h1,h2,h3,h4,h5,h6,a {
		font-family: $font_primary;
	}

	.accordion_btn {
		padding-left: 0;
	}

	.single_pkg_plan {

		span {
		color: #3734f0;

			&:after {
				background: #3734f0;
			}
		}

		&:hover {

			.single_pkg_bottom:before {
				background: rgb(240,107,0);
				background: -moz-linear-gradient(right, rgba(240,107,0,1) 0%, rgba(240,158,0,1) 100%);
				background: -webkit-linear-gradient(right, rgba(240,107,0,1) 0%,rgba(240,158,0,1) 100%);
				background: linear-gradient(to left, rgba(240,107,0,1) 0%,rgba(240,158,0,1) 100%);
			}

			.accordion_btn a {
				color: #fff;

				i {
					color: #fff;
				}
			}
		}
	}
}
