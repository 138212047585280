.product_all_collection.with-sidebar {
	padding: 65px 0 132px;
}
.woocommerce-ordering {
	margin-bottom: 30px;

	.short {
		position: relative;

		select {
			height: 46px;
			padding: 0 9px;
			font-size: 14px;
			color: #5e5e5e;
			font-weight: 300;
			-moz-appearance: none;
			border: 1px solid #ddd;

			&:focus {
				border: 1px solid $home6_primary;
			}
		}

		p {
			font-size: 12px;
			color: #5e5e5e;
			font-weight: 300;
			display: inline-block;
			margin-bottom:0;
			margin-right: 20px;
		}

		&:after {
			position: absolute;
			content: "\f0d8";
			right: 18px;
			top: 7px;
			font-family: "Font Awesome 5 Free";
			font-weight: 900;
			color: #5e5e5e;
			font-size:12px;
			pointer-events: none;
		}

		&:before {
			position: absolute;
			content: "\f0d7";
			right: 18px;
			bottom: 7px;
			font-family: "Font Awesome 5 Free";
			font-weight: 900;
			color: #5e5e5e;
			font-size:12px;
			pointer-events: none;
		}
	}
}
.woocommerce {

	.sn_related_product {
		margin-bottom: 30px;
		box-shadow: 22px 7px 41px rgba(0,0,0,0.06);
		border: 1px solid #efefef;
		padding: 30px 30px 22px;

		.woocommerce-loop-product__title {
			font-size: 13px;
			color: #000;
			font-weight: 400;
			letter-spacing: normal;
			text-transform: capitalize;
		}

		.sn_pd_img.product-thumb {
			text-align: center;
			height: 120px;
		}
	}

	.woocommerce-LoopProduct-link {
		padding: 0;
		display: block;
	}

	.star-rating {
		margin-bottom: 5px;

		i {
			font-size: 14px;
			color: $home6_primary3;
			margin-right: 3px;

			&:last-child {
				color: #b2b2b2;
			}
		}
	}
}

.product_sidebar {

	.widget {
		padding-right: 1.33rem;
		margin-bottom: 87px;
	}

	#product-price h3 {
		margin-bottom: 11px;
	}

	.widget.widget_search {
		position: relative;

		input {
			width: 100%;
			height: 49px;
			border: 1px solid #ddd;
			padding: 0px 15px;
			color: #4c4c4c;
			border-radius: 0;

			&:focus {
				border: 1px solid $home6_primary;
			}
		}

		button {
			position: absolute;
			content: "";
			right: 0px;
			top: 0;
			color: #222;
			font-size: 16px;
			line-height: 49px;
			cursor: pointer;
			margin-top: 0;

			i {
				margin-top: 0;
			}
		}
	}
	.widget.widget_price {
		margin-bottom: 90px;
	}
	.widget.widget_catergories h3 {
		margin-bottom: 12px;
	}
	.widget_title {
		font-size: 16px;
		color: $home5_black;
		font-weight: 700;
		text-transform: uppercase;
		letter-spacing: 1.5px;
		border-bottom: 0px;

		&:after {
			display: none;
		}
	}

	.widget_catgories li {

		a {
			font-size: 16px;
			color: #7b7b7b;
			padding: 0;
			display: block;
			padding: 10px 0;
			border-bottom: 1px solid #ddd;
			border-radius: 0;
			font-weight: 300;
			transition: 0.5s;

			&:hover {
				color: $home6_primary;
				padding-left: 8px;
				font-weight: 400;
			}
		}

		&:first-child {

			a {
				padding-top: 0;
			}
		}
	}
}
.product_sidebar {
	padding-top: 78px;

	.widget {
		padding-right: 0;
	}
	.whc_range_item {
		display: inline-block;
		font-size: 10px;
		color: #7b7b7b;

		&.item2 {
			float: right;
		}
	}
	.whc_range_plan {
		margin-bottom: 15px;
	}

	.price-filter span {
		margin-bottom: 6px;
		display: inline-block;
		font-size: 14px;
		font-weight: 300;
		color: #7b7b7b;
	}
}
.product_all_collection_flex {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: space-between;
}
.product_all_collection_flex_item {
	width: 245px;
	height: 85vh;
	min-height: 715px;
	position: sticky;
	position: -webkit-sticky;
	top: 10%;
	transition: all 0.5s ease;
}
.product_all_collection_flex_item_big {
	width: calc(100% - 275px );
}

@media (min-width: 576px) and (max-width: 767px) {
	.product_all_collection.with-sidebar {
		padding: 80px 0 70px;
	}
}

@media only screen and (max-width: 991px) {
	.product_all_collection_flex {
		flex-wrap: wrap;
	}
	.product_all_collection_flex_item {
		width: 60%;
		margin: 0 auto;
	}
	.product_all_collection_flex_item_big {
		width: 100%;
	}
	.product_all_collection_flex_item {
	    position: static;
	    margin-bottom: 70px;
	}
	.text-right {
	    text-align: left;
	    display: inline-block;
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	.product_sidebar {
		padding-top: 70px;
	}
	.product_all_collection.with-sidebar {
		padding: 30px 0 90px;
	}
}

@media only screen and (max-width: 767px) {

	.product_all_collection_flex_item {
		width: 70%;
		margin: 0 auto;
	}
	.product_all_collection_flex_item_big {
		width: 100%;
	}
	.product_all_collection_flex_item {
	    position: static;
	    margin-bottom: 0px;
	}
	.product_sidebar {
	    padding-top: 0px;
	}
}

@media only screen and (max-width: 575px) {
	.product_all_collection_flex_item {
		width: 100%;
	}
	.product_all_collection_flex_item_big {
		width: 100%;
	}

	.product_all_collection.woocommerce {
	    padding: 80px 0 75px;
	}

	.product_all_collection.with-sidebar {
		padding: 80px 0 70px;
	}
}

.rangeslider--horizontal {
	height: 7px;
	width: 100%;
	border-radius: 0;
	box-shadow: none;
}

.whc_range_control {
	width: calc(100% - 60px);
	margin: 0 auto;
	position: relative;

	&:after {
		position: absolute;
		content: "";
		left: -30px;
		width: calc(100% + 60px);
		top: 0;
		height: 7px;
		background: #e6e6e6;
		z-index: -1;
	}

	&:before {
		position: absolute;
		content: "";
		left: 0;
		width: 13px;
		height: 24px;
		background: #fff;
		top: -10px;
		border: 1px solid #ddd;
		z-index: 1;
	}

	.rangeslider__handle {
		border: 1px solid #ddd;
		width: 13px;
		height: 24px;
		border-radius: 0;
		box-shadow: none;
		z-index: 9;
		background-image: none;

		&:after {
			display: none;
		}
	}

	.rangeslider__fill {
		background: $home6_primary;
		position: absolute;
		border-radius: 0;
	}
}

