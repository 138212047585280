.related_product_two {
	p,h1,h2,h3,h4,h5,h6,a {
		font-family: $font_primary;
	}
	.sn_pd_rating a i {
		color: $home6_primary3;
		font-size: 14px;
	}
	.sn_pd_detail a h5 {
		font-size: 14px;
		font-weight: 400;
		text-transform: initial;
	}
	.related_product {
		padding: 110px 0 80px;
	}
}