.contact_area_two {
	.section_title_two {
		text-align: left;
	}

	.btn_one, .btn_two {
		border: 2px solid $home5_black;
		color: $home5_black;

		&:hover {
			border: 2px solid #3734f0;
			color: #fff;

			&:before {
				background: #3734f0;
			}
		}
	}

	.section_title_two img {
		margin-bottom: 0;
	}
	.section_title_two {
		margin-bottom: 60px;
	}
}