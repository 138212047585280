.video_three_area.video5 {
	background: #272727;
	position: relative;

	.vigo_container_two {
		position: inherit;
	}

	.video_bg_left {
		position: absolute;
		left: 0;
		bottom: -104px;
	}

	.video_bg_right {
		position: absolute;
		right: 0;
		top: -104px;
	}

	.video_three_area_bg {
		padding: 107px 0 119px;
	}
	.section_title_two {
		margin-bottom: 0;
		padding: 0 50px;
	}

	.video_three_content {
		position: absolute;
		left: 50%;
		-webkit-transform: translateX(-50%);
		-ms-transform: translateX(-50%);
		transform: translateX(-50%);
		height: 158px;
		border: 23px solid #fff;
		bottom: -78px;
		z-index: 9;
	}

	.vido_three_inner {
		margin-bottom: 0;
		border-radius: 0px;
		background: $home5_primary;
		background: -o-linear-gradient(bottom, #3db5e6 0%, #0086c3 100%);
		background: -webkit-gradient(linear, right top, left top, from(#3db5e6), to(#0086c3));
		background: linear-gradient(to top, #3db5e6 0%, #0086c3 100%);
		width: 200px;
		transition: 0.5s;

		&:hover {
			background: -o-linear-gradient(top, #3db5e6 0%, #0086c3 100%);
			background: -webkit-gradient(linear, right bottom, left top, from(#3db5e6), to(#0086c3));
			background: linear-gradient(to bottom, #3db5e6 0%, #0086c3 100%);

			.video_three_play i {
				-webkit-box-shadow: 0px 5px 10px rgba(255, 255, 255, 0.3);
				box-shadow: 0px 5px 10px rgba(255, 255, 255, 0.3);
			}

		}

		.video_three_play {
			top: 50%;
			transform: translate(-50%, -50%);

			i {
				font-size: 45px;
				color: #fff;
				transition: 0.3s;
				border-radius: 50%;
			}
		}
	}
}

@media only screen and (max-width: 575px) {
	.video_three_area.video5 .section_title_two {
		padding: 0 0px;
	}
	.video_three_area.video5 .vido_three_inner {
		width: 150px;
	}
	.video_three_area.video5 .video_three_content {
		border: 13px solid #fff;
		height: 138px;
	}
}