.home5_video_total {
	background: #3ad82e url(../../media/images/home6/video5-bg.png) no-repeat scroll right top/contain;
	padding: 68px 80px 68px;
	position: relative;

	.section_title_four {
		position: absolute;
		top: 68px;
		margin-bottom: 0;
		margin-left: -16%;
		box-shadow: 0px 5px 10px rgba(0,0,0,0.1);


		h2 {
			margin-top: 0;
		}
	}
}

.home5_video_left {
	width: calc(100% - 720px);
	display: inline-block;
	vertical-align: middle;
	padding-right: 123px;
	padding-top: 117px;

	p {
		font-size: 20px;
		color: $home5_black;
		letter-spacing: .25px;
		font-weight: 400;
		margin-bottom: 45px;
		line-height: 30px;
		opacity: 0.7;
	}
}
.home5_video_right {
	display: inline-block;
	position: relative;
	width: 715px;
	cursor: pointer;
	vertical-align: middle;
	z-index: 1;

	i {
		position: absolute;
		bottom: 52px;
		width: 78px;
		height: 78px;
		background: $home6_primary3;
		text-align: center;
		line-height: 78px;
		right: -25px;
		color: $text_white;
		font-size: 45px;
		transition: 0.3s;

		&:hover {
			color: $home6_primary;
			background: #fff;
		}

		&.pause {
			display: none;
		}
	}

	&:after {
		position: absolute;
		content: "";
		left: 60%;
		top: -112px;
		width: 25px;
		height: calc(100% + 230px);
		content: "";
		background: #fff;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
		z-index: -1;
	}
}
.youtube-wrapper .youtube-poster {
	height: 402px;
	background-position: center;
	background-size: cover;
	border: 22px solid $home6_primary2;
	box-shadow: 22px 32px 70px rgba(0,0,0,0.35);
}

.youtube-wrapper iframe {
	height: calc(100% - 44px);
	width: calc(100% - 44px);
	position: absolute;
	top: 22px;
	left: 22px;
	border: none;
	visibility: hidden;
}

.youtube-wrapper.reveal iframe {
	visibility: visible;
}

.home5_video_social{

	a {
		padding: 0;
		font-size: 14px;
		color: $home6_primary2;
		padding: 0;
		height: 30px;
		width: 30px;
		text-align: center;
		border: 1px solid transparent;
		border-radius: 0;

		&:hover {
			border: 1px solid #2ead25;
		}
	}

	span {
		font-size: 10px;
		color: $home6_primary2;
		letter-spacing: 1.3px;
	}
}

.home5_video {
	padding-bottom: 140px;
}

.youtube-wrapper.home5_video_right.reveal i {

	&.play {
		display: none;
	}

	&.pause {
		display: block;
	}
}

@media (min-width: 992px) and (max-width: 1199px) {
	.home5_video_total .section_title_four {
		margin-left: -35px;
	}
}

@media (min-width: 768px) and (max-width: 1499px) {
	.home5_video_total {
		padding: 68px 35px 72px;
	}

	.home5_video_left {
		padding-top: 90px;
	}

	.home5_video_left p {
		margin-bottom: 30px;
	}
}

@media (max-width: 575px) {
	.home5_video_total .section_title_four {
		margin-left: -20px;
	}
	.home5_video_left p {
		font-size: 18px;
	}
	.home5_video_right i {
		right: 0;
	}
	.home5_video_total .section_title_four {
		top: 70px;
	}
}
