.footer_area {
	background-color: $toolbar_bg;
	background-image: url(../../assets/img/footer-bg.png);
	font-family: $font_heading;
}

.footer_top {
	padding: 7.5rem 0;
	border-bottom: 2px solid #616161;
}

.footer_bottom {
	padding: 1.89rem 0;
}

.footer_top .widget {
		padding-right: 1.33rem;
		margin-bottom: 0;

	.widget_logo {
		margin-bottom: 1.5rem;

		a {
			padding: 0;
		}

		.svg {
			width: 112px;

			.st1,.st0 {
				fill: $main_theme_color;
				stroke: $main_theme_color;
			}

			path {
				fill: $text_white;
			}
		}
	}

	.widget_desc {
		margin-bottom: 1.23rem;

		p {
			color: $text_footer;
		}
	}

	.widget_social {

		> span {
			display: block;
			font-size: 0.89rem;
			color: $text_white;
			text-transform: uppercase;
			font-weight: 600;
			font-family: $font_heading;
			margin-bottom: 0.56rem;

			b {
				color: $main_theme_color;
				font-weight: 600;
			}
		}

		a {
			width: 1.78rem;
			height: 1.78rem;
			border: 1px solid $text_white;
			border-radius: 50%;
			text-align: center;
			padding: 0;
			margin-right: 0.33rem;

				&:hover {
					border: 1px solid $main_theme_color;

					span {
						color: $main_theme_color;
					}
				}

			span {
				color: $text_white;
				font-size: 0.78rem;
				line-height: 1.78rem;
			}

			&:last-child{
				margin-right: 0;
			}
		}
	}

	.widget_title {
		margin-bottom: 2.35rem;

		h5 {
			color: $text_white;
			text-transform: uppercase;
			margin-bottom: 0px;
		}
	}

	.widget_address {

		ul {
			padding-left: 0;

			li {
				font-size: 1rem;
				color: #e1e1e0;
				margin-bottom: 0.84rem;

				i {
					width: 25px;
					color: $main_theme_color;
					display: inline-block;
					vertical-align: middle;
				}

				span {
					width: calc(100% - 40px);
					display: inline-block;
					vertical-align: middle;
					margin-left: 10px;

					a {
						display: block;
						color: #e1e1e0;
						padding: 0;
						line-height: 1.67rem;

						&:hover {
							color: $main_theme_color;
						}
					}
				}

				&:nth-child(2) i {
					-webkit-transform: rotate(95deg);
					-ms-transform: rotate(95deg);
					transform: rotate(95deg);
					margin-left: -3px;
				}
			}
		}
	}

	.widget_form {
		margin-bottom: 2rem;

		p {
			color: $text_footer;
			margin-bottom: 1.23rem;
		}

		form {
			position: relative;
			font-size: 0.84rem;

			input[type="text"] {
				width: 100%;
				height: 2.46rem;
				border: 1px solid #b1b0ac;
				background: transparent;
				padding: 0 5rem 0 1.12rem;
				color: $text_footer;

				::placeholder{
					color: $text_footer;
				}
			}

			button {
				position: absolute;
				right: 0;
				top:0;
				height: 100%;
				width: 80px;
				border-top-left-radius: 0px;
				border-bottom-left-radius: 0px;
				background: $main_theme_color;
				color: $text_white;
				border: none;
				cursor: pointer;
			}
		}
	}

	.widget_payment {

		span {
			font-size: 0.89rem;
			color: $text_white;
			text-transform: uppercase;
			font-weight: 600;
			font-family: $font_heading;
			margin-bottom: 0.56rem;
			margin-right: 1.12rem;

			b {
				color: $main_theme_color;
				font-weight: 600;
			}
		}

		a {
			padding: 0;
			vertical-align: middle;
			margin-right: 0.33rem;

				&:hover i{
					color: $main_theme_color;
				}

			i {
				font-size: 2rem;
				color: #fefefe;
			}
		}
	}
}

.copyright {
	text-align: center;

	span {
		color: $text_footer_bottom;
		text-transform: uppercase;
		font-weight: 400;
		font-family: $font_heading;
		font-size: 0.89rem;

		a {
			color: $home5_black;
			padding: 0;
			text-transform: initial;
		}
	}
}

#footer .copyright span a {
	color: $main_theme_color;
}

#footer .widget{
	padding-right: 0px;
}
#footer .widget.widget_about {
	padding-right: 50px;
}
#footer .widget.widget_about_two {
	padding-right: 35px;
}
