.sin_post{
	margin-top: 0;
	border-bottom: 1px solid #f1f1f1;
	padding-bottom: 50px;
	margin-bottom: 60px;
	h3{
		margin-bottom: 10px;
		a{
			color: $text_main;
			padding:0;
			&:hover{
				color: $main_theme_color;
			}
		}
	}
	.post_image{
		border: 3px solid $text_white;
		box-shadow: 0px 0px 7.28px 0.72px rgba(72, 72, 72, 0.14);
		margin-bottom: 1.7rem;
		a{
			padding: 0;
		}
		img{
			margin-bottom: 0;
		}

	}
	a{
		color: $main_theme_color;
		padding:0;

	}
	p{
		strong{
			font-weight: 500;
			color: #5c5b5b;
			font-style: italic;
			padding: 10px 0;
			display: block;
		}
	}
	h6{
		font-size: 1rem;
		color: $text_main;
		margin-top:30px;
	}
	blockquote{
		text-align: center;
		padding: 30px 50px;
		text-transform: uppercase;
		p{
			font-weight: 300;
			color: #6b6b6b;
			i{
				padding: 0 15px;
				color: $main_theme_color;
				position: relative;
				top: 12px;
			}
			i:first-child{
				top:-10px;
			}
		}
	}

}

.page_content {
	padding: 7.5rem 0;
}



.post_meta {
	margin-bottom: 2rem;
	p {
		a {
			font-size: 14px;
			color: #848383;
			text-transform: capitalize;
			font-weight: 500;
			padding: 0 0.89rem;
			border-radius: 0;

			&:first-child {
				padding-left: 0;
				position: relative;

				&:after {
					position: absolute;
					content: "|";
					right: -3px;
					font-size: .9rem;
					font-weight: 300;
					top: -1px;
				}
			}
			&:nth-child(2) {
				position: relative;

				&:after {
					position: absolute;
					content: "|";
					right: -3px;
					font-size: .9rem;
					font-weight: 300;
					top: -1px;
				}
			}
			span.admin{
				color: $main_theme_color;
			}
		}
	}

	a {
		font-size: 0.89rem;
		color: $main_theme_color;
		text-transform: uppercase;
		padding: 0;
		font-weight: 600;

		&.continue {
			margin-top: 0.56rem;
		}
	}
}

.pagination{
	ul{
		padding-left: 0;
		margin-bottom: 0;
		li{
			display:inline-block;
			margin-right: 30px;

			a{
				font-size: 14px;
				color:$text_sub_title;
				font-weight: 500;
				padding: 3px 8px;
				&:hover{
					color: $main_theme_color;
				}
			}
			a.active{
				color: $main_theme_color;
			}
		}
		li:first-child{
			border: 1px solid #ededed;
			padding: 5px 25px;;
			border-radius: 5px;
			a{
				color:$main_theme_color;
			}
			&:hover{

				background: $main_theme_color;
				border: 1px solid $main_theme_color;
				a{
					color: #fff;
				}

			}
		}
		li:last-child{
			border: 1px solid #ededed;
			padding: 5px 25px;;
			border-radius: 5px;
			a{
				color:$main_theme_color;
			}
			&:hover{

				background: $main_theme_color;
				border: 1px solid $main_theme_color;

				a{
					color: #fff;
				}

			}
		}

	}
}

.widget{
	h3{
		font-size: 1rem;
		width: 100%;
		display: block;
		padding-bottom: 18px;
		border-bottom: 1px solid #e3eaef;
		color: #383838;
		position: relative;
		margin-bottom: 35px;
		span{
			color: inherit;
		}
		&:after{
			content: '';
			position: absolute;
			left: 9px;
			top: 38px;
			background: $main_theme_color;
			width: 40px;
			height: 5px;
		}
	}
}
.search-submit {
	border: none;
	background: no-repeat;
	font-size: 19px;
	padding: 0;
	float: right;
	margin-top: 5px;
	padding: 0 12px;
	color: $main_theme_color;
}
.search-field {
	border: none;
	width: 87%;
	font-size:14px;
}
.search-form {
	border-bottom: 1px dotted #ddd;
}
.widget {
	padding-right: 1.33rem;
	margin-bottom: 4.2rem;
	ul{
		padding:0;
	}
}

.widget_single_post{
	display:block;

	.post_thumb{
		float:left;
		width:100px;
		float:left;
		width:25%;

	}
	.wid_post_content{
		width: 75%;
		overflow: hidden;
		padding-left: 1rem;
		padding-top: 4px;
		h6{
			font-size: 14px;
			a{
				color: $text_main;
				padding: 0;
				&:hover{
					color: $main_theme_color;
				}
			}
		}
		.post_att{
			font-size: 14px;
			line-height: 14px;
			font-weight: 500;
			i{
				color: $main_theme_color;

			}
			span{
				color: $main_theme_color;
			}
		}
	}
}
.widget_post {
	h3 {
		margin-bottom: 40px;
	}
	ul{
		li{
			overflow: hidden;
			border-top: 1px dotted #ddd;
			border-bottom: 1px dotted #ddd;
			padding: 22px 0px;

		}
		li:first-child{
			border-top: none;
			padding-top:0;
			border-bottom:none;

		}
		li:last-child{
			border-top: none;
			padding-bottom:0;
			border-bottom:none;

		}
	}

}
.widget_category{
	h3 {
		margin-bottom: 35px;
	}
	ul{
		margin-bottom: 0;
		li{
			width:100%;
			border-bottom: 1px dotted #ddd;
			padding:10px 0;
			a{
				font-size: 16px;
				color: $text_main;
				font-weight: 500;
				padding:0;
				&:hover{
					color: $main_theme_color;
				}
			}
			span{
				float:right;
				font-size: 16px;
				color: $main_theme_color;
				padding:0;
			}
		}
		li:last-child{
			border-bottom: none;
			padding-bottom:0;
		}
		li:first-child{
			padding-top:0;
		}
	}
}

.widget_archive{
	ul{
		margin-bottom: 0;
		li{
			width:100%;
			border-bottom: 1px dotted #ddd;
			padding: 10px 0;
			a{
				font-size: 16px;
				color: $text_main;
				font-weight: 500;
				padding: 0;
				&:hover{
					color: $main_theme_color;
				}
			}
			span{
				float:right;
				font-size: 16px;
				color: $main_theme_color;
				padding:0px;
			}
		}
		li:last-child{
			border-bottom: none;
			padding-bottom: 0;
		}
		li:first-child{
			padding-top: 0;
		}
	}
}

.widget_tags{
	h3 {
		margin-bottom: 40px;
	}
	ul{
		li{
			display:inline-block;
			margin-right:10px;
			margin-bottom:10px;
			a{
				font-size: 16px;
				color: #737373;
				font-weight: 500;
				border: 1px solid #ddd;
				border-radius: 0;
				padding: 0px 11px;
				text-transform: uppercase;
				font-family: "Hind", sans-serif;
				line-height: 45px;
				height: 45px;
				transition: all 0.3s;
				&:hover{
					color: $main_theme_color;
					border: 1px solid $main_theme_color;
				}
			}

		}
	}
}

.widget_newsletter{
	text-align: left;
	input[type="text"]{
		border:1px solid #f1f1f1;
		background:#fbfbfb;
		color:#b4b4b4;
		width:100%;
		height: 47px;
		padding-left:10px;

	}

	input[type="submit"]{
		border: none;
		border-radius:0;
		cursor: pointer;
		color:#fff;
		font-size:16px;
	}
	.sidebar_newsletter{
		text-align: left;
		border: 1px solid #ddd;
		border-top: none;
		padding: 40px 25px;
		.btn_wrapper{
			margin:22px auto 0;
		}
		p{
			font-size:1rem;
			color:$text_body_main;
			margin-top:23px;
		}
	}
}
.widget.widget_newsletter h3 {
	font-size: 1rem;
	width: 100%;
	display: block;
	padding-bottom: 18px;
	border-bottom: 1px solid #e3eaef;
	color: #383838;
	position: relative;
	margin-bottom: 0;
}

/*--------------------------------------------------------------
  ##  Blog details
  --------------------------------------------------------------*/
  .side_bar {
  	padding-left: 20px;
  	.widget{
  		background: #f8f8f8;
  		padding: 30px;

		ul {
			margin-bottom: 0;
		}

		&.widget_category {
			padding-bottom: 25px;
		}

		&.widget_archive {
			padding-bottom: 25px;
		}
		&.widget_tags {
			padding-bottom: 20px;
		}
  	}
  }

  .comments-title {
  	font-size: 24px;
  	color: #383838;
  	position: relative;
  	width: 100%;
  	padding-bottom: 8px;
  	border-bottom: 1px solid #ddd;
  	margin-bottom: 100px;

  	&::after {
  		content: '';
  		position: absolute;
  		left: 9px;
  		top: 38px;
  		background: $main_theme_color;
  		width: 40px;
  		height: 5px;
  		z-index: 99;
  	}
  }
  .sin_post_tag{
  	span{
  		float:left;
  		font-weight:500;
  		i{
  			color: $main_theme_color;
  		}
  	}
  	ul{
  		display: block;
  		padding: 0px;
  		overflow: hidden;
  		li{
  			display:inline;
  			margin-left:22px;
  			a{
  				padding:0;
  				color:#6b6b6b;
  				font-size:16px;
  			}
  		}
  	}
  }

  .comment-list, .comment-list {
  	list-style: outside none none;
  }

  .comment-list {
  	margin-bottom: 25px;
  	padding-bottom: 25px;
  	padding-left: 0px;
  }

  .comment-list div.avatar {
  	float: left;
  	margin-right: 17px;
  	overflow: hidden;
  	display: block;
  	border-radius: 50px;
  }

  .comment-list .comment-body {
  	overflow: hidden;
  	position:relative;
  }

  .children-comment {
  	padding-left: 70px;
  }

  .com-name {
  	color: #7b7b7b;
  	font-size: 12px;
  	font-weight: normal;
  	margin-bottom: 0;
  }

  .comment-content {

  	font-weight: 400;
  	p{
  		color: #9e9c9c;
  		font-size:16px;
  		padding-top:15px;
  		line-height:28px;
  	}
  }

  .comment-author b a {
  	font-size: 16px;
  	padding: 0;
  	color: #383838;
  	padding-bottom:5px;
  	text-transform:uppercase;
  }
  .comment-author img {
  	float: left;
  	border: 2px solid #fff;
  	-webkit-box-shadow: 0px 0px 7.28px 0.72px rgba(72, 72, 72, 0.14);
  	box-shadow: 0px 0px 7.28px 0.72px rgba(72, 72, 72, 0.14);
  	margin-right: 25px;
  }

  .comment-metadata {
  	line-height: 0;
  	a{
  		padding:0;
  		line-height: 0;
  		color:#6d6d6d;
  		font-size:14px;
  		font-family:$font_heading;
  		font-weight:500;
  	}
  	span{
  		color: $main_theme_color;

  	}
  }
  .comment-body .reply {

  	a{

  	}
  	i{
  		margin-right: 5px;
  		font-size: 14px;
  	}
  }
  .children {
  	padding-left: 100px;
  }

  .comment-reply-title{
  	font-size:1.34rem;
  	position:relative;
  	padding-bottom: 8px;
  	border-bottom: 1px solid #ddd;
  	margin-bottom: 100px;
  	&:after{
  		content: '';
  		position: absolute;
  		left: 9px;
  		top: 35px;
  		background: $main_theme_color;
  		width: 40px;
  		height: 5px;
  		z-index: 99;
  	}
  }

  .comment-form{
  	input{


  	}
  }

  .comment-form .form-control:focus {
  	color: #495057;
  	background-color: #fff;
  	border-color: #d0d0d0;
  	outline: 0;
  	box-shadow: none;
  }
  .comment-form .form-control{
  	padding: 10px 15px;
  	border-color:#d0d0d0;
  	border-radius:3px;

  }
  .form-control::placeholder {
  	color: #c6c6c6;
  	font-size:14px;
  	font-weight:300;
  }

 //  .form-submit input[type="submit"] {
	// font-size: 16px;
	// border: 2px solid $main_theme_color;
	// color: #fff;
	// padding: 14px 31px;
	// text-transform: uppercase;
	// font-weight: 400;
	// cursor: pointer;
	// background: transparent;
	// color: $main_theme_color;
	// font-weight: 700;

 //  	&:hover{
 //  		background: $main_theme_color;
 //  		@include transition(all 0.3s);
 //  		color: #fff;
 //  	}
 //  }

  .sin_post_tag {
  	margin-bottom: 90px;
  }
  .post_details{
  	margin-bottom: 20px;
  }

  .widget_search .search-field {
	background: transparent;
}
p.form-submit {
	margin-bottom: 0;
}
.page_content_two.section_padding_two {
	padding: 115px 0;
}
.comment-form .form-control {
	resize: none;
}

.side_bar .widget:last-child {
	margin-bottom: 0;
}
.wid_post_content h6 {
	text-transform: capitalize;
	font-weight: 600;
}