.home5_pricing {
	padding-bottom: 140px;
}

.home5_pricing_single {
	background: #f5f5f5;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 83px 0 72px;
	padding-left: 89px;
	padding-right: 0;
	position: relative;

	i {
		position: absolute;
		bottom: 0;
		right: 0;
		width: 68px;
		height: 68px;
		text-align: center;
		line-height: 66px;
		border-top: 3px solid #fff;
		border-left: 3px solid #fff;
		color: $home6_primary3;
		font-size: 28px;
		transition: 0.5s;
	}

	h3 {
		font-size:24px;
		font-weight: 600;
		color: $home6_primary2;
		letter-spacing: 2.5px;
		margin-bottom: 0;
		position: relative;
		z-index: 1;
		transition: 0.5s;
		margin-bottom: 5px;

		&:after {
			position: absolute;
			left: 7px;
			top: -19px;
			width: 10px;
			height: 67px;
			background: #fff;
			content: "";
			transform: rotate(45deg);
			z-index: -2;
		}
	}

	span {
		font-size: 14px;
		color: $text_inner74;
		font-weight: 500;
		letter-spacing: 1.8px;
	}

	&:hover {

		h3 {
			color: $home5_black;
		}

		i {
			color: $home6_primary;
		}
	}
}

@media (max-width: 575px) {
	.home5_pricing_single span {
		font-size: 14px;
	}
}