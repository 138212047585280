.feature_benifit_area {
	font-family: $font_primary;
	padding-bottom: 120px;

	p,h1,h2,h3,h4,h5,h6,a {
		font-family: $font_primary;
	}

	.card {
		border: 0;
		padding: 0 45px;
		width: 100%;
		margin-bottom:20px;
		position: relative;

		&.active {
			border: 0px;
			padding: 50px 47px;
			border:1px solid #f4f4f4;
			box-shadow: 0px 6px 10px rgba(23,23,118,0.08);
			width: 100%;
			margin-bottom: 35px;

			&:after {
				position: absolute;
				content: "";
				right: -6px;
				top: calc(50% - 6px);
				width: 12px;
				height: 12px;
				background: #fff;
				transform: rotate(45deg);
				border-right: 1px solid #f4f4f4;
				border-top: 1px solid #f4f4f4;

			}
		}

		.card-header {
			padding: 0;
			margin-bottom: 0;
			background-color: transparent;
			border-bottom: 0;
			cursor: pointer;
		}

		.card-body {
			font-size: 16px;
			color: #4f4f4f;
			font-weight: 400;
			line-height: 26px;
			padding: 0 26px;
			letter-spacing:0.3px;
			padding-top: 12px;
		}
	}

	.accordion_btn {
		padding-left: 45px;
	}
}

.card-header {

	h5 {
		position: relative;
		font-size: 18px;
		color: #3734f0;
		font-weight: 500;
		padding-left: 25px;
		text-transform: capitalize;
		margin-bottom: 0px;
		transition: 0.5s;

		&:before {
			position: absolute;
			content: "\f46b";
			font-family: 'Font Awesome\ 5 Free';
			font-weight: 700;
			font-size: 16px;
			left: 0;
			transition: 0.5s;
		}

		&.collapsed {
			color: #4f4f4f;
			margin-bottom: 0;
			transition: 0.5s;

			&:before {
				content: "\f058";
				color: #cfcfcf;
				transition: 0.5s;
			}
		}
	}
}
.accordion_btn {

	a {
		font-size: 16px;
		color: #cf3815;
		text-transform: uppercase;
		letter-spacing: 1.8px;
		padding: 0;
		display: block;
		width: 100%;

		i {
			font-size: 21px;
			vertical-align: middle;
			color: #cf3815;
			margin-left: 10px;
			margin-top: -4px;
			transition: 0.5s;
		}

		&:hover i {
			transform: rotate(-180deg);
		}
	}
}

.tab-content {
	margin-left: 47px;
}

.feature_benifit_area .fade {
	opacity: 0;
	transform: scaleX(0);
	transition: 0.3s;
}
.feature_benifit_area .fade.show {
	opacity: 1;
    transform: scaleX(1);
}
.collapse {
	// -webkit-transition: 1.5s;
	// -o-transition: 1.5s;
	// transition: 1.5s;

	&.show {
	}
}
.feature_benifit_area #accordion {
	margin-bottom: 27px;
}