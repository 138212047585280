.header_area {
    position: relative;
    z-index: 9;
    background: $text_white;
    box-shadow: 0px 0px 7.28px 0.72px rgba(72, 72, 72, 0.08);
    -webkit-box-shadow: 0px 0px 7.28px 0.72px rgba(72, 72, 72, 0.08);
    -moz-box-shadow: 0px 0px 7.28px 0.72px rgba(72, 72, 72, 0.8);

}
.header_nav {
	max-height: 80px;
}
.header_logo .svg {
    width: 116px;
    padding: 35px 0 0;

    .st1,
    .st0 {
        fill: $main_theme_color;
        stroke: $main_theme_color;
    }
}

#mobile-logo .svg {
    width: 90px;

    .st1,
    .st0 {
        fill: $main_theme_color;
        stroke: $main_theme_color;
    }
}

.mobile_accor_logo .svg {
    width: 116px;

    .st1,
    .st0 {
        fill: $main_theme_color;
        stroke: $main_theme_color;
    }
}

.top_toolbar {
    background: $toolbar_bg;
    height: 2.5rem;
    transition: 0.5s;
}
.toolbar_left {

    ul {
        padding-left: 0;
        margin-bottom: 0;

        li {
            display: inline-block;
            font-size: 0.9rem;
            font-weight: 400;
            color: #e5e2e2;
            padding: 0 1.39rem;
            position: relative;
            line-height: 2.50rem;

            &:last-child {
                float: right;
                padding-right: 0;
            }

            span {
                color: $main_theme_color;
                font-size: 0.9rem;
                margin-right: 5px;

                &.fa {
                    margin-right: 0.28rem;
                }
            }

            a {
                color: $toolbar_color;
                padding: 0;
                font-size: 0.9rem;
                text-transform: capitalize;

                &:hover {
                    color: $main_theme_color;
                }
            }

            &:first-child {
                padding-left: 0;

                &:after {
                    position: absolute;
                    content: "";
                    right: 0;
                    top: 50%;
                    width: 0.06rem;
                    height: 0.84rem;
                    background: #514e4e;
                    -webkit-transform: translateY(-50%);
                    -ms-transform: translateY(-50%);
                    transform: translateY(-50%);
                }
            }
        }
    }
}

.submenuItems i {
    float: left;
    font-size: 9px;
    margin-top: 3px;
    padding-right: 6px;
}

.phone_number {
    width: 52%;
    float: left;
    text-align: left;
    line-height: 36px;

    span {
        color: $main_theme_color;
        font-size: 0.89rem;
        margin-right: 5px;
    }

    a {
        color: #666;
        padding: 0;
        font-size: 0.89rem;
    }
}

.header_nav {
	margin-top: 0;
	transition: 0.5s;

    &.fixed {
        position: fixed;
        top: 0;
        -webkit-animation: headerFixedTop .35s;
        animation: headerFixedTop .35s;
        width: 100%;
        z-index: 9999;
        background: #fff;
        @include box-shadow(0, 0px, 5px, rgba(0, 0, 0, 0.2));
    }
}
.hdr_btn_wrapper {
	padding: 20px 0;
}
@keyframes headerFixedTop {
    0% {
        opacity: 0;
        transform: translateY(-100%);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.header_logo a {
    padding: 0;

    .logo-white {
    	display: none;
    }
}

.main_menu {
    font-family: $font_primary;
    padding: 18px 0;

    > ul {
        margin-bottom: 0;

        > li {
            display: inline-block;
            position: relative;

            > a {
                font-size: 1rem;
                text-transform: uppercase;
                color: #363636;
                font-weight: 500;
                @include rounded(0);
                line-height: 1rem;
                display: block;
                padding: 14px 28px;

                &.current_page_item {
	                color: $main_theme_color;
	            }

	            &:hover {
	                color: $main_theme_color;
	            }
            }
        }
    }
}

.Switcher__control {
    top: -1px;
    border-radius: 0;
    width: 40px;
    height: 40px;
    margin-left: -40px;

    i {
        color: $main_theme_color;
    }
}

.Switcher {
    top: 142px;
}

.headroom--not-top .header_nav {
    height: 5rem;
}

.headroom--not-top .header_nav .main_menu {
    padding: 1.3rem 0 1.3rem;
}

.headroom--not-top .header_nav .header_logo .svg {
    padding: 23px 0 0;
}

.headroom--not-top .header_nav .btn_wrapper {
    margin: 24px 0;
    height: 2.2rem;

    .btn_one {
        line-height: 1.2rem;
    }
}

.headroom--pinned {
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
    position: fixed;
    -webkit-animation-name: headerSlideDown;
    animation-name: headerSlideDown;
}

.header-area {
    background: #fff;
}

#header {
    z-index: 999;
    position: fixed;
    width: 100%;
    left: 0;
}

.headroom--pinned {
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
    position: fixed;
    -webkit-animation-name: headerSlideDown;
    animation-name: headerSlideDown;
}

.headroom {
    -webkit-transition: -webkit-transform 200ms linear;
    transition: -webkit-transform 200ms linear;
    -o-transition: transform 200ms linear;
    transition: transform 200ms linear;
    transition: transform 200ms linear, -webkit-transform 200ms linear;
    -webkit-animation-duration: .5s;
    animation-duration: .5s;
}

.headroom--unpinned {
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
    position: fixed;
}

@-webkit-keyframes headerSlideDown {
    0% {
        margin-top: -150px;
    }

    100% {
        margin-top: 0;
    }
}

.animated.slideUp {
    -webkit-animation-name: slideUp;
    animation-name: slideUp;
}

.hide-topbar .top_toolbar {
    transform: scaleY(0);
    transform-origin: top left;
}

.hide-topbar .header_nav {
	margin-top: -45px;
}
/*--------------------------------------------------------------
  ##  Header
  --------------------------------------------------------------*/
.sub-menu {
	position: absolute;
	z-index: 9;
	background-color: #fff;
	width: 220px;
	-webkit-box-shadow: -0.764px 0.804px 7px 0px rgba(102, 101, 101, 0.22);
	box-shadow: -0.764px 0.804px 7px 0px rgba(102, 101, 101, 0.22);
	top: calc(100% + 30px);
	visibility: hidden;
	opacity: 0;
	-webkit-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
	padding: 0;
	margin: 0;
	-webkit-box-shadow: 0 11px 21px rgba(0, 0, 0, 0.25);
	box-shadow: 0 11px 21px rgba(0, 0, 0, 0.25);
	padding: 26px;
	left: calc(50% - 110px);
	top: calc(100% + 25px);

	&:after {
		position: absolute;
		content: "";
		left: calc(50% - 6px);
		top: -5px;
		width: 10px;
		height: 10px;
		background: #fff;
		z-index: -2;
		transform: rotate(45deg);
		border-radius:2px;
	}
}

.sub-menu li {
    width: 100%;
}

.sub-menu li a {
	padding: 7px 0px;
	width: 100%;
	display: block;
	color: $home6_primary2;
	font-size: .88rem;
	font-weight: 400;
	border-radius: 0;
	text-transform: uppercase;
	position: relative;
	-o-transition: all 0.3s;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
	font-size: 14px;
	font-weight: 500;
	border-bottom: 1px solid #ededed;
}

.sub-menu li:first-child a {
	padding-top: 0;
}
.sub-menu li a i {
    margin-right: 10px;
    font-size: 8px;
    position: absolute;
    left: 11px;
    top: 6px;
}

.main_menu li:hover .sub-menu {
	visibility: visible;
	opacity: 1;
	-webkit-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
	left: calc(50% - 110px);
}

.main_menu .sub-menu a:hover,
.main_menu .sub-menu a.current_page_item {
    @include transition(all .3s);
    color: $main_theme_color;
}

/*--------------------------------------------------------------
  ##  Header
  --------------------------------------------------------------*/
.header_logo .svg {
    width: 116px;
    padding: 18px 0;
}

.header_logo .svg .st1,
.header_logo .svg .st0 {
    fill: $main_theme_color;
    stroke: $main_theme_color;
}

#mobile-logo .svg {
    width: 90px;
}

#mobile-logo .svg .st1,
#mobile-logo .svg .st0 {
    fill: $main_theme_color;
    stroke: $main_theme_color;
}

.mobile_accor_logo .svg {
    width: 116px;
}

.mobile_accor_logo .svg .st1,
.mobile_accor_logo .svg .st0 {
    fill: $main_theme_color;
    stroke: $main_theme_color;
}

.top_toolbar {
    background: #363636;
    height: 2.75rem;
}

.toolbar_left ul {
    padding-left: 0;
    margin-bottom: 0;
}

.toolbar_left ul li {
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    color: #e5e2e2;
    padding: 0 1.39rem;
    position: relative;
    line-height: 2.75rem;
}

.toolbar_left ul li:last-child {
    float: right;
    padding-right: 0;
}

.toolbar_left ul li span {
    color: $main_theme_color;
    font-size: 14px;
    margin-right: 5px;
}

.toolbar_left ul li span.fa {
    margin-right: 0.28rem;
}

.toolbar_left ul li a {
    color: #e5e2e2;
    padding: 0;
    font-size: 14px;
    text-transform: capitalize;
}

.toolbar_left ul li a:hover {
    color: $main_theme_color;
}

.toolbar_left ul li:first-child {
    padding-left: 0;
}

.toolbar_left ul li:first-child:after {
    position: absolute;
    content: "";
    right: 0;
    top: 50%;
    width: 0.06rem;
    height: 0.84rem;
    background: #514e4e;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.submenuItems i {
    float: left;
    font-size: 9px;
    margin-top: 3px;
    padding-right: 6px;
}

.phone_number {
    width: 52%;
    float: left;
    text-align: left;
    line-height: 36px;
}

.phone_number span {
    color: $main_theme_color;
    font-size: 0.89rem;
    margin-right: 5px;
}

.phone_number a {
    color: #666;
    padding: 0;
    font-size: 0.89rem;
}

.header_nav.fixed {
    position: fixed;
    top: 0;
    -webkit-animation: headerFixedTop .35s;
    animation: headerFixedTop .35s;
    width: 100%;
    z-index: 9999;
    background: #fff;
    -webkit-box-shadow: 0 0px 5px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0px 5px rgba(0, 0, 0, 0.2);
}

@keyframes headerFixedTop {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

.Switcher__control {
    top: -1px;
    border-radius: 0;
    width: 40px;
    height: 40px;
    margin-left: -40px;
}

.Switcher__control i {
    color: $main_theme_color;
}

.Switcher::before, .Switcher__control {
	position: absolute;
	left: calc(100% + 40px);
	background-color: #fff;
}
.Switcher {
	top: 124px;
	left: 0px;
	width: 208px;
	margin-left: -208px;
	transition:0.5s;
}
.Switcher.Switcher--show {
	margin-left: 0;
}
.Switcher::before {
	display: none;
}
.headroom--pinned {
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
    position: fixed;
    -webkit-animation-name: headerSlideDown;
    animation-name: headerSlideDown;
}

.header-area {
    background: #fff;
}

#header {
    z-index: 999;
    position: fixed;
    width: 100%;
    left: 0;
}

.headroom--pinned {
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
    position: fixed;
    -webkit-animation-name: headerSlideDown;
    animation-name: headerSlideDown;
}

.headroom {
    -webkit-transition: -webkit-transform 200ms linear;
    transition: -webkit-transform 200ms linear;
    -o-transition: transform 200ms linear;
    transition: transform 200ms linear;
    transition: transform 200ms linear, -webkit-transform 200ms linear;
    -webkit-animation-duration: .5s;
    animation-duration: .5s;
}

.headroom--unpinned {
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
    position: fixed;
}

@-webkit-keyframes headerSlideDown {
    0% {
        margin-top: -150px;
    }
    100% {
        margin-top: 0;
    }
}

.animated.slideUp {
    -webkit-animation-name: slideUp;
    animation-name: slideUp;
}

// .hide-topbar .top-bar {
//     display: none;
// }

// .hide-topbar .top_toolbar {
//     display: none;
// }

/*--------------------------------------------------------------
  ##  Header
  --------------------------------------------------------------*/
