.footer_five_area {
	background: url(../../media/images/home6/footer-bg.png) no-repeat scroll 0 0/cover;
	position: relative;
	z-index: 1;

	.widget.widget5 {
		width: 17%;
		margin-bottom: 0;
		padding-right: 0;

		&:last-child {
			width:32%;
		}
		.widget_gmail {
			padding: 0;
			margin-top: 20px;
			color: #7474BA;
			font-size: 16px;

			i {
				margin-right: 5px;
				color: #fff;
				font-size: 13px;
			}

			&:hover {
				color: #fff;
			}
		}
		.widget5_about {
			padding-right: 80px;
			margin-top: -11px;

			p {
				font-weight: 300;
				line-height: 28px;
				letter-spacing: 0.4px;
				text-align: left;
				color: #b0b0b0;

				span {
					color: #fff;
				}

				b {
					font-weight: normal;
				}
			}
		}

		.widget-logo {
			margin-bottom: 37px;

			a {
				padding: 0;
				width: 35%;
			}
		}
	}

	.footer_five_top_flex {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-line-pack: justify;
		justify-content: space-between;

		.footer_five_top_left a {
			padding: 0;
			line-height: 45px;
		}

		.footer_five_top_right {

			label {
			height: 50px;
			background: #353535;
			line-height: 50px;
			padding: 0px 20px;
			position: relative;
			margin-bottom: 0;
			vertical-align: middle;
			padding-right: 46px;
			z-index: 1;

				&:after {
					position: absolute;
					content: "";
					left: 0px;
					width: 50px;
					height: 50px;
					background: #353535;
					top: 0;
					-webkit-transform: skewX(-45deg);
					-ms-transform: skewX(-45deg);
					transform: skewX(-45deg);
					-webkit-transform-origin: top right;
					-ms-transform-origin: top right;
					transform-origin: top right;
					z-index: -1;
				}

				i {
					font-size: 10px;
					color: $text_inner74;
					margin-right: 10px;
				}

				span {
					font-size: 14px;
					color: $text_white;
					font-weight: 500;
					text-transform: uppercase;
					letter-spacing: 1.7px;
				}
			}

			input {
				height: 50px;
				border: none;
				border-radius: 0;
				padding: 0px 40px;
				margin-left: -4px;
				background: #2b2b2b;
				font-size: 14px;
				font-weight: 300;
				color: $text_white;
				vertical-align: middle;
				width: 356px;
				letter-spacing: 0.25px;
			}

			button {
				width: 50px;
				height: 50px;
				vertical-align: middle;
				border-radius: 0;
				background: $text_white;
				border: none;
				margin-left: -3px;
				color: $text_inner74;
				font-size: 20px;
				padding: 0;
				cursor: pointer;
				transition: 0.3s;

				&:focus, &:hover {
					background: $home6_primary;
					color: #fff;
				}

				i {
					font-size: 20px;
					line-height: 50px;
					margin-right: -3px;
				}
			}
		}

		.footer_five_top_left {

			h3 {
				font-size: 32px;
				margin-bottom: 0;
				color: #fff;
				line-height: 50px;
				text-transform: capitalize;
				font-weight: 600;
				text-transform: uppercase;
				letter-spacing: 2.5px;
				font-family: $font_heading;


				span {
					color: $home6_primary;
				}
			}
		}
	}

	.footer_five_bottom_flex {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 30px 0px;
		position: relative;
		border-top: 1px solid #313131;

		.backtotop {
			position: absolute;
			right: 0;
			top: 53%;
			cursor: pointer;
			transform: translateY(-50%);
			line-height: normal;

			i {
				color: #fff;
			}
		}
	}

	.footer_four_bottom_left p {
		margin-bottom: 0;
		padding: 0;
		font-size: 14px;
		color: #b0b0b0;
		letter-spacing: 0.4px;

		&:hover {

			a {
				color: $home6_primary;
			}
		}

		a {
			padding: 0;
			font-size: 14px;
			color: #d6d6d6;
			font-weight: 500;
		}
	}

	.footer_four_bottom_right {
		margin-right: 30px;

		a {
			padding: 0px 9px;
			transition: 0.3s;

			img {
				opacity: 0.3;
			}

			&:hover img {
				opacity: 0.7;
			}
		}
	}

	.footer_five_middle_flex {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
	}

	.widget5_social {
		margin-top: 32px;

		a {
			width: 37px;
			height: 37px;
			padding: 0;
			line-height: 35px;
			background: #fff;
			border-radius: 50%;
			text-align: center;
			background: #353535;
			margin-right: 10px;

			i {
				font-size: 10px;
				color: #fff;
			}

			&:hover {
				background: $home6_primary;
			}
		}
	}

	.widget5_title {

		h3 {
			font-size: 20px;
			font-weight: 500;
			font-style: normal;
			font-stretch: normal;
			line-height: 20px;
			letter-spacing: 2.4px;
			text-align: left;
			color: $home6_primary;
			border-bottom: 0px;
			margin-bottom: 14px;
			transition: 0.3s;

			&:after {
				display: none;
			}
		}
	}

	.widget5_desc a {
		padding: 0;
		font-size: 16px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.75;
		letter-spacing: 0.4px;
		text-align: left;
		color: $footer_common;
		display: block;
		transition: 0.3s;
		margin-bottom: 7px;

		&:last-child {
			margin-bottom: 0;
		}

		i {
			transition: 0.3s;
		}

		&:hover {
			color: #fff;
			margin-left: 6px;

			i {
				color: $home6_primary;
			}
		}
	}
}


#twitter_feed {
	position: relative;
	padding-top: 0px;

	ul {
		margin-bottom: 0;
	}

	.user {
		padding-left: 0;

		a, div{
			padding: 0;
			display: inline-block;
			vertical-align: top;
			color: #fff;
			margin-bottom: 10px;
			font-size: 16px;
			line-height: normal;
		}

		> div {
			padding-left: 16px;
		}

		> a {
			width: 42px;
			height: 42px;
			margin-top: 5px;
		}
	}

	.tweet {
		color: $footer_common;
		font-size: 16px;
		position: relative;
		left: 62px;
		top: -27px;
		margin-right: 62px;

		a {
			color: $text_inner74;
			padding: 0;
			display: block;
			word-wrap: break-word;
		}
	}

	.interact {
		display: none;
	}
}

.widget.widget5:hover {

	.widget5_title {

		h3 {
			color: $home6_primary;
		}
	}
}

#twitter_feed li {
	margin-bottom: 0px;
	position: relative;
}

.footer_five_top {
	padding: 77px 0;
	position: relative;
	z-index: 1;

	&:before {
		position: absolute;
		content: "";
		right: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: rgba(0,0,0,0.15);
		z-index: -2;
	}

	&:after {
		position: absolute;
		content: "";
		left: -104px;
		top: 0;
		width: calc(38% + 104px);
		height: 100%;
		background: rgba(0,0,0,0.15);
		transform: skewX(-45deg);
		z-index: -1;
	}
}

.footer_five_middle {
	padding: 90px 0 60px;
}

@media (min-width: 1500px) and (max-width: 1600px) {
	.footer_five_area .footer_five_top_flex .footer_five_top_left h3 {
		font-size: 23px;
	}
	.home_five_single_service {
		width: 31.6%;
	}
}

@media (min-width: 1200px) and (max-width: 1500px) {
	.footer_five_area .widget5_desc a {
		margin-bottom: 10px;
	}
	.footer_five_area .footer_five_top .footer_five_top_right input {
		width: 266px;
	}
	.footer_five_area .footer_five_top_flex {
		justify-content: center;
	}
	.footer_five_top_left {
		display: none;
	}
	.footer_five_area .widget5_title h3 {
		font-size: 17px;
	}
}

@media (min-width: 992px) and (max-width: 1199px) {
	.footer_five_top_right {
		text-align: right;
	}
	.footer_five_area .footer_five_top .footer_five_top_right input {
		width: 245px;
	}

	.footer_five_area .footer_five_bottom .backtotop {
		top: 52%;
	}
	.footer_five_area .footer_five_top_flex {
		justify-content: center;
	}
	.footer_five_top_left {
		display: none;
	}
	.footer_five_area .footer_five_top_flex .footer_five_top_right label::after {
		display: none;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.footer_five_area .footer_five_top_flex .footer_five_top_left h3 {
		font-size: 25px;
	}
}
@media (min-width: 576px) and (max-width: 767px) {
	.footer_five_top_right {
		width: 100%;
	}
	.footer_five_area .footer_five_middle_flex {
		flex-wrap: wrap;
	}
	.footer_five_area .footer_five_bottom_flex {
		flex-wrap: wrap-reverse;
	}
}

@media only screen and (max-width: 767px) {
	.footer_five_area .footer_five_top .footer_five_top_right label::after {
		display: none;
	}

	.footer_five_area .footer_five_top .footer_five_top_right input {
		margin-left: 0px;
		width: calc(100% - 52px);
	}

	.footer_five_top_left {
		margin-bottom: 20px;
	}

	.backtotop {
	    margin-bottom: 13px;
	}
	.footer_five_area .footer_four_bottom_right a {
	    padding: 0px 10px;
	    margin-bottom: 5px;
	}
	.footer_four_bottom_left {
		margin-top: 16px;
	}
	.footer_five_top_right label {
		display: none;
	}
}

@media (max-width: 575px) {
	.footer_five_area .footer_five_bottom {
		padding: 50px 0px 40px;
	}
	.footer_five_top::after {
		display: none;
	}
	.footer_five_area .footer_five_top_flex .footer_five_top_left h3 {
		font-size: 23px;
		line-height: normal;
	}
}