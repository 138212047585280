.ingredeint_section {
  padding-right: 20px;
}

.ingredeint_section .nav-tabs {
  border-bottom: 0px solid #dee2e6;
}

.gredient_tab {
	padding: 0;
	margin: -10px auto 0;
}
.gredient_tab li {
	text-align: center;
	padding: 5px 0;
	margin: 10px 0;
	position: relative;
	background: #f8f8f8;
}
.slick-slide img {
	max-height: 100%;
}
.gredient_tab .slick-slide img {
  display: inline;
}

.slick_slider_two {
  padding: 0px;
}

.gredient_tab .slick-prev::before {
  content: '\f107';
  font-family: 'Font Awesome 5 Free';
  font-size: 20px;
  font-weight: 900;
  line-height: 1;
  opacity: .75;
  color: $main_theme_color;
}

.gredient_tab .slick-next::before {
  content: '\f106';
  font-family: 'Font Awesome 5 Free';
  font-size: 20px;
  font-weight: 900;
  line-height: 1;
  opacity: .75;
  color: $main_theme_color;
}

.gredient_tab .slick-next.slick-arrow {
	display: none !important;
}

.gredient_tab .slick-prev.slick-arrow {
	display: none !important;;
}

.ingredient_content h5 {
	position: relative;
	margin-bottom: 23px;
	font-size: 36px;
}

.ingredient_content .mini-des {
  padding-bottom: 30px;
  border-bottom: 1px solid #e8f0f6;
  margin-bottom: 40px;
}

.ingredient_content .mini-des p {
  font-size: .88rem;
  font-weight: 600;
}

.ingredient_content .mini-des ul {
  padding-left: 0;
}

.ingredient_content .mini-des ul li {
  font-size: 14px;
  margin-bottom: 10px;
}

.ingredient_content > p {
  font-size: 1rem;
}

.ingredient_content_heading h5 {
  position: relative;
  margin-bottom: 60px;
}

.ingredient_content_heading h5:after {
  content: '';
  width: 30px;
  height: 3px;
  background: $main_theme_color;
  position: absolute;
  left: 0;
  top: 40px;
}

.ingridient_list {
  margin-top: 50px;
}

.ingridient_list h6 {
  font-size: .88rem;
  margin-bottom: 30px;
}

.ingridient_list h6 span {
  color: inherit;
}

.ingridient_list table {
  width: 100%;
  margin-bottom: 0;
}

.ingridient_list table tr {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  font-size: .88rem;
  padding: 9px 0px;
}

.ingridient_list table tr td {
  padding: 9px 0;
}

.ingredient-img {
	position: relative;
	width: 99%;
	text-align: center;
	padding: 100px 0;
	height: 100%;
	background: #f8f8f8;
}
.slick_slider_two.slick-initialized.slick-slider {
	margin-bottom: 0;
}
.ingredeint_section_one .ingredient-img img {
	display: inline-block;
	position: relative;
	top: 50%;
	transform: translateY(-50%);
}

.link_overlay {
  visibility: hidden;
  opacity: 0;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.link_overlay::after {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border: 2px solid $main_theme_color;
  content: '';
  opacity: .8;
  z-index: 0;
  background: transparent !important;
}
.toolbar_left ul li span.flat {
  font-size: 1rem;
  vertical-align: middle;
}
.link_overlay a {
  color: #fff;
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.slick-current .link_overlay {
  visibility: visible;
  opacity: 1;
}

.slick-slide:hover .link_overlay {
  visibility: visible;
  opacity: 1;
}

.slick_slider_two .slick-prev::before {
  content: '\f104';
  font-family: 'Font Awesome 5 Free';
  font-size: 20px;
  font-weight: 900;
  line-height: 1;
  opacity: .75;
  color: $main_theme_color;
}

.slick_slider_two .slick-next::before {
  content: '\f105';
  font-family: 'Font Awesome 5 Free';
  font-size: 20px;
  font-weight: 900;
  line-height: 1;
  opacity: .75;
  color: $main_theme_color;
}

.slick_slider_two .slick-next.slick-arrow {
  position: absolute;
  top: 285px;
  left: 79%;
  margin-left: -24px;
  /* 	background-image: url(../../media/images/arrow_right.png); */
  border-radius: 50px;
  padding: 0;
  height: 64px;
  width: 44px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.slick_slider_two .slick-prev.slick-arrow {
  position: absolute;
  top: 285px;
  left: 22%;
  margin-left: -24px;
  border-radius: 50px;
  padding: 0;
  height: 64px;
  width: 44px;
  z-index: 5;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.ingredeint_section.section_padding {
	padding: 7.5rem 0;
}
