.contact_content_two {
	p,h1,h2,h3,h4,h5,h6,a {
		font-family: $font_primary;
	}
	&.section_padding_two {
		padding-bottom: 0;
	}
	.sn_contact_icon i {
		color: #3734f0;
	}
	.sn_contact_content p {
		color: #9e9c9c;
	}
	.sn_contact_content a {
		color: #9e9c9c;
	}
	.form_group input[required] + label::after {
		color: #3734f0;
	}

	.btn_one, .btn_two {
		border: 2px solid $home5_black;
		color: $home5_black;
	}

	.btn_two:hover {
		color: $text_white;
		border-color: #3734f0;
	}
	.btn_two::before {
		background: #3734f0;
	}
	.btn_two:hover:before {
		background: #3734f0;
	}

	.form_group input {
		height: 60px;
		padding: 0 30px;
		font-size: 1rem;
	}
	.form_group input[required] + label {
		left: 30px;
		top: 1.1rem;
		font-size: 1rem;
	}
	.form_group.half:nth-child(2) input[required] + label {
		left: 43px;
		font-size: 1rem;
	}
	.form_group textarea {
		height: 13rem;
		border: none;
		padding: 15px 30px;
		background: #fff;
		resize: none;
		font-size: 1rem;
		color: #9f9f9f;
		border: 1px solid #e4ecf3;
	}
}
