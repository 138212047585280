.top_feature_area.top_feature_three {
	background: #2e2c97;

	.single_top_feature span {
		color: #fff;
	}
	.single_top_feature .tf_content h5 {
		color: #fff;
		opacity: 1;
	}
	.single_top_feature .tf_content p {
		opacity: 1;
		color: #fff;
	}

	.single_top_feature {
		margin-top: 30px;
	}
}