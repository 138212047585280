.home5-most-sold {
	background-position: center;
	background-attachment: fixed;
	padding: 140px 0 110px;


	.section_title_four {
		position: absolute;
		left: 0;
		top: -140px;
		margin-bottom: 0;

		h2 {
			margin-top: -51px;
		}
	}

	.sn_related_product {
		background: #fff;
		border: none;
		margin-bottom: 30px;
		padding: 29px;
		padding-bottom: 21px;

		&:hover {
			border: none;
		}

		.sn_pd_img {
			text-align: center;
		}

		.sn_pd_rating a {

			i {
				color: $home6_primary3;
			}

			&:last-child i {
				color: #b2b2b2;
			}
		}
	}
}

@media (max-width: 575px) {
	.home5-most-sold .sn_related_product {
		padding-right: 29px;
	}
}