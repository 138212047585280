/*--------------------------------------------------------------
  ##  Header
  --------------------------------------------------------------*/
@import "header";

/*--------------------------------------------------------------
  ##  Header2
  --------------------------------------------------------------*/
@import "header2";

/*--------------------------------------------------------------
  ##  Header2
  --------------------------------------------------------------*/
@import "header3";

/*--------------------------------------------------------------
  ##  Header2
  --------------------------------------------------------------*/
@import "header4";

/*--------------------------------------------------------------
  ##  Banner
  --------------------------------------------------------------*/
@import "banner";

/*--------------------------------------------------------------
  ##  Banner5
  --------------------------------------------------------------*/
@import "banner5";

/*--------------------------------------------------------------
  ##  Why use
  --------------------------------------------------------------*/
@import "why-use";

/*--------------------------------------------------------------
  ##  Feature benifit
  --------------------------------------------------------------*/
@import "feature-benifit";

/*--------------------------------------------------------------
  ##  Breadcrumb
  --------------------------------------------------------------*/
@import "breadcrumb";

/*--------------------------------------------------------------
  ##  Breadcrumb2
  --------------------------------------------------------------*/
@import "breadcrumb2";

/*--------------------------------------------------------------
  ##  Top feature
  --------------------------------------------------------------*/
@import "top-feature";

/*--------------------------------------------------------------
  ##  Top feature Two
  --------------------------------------------------------------*/
@import "top-feature-two";

/*--------------------------------------------------------------
  ##  Top feature Two
  --------------------------------------------------------------*/
@import "top-feature3";

/*--------------------------------------------------------------
  ##  About area
  --------------------------------------------------------------*/
@import "about";

/*--------------------------------------------------------------
  ##  Product with carousel
  --------------------------------------------------------------*/
@import "product-two";

/*--------------------------------------------------------------
  ##  Product collection
  --------------------------------------------------------------*/
@import "product-collection";

/*--------------------------------------------------------------
  ##  Product all collection
  --------------------------------------------------------------*/
@import "product-all-collection";

/*--------------------------------------------------------------
  ##  Product all collection
  --------------------------------------------------------------*/
@import "privacy";

/*--------------------------------------------------------------
  ## Home Service 5
  --------------------------------------------------------------*/
@import "home5-service";

/*--------------------------------------------------------------
  ## Home Service 5
  --------------------------------------------------------------*/
@import "home5-recent";

/*--------------------------------------------------------------
  ## Home Service 5
  --------------------------------------------------------------*/
@import "home5-feature";

/*--------------------------------------------------------------
  ## Home Service 5
  --------------------------------------------------------------*/
@import "home5-offer";


/*--------------------------------------------------------------
  ## Home Service 5
  --------------------------------------------------------------*/
@import "video-three";


/*--------------------------------------------------------------
  ## Home Service 5
  --------------------------------------------------------------*/
@import "video-five";

/*--------------------------------------------------------------
  ## Home Service 5
  --------------------------------------------------------------*/
@import "home5-video";

/*--------------------------------------------------------------
  ## Home Service 5
  --------------------------------------------------------------*/
@import "home5-pricing";

/*--------------------------------------------------------------
  ## Features
  --------------------------------------------------------------*/
@import "features";

/*--------------------------------------------------------------
  ## Features
  --------------------------------------------------------------*/
@import "home5-most-sold";

/*--------------------------------------------------------------
  ##  Product Detail
  --------------------------------------------------------------*/
@import "product-detail";

/*--------------------------------------------------------------
  ##  Faq
  --------------------------------------------------------------*/
@import "faq";

/*--------------------------------------------------------------
  ##  Faq
  --------------------------------------------------------------*/
@import "faq-custom";

/*--------------------------------------------------------------
  ##  Blog
  --------------------------------------------------------------*/
@import "blog-list";

/*--------------------------------------------------------------
  ##  Blog list detail
  --------------------------------------------------------------*/
@import "blog-list-detail";

/*--------------------------------------------------------------
  ##  Contact
  --------------------------------------------------------------*/
@import "contact";

/*--------------------------------------------------------------
  ##  Contact2
  --------------------------------------------------------------*/
@import "contact2";

/*--------------------------------------------------------------
  ##  Footer
  --------------------------------------------------------------*/
@import "footer";

/*--------------------------------------------------------------
  ##  Call to action
  --------------------------------------------------------------*/
@import "call-to-action";

/*--------------------------------------------------------------
  ##  Vaxine all fact
  --------------------------------------------------------------*/
@import "vaxine-all-fact";

/*--------------------------------------------------------------
  ##  Vaxine all fact2
  --------------------------------------------------------------*/
@import "vaxine-all-fact2";

/*--------------------------------------------------------------
  ##  Call to action2
  --------------------------------------------------------------*/
@import "call-to-action2";

/*--------------------------------------------------------------
  ##  Call to action2
  --------------------------------------------------------------*/
@import "call-to-action-green";

/*--------------------------------------------------------------
  ##  Call to action2
  --------------------------------------------------------------*/
@import "call-to-action-grey";

/*--------------------------------------------------------------
  ##  Package Plan
  --------------------------------------------------------------*/
@import "package-plan";

/*--------------------------------------------------------------
  ##  Package Plan2
  --------------------------------------------------------------*/
@import "package-plan2";

/*--------------------------------------------------------------
  ##  Supplement service
  --------------------------------------------------------------*/
@import "supplement-service";

/*--------------------------------------------------------------
  ##  Supplement service2
  --------------------------------------------------------------*/
@import "supplement-service2";

/*--------------------------------------------------------------
  ##  Supplement service2
  --------------------------------------------------------------*/
@import "supplement-select";

/*--------------------------------------------------------------
  ##  Supplement service2
  --------------------------------------------------------------*/
@import "supplement-at-glance";

/*--------------------------------------------------------------
  ##  Supplement benifit
  --------------------------------------------------------------*/
@import "supplement-benifit";

/*--------------------------------------------------------------
  ##  Supplement benifit
  --------------------------------------------------------------*/
@import "supplement-benifit-two";

/*--------------------------------------------------------------
  ##  Supplement benifit
  --------------------------------------------------------------*/
@import "supplement-more";

/*--------------------------------------------------------------
  ##  Supplement benifit
  --------------------------------------------------------------*/
@import "supplement-hot-sell";

/*--------------------------------------------------------------
  ##  Supplement benifit
  --------------------------------------------------------------*/
@import "supplement-hot-sell-two";

/*--------------------------------------------------------------
  ##  Supplement page top
  --------------------------------------------------------------*/
@import "supplement-page-top";

/*--------------------------------------------------------------
  ## 404 Error content
  --------------------------------------------------------------*/
@import "error-content";

/*--------------------------------------------------------------
  ## 404 Error content2
  --------------------------------------------------------------*/
@import "error-content2";

/*--------------------------------------------------------------
  ##  Contact page
  --------------------------------------------------------------*/
@import "contact-main";

/*--------------------------------------------------------------
  ##  Contact page2
  --------------------------------------------------------------*/
@import "contact-main2";

/*--------------------------------------------------------------
  ##  Footer 4
  --------------------------------------------------------------*/
@import "footer4";

/*--------------------------------------------------------------
  ##  Footer 4
  --------------------------------------------------------------*/
@import "footer5";

/*--------------------------------------------------------------
  ##  Blog page
  --------------------------------------------------------------*/
@import "blogpage";

/*--------------------------------------------------------------
  ##  Blog page2
  --------------------------------------------------------------*/
@import "blogpage2";

/*--------------------------------------------------------------
  ## Ingredient
  --------------------------------------------------------------*/
@import "ingredient";

/*--------------------------------------------------------------
  ## Ingredient2
  --------------------------------------------------------------*/
@import "ingredient2";

/*--------------------------------------------------------------
  ## Ingredient3
  --------------------------------------------------------------*/
@import "ingredient3";

/*--------------------------------------------------------------
  ## Ingredient4
  --------------------------------------------------------------*/
@import "ingredient4";

/*--------------------------------------------------------------
  ## Ingredient4
  --------------------------------------------------------------*/
@import "ingredients-key";

/*--------------------------------------------------------------
  ## Related product
  --------------------------------------------------------------*/
@import "related-product";

/*--------------------------------------------------------------
  ## Related product2
  --------------------------------------------------------------*/
@import "related-product2";

/*--------------------------------------------------------------
  ## Count Up
  --------------------------------------------------------------*/
@import "count-up";

/*--------------------------------------------------------------
  ## Map
  --------------------------------------------------------------*/
@import "map";

/*--------------------------------------------------------------
  ## Map Two
  --------------------------------------------------------------*/
@import "map-two";

/*--------------------------------------------------------------
  ## Map
  --------------------------------------------------------------*/
@import "combine-section";

/*--------------------------------------------------------------
  ## Map
  --------------------------------------------------------------*/
@import "combine-section2";

/*--------------------------------------------------------------
  ## Extra
  --------------------------------------------------------------*/
@import "extra";

/*--------------------------------------------------------------
  ##  Responsive
  --------------------------------------------------------------*/
@import "responsive_two";

@import "responsive";