.supplement_at_item {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	background: #f9f9f9;
	padding: 25px;
	margin-bottom: 30px;
	position: relative;
	border-right: 5px solid #dfdfdf;
	transition-delay: 0.5s ease;
	transition: 0.3s;
	align-items: center;

	&:after {
		position: absolute;
		content: "";
		right: -5px;
		width: 5px;
		height: 0;
		top: 0;
		background: $home5_primary;
		transition: 0.5s linear;
	}

	&:hover {
		background: #fff;
		box-shadow: 18px 18px 38px rgba(222,222,222,1.0);

		&:after {
			height: 100%;
		}

		.supplement_at_item_content a i {
			color: $home5_primary;
		}
	}
}
.supplement_at_item_img {
	width: 139px;
	height: 139px;
	text-align: center;
	line-height: 144px;
	background: #fff;
	transition: 0.5s;
}
.supplement_at_item_content {
	width: calc(100% - 144px);
	padding-left: 25px;
	padding-right: 20px;

	h3 {
		font-size: 20px;
		font-weight: 500;
		font-style: normal;
		font-stretch: normal;
		letter-spacing: normal;
		text-align: left;
		color: #424e5a;
		text-transform: capitalize;
		line-height: normal;
		margin-bottom: 7px;
		margin-top: 5px;
	}

	p {
		 font-size: 16px;
		  font-weight: normal;
		  font-style: normal;
		  font-stretch: normal;
		  line-height: 1.5;
		  letter-spacing: normal;
		  text-align: left;
		  color: #424e5a;
		  margin-bottom: 13px;
	}

	a {
		padding: 0;

		i {
			font-size: 16px;
			color: #c6c6c6;
			transition: 0.3s;
			transition-delay: 0.5s;
		}
	}
}
.supplement_at_a_glance.section_padding_two {
	padding-bottom: 90px;
	padding-top: 120px;
}

@media (min-width: 992px) and (max-width: 1199px) {
	.supplement_at_item {
		align-items: center;
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	.supplement_at_a_glance.section_padding_two {
		position: relative;
		z-index: 1;
	}
	.supplement_at_a_glance.section_padding_two {
		padding-top: 100px;
		padding-bottom: 70px;
	}
}

@media (min-width: 576px) and (max-width: 767px) {
	.supplement_at_a_glance.section_padding_two {
		position: relative;
		z-index: 1;
	}

	.supplement_at_item_content {
		width: calc(100% - 50px);
	}

	.supplement_at_a_glance.section_padding_two {
		padding-bottom: 50px;
		padding-top: 80px;
	}

}

@media only screen and (max-width: 575px) {
	.supplement_at_item_content {
		width: 100%;
		padding-left: 0;
	}

	.supplement_at_a_glance.section_padding_two {
		padding-bottom: 50px;
		padding-top: 0px;
	}
}