.sn_contact_feature {
	width: 33%;
	text-align: center;
	display: inline-block;
	margin-bottom: 60px;
	padding: 0 3.89rem;
}

.sn_contact_icon {
	margin-bottom: 1.12rem;

	i {
		font-size: 35px;
		color: $main_theme_color;
		display: inline-block;
		line-height: 35px;
	}
}

.sn_contact_content {

	 h6 {
		font-weight: 600;
		color: $box_heading;
	}

	a {
		display: block;
		padding: 0;
		color: $text_body_main;
	}
}
.contact_page_form {
	padding-bottom: 120px;

	.form_group input,
	.form_group textarea {
		border: 1px solid #e4ecf3;
	}

}
.contact_form .btn_one {
	padding: 0;
}
.contact_content_area .form_group {
	margin-bottom: 2rem;

	&:last-child {
		margin-bottom: 0;
	}
}

.contact_content_area {

	&.section_padding {
		padding-bottom: 0
	}

	.map_main {
		position: static;
		width: 100%;
		height: 500px;
		padding-right: 0;
	}
}

.contact_form{
	.btn_one {
		cursor: pointer;
	}
}



