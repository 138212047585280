.vaxine_all_fact2 {
	p,h1,h2,h3,h4,h5,h6,a {
		font-family: $font_primary;
	}

	.product_review_tab .nav li a.active,
	.sn_review_right .sn_review_icon a,
	.sn_pd_rating a i {
		color: $home6_primary3;
	}

	.accordion_btn {
		padding-left: 0px;
	}

	.product_review_tab .tab-content #description p {
		color: $text_inner45;
		letter-spacing: 0.38px;
		margin-bottom: 10px;
	}
	.supplement_nutrition_fact p {
		color: $text_inner45;
	}
	.single_shippin45455g_info li {
		color: $text_inner45;
	}
	.sn_review_right .sn_review_desc p {
		color: $text_inner45;
	}
	.btn_one, .btn_two {
		border: 2px solid $home6_primary3;
		color: $home6_primary3;
	}
	.btn_two::before {
		background: $home6_primary3;
	}
	.btn_two:hover {
		border: 2px solid $home6_primary3;
		color: #fff;
		background: $home6_primary3;
	}
	.btn_two:hover {
		border: 2px solid $home6_primary3;
		color: #fff;
	}


}
#description h5 {
	margin-bottom: 30px;
	color: #222;
	line-height: 28px;
	font-weight: 500;
	text-transform: capitalize;
}
.entry-content-list h4 {
	margin-bottom: 30px;
	font-size: 20px;
	text-transform: capitalize;
	font-weight: 500;
}
.entry-content-list li {
	margin-bottom: 15px;
}
.entry-content-list {
	margin-top: 30px;
	margin-bottom: 25px;

	ul {
		padding-left: 0;
	}
}