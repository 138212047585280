.hdr_area_three {

	.sub-menu li a {
		color: #121212;
	}

	.hdr_btn_wrapper a {
		color: $home5_black;
	}

	.top_toolbar_new .toolbar_right ul li.cart::before {
		right: 0;
	}
	.top_toolbar_new .toolbar_right ul li.cart {
		padding-right: 0;
	}

	.top_toolbar_new .toolbar_right ul li {
		&:hover {
			a,i {
				color: $home5_primary;
			}
		}
	}
	.top_toolbar_new .toolbar_right ul li:last-child::after {
		left: 4px;
		background: rgba(51,51,51,0.2);
	}

	.cart_more a i {
		color: $home5_primary !important;
	}

	&.headroom--not-top {

		&.hdr_area_two #mobile-nav-wrap {
			background: $home5_primary;
		}

		&.headroom--not-top .header_nav {
			background: #fff;
			box-shadow: 0px 0px 10px rgba(0,0,0,0.3);
		}
	}



	.btn_one,
	.btn_two {

		&:hover {
			color: #fff;

			&:before {
				background: $home5_primary;
			}
		}
	}

	.top_toolbar_new {
		background: #f2f2f2;

		.toolbar_left p {
			color: $home5_black;

			span {
				color: $home5_black;
			}
		}

		.toolbar_right ul li {

			> i {
				color: $home5_black;
			}

			&.cart {

				&:before {
					background: $home5_primary;
				}

				&:after {
					background: rgba(51, 51, 51, 0.2);
				}
			}

			a {
				color: $home5_black;
			}
		}
	}
	.main_menu > ul > li > a {
		color: $home5_black;
		font-size: 14px;
		letter-spacing: normal;
		line-height: normal;
		height: auto;
		padding: 0px 19.2px;
	}
	.main_menu > ul > li:hover > a,
	.main_menu > ul > li > a.current_page_item {
		background: transparent;
		color: $home5_primary;
	}
	.main_menu .sub-menu a:hover,
	.main_menu .sub-menu a.current_page_item {
		color: $home5_primary;
	}

	.header_nav {
		background: #fff;
	}

	.btn_one,
	.btn_two {
		border: 2px solid $home5_primary;
		color: $home5_primary;
		box-shadow: 18px 18px 38px rgba(222,222,222,0.01);
	}

	.header_logo a img {
		margin-top: 0px;
		margin-left: 0;
	}

	&.hdr_area_two #mobile-nav-wrap {
		background: $home5_primary;
	}
}

.mobile-menu-inner-four.mobile-menu-inner-two .mobile_accor_togo {
	background: $home5_primary;
}



.main_menu > ul > li.inherit {
	position: inherit;

	&:hover {

		.megamenu {
			visibility: visible;
			opacity: 1;
			-webkit-transition: all 0.5s ease 0s;
			-o-transition: all 0.5s ease 0s;
			transition: all 0.5s ease 0s;
			left: 15px;
			width: 100%;
		}
	}
}

.col-auto.no-position {
	position: inherit;
}

.vigo_container_two,
.vigo_container_one {
	position: relative;
}

.megamenu {
	position: absolute;
	left: 15px;
	width: calc(100% - 30px);
	max-width: calc(100% - 30px);
	background: #fff;
	top: 100%;
	opacity: 0;
	-webkit-transition: all 0.5s ease 0s;
	-o-transition: all 0.5s ease 0s;
	transition: all 0.5s ease 0s;
	visibility: hidden;
}

.megamenu {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	width: auto;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	margin: 0 auto;
	border-bottom: 11px solid $home6_primary2;
	padding: 70px;
	box-shadow: 0px 19px 21px rgba(0,0,0,0.25);
	background: url(../../media/images/popup/Cookies.png) no-repeat scroll bottom right/contain;
	background-size: 13%;
	background-color: rgba(255,255,255,1);


	.column:nth-child(2n+1) {
		width: 43%;
	}

	.column:first-child {
		width: 35%;
	}

	.column:nth-child(2n) {
		width: 27%;
		text-align: left;
	}

	.column {
		position: relative;

		&:after {
			position: absolute;
			content: "";
			right: 0;
			top: 0;
			width: 1px;
			height: 92%;
			background: #ddd;
		}

		&:last-child {
			border-right: 0;
			padding-left: 80px;
			padding-right: 150px;

			&:after {
				display: none;
			}

			.mm-header {
				margin-bottom: 40px;
			}
		}

		ul {
			padding-left: 0;

			li {
				line-height: normal;
				margin-bottom: 25px;

			}
			a {
				padding: 0;
				font-size: 14px;
				color: $home5_black;
				font-weight: 400;
				line-height: normal;
			}

			i {
				vertical-align: middle;
			}
		}
	}
	.product-highlight-slider.owl-carousel .owl-item img {
		display: inline-block;
		width: auto;
	}
	.sn_related_product {
		border: 1px solid transparent;
		margin-right: 15px;
		padding-left: 0;

		&:hover {
			box-shadow: none;
		}
	}

	.column-inner {
		text-align: left;
		margin: 0 auto;
		display: inline-block;
		width: auto;
		padding-left: 80px;
	}

	.video-popup {
		margin-bottom: 70px;

		a {
			width: 60px;
			height: 60px;
			padding: 0;
			text-align: center;
			background: $home6_primary;
			border-radius: 0px;
			transition: 0.5s;

			&:hover {
				background: #222;

				i {
					color: $home6_primary;
				}
			}

			i {
				color: $home5_black;
				font-size: 35px;
				line-height: 60px;
				transition: 0.5s;
			}
		}
	}

	.sharing-caring a {
		width: 44px;
		height: 44px;
		padding: 0;
		display: inline-block;
		background: #ebebeb;
		text-align: center;
		line-height: 44px;
		border-radius: 50%;
		transition: 0.3s;

		i {
			font-size: 14px;
			color: $home5_black;
		}

		&:hover {
			background: $home6_primary;

			i {
				color: #fff;
			}
		}
	}

	.sn_pd_img {
		position: relative;

		a {
			display: inline-block;
		}

		span {
			width: 45px;
			height: 45px;
			background: red;
			display: inline-block;
			border-radius: 50%;
			text-align: center;
			line-height: 45px;
			position: absolute;
			right: 20%;
			bottom: 0;
			color: #fff;
			background: $home6_primary;
			font-size: 16px;
		}
	}

	.sn_pd_rating a {

		&:last-child {
			i {
				color: #b2b2b2;
			}
		}

		i {
			font-size: 14px;
			color: $home6_primary3;
		}
	}

	.sn_pd_detail a h5 {
		font-size: 16px;
		font-weight: 500;
		text-transform: capitalize;
		color: #000;
		letter-spacing:0.5px;
	}
}
.mm-header {

	h3 {
		font-size: 20px;
		color: $home5_black;
		font-weight: 500;
		letter-spacing: 1.2px;
		margin-bottom: 20px;
		padding-bottom: 20px;
		position: relative;

		&:after {
			position: absolute;
			left: 0;
			bottom: 0;
			content: "";
			width: 10px;
			height: 2px;
			background: $home6_primary;
		}
	}

	p {
		font-size: 14px;
		color: $home5_black;
		font-weight: 300;
		letter-spacing: 0.25px;
	}
}

.megamenu .sn_pd_detail a h5{
	transition: 0.3s;

	&:hover {
		color: $home6_primary;
	}
}

.megamenu .column ul li {
	transition: 0.3s;

	a,i {
		transition: 0.3s;
		color: $home5_black;
		letter-spacing: 0.5px;
	}

	i {
		margin-right: 3px;
	}

	&:hover {
		margin-left: 15px;
	}

	&:hover i {
		color: $home6_primary;
	}
}


.megamenu-two {

	.mm-header h3::after {
		background: $home5_primary;
	}
	&.megamenu .sn_pd_img span {
		background: $home5_primary;
	}
	&.megamenu .sn_pd_rating a i {
		color: $home5_primary;
	}
	&.megamenu .sn_pd_detail a h5:hover {
		color: $home5_primary;
	}
	&.megamenu .column ul li a:hover, &.megamenu .column ul li i {
		color: $home5_primary;
	}
	&.megamenu .video-popup a {
		background: $home5_primary;

		&:hover {
			background: #222;

			i {
				color: $home5_primary;
			}
		}
	}

	&.megamenu .sharing-caring a:hover {
		background: $home5_primary;
	}

	&.megamenu {
		border-bottom: 11px solid $home5_primary;
	}

	.megamenu.sn_related_product {
		padding: 10px;
	}
}

.hdr_area_three {

	&.hdr_area_two.headroom--not-top .header_nav .main_menu .sub-menu a.current_page_item {
		color: $home5_primary;
	}
	.main_menu > ul > li > a {
		position: relative;

		&:after {
			position: absolute;
			content: "";
			width: 0px;
			height: 1px;
			background: $home5_black;
			opacity: 1;
			z-index: 1;
			left: 20px;
			top: calc(100% + 5px);
			transition: 0.3s;
		}

		&:hover, &.current_page_item {

			&:after {
				width: 30px;
			}
		}
	}

	.hdr_btn_wrapper a:hover {
		color: $home5_primary;

		i {
			color: $home5_primary;
		}
	}
}


@media (min-width: 992px) and (max-width: 1199px) {
	.hdr_area_three .main_menu > ul > li > a {
		font-size: 12px;
	}
	.megamenu .sn_related_product {
		padding: 0;
	}
	.megamenu .column-inner {
		width: auto;
	}
	.megamenu .column:last-child {
		padding-left: 30px;
		padding-right: 0;
	}
	.megamenu .column-inner {
		padding-left: 30px;
	}
}

@media (min-width: 1200px) and (max-width: 1500px) {
	.hdr_area_three .main_menu > ul > li > a {
		padding: 0px 15px;
	}
}