.error_cont_desc {
	text-align: center;
}

.error_cont_desc {
	text-align: center;
	position: relative;
	top: 50%;
	transform: translateY(-50%);

	h1 {
		font-size: 150px;
		font-weight: 500;
		line-height: 150px;
		margin: 0;

		span {
			color: $main_theme_color;
		}
	}

	p {
		margin-bottom: 40px;
		font-size:20px;
	}
}
.error_content_area.section_padding {
	padding: 112px 0;
}