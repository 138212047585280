.blog_single_list_content {
	width: calc(100% - 55.66%);
	margin-left: -62px;
	background: #fff;
	z-index: 9;
	position: relative;
	padding: 36px 40px;
	line-height: 30px;
	font-weight: 600;
	color: $home5_black;
	padding-right: 0;
	padding-bottom: 0;
	height: 175px;


	&:before {
		position: absolute;
		content: "";
		left: 0;
		top: -5px;
		height: 7px;
		width: 62px;
		background: $home6_primary;
		transition: 0.5s;
	}

	h3 {
		margin-bottom: 18px;

		a {
			font-size: 20px;
			font-weight: 600;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.5;
			letter-spacing: 0.5px;
			text-align: left;
			color: $home5_black;
			font-family: $font_heading;
			padding: 0;
			transition: 0.3s;
			text-transform: capitalize;
		}
	}

	.blog_meta a{
		padding: 0;

		span {
			text-transform: capitalize;
			display: block;
			font-size: 14px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			line-height: 2.57;
			letter-spacing: 1.2px;
			text-align: left;
			color: $home6_primary2;
		}
	}
}

.blog_single_list_img {
	position: relative;
	width: 55.66%;

	.post-thumbnail {
		transition: 0.5s;
		position: relative;

		img {
			opacity: 1;
			transition: 0.5s;
		}

		&:before {
			position: absolute;
			content: "";
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background: rgba(58,216,46,0.1);
			opacity: 0;
			transition: 0.5s;
		}
	}
}

.blog_single_list {
	display: flex;
	align-items: center;
	padding: 60px 0;
	border-bottom: 1px solid #ddd;

	&:first-child {
		padding-top: 0;
	}

	&:hover {
		.post-thumbnail {

			&:before {
				opacity: 1;
			}
		}

		.blog_single_list_btn{
			left: 0;

			span {
				opacity: 1;
			}
		}

		.blog_single_list_content {

			&:before {
				transform: rotate(-45deg);
				transform-origin: top;
				z-index: -1;
				left: 10px;
				top: 50px;
			}
		}
	}
}

.blog_single_list_btn {
	height: 49px;
	line-height: 48px;
	padding: 0 15px;
	position: absolute;
	bottom: 0;
	background: #fff;
	border-radius: 0;
	left: -41px;
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;

	&:hover {

		span {
			margin-right: 10px;
		}
	}

	span {
		font-size: 14px;
		font-weight: bold;
		font-style: normal;
		font-stretch: normal;
		line-height: 2.14;
		letter-spacing: 1.7px;
		text-align: center;
		color: $home6_primary3;
		text-transform: uppercase;
		transition: 0.5s;
		opacity: 0;
	}

	i {
		font-size: 16px;
		color: $home6_primary3;
		vertical-align: middle;
	}
}

.blog_list_pagination {
	padding-top: 27px;
	padding-left: 2px;
	line-height: normal;
}

.blog_list_nav_links {
	padding-left: 0;
	margin-bottom: 0;
	display: inline-block;
	line-height: normal;

	&.two {
		float: right;

		li:last-child a{
			padding-right: 0;
		}
	}

	li {
		display: inline-block;

		a {
			padding: 0;
			font-size: 14px;
			font-weight: 400;
			color: $home5_black;
			padding: 0px 9px;
			transition: 0.3s;
			border-radius: 0;

			&:hover {
				color: $home6_primary;
			}

			&.prev, &.next {
				font-size: 14px;
				font-weight: 300;
				font-style: normal;
				font-stretch: normal;
				line-height: 3.86;
				letter-spacing: 7px;
				text-align: left;
				color: $home6_primary3;
				text-transform: uppercase;
				transition: 0.3s;

				i {
					transition: 0.3s;
				}

				&:hover {

					i {
						color: $home6_primary;
					}
				}
			}

			&.prev {
				margin-left: 21px;
				padding-right: 6px;
				line-height: normal;
			}
			&.next {
				padding-left: 23px;
				line-height: normal;
				border-left: 1px solid #ddd;
			}
		}
	}
}

.blog_list_nav_links li:first-child a {
	padding-left: 0;
}

.widget_social_list{

	 li {
		margin-right: 5px;
		margin-bottom: -1px;

	 	a {
			width: 55px;
			height: 55px;
			line-height: 55px;
			border: 1px solid #ddd;
			border-radius: 0px;
			text-align: center;
			padding: 0;

			&.active, &:hover {

				i {
					color: #1954ab;
				}
			}

			i {
				font-size: 16px;
				color: #bababa;
			}
		}
	}
}

.blog_list_sidebar {

	.widget_facebook iframe {
		width: 246px;
		height: 326px;
		border: none;
	}

	.widget {
		padding-right: 0;
		margin-bottom: 66px;
	}

	.widget_social_content.tab-content {
		margin-left: 0;
		text-align: center;
		border: 1px solid #ddd;
		padding: 20px 0;
	}
	.embedly-card {
		padding: 20px;
		height: 346px;
		overflow-y: scroll;
		overflow-x: hidden;
	}

	.widget.widget_categories {
		margin-bottom: 50px;

		.widget-title {
			color: $home5_black;
			font-weight: 500;
			font-size: 20px;
			padding-bottom: 15px;
			margin-bottom: 16px;
			border-bottom: 5px solid #ebebeb;
		}

		ul {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;

			.cat-item {
				width: 128px;
				height: 120px;
				text-align: center;
				background: #f3f3f3;
				margin-bottom: 19px;
				padding: 22px 15px;
				transition: 0.3s;

				&:hover {
					background: $home6_primary;

					i,p {
						color: #fff;
					}
				}

				i {
					width: 100%;
					font-size: 22px;
					color: $home6_primary3;
					margin-bottom: 19px;
					transition: 0.3s;
				}

				p {
					line-height: 20px;
					margin-bottom: 0;
					color: #222;
					transition: 0.3s;
					font-size: 14px;
					font-weight: 500;
				}
			}
		}
	}
	.widget.widget_search {

		form {
			height: 57px;
			padding: 0;
			border: 1px solid #ddd;
			padding: 14px 0;
			transition: 0.3s;

			label {
				margin-bottom: 0;
				padding-left: 20px;

				i {
					padding-right: 10px;
					border-right: 2px solid #bababa;
					margin-right: 10px;
				}

				.search-field {
					background: transparent;
					border: none;
					padding: 0;
					width: auto;
					color: #7a7a7a;
				}
			}

			.search-submit {
				font-size: 14px;
				color: #7a7a7a;
				padding-right: 18px;
				padding-left: 0;
				cursor: pointer;
			}

			&:hover {
				border: 1px solid $home6_primary;
			}
		}
	}

	.widget.widget_advertise a {
		padding: 0;
		padding-top: 5px;
	}
}

.blog_list_flex {
	display: flex;
	padding-top: 140px;
}

.blog_list_flex_item {
	max-width: 275px;
}

.blog_list_flex_item_big {
	width: calc(100% - 275px);
	padding-right: 134px;
}

.blog_list_nav_links li a span {
	padding: 0 6.5px;
}

@media (min-width: 1200px) and (max-width: 1500px) {
	.blog_list_flex_item_big {
		padding-right: 50px;
	}
	.megamenu .column-inner {
		width: auto;
	}
	.blog_single_list_content {
		width: calc(100% - 43.66%);
	}
	.blog_list_sidebar.sidebar .widget:last-child {
		margin-bottom: 0;
	}
}

@media (min-width: 992px) and (max-width: 1199px) {
	.blog_single_list_content {
		width: 80%;
	}

	.blog_single_list_content {
		padding: 6px 23px;
		height: auto;
		padding-bottom: 0;
	}

	.blog_single_list_content h3 a {
		font-size: 19px;
	}

	.blog_list_flex_item_big {
		margin-right: 30px;
	}

	.widget:last-child {
		margin-bottom: 0;
	}

	.blog_single_list:hover .blog_single_list_content::before {
		left: 7px;
		top: 18px;
	}

	.blog_list_flex_item_big {
		margin-right: 0;
		padding-right: 30px;
	}

}
@media (min-width: 768px) and (max-width: 991px) {
	.blog_single_list {
		flex-wrap: wrap;
	}
	.blog_single_list_img {
		width: 100%;
	}
	.blog_single_list_content {
		width: 100%;
		margin-left: 0;
	}
	.blog_list_flex_item_big {
		width: calc(100% - 275px);
		margin-right: 30px;
	}
	.blog_list_nav_links li a.prev {
		margin-left: 0;
		padding-right: 5px;
	}
	.blog_list_nav_links li a {
		padding: 0px 3px;
	}

	.blog_list_nav_links li a.next {
		padding-left: 0;
		border-left: 0;
	}
	.blog_single_list:hover .post-thumbnail {
		opacity: 1;
	}
	.blog_list_flex {
		padding-top: 100px;
	}
}
@media (min-width: 576px) and (max-width: 767px) {
	.blog_list_nav_links li a {
		padding: 0px 7px;
	}
}
@media only screen and (max-width: 767px) {
	.blog_list_flex_item_big {
		width: 100%;
		margin-right: 0;
		padding-right: 0;
	}

	.blog_list_nav_links li a {
		padding: 0px 7px;
	}

	.blog_list_flex, .blog_single_list {
		flex-wrap: wrap;
	}
	.blog_single_list_content {
		width: 100%;
		margin-left: 0;
		height: 140px;
	}
	.blog_single_list_img {
		width: 100%;
	}
	.blog_single_list:hover .post-thumbnail {
		opacity: 1;
	}
	.blog_list_nav_links li a.prev {
		margin-left: 0;
	}
	.blog_list_pagination {
		text-align: center;
	}

	.blog_list_sidebar.sidebar {
		margin-top: 54px;
		max-width: 275px;
	}
	.blog_list_flex {
		padding-top: 80px;
	}

	.blog_list_flex_item {
		max-width: 100%;
		width: 100%;
		margin-top: 50px;
	}
	.widget.widget_advertise {
		margin-bottom: 0;
	}
}
@media (max-width: 575px) {
	.blog_list_sidebar.sidebar {
		max-width: 100%;
	}
}