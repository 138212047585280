@media (min-width: 1500px) and (max-width: 1699px) {
	.home_five_single_service {
		width: 31.89%;
	}
}

@media (min-width: 1200px) and (max-width: 1499px) {
	.vigo_container_one,.vigo_container_two {
		max-width: 1170px;
	}
	.main_menu > ul > li > a {
		padding: 0.8rem 1rem 0.6rem;
	}
	.header_nav .btn_wrapper {
		margin: 2.5rem 0 0;
		height: 2.2rem;
	}
	.main_menu {
		padding: 2rem 0;
	}
	.banner_area .owl-dots {
		display: none;
	}
	.megamenu .column:last-child {
		padding-right: 0px;
	}
	.widget .widget_payment span {
		display: block;
	}
	.services_area.section_padding {
		padding-bottom: 2.7rem;
	}
	.banner_left .banner_left_cell h1 {
		font-size: 2rem;
	}
	.header_nav .btn_wrapper {
		width: 8rem;
	}
	.promote_sub ul li span {
		font-size: 0.89rem;
	}
	.promote_right {
		position: absolute;
		bottom: -70px;
		width: 66%;
	}
	.promote_sub {
		padding: 2rem;
		margin-left: 15px;
	}
	.promote_sub h2 {
		font-size: 1.5rem;
	}
	.single_pkg_bottom p {
		font-size: 0.9rem;
	}
	.footer_top_two .widget.widget_about_two {
		padding-right: 0;
	}
	.widget_instafeed .widget_insta_list ul li {
		width: 82px;
		height: 82px;
	}
	.widget_recent_post .widget_post_list li a span {
		margin-right: 7px;
	}
	.footer_top_two .widget {
		font-size: 0.9rem;
	}
	.supplement_service_img {
		-webkit-transform: translateY(-25%);
		-ms-transform: translateY(-25%);
		transform: translateY(-25%);
	}
	.promote_area.section_padding {
		padding-bottom: 120px;
	}
	.advertise_text_left {
		width: 65%;
	}
	.advertise_text_right {
		margin-top: 30px;
	}
	.banner_left .banner_left_cell p {
		margin-bottom: 3rem;
		font-size: 20px;
		line-height: 30px;
	}
	// home one
	.main_menu {
		padding: 1.3rem 0;
	}
	.banner_two_area .banner_left .banner_left_cell h1 {
		font-size: 55px;
		line-height: 57px;
		margin-bottom: 10px;
	}
	.banner_two_area .banner_left .banner_left_cell p {
		margin-bottom: 30px;
	}
	.why_use_area {
		background-size: contain;
		background-repeat: no-repeat;
	}
	.tesm_three_content {
		background-position: center center;
	}
	.quote_mark {
		left: calc(50% - 32px);
	}
	.feature_benifit_area .card.active {
		padding: 30px 20px;
	}
	.sn_pd_two_offer {
		right: 32px;
		top: 98px;
	}
	.call_to_action_left_two {
		margin-right: 30px;
	}
	.sn_pd_two_slide {
		padding: 30px 20px;
	}
	.services_bg_two .pl-60 {
		padding-left: 30px;
	}
	.promote_sub {
		left: 0;
	}
	.backtotop.backtop_two {
		right: 0;
	}
	.promote_area.promote_area_one.section_padding {
		text-align: center;
	}
	.promote_area.promote_area_one .promote_sub {
		left: 0;
		text-align: left;
		margin-bottom: 50px;
	}
	.banner_area.banner_three .banner_left .banner_left_cell h1 {
		font-size: 55px;
		line-height: 55px;
	}
	.home_five_single_service {
		width: 31.2%;
	}
	.home_five_single_service_right h3 {
		font-size: 18px;
		letter-spacing: 1.5px;
	}
	.home_five_single_service_right p {
		margin-bottom: 0;
	}
	.footer_five_area .widget.widget5 .widget5_about {
		padding-right: 26px;
		margin-top: 0;
	}
	.home_five_single_service_inner img {
		padding-top: 28px;
	}
	.home_five_single_service_img {
		left: -35px;
	}
	.home_five_single_service:last-child .home_five_single_service_img {
		right: -33px;
	}
	.home5_offer_center h2 {
		font-size: 53px;
	}
	.home5_recent_slider .owl-nav {
		width: 100%;
		left: 0;
	}
	.home5_video_left {
		width: 40%;
		padding-right: 100px;
	}
	.home5_video_right {
		width: 59%;
	}
	.banner_static .banner_static_left_cell h1 span {
		font-size: 68px !important;
	}
	.home5_video_total .section_title_four {
		margin-left: -35px;
		z-index: 9;
	}
	.home5_offer_social {
		padding-right: 0;
	}
	.home5_offer_center h2::after {
		left: 12px;
		top: -90%;
	}
	.home5-most-sold .sn_related_product {
		padding: 30px 10px;
	}
	.footer_five_area .widget5_social a {
		width: 30px;
		height: 30px;
		line-height: 26px;
	}

	.main_menu > ul {
		padding-left: 0;
	}

	.sn_bnr_slide {
		padding: 0 30px;
	}
	.banner_area {
		height: 75vh;
	}

	.banner_right,.banner_left {
		padding-top: 125px;
		height:75vh;
	}
}

@media (min-width: 1200px) and (max-width: 1400px) {
	.vigo_container_one, .vigo_container_two {
		max-width: 1140px;
	}
	.home5_offer_left {
		left: -164px;
	}
	.home5_offer_center h2::after {
		left: 15px;
		top: -92%;
	}
}

@media (min-width: 992px) and (max-width: 1199px) {
	.vigo_container_one,
	.vigo_container_two {
		max-width: 960px;
	}
	.banner_right,.banner_left {
		padding-top: 99px;
	}
	.banner_area {
		height: 100vh;
	}
	.banner_right, .banner_left {
		height: 100vh;
	}
	#example-one {
		padding-left: 0;
	}
    .main_menu > ul > li > a {
		font-size: 0.9rem;
		padding: 0.7rem 0.5rem 0.5rem;
	}
	.btn_wrapper {
		width: 8rem;
	}
	.sn_post_detail p a {
		padding: 0 0.2rem;
	}
	.promote_area_one.promote_area.section_padding {
		text-align: center;
	}
	.promote_area_one .promote_sub {
		width: 65%;
		text-align: left;
		margin-bottom:50px;
	}
	.sn_post_detail p a:last-child {
		margin-left: 0;
	}
	.btn_one {
		font-size: 0.9rem;
	}
	.widget {
	    padding-right: 0;
	    margin-bottom: 0;
	}
	.widget .widget_payment span {
	    display: block;
	}
	.footer_top_two .widget {
	    margin-bottom: 40px;
	}
	.ingredient_slider_one .slick-prev, .ingredient_slider_one .slick-next {
		top: 86%;
	}
	.footer_top_two.section_padding {
		padding-bottom: 3.8rem;
	}
	.widget.widget_store_links,
	.widget.widget_instafeed {
		padding-left: 60px;
	}
	.contact_content {
		padding-left: 30px;
	}
	.product_detail_desc {
		top: 5rem;
		padding: 3rem 0 0 1.7rem;
	}
    .product_detail_slider {
    	width: calc(50% + 70px);
    }
	.question {
		padding: 1rem;
	}
	.question span {
		line-height: 1.3rem;
	}
    .ans_body {
    	padding: 1rem;
  	}
	.faq_right_bg {
		position: relative;
		top: 50%;
		-webkit-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);
	}
	.btn_wrapper {
	    width: 9rem;
	}
	.header_nav .btn_wrapper {
		width: 7rem;
	}
	.pricing_head h6 {
	    font-size: 1rem;
	}
	.about_section {
		padding: 0;
	}
  .banner_left .banner_left_cell h1 {
    font-size: 2rem;
  }
  .banner_right {
    position: static;
  }
  .banner_left {
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    margin-top: 10px;
  }
  #feature {
    padding-bottom: 3rem;
  }
  .widget_instafeed .widget_insta_list ul li {
    width: 105px;
    height: 105px;
  }
  .supp_ser_list,
  .supplement_service_area .section_title {
    width: 75%;
    margin: 0 auto 40px;
  }
  .supp_ser_list{
    margin: 0 auto 80px;
  }
  .promote_area.section_padding {
    padding-bottom: 110px;
  }
  .package_plan_area.section_padding {
    padding-bottom: 60px;
  }
  .single_pkg_plan {
    max-width: 339px;
    margin: 0 auto;
    }
  .single_pkg_plan {
    margin-bottom: 60px;
  }
  .advertise_text_left {
    width: 74%;
  }
  .advertise_text_right {
    margin-top: 31px;
  }
  .advertise_area .row.background_white {
    padding: 30px 0;
  }
  // Home One
	.banner_two_area .banner_left .banner_left_cell h1 {
		font-size: 55px;
		line-height: 60px;
	}
	.tesm_three_content {
		background-position: center center;
	}
	.quote_mark {
		top: -55px;
		left: calc(50% - 31px);
	}
	.hdr_area_two .btn_one {
		line-height: 38px;
	}
	.hdr_area_two .btn_one, .hdr_area_two .btn_two {
		width: 125px;
	}
	.hdr_area_two .main_menu li:hover .sub-menu {
		padding-left: 26px;
	}
	.why_use_area {
		background-size: contain;
		background-repeat: no-repeat;
	}
	.banner_two_area .banner_left .banner_left_cell p {
		margin-bottom: 50px;
	}
	.quote_mark {
		top: -58px;
	}
	.banner_two_area .quote_mark {
		height: calc(100vh - 147px);
	}
	.sn_pd_two_slide {
		padding: 20px;
	}
	.sn_pd_two_detail h4 {
		display: inline;
	}
	.sn_pd_two_offer {
		right: 12%;
		top: 26%;
	}
	.widget.widget_three .widget_nwsfeed_list a {
		width: 43px;
		height: 43px;
	}
	.banner_two_area .banner_left {
		height: calc(100vh - 147px)
	}
	.client_talk {
		display: block;
	}
	.client_talk p {
		margin-bottom: 10px;
		margin-right: 0;
	}
	// product detail page
	.single_promote .btn_wrapper{
		margin: 0 auto;
	}
	.single_pkg_bottom .btn_wrapper {
		margin: 0 auto;
	}
	.section_title_two h2 {
		font-size: 28px;
		line-height: 36px;
	}
	.form_group .btn_wrapper {
		margin-top: 0;
		margin:0 auto;
	}
	.error_content_area .btn_wrapper {
		margin: 0 auto;
	}
	.error_content_area .btn_one,
	.error_content_area .btn_two {
		padding: 0 21px;
	}

	.banner_area .btn_wrapper {
		margin: 0 auto;
	}
	.quickview-wrapper .ingredient_slider_two .ingredient-img {
		padding: 50px;
	}
	.modal.quickview-wrapper::before {
		width: calc(100% - 138px);
	}
	.hdr_area_two .main_menu > ul > li > a {
		padding: 0 8px;
	}
	.call_to_action_area_two::before {
		display: none;
	}
	.backtotop.backtop_two {
		right: 0;
		bottom: 66px;
	}
	.single_service_two {
		padding: 0px 0px;
	}
	.services_bg_two .pl-60 {
		padding-left: 30px;
	}
	.ingredeint_section_three .ingredient_content h5 {
		font-size: 26px;
	}
	.call_to_action_left {
		width: 82%;
	}
	.pl-60 {
		padding-left: 30px;
	}
	.services_area .section_title h2 {
		line-height: 30px;
	}
	.banner_area.banner_three .banner_left .banner_left_cell h1 {
		font-size: 53px;
		line-height: 58px;
	}
	.banner_area.banner_three .banner_left .banner_left_cell p {
		margin-bottom: 5rem;
	}
	.supplement_service_area_two.section_padding_two {
		padding-bottom: 90px;
	}
	.banner_two_area .owl-prev {
		top: calc(50% - 40px);
		left: -15px;
		width: 40px;
		height: 80px;
		border-top-right-radius: 80px;
		border-bottom-right-radius: 80px;
	}
	.banner_two_area .owl-next {
		top: calc(50% - 40px);
		width: 40px;
		height: 80px;
		border-top-left-radius: 80px;
		border-bottom-left-radius: 80px;
	}
	.banner_two_area .owl-next i {
		margin-right: 5px;
	}
	.banner_two_area .owl-prev i {
		margin-left: 5px;
	}
	.home_five_single_service {
		width: 55%;
		left: 50%;
		transform: translateX(-50%);
		margin-bottom: 50px;
	}

	// home5
	.hdr_area_four .main_menu > ul > li > a {
		padding: 0 7px;
	}
	.hdr_area_four .main_menu > ul > li > a {
		font-size: 12px;
	}
	.banner_static .banner_static_left_cell h1 span {
		font-size: 54px !important;
	}
	.banner_static .banner_static_left_cell h1  {
		line-height: 60px !important;
	}
	.banner_static_download p {
		font-size: 12px !important;
		margin-bottom: 10px !important;
	}
	.banner_static .banner_static_left_cell h1::after {
		left: 0;
		top: -50%;
		height: 250px;
	}
	.hdr_area_four .main_menu > ul > li > a::after {
		left: 0;
	}
	.home5_offer_social {
		padding-right: 0;
	}
	.home5_offer_center h2 {
		font-size: 36px;
	}
	.home5_offer_center h2::after {
		left: 0;
		top: -62px;
	}
	.home5_offer_right span {
		left: auto;
		right: 40px;
	}
	.home5_video_right {
		width: 54%;
	}
	.home5_video_left {
		width: 45%;
		padding-right: 0;
	}
	.home5_recent_slider .owl-nav {
		left: 0;
		width: 100%;
	}
	.home5_video_total .section_title_four {
		z-index: 9;
	}
	.sn_pd_detail a {
		display: block;
	}
	.home5_pricing_single {
		margin-bottom: 50px;
	}
	.footer_five_area .footer_five_top {
		display: block;
	}
	.footer_five_area .footer_five_middle_flex {
		flex-flow: wrap;
	}
	.footer_five_area .widget.widget5 {
		width: 33.33%;
		margin-bottom: 50px;
	}
	.footer_five_area .widget.widget5:last-child {
		width: 63%;
	}
	.footer_five_area .footer_five_middle {
		padding: 95px 0 15px;
	}
	.footer_five_top_left {
		text-align: center;
		margin-bottom: 20px;
	}
	.loading-subscribe.loading-subscribe-white {
		max-width: 700px;
		min-width: auto;
	}
}

@media (min-width: 768px) and (max-width: 991px) {
  body {
    font-size: 0.9rem;
  }
  .vigo_container_one,
  .vigo_container_two {
    max-width: 720px;
  }
  h2 {
    font-size: 1.2rem;
  }
  h4 {
    font-size: 1rem;
  }
  h6 {
    font-size: 1rem;
  }
  .btn_wrapper {
		width: 9rem;
		height: 3rem;
	}
	.single_promote .btn_wrapper{
		margin: 0 auto;
	}
	.single_pkg_bottom .btn_wrapper {
		margin: 0 auto;
	}
  .section_padding {
    padding: 5rem 0;
  }
  .section_title {
    margin-bottom: 2rem;
  }
  .footer_top_two.section_padding {
    padding-bottom: 2rem;
  }
  .btn_one {
    line-height: 2rem;
  }
  .widget .widget_form form button {
    width: 44px;
  }
  .widget .widget_payment a i {
    font-size: 1.6rem;
  }
  .footer_top .widget {
    padding-right: 0;
    margin-bottom: 0;
  }
  .contact_content {
    padding-left: 30px;
  }
  .section_title h2 {
		line-height: 36px;
		font-size: 24px;
	}
	.pl-60 {
		padding-left: 0;
	}
	.video_area_two .about_section {
		padding: 0;
	}
  .form_group.half {
    width: 100%;
    padding: 0;
  }
  .error_content_area.section_padding {
		padding: 80px 0;
	}
	.error_content_area.error_content_two.section_padding {
		padding: 112px 0 90px;
	}
  .sn_post_detail p a:last-child {
    margin-left: 0;
  }
  .sn_post_detail p a {
    padding: 0 0.5rem;
  }
  .contact_page_form {
		padding-bottom: 80px;
	}
	.contact_content_two .contact_page_form {
		padding-bottom: 120px;
	}
  .product_detail_desc {
    padding: 1rem 0 0 1rem;
    height: 100%;
    top: 2rem;
  }

	.backtotop.backtotop_three {
		bottom: -2px;
		right: -12px;
	}
  .product_detail_slider {
    width: calc(50% + 30px);
  }
  .question {
    padding: 1rem;
  }
  .call_to_action_left {
		width: 78%;
	}
  .question span {
    line-height: 1.4rem;
  }
  .ans_body {
    padding: 1rem;
  }
  .faq_right_bg {
    text-align: right;
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%);
  }
  .single_process h6 {
    font-size: 0.9rem;
  }
  .about_section {
    padding: 0;
  }
  .banner_right {
    position: static;
  }

	.home5_banner .btn_two {
		box-shadow: none;
	}
  .promote_area.section_padding {
    padding-bottom: 80px;
    padding-top: 80px;
  }
  .package_plan_area.section_padding {
    padding-bottom: 0;
  }
  .promote_right {
    margin-top: 50px;
  }
  .advertise_text_left {
    width: 62%;
  }
  .advertise_area .row.background_white {
    padding: 30px 0;
  }
  .advertise_text_right {
    margin-top: 21px;
  }
  .services_area_bg .image-box {
    display: none;
  }
  .service_two.section_padding {
    padding-bottom: 0;
  }
	.banner_two_area .banner_left .banner_left_cell h1 {
		font-size: 41px;
		line-height: 48px;
	}
	.banner_right_cell .bnr_secondary_img {
		margin-top: 0;
	}
	.tesm_three_content {
		background-position: center;
	}
	.client_talk {
		display: block;
		padding: 0 15px;
	}
	.client_talk p {
		margin-bottom: 10px;
	}
	.quote_mark {
		left: calc(50% - 31px);
	}
	.sn_pd_two_slide {
		padding: 20px 30px;
	}
	.sn_pd_two_offer {
		right: 10%;
		top: 24%;
	}
	.sn_pd_two_detail {
		display: flex;
	}
	.sn_pd_two_slide {
		padding: 20px;
	}
	.widget.widget_three {
		width: 33.33%;
	}
	.widget.widget_three:last-child {
		width: 40%;
	}
	.widget.widget_three {
		margin-bottom: 30px;
	}
	.accordion_btn {
		margin-bottom: 50px;
	}
	.why_use_area {
		overflow: hidden;
	}
	.banner_two_area .banner_left .banner_left_cell p {
		margin-bottom: 60px;
		font-size: 18px;
	}
	// product detail page
	.ingredient_slider_two .ingredient-img {
		margin: 0;
		width: 100%;
	}
	.ingredient_slider_detail {
		padding-left: 0;
		margin-top: 80px;
	}
	.related_product_title h1 {
		font-size: 27px;
		margin-bottom: 63px;
	}
	.sn_review_input input,
	.sn_review_input textarea {
		width: 100%;
	}
	.form_group .btn_wrapper {
		margin-top: 0;
		margin:0 auto;
	}
	.error_content_area .btn_wrapper {
		margin: 0 auto;
	}

	.error_content_area .btn_one,
	.error_content_area .btn_two {
		padding: 0 21px;
	}
	.banner_area .btn_wrapper {
		margin: 0 auto;
	}
	.client_talk p {
		font-size: 20px;
		margin-right: 0;
	}
	.section_title_two p {
		font-size: 18px;
	}
	.section_title_two {
		margin-bottom: 80px;
	}
	.product_two_slider .owl-nav {
		top: -60px;
	}
	.quickview-wrapper .col-xl-7,
	 .quickview-wrapper .col-xl-5 {
		max-width: auto !important;
		width: 100% !important;
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
	}
	.single_service_two {
		padding: 0px 0px;
	}
	.services_bg_two .pl-60 {
		padding-left: 0;
	}
	.work_process_area_two .single_process h6 {
		font-size: 14px;
	}
	.ingredient_content {
		margin-top: 35px;
	}

	.ingredeint_section_one .ingredient-img img {
		top: 0;
		-webkit-transform: translateY(0%);
		-ms-transform: translateY(0%);
		transform: translateY(0%);
		padding: 25px;
	}
	.banner_area.banner_three .banner_left .banner_left_cell h1 {
		font-size: 36px;
		line-height: 36px;
		margin-top: 0px;
	}

	.banner_right_cell {
		padding-top: 0;
	}
	.banner_area.banner_three .banner_left .banner_left_cell p {
		margin-bottom: 2rem;
	}
	#product_detail .product_detail_desc {
		height: auto;
	}
	.faq_right_bg {
		display: none;
	}
	#contact {
		padding-bottom: 5rem;
	}

	.footer_three_area {
		padding: 113px 0 88px;
	}
	.breadcrumb_area_two .page_header h1 {
		font-size: 44px;
		line-height: 46px;
	}
	.banner_three .sn_bnr_slide {
		padding-top: 46px;
	}

	// Home 5
	.home_five_single_service {
		width: 76%;
		margin-bottom: 50px;
		left: 50%;
		right: auto;
		transform: translateX(-50%);
	}
	.home5_offer_center {
		width: 90%;
		padding-bottom: 56px;
		padding-left: 0;
		padding-top: 56px;
	}
	.home5_offer_right {
		display: none;
	}
	.home5_offer_left {
		display: none;
	}
	.home5_video_left {
		width: 100%;
		padding-right: 0;
		margin-bottom: 50px;
	}
	.home5_video_right {
		width: 100%;
	}
	.home5_pricing_single {
		margin-bottom: 50px;
	}
	.footer_five_area .footer_five_middle_flex {
		flex-wrap: wrap;
	}
	.footer_five_area .widget.widget5 {
		width: 33.33%;
		margin-bottom: 50px;
	}
	.footer_five_area .widget.widget5:last-child {
		width: 66%;
	}
	.footer_five_area .footer_five_bottom {
		flex-wrap: wrap-reverse;
		text-align: center;
	}
	.footer_five_area .footer_five_top {
		display: block;
	}

	.footer_five_top_left {
		margin-bottom: 0;
	}
	.footer_five_area .footer_five_top .footer_five_top_right label::after {
		display: none;
	}
	.footer_five_area .footer_five_top .footer_five_top_right label {
		display: none;
	}
	.footer_five_area .footer_five_top .footer_five_top_right input {
		margin-left: 0;
		width: 80%;
	}
	.footer_five_area .widget.widget5 .widget5_about {
		padding-right: 30px;
		margin-top: 0;
	}
	.footer_five_area .footer_five_bottom_flex {
		flex-wrap: wrap;
	}
	.sn_pd_detail a {
		display: block;
	}
	.footer_five_area .footer_five_middle {
		padding: 95px 0 13px;
	}
	.banner_static .banner_static_left_cell h1 span {
		font-size: 40px !important;
	}
	.banner_static .banner_static_left_cell h1 {
		line-height: 60px !important;
	}
	.banner_static .banner_static_left_cell h1::after {
		left: 0;
		top: -50%;
		height: 200px;
	}
	.home_five_service {
		padding: 100px 0 45px;
	}
	.home5_recent {
		padding-bottom: 100px;
		padding-top: 5px;
	}
	.home5_recent.home5_feature {
		padding-top: 5px;
	}
	.home5_offer_detail {
		padding: 70px 0 77px;
	}
	.home5_offer {
		padding: 0px 0 95px;
	}
	.home5_video_total .section_title_four {
		margin-left: -35px;
	}
	.home5_video {
		padding-bottom: 100px;
	}
	.home5_pricing {
		padding-bottom: 78px;
	}
	.call_to_action_green {
		padding: 100px 0;
	}
	.footer_five_area .footer_five_top {
		padding: 50px 0;
	}
}

@media (min-width: 576px) and (max-width: 767px) {
  html {
    font-size: 16px;
  }
  h2 {
    font-size: 1.5rem;
  }
  .footer_top_two.section_padding {
    padding-bottom: 2rem;
  }
  .blog_area.section_padding_less {
		padding-bottom: 1.5rem;
	}
  .section_title {
    margin-bottom: 2.45rem;
  }
  .section_padding {
    padding: 5rem 0;
  }
  .section_padding_less {
    padding: 5rem 0;
  }
  .call_to_action_left p {
		width: 100%;
	}
  #portfolio, #ingredient, #work_process {
    padding-bottom: 5rem;
    padding-top: 5rem;
  }
  #feature {
    padding-bottom: 1.2rem;
  }
  .vigo_container_one,
  .vigo_container_two {
    max-width: 540px;
  }
  .widget {
    margin-bottom: 2.5rem;
    padding-right: 0;
  }
  .banner_right_cell .bnr_secondary_img {
		margin-top: 0;
	}
	.banner_area .owl-dots {
		display: none;
	}

	.banner_three .sn_bnr_slide {
		padding-top: 46px;
	}
  .footer_top {
    padding: 5rem 4rem;
  }
  .footer_top .col-xl-4:last-child .widget {
    margin-bottom: 0;
  }
  .contact_area.section_padding {
    padding-bottom: 20.67rem;
  }
  .map_main {
    top: calc(100% - 16.67rem);
    width: 100%;
    height: 16.67rem;
    padding-right: 0;
  }
  .contact_content {
    padding-left: 0;
  }
  .all_post {
    padding: 0 3rem;
  }
  .single_post {
    margin-bottom: 3rem;
  }
  .product_detail_slider {
    width: 100%;
  }
  .product_detail_desc {
    position: static;
    width: 100%;
  }
  #accordion {
    margin-bottom: 0;
  }
  .process_icon {
    width: 4.89rem;
    height: 4.89rem;
  }
  .process_icon span {
    font-size: 2rem;
    line-height: 4.89rem;
  }
  .single_price {
    margin-bottom: 2.45rem;
  }
  .pricing_table_area.section_padding {
    padding: 4rem 0 1.65rem;
  }
  .about_section {
    margin-top: 2.45rem;
  }
	.banner_left .banner_left_cell p {
		font-size: 17px;
	}
	.banner_left {
		padding-right: 0;
		height: auto;
	}
	.banner_right {
		height: auto;
	}
	.banner_left .btn_one, .banner_left .btn_two {
		width: 128px;
	}
	.banner_right_cell {
		padding-top: 0;
	}
  .banner_left .banner_left_cell h1 {
    font-size: 1.3rem;
  }
  .advertise_area .row.background_white {
    padding: 30px 0;
  }
  .advertise_text_left {
    width: 60%;
  }
  .advertise_text_right {
    margin-top: 30px;
  }
  .product_review_tab .tab-content {
		padding: 50px 40px;
	}
	.sn_review_input input {
		width: 100%;
	}
	.sn_review_input textarea {
		width: 100%;
	}
	.review_form h5 span {
		display: block;
		margin-top: 10px;
	}
  .advertise_img {
    margin-bottom: 30px;
  }
  .widget.widget_instafeed .widget_insta_list ul li {
    width: 72px;
    height: 72px;
  }
  .feature_three.section_padding {
    padding-bottom: 3rem;
  }
  .services_area_bg .image-box {
    display: none;
  }
  .service_two.section_padding {
    padding-bottom: 0;
  }
  .single_service_two {
    margin-bottom: 50px;
  }
  .single_service_two span {
    margin-bottom: 36px;
  }
  .service_two.section_padding .col-md-4:last-child .single_service_two {
    margin-bottom: 0;
  }
  .single_process h6 {
    font-size: 0.75rem;
  }

  	//home one
    .widget.widget_three {
		width: 33.33%;
		margin-bottom: 15px;
	}
    .widget.widget_three:last-child {
		width: 50%;
	}
	.accordion_btn {
		margin-bottom: 0px;
	}
	.sn_pd_two_slide {
		padding: 25px 10px;
	}
	.banner_two_area .banner_left .banner_left_cell p {
		margin-bottom: 25px;
	}
	.banner_two_area .banner_left .banner_left_cell h1 {
		line-height: 34px;
		font-size: 34px;
	}
	.banner_two_area .banner_left .banner_left_cell p {
		font-size: 18px;
		line-height: 28px;
	}
	.bnr_right_extra {
		width: 100px;
		height: 100px;
	}
	.banner_two_area {
		height: 100vh;
	}
	.tesm_three_content {
		margin-top: 0;
	}
	.btn_one, .btn_two {
		padding: 0 13px;
	}
	.client_talk p {
		margin-bottom: 10px;
		margin-right: 0;
		font-size: 20px;
		line-height: 26px;
	}
	.client_talk {
		display: block;
	}
	.tesm_three_content {
		margin-top: -76px;
		background-position: center;
		padding: 0 30px;
	}
	.quote_mark {
		left: calc(50% - 30px);
		top: -55px;
	}
	.tesm_three_content {
		background-position: center;
	}
	.why_use_area .vigo_container_one {
		width: 100%;
	}
	.why_use_area {
		overflow: hidden;
	}
	.banner_two_area {
		height: auto;
	}
	.why_use_area {
		padding: 75px 0 60px;
	}
	.section_title_two p {
		font-size: 18px;
	}
	.feature_benifit_area {
		padding-bottom: 80px;
	}
	.video_three_area_bg {
		padding: 80px 0;
	}
	.video_three_area_bg {
		padding: 72px 0 80px;
	}
	.video_three_area .section_title_two {
		margin-bottom: 76px;
	}
	.call_to_action_left_two {
		margin-right: 10px;
	}
	.section_title_two {
		margin-bottom: 75px;
	}
	.product_two_slider .owl-nav {
		top: -50px;
	}
	.footer_three_area {
		padding: 80px 0;
	}
	.footer_three_area {
		padding: 80px 0 50px;
	}
	.product_two_area {
		padding: 72px 0 55px;
	}
	// product detail page
	.ingredient_slider_one .slick-prev,
	.ingredient_slider_one .slick-next {
		top: 430px;
	}
	.ingredient_slider_two .ingredient-img {
		width: 100%;
		padding-bottom: 0;
		margin-bottom: 0;
	}
	.product_review_tab .nav li a {
		font-size: 14px;
		letter-spacing: normal;
	}
	.ingredient_slider_detail {
		padding-left: 0;
		margin-top: 50px;
	}
	.related_product {
		padding: 80px 0 70px;
	}
	.related_product_title h1 {
		font-size: 24px;
		margin-bottom: 60px;
	}
	.why_use_area .carousel {
		display: none;
	}
	.carousel_screenshot {
		display: block;
	}
	.banner_two_area .btn_one, .banner_two_area .btn_two {
		width: 130px;
	}
	.banner_two_area .banner_right {
		position: relative;
		height: 93vh !important;
	}
	.sn_pd_two_offer {
		right: 18px;
		top: 100px;
	}
	.call_to_action_hello {
		display: block;
		text-align: center;
	}
	.call_to_action_left_two {
		margin-right: 0;
		text-align: center;
		margin-bottom: 30px;
	}
	.services_bg_two .pl-60 {
		padding-left: 15px;
	}
	.work_process_area_two .single_process h6 {
		font-size: 11px;
	}
	.promote_area_two .single_promote ul {
		padding: 0 15px;
	}
	.ingredeint2_section {
		padding: 80px 0;
	}
	.page_content_two.section_padding_two {
		padding: 80px 0;
	}
	.video_area_two .about_section {
		margin-top: 0;
		margin-bottom: 60px;
	}
	.banner_area.banner_three .banner_left .banner_left_cell h1 {
		font-size: 30px;
		line-height: 30px;
		margin-top: 0;
	}
	.banner_area.banner_three .banner_left .banner_left_cell p {
		display: none;
	}
	.banner_area.banner_three .btn_two {
		width: 110px;
	}
	.banner_area.banner_three .banner_right {
		height: 100vh !important;
	}
	.product_detail_area.section_padding {
		padding-bottom: 5rem;
	}
	.breadcrumb_area_two .page_header h1 {
		font-size: 44px;
		line-height: 45px;
	}

	.banner_right_cell img {
		margin-top: 0;
	}

	.sn_bnr_slide {
		padding: 0px 50px;
	}

	.banner_area {
		height: 600px;
		padding: 165px 0 80px;
	}
	.banner_left .banner_left_cell p {
		display: none !important;
	}
	.banner_right {
		margin-left: -87px;
	}
	.sn_bnr_slide {
		height: 370px;
	}
	.footer_four_area .footer_four_bottom_right ul li:first-child a {
		padding-left: 0;
	}
	.home5_recent {
		padding-top: 5px;
	}
	.section_title_four h2 {
		font-size: 44px;
		box-shadow: -1px -1px 5px rgba(0, 0, 0, 0.05);
	}
	.home_five_service {
		padding: 80px 0 25px;
	}
	.home5_offer_social {
		padding-top: 0;
		padding-right: 0;
	}
	.home5_recent {
		padding-bottom: 75px;
	}
	.home5_offer_detail {
		padding: 45px 0 0;
	}
	.home5_offer {
		padding: 0px 0 75px;
	}
	.home5_recent.home5_feature {
		padding-bottom: 80px;
	}
	.home5_video_total .section_title_four {
		margin-left: -80px;
	}
	.home5_video {
		padding-bottom: 80px;
	}
	.home5_pricing {
		padding-bottom: 80px;
	}
	.footer_five_area .footer_five_top {
		padding-top: 75px;
	}
}

@media (min-width: 991px) {
  #mobile-nav-wrap {
    display: none;
  }
  .sn_pd_slide {
    padding: 0px 70px;
  }
}

@media (max-width: 1200px) {
    .video_popup {
		height: auto;
		top: 50%;
		-webkit-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);
  }
  .footer_top .widget {
    padding-right: 0;
    margin-bottom: 0;
  }
  .sn_contact_feature {
    padding: 0;
  }
  .promote_right {
    position: static;
    width: 100%;
  }
  .supplement_service_img {
    text-align: center;
  }
  .supp_icon {
    margin-top: 15px;
  }
  #mobile-nav-wrap {
    display: block;
  }
  .top_toolbar,
  .header_nav {
    display: none;
  }

	.hdr_area_two .mobile_toolbar {
		background: $home6_primary2;
	}

	.hdr_area_three .mobile_toolbar {
		background: #333;
	}

	.hdr_area_two .mobile_toolbar .header_login a {
		color: #fff;
	}
	.hdr_area_two #mobile-nav-wrap {
		background: $home6_primary3;
		border-bottom: 1px solid rgba(255,255,255,0.3);
	}

	.headroom--not-top.hdr_area_two #mobile-nav-wrap {
		background: $home6_primary3;
	}
	#mobile-nav-wrap .bottom_nav_two #mobile-logo {
		padding: 0;
		line-height:60px;
	}
	.mobile-menu-inner-two .mobile_accor_togo {
		background: $home6_primary;
	}
	.close-menu span::after {
		background: $home5_black;
	}
	.close-menu span::before {
		background: $home5_black;
	}
	.mobile-menu-inner-two .close-menu span::after {
		background: #fff;
	}
	.mobile-menu-inner-two .close-menu span::before {
		background: #fff;
	}
	.hdr_area_two .phone_number a {
	    color: #fff;
	}
	.phone_number span {
	    color: #fff;
	}
	.toggle-inner i {
	    color: #fff;
	}
	.mobile-menu-inner {
	    width: 33%;
	}
}

@media (max-width: 991px) {
  .ingredeint_section.section_padding {
		padding: 4.7rem 0 3.7rem;
	}
	.banner_two_area .banner_left .banner_left_cell h1 {
		margin-top: 0;
	}
  .sn_pd_slide {
    padding: 0px 70px;
  }
  .single_price {
    margin-bottom: 40px;
  }
  .video_popup a {
    left: calc(50% - 45px);
    top: calc(50% - 45px);
    width: 90px;
    height: 90px;
  }
  .video_popup a i {
    font-size: 2.72rem;
  }
  .video_two_area {
    height: 70vh;
  }
  .image-box-half {
    display: none;
  }
  .mobile-menu-inner {
    width: 50%;
  }
  .sn_contact_feature {
    width: 32.8%;
    margin-bottom: 50px;
  }
  .footer_bottom_two .copyright,
  .footer_bottom_two .widget_payment {
    text-align: center;
  }
  .footer_top_two .widget_title {
    margin-bottom: 2.22rem;
  }
  .footer_top_two .widget {
    margin-bottom: 40px;
  }
  .promote_sub {
    padding: 4.45rem;
    width: 100%;
    margin: 0 auto;
    left: 0;
  }
  .promote_right {
    position: static;
    bottom: 0;
    width: 100%;
  }
  .single_promote ul li {
    font-size: 0.7rem;
  }
  .single_pkg_plan {
    margin-bottom: 1.8rem;
  }
  .supp_ser_list {
    padding-right: 0;
    margin-bottom: 50px;
  }
  .widget.widget_about_two {
    padding-right: 0;
  }
  .banner_left .banner_left_cell h2 {
    font-size: 1rem;
  }
  .bnr_title_img {
    margin-bottom: 12px;
  }
  .supplement_service_area.section_padding {
		padding: 5rem 0 4.5rem;
	}

	// home one
	.hdr_area_two .phone_number a {
		color: #fff;
	}
	.hdr_area_two .phone_number span {
		color: #fff;
	}
	.hdr_area_two .toggle-inner i {
		color: #fff;
	}
	.headroom--not-top.hdr_area_two .toggle-inner i {
		color: #fff;
	}
	.banner_two_area .banner_slider {
		padding-top: 0;
	}
	.breadcrumb_area_two .page_header {
		padding-top: 98px;
	}
	.breadcrumb_area_two .breadcrumb_inner {
		height: 300px;
	}
	.backtotop.backtop_two {
		bottom: 20px;
		right: 20px;
	}
	.supplement_service_area_two .supplement_service_img {
		display: none;
	}
	.supplement_service_area_two .supp_ser_list {
		margin-bottom: 0;
	}
	.call_to_action_area_two::before,
	.call_to_action_area_two::after {
		display: none;
	}
	.accordion_btn {
		padding-left: 0;
	}

	.bottom_nav_two .toggle-inner i {
		padding-top: 0;
		line-height:60px;
	}

	.services_area_bg.services_bg_two {
		background: #f8f8f8;
	}
	.tab-content {
		margin-left: 0;
	}
	.feature_benifit_area #accordion {
		margin-bottom: 0;
	}
	.feature_benifit_area .accordion_btn {
		margin-bottom: 65px;
	}
	.modal.quickview-wrapper {
		overflow-y: scroll;
		height: 400px;
		width: 400px;
	}
	.quickview-wrapper .ingredient_slider_one.slick-slider {
		height: auto;
	}
	.quickview-wrapper .ingredient_slider_main {
		margin-bottom: 0;
	}
	.modal.quickview-wrapper::before {
		display: none;
	}
	.quickview-wrapper .ingredient_slider_two .ingredient-img {
		padding-bottom: 0;
		padding-left: 0px;
		padding-top: 0;
		padding-right: 25px;
	}
	.quickview-wrapper {
		width: 100%;
	}
	.quickview-wrapper .ingredient_slider_detail {
		margin-left: 20px;
	}
	.mask-overlay2 {
		z-index: 999;
		background: rgba(0,0,0,0.8);
	}

	.quickview-wrapper .ingredient_slider_one .slick-prev,
	.quickview-wrapper .ingredient_slider_one .slick-next {
		display: none !important;
	}
	.modal.quickview-wrapper {
		background: #fff;
	}
	.promote_area_two .promote_sub {
		top: 0;
		transform: translateX(-50%);
		width: auto;
		left: 50%;
	}
	.services_area_bg.section_padding {
		padding-bottom: 1rem;
		background: #f8f8f8;
	}
	.service_two.section_padding {
		padding-bottom: 80px;
	}
	.services_area_bg::before, .services_area_bg::after {
		background: #f8f8f8;
		width: 100%;
	}
	.breadcrumb_area {
		height: 280px;
	}

	.banner_left .banner_left_cell h1 {
		font-size: 25px;
	}
	.banner_left {
		padding-right: 5px;
	}
}

@media (max-width: 767px) {
  .call_to_action_right {
    float: none;
    margin-top: 30px;
  }


  // banner
    .banner_left {
		padding-top: 50px;
	}
	.banner_right {
		padding-top: 0;
	}

	.home5_banner .btn_two {
		-webkit-box-shadow: 18px 18px 38px #dedede;
		box-shadow: none;
	}
  .call_to_action_area,
  .call_to_action_left {
    text-align: center;
  }
  .footer_top_two .widget.widget_about_two {
    padding-right: 0;
  }
  .promote_sub {
    padding: 1rem;
    width: 100%;
  }
  .promote_right {
    position: static;
    width: 100%;
  }
  .promote_sub {
    padding: 1rem;
    width: 100%;
    margin-bottom: 50px;
  }
	.contact_page_form {
		padding-bottom: 4.8rem;
	}
  .single_promote {
    margin-bottom: 50px;
  }
  .promote_sub h2 {
    font-size: 1.2rem;
  }
  .promote_area.section_padding {
    padding: 80px 0 30px;
  }
  .package_plan_area.section_padding {
    padding-bottom: 50px;
  }
  .single_pkg_plan {
    margin-bottom: 30px;
  }
  .error_cont_img {
    margin-bottom: 50px;
  }
  .error_cont_desc h1 {
    font-size: 110px;
    line-height: 110px;
  }
  .error_cont_desc p {
    font-size: 1rem;
  }
  .widget_recent_post .widget_post_list li a span {
    margin-right: 5px;
  }
  .video_two_area {
    height: 60vh;
  }
  .supp_icon {
    margin-top: 15px;
  }
  .services_area_bg.section_padding {
    padding-bottom: 1.5rem;
  }

  .footer_bottom_two .copyright span {
		margin-top: 0;
	}
	.sn_contact_content p {
		font-size: 0.9rem;
	}
	.sn_contact_content a {
		font-size: 0.9rem;
	}
	.breadcrumb_area_two .page_header {
		padding-top: 98px;
	}
	.breadcrumb_area_two .breadcrumb_inner {
		height: 300px;
	}
	.section_title_two h2 {
		font-size: 28px;
		line-height: 33px;
	}
	.promote_area_two.section_padding_two {
		padding-bottom: 30px;
	}
	.testimonial_hm_two .section_padding_two {
		padding: 80px 0;
	}
	.package_plan_two.section_padding_two {
		padding-bottom: 30px;
	}

	.service_three.section_padding_two {
		padding-bottom: 30px;
	}
	.feature_four.section_padding_two {
		padding-bottom: 50px;
	}

	.banner_two_area .owl-prev {
		width: 35px;
		height: 60px;
		border-top-right-radius: 60px;
		border-bottom-right-radius: 60px;
	}
	.banner_two_area .owl-next {
		width: 35px;
		height: 60px;
		border-top-left-radius: 60px;
		border-bottom-left-radius: 60px;
	}
	.banner_two_area .owl-prev i {
		margin-right: -10px;
	}
	.banner_two_area .owl-next i {
		margin-left: -10px;
	}
	.ingredeint_section_one .ingredient-img img {
		top: 0;
		-webkit-transform: translateY(0%);
		-ms-transform: translateY(0%);
		transform: translateY(0%);
		padding: 25px;
	}
	.map_main {
		padding-left: 0;
	}
	.contact_content {
		padding-left: 15px;
	}
	#contact {
		padding-bottom: 80px;
	}

	#footer .footer_top .widget {
		margin-bottom: 40px;
	}
	#footer .footer_top .widget.widget_subscribe {
		margin-bottom: 0px;
	}
	.footer_three_area {
		padding: 80px 0 65px;
	}

	.section_padding_two {
		padding: 80px 0;
	}

	.services_area_bg.services_bg_two {
		padding-bottom: 15px;
	}

    .error_content_area.section_padding {
		padding: 75px 0;
	}
	.error_cont_desc .btn_wrapper {
		height: auto;
	}

	.banner_static .banner_static_left_cell h1 {
		font-size: 50px;
		line-height: 50px;
	}
	// home5
	.banner_static_right {
		display: none;
	}
	.banner_static .banner_static_left_cell h1::after {
		left: 44px;
		top: -33%;
		height: 150px;
	}
	.home_five_single_service {
		width: 100%;
		margin-bottom: 50px;
	}
	.home5_offer_left {
		position: static;
	}
	.home5_offer_right {
		position: relative;
		text-align: right;
	}
	.home5_offer_inner {
		margin-left: 0;
		padding: 19px;
	}
	.home5_offer_center {
		padding-left: 30px;
		width: 100%;
		padding-right: 0px;
	}
	.home5_offer_center h2 {
		font-size: 42px;
	}
	.home5_offer_right span {
		left: 50%;
	}
	.home5_offer_center h2::after {
		left: 8px;
		top: -123%;
	}
	.home5_offer_detail .home5_offer_detail_bg {
		display: none;
	}
	.section_title_four h2 {
		font-size: 44px;
	}
	.home5_video_left {
		width: 100%;
		margin-bottom: 30px;
		padding-right: 0;
	}
	.home5_video_right {
		width: 100%;
	}
	.home5_pricing_single {
		margin-bottom: 50px;
	}
	.footer_five_area .footer_five_middle {
		flex-flow: wrap;
	}
	.footer_five_area .widget.widget5 {
		width: 50%;
		margin-bottom: 50px;
	}
	.footer_five_area .widget.widget5:last-child {
		width: 100%;
		margin-bottom: 0;
	}
	.sn_pd_detail a {
		display: block;
	}

	.footer_five_area .footer_five_bottom {
		flex-flow: wrap-reverse;
		text-align: center;
	}
	.footer_four_bottom_left {
		width: 100%;
		margin-top: 16px;
	}
	.home5_video_right::after {
		top: -35px;
	}
	.footer_five_area .footer_five_top_flex {
		flex-wrap: wrap;
	}
	.footer_five_area .footer_five_top .footer_five_top_right label {
		margin-bottom: 30px;
	}
	.footer_five_area .footer_five_top .footer_five_top_right label {
		line-height: 24px;
	}
}

@media (max-width: 575px) {
	.banner_right {
		height: auto;
	}
  html {
    font-size: 16px;
  }
  h2 {
    font-size: 1.5rem;
  }
  .banner_area.banner_three .banner_left .banner_left_cell p {
		font-size: 18px;
	}
  .promote_sub h2 {
    font-size: 1.1rem;
  }
  .services_area .section_title h2 {
	line-height: 35px;
	}
	.footer_five_top_right {
		width: 100%;
	}
  .section_title h2 {
    line-height: 35px;
    font-size: 25px;
  }
  .section_title {
    margin-bottom: 2.45rem;
  }
  .ingredient_content_heading h5 {
		font-size: 1.1rem;
	}
  .section_padding {
    padding: 5rem 0;
  }
  .ingredeint_section.section_padding {
    padding-bottom: 4rem;
  }
  .section_padding_less {
    padding: 5rem 0;
  }
  .widget_payment span {
    display: block;
  }
  .top_toolbar {
    display: none;
  }
  .header_nav {
    display: none;
  }
  .services_area .section_title svg {
    display: none;
  }
  .widget {
    margin-bottom: 2.5rem;
    padding-right: 0;
  }
  .footer_top .col-xl-4:last-child .widget {
    margin-bottom: 0;
  }
  .map_main {
    position: static;
    width: 100%;
    height: 400px;
    padding-right: 0;
  }
  .contact_content {
    padding-top: 2.45rem;
  }
  .form_group.half {
    width: 100%;
    padding: 0;
  }
  .form_group.half:nth-child(2) input[required] + label {
    left: 0.84rem;
  }
  .sn_post_detail p a {
    font-size: 13px;
    padding: 0 0.4rem;
  }
  .sn_post_detail p a:last-child {
    margin-left: 0;
  }
  .product_detail_desc {
    position: static;
    width: 100%;
    padding: 1rem 0 0 1rem;
  }
  .product_detail_slider {
    width: 100%;
  }
  .product_detail_desc .btn_wrapper {
    margin-top: 30px;
    margin-left: -3px;
  }
  .question {
    padding: 1rem;
  }
  .question span {
    line-height: 1.5;
  }
  .question span:after, .question span:before {
    display: none;
  }
  #accordion {
    margin-bottom: 0;
  }
  .ans_body {
    padding: 1rem;
  }
  .all_processes .single_process {
    width: 100%;
  }
  .all_processes svg {
    display: none;
  }
  .all_processes .single_process {
    margin-bottom: 2.5rem;
  }
  .all_processes .single_process:last-child {
    margin-bottom: 0rem;
  }
  .single_price {
    margin-bottom: 2.45rem;
  }
  .about_section {
    padding: 0;
    margin-top: 2.45rem;
  }
  .video_two_area {
    height: 55vh;
  }
  .video_popup {
    margin-bottom: 0;
  }
  .video_popup:after {
    display: none;
  }
  .video_popup a {
    left: calc(50% - 35px);
    top: calc(50% - 35px);
    width: 70px;
    height: 70px;
  }
  .video_popup a i {
    font-size: 2.5rem;
  }

  // banner
    .banner_left {
		padding-right: 15px;
		padding-left: 15px;
		height: auto;
		padding-top: 50px;
	}
	.banner_right {
		padding-top: 0;
	}
	.banner_left .banner_left_cell h1 {
		font-size: 21px;
	}
	.banner_right {
		display: block;
	}
	.banner_left .banner_left_cell h2 {
		padding-top: 0;
	}
	.banner_right_cell .bnr_secondary_img {
		margin-top: 0;
	}
	.banner_right_cell {
		padding-top: 0;
	}
	.banner_slider {
		height: 480px;
		padding-top: 113px;
	}
	.banner_left .banner_left_cell h1 span {
		margin-top: 8px;
		font-size: 21px;
	}
	.banner_left .banner_left_cell h1 {
		font-size: 18px;
	}
	.banner_area {
		height: auto;
	}

	.slr_btn_wrapper .btn_two {
		width: 120px;
		font-size: 14px;
	}
  .sn_contact_feature {
    width: 100%;
    margin-bottom: 2.77rem;
  }
  .contact_page_form {
    padding-bottom: 5rem;
  }
  .page_header h1 {
    font-size: 1.89rem;
  }
  .widget_instafeed .widget_insta_list ul li {
    width: 5.5rem;
    height: 5.5rem;
  }
  .footer_top_two .widget_title {
    margin-bottom: 2rem;
  }
  .footer_top_two .widget {
    margin-bottom: 50px;
    width: 87%;
  }
  .footer_area .widget {
    margin-bottom: 50px;
  }
  .advertise_area .row.background_white {
    padding: 30px 10px;
  }
  .advertise_img {
    margin-bottom: 30px;
  }
  .advertise_text_left {
    margin-bottom: 30px;
  }
  .advertise_text_right {
    float: left;
  }
  .footer_top_two.section_padding {
    padding-bottom: 1.2rem;
  }
  #feature {
    padding-bottom: 0.8rem;
  }
  .video_popup_two h3 {
    font-size: 24px;
  }
  #portfolio, #ingredient, #work_process {
		padding-bottom: 5rem;
		padding-top: 5rem;
	}
  .testimonial_area_two .section_title {
    margin-bottom: 2rem;
  }
  #blog {
    padding-bottom: 3rem;
  }
  .services_area_bg .image-box {
    margin-bottom: 50px;
  }
  .padding_right {
    padding-right: 0;
  }
  .services_area_bg.section_padding {
    padding-bottom: 1rem;
    padding-top: 3.5rem;
  }
  .feature_three.section_padding {
    padding-bottom: 3.2rem;
  }
  .single_service_two {
    margin-bottom: 50px;
  }
  .service_two.section_padding {
	padding: 75px 0 30px;
	}
  .single_service_two span {
    margin-bottom: 35px;
  }
  .advertise_area {
    padding-bottom: 0;
  }
  .supplement_service_area.section_padding {
		padding: 5rem 0;
	}
	// home one
	.widget.widget_three {
		width: 50%;
		margin-bottom: 30px;
	}
	.widget.widget_three:last-child {
		width: 67%;
	}
	.call_to_action_left_two h2 {
		font-size: 30px;
	}
	.call_to_action_area_two::before {
		background: transparent;
	}
	.sn_pd_two_offer {
		right: 42px;
		top: 96px;
	}
	.accordion_btn {
		padding-bottom: 30px;
	}
	.sn_pd_two_slide {
		padding: 10px 10px;
	}
	.tesm_three_content {
		background-position: center;
	}
	.banner_two_area {
		height: auto;
	}

	.banner_two_area .banner_left .banner_left_cell h1 {
		font-size: 40px;
		line-height: 48px;
	}
	.carousel {
		display: none;
	}

	.carousel_screenshot {
		display: block;
	}
	.client_talk {
		display: block;
	}
	.client_talk p {
		font-size: 16px;
		margin-bottom: 10px;
	}
	.quote_mark {
		left: calc(50% - 31px);
	}

	.banner_two_area .banner_right {
		margin-bottom: 0px;
		display: none;
	}
	.banner_two_area .banner_slider {
		padding-top: 0;
		padding-bottom: 0;
	}

	.call_to_action_area_two::after {
		background: none;
	}
	.why_use_area {
		padding: 72px 0 60px;
	}
	.section_title_two {
		margin-bottom: 72px;
	}
	.video_three_area .section_title_two {
		margin-bottom: 72px;
	}
	.client_talk p {
		padding: 0 15px;
		margin-right: 0;
	}
	.feature_benifit_area #accordion {
		margin-bottom: 0;
	}
	.feature_benifit_area {
		padding-bottom: 80px;
	}
	.video_three_area_bg {
		padding: 70px 0 80px;
	}
	.product_two_area {
		padding: 80px 0;
	}
	.product_two_area {
		padding: 72px 0 54px;
	}
	.footer_three_area {
		padding: 80px 0;
	}
	.product_two_slider .owl-nav {
		top: -60px;
	}
	// product detail page
	.ingredient_slider_one .slick-slide {
		width: 100px;
		height: 120px;
	}
	.ingredient_slider_two .ingredient-img {
		border: 0px solid #fff;
		background: #fff;
		width: 100%;
		padding: 19px 0;
		margin: 0;
	}

	.ingredient_slider_one .slick-prev,
	.ingredient_slider_one .slick-next {
		top: 273px;
	}

	.ingredient_slider_main {
		margin-bottom: 50px;
	}
	.ingredient_slider_detail {
		padding-left: 0;
	}
	.product_review_tab .nav li {
		width: 50%;
		margin-top: -1px;
	}
	.product_review_tab .tab-content {
		padding: 20px;
	}
	.ingredient_slider_detail .product_quantity li {
		width: 92px;
		margin-right: 2px;
	}
	.ingredient_slider_detail .product_ratting span {
		font-size: 13px;
		margin-right: 5px;
	}
	.related_product_title h1 {
		font-size: 20px;
	}
	.related_product_slider .owl-nav {
		right: -3px;
		top: -54px;
	}
	.related_product {
		padding: 80px 0px 70px;
	}
	.sn_review_right {
		width: 100%;
		padding-left: 0;
		margin-top: 20px;
	}
	.sn_review_input textarea {
		width: 100%;
	}
	.sn_review_input input {
		width: 100%;
	}
	.backtotop.backtop_two {
		bottom: 10px;
		right: 10px;
	}
	.breadcrumb_area_two .page_header {
		padding-top: 88px;
	}
	.breadcrumb_area_two .breadcrumb_inner {
		height: 280px;
	}
	.section_padding_two {
		padding: 72px 0;
	}
	.faq3_area.section_padding_two {
		padding: 74px 0 0;
	}
	.section_title_two p {
		font-size: 15px;
	}
	.supplement_service_area_two .supplement_service_img {
		display: none;
	}
	.supplement_service_area_two .supp_ser_list {
		margin-bottom: 0;
	}
	.testimonial_hm_two .section_padding_two {
		padding: 72px 0;
	}

	.portfolio_area_two .accordion_btn {
		padding: 0;
	}
	.call_to_action_left_two p {
		font-size: 16px;
	}
	.section_title_two h2 {
		font-size: 28px;
		line-height: 36px;
	}
	.call_to_action_left_two {
		margin-right: 0;
		text-align: center;
		margin-bottom: 30px;
	}
	.call_to_action_left_two h2 {
		font-size: 28px;
		letter-spacing: 4px;
	}
	.feature_four.section_padding_two {
		padding-bottom: 50px;
	}
	.service_three.section_padding_two {
		padding-bottom: 30px;
	}
	.banner_left .banner_left_cell p {
		display: none !important;
	}
	.banner_two_area {
		height: 100vh;
	}
	.banner_two_area .sn_bnr_slide {
		height: 100vh;
	}
	.banner_two_area .banner_left .banner_left_cell p {
		display: none !important;
	}
	.banner_two_area .btn_one, .banner_two_area .btn_two {
		width: 120px;
	}
	.banner_two_area .banner_left .banner_left_cell h1 {
		margin-bottom: 40px;
	}
	.banner_area {
		height: 480px;
	}
	.modal.quickview-wrapper {
		width: 400px;
	}
	.call_to_action_hello {
		display: block;
		text-align: center;
	}
	.sn_pd_two_offer {
		right: 26px;
		top: 90px;
	}

	.product_two_slider {
		max-width: 280px;
		margin: 0 auto;
	}
	.services_bg_two .pl-60 {
		padding-left: 15px;
	}
	.single_feature_3 {
		padding: 45px 15px;
	}
	.single_service_two {
		padding: 0px 15px;
	}
	.related_product_title h1 {
		margin-bottom: 30px;
	}
	.ingredeint_section_three .ingredient-img {
		padding: 0 25px;
	}
	.ingredeint_section_three .ingredient_content {
		margin-top: 50px;
	}
	.ingredeint_section_three .slick_slider_two .slick-prev.slick-arrow {
		top: 0;
	}
	.ingredeint_section_three .slick_slider_two .slick-next.slick-arrow {
		top: 0;
	}
	.page_content_two.section_padding_two {
		padding: 80px 0;
	}
	.video_area_two .about_section {
		margin-top: 0;
		margin-bottom: 30px;
	}
	.call_to_action_left p {
		width: 100%;
	}

	//homeonepage
	.banner_area.banner_three .banner_right {
		height: auto;
	}
	.banner_area.banner_three .banner_left .banner_left_cell h1 {
		font-size: 36px;
		line-height: 40px;
	}
	.banner_area.banner_three .banner_left {
		height: 100vh;
	}

	#pricing {
		padding-bottom: 40px;
	}
	#work_process.work_process_area {
		padding-bottom: 75px;
	}
	.product_detail_area.section_padding {
		padding: 80px 0;
	}
	.share-wrap .product-share-link a {
		margin-left: 10px;
	}
	.share-wrap {
		padding: 16px 8px 16px;
	}
	.question span {
		padding: 0;
		font-size: 14px;
	}
	.faq_area #accordion {
		margin-bottom: 30px;
	}
	.product_detail_desc .btn_wrapper {
		margin-top: 0px;
	}
	.product-cart .btn_two {
		width: 118px;
	}
	#contact {
		padding-bottom: 80px;
	}
	.sin-portfolio .portfolio_details span {
		letter-spacing:0;
	}
	#faq {
		padding-bottom: 60px;
	}

	.banner_two_area .banner_left .banner_left_cell h1 {
		margin-top: 0;
	}
	.widget.widget_three .widget_logo {
		margin-top: 0;
	}
	.widget.widget_three:last-child {
		margin-bottom: 0;
	}
	.breadcrumb_area_two .page_header h1 {
		font-size: 36px;
		letter-spacing: 4px;
	}
	.sin_post_tag {
		margin-bottom: 60px;
	}
	.comments-title {
		margin-bottom: 73px;
	}
	.comment-list {
		margin-bottom: 0;
		padding-bottom: 0;
	}
	.comment-reply-title {
		margin-bottom: 80px;
	}
	.sin_post_tag ul li {
		margin-left: 15px;
	}
	.filter-button li {
		padding: 0 5px;
	}
	#ingredient .video-pop-inner {
		top: calc(50% - 35px);
		left: calc(50% - 35px);
	}
	#footer .widget.widget_about_two {
		padding-right: 0;
	}
	.top_feature_border {
		padding: 30px 10px;
	}
	// home 5
	.home_five_single_service {
		width: 100%;
		margin-bottom: 50px;
	}
	.home5_offer_center {
		width: 100%;
		padding-left: 0;
	}
	.home5_offer_left {
		position: static;
	}
	.home5_offer_center h2 {
		font-size: 30px;
	}
	.home5_video_total {
		padding: 68px 20px 72px;
	}
	.section_title_four h2 {
		font-size: 39px;
	}
	.home5_video_left {
		width: 100%;
		padding-right: 0;
	}
	.home5_video_right {
		width: 100%;
	}
	.home5_pricing_single {
		margin-bottom: 50px;
	}
	.footer_five_area .footer_five_top {
		display: block;
	}
	.footer_five_top_left {
		margin-bottom: 30px;
	}
	.footer_five_area .footer_five_top .footer_five_top_right label {
		line-height: normal;
		padding-right: 0px;
		margin-bottom: 30px;
		padding-top: 6px;
	}
	.home5_offer_center {
		padding-bottom: 0px;
		margin-bottom: 30px;
	}
	.home_five_service {
		padding: 80px 0 25px;
	}
	.home5_offer_inner {
		padding: 19px;
	}
	.home5_offer_center h2::after {
	    left: 11px;
	    top: -55px;
	    height: 130px;
	}
	.footer_five_area .footer_five_middle_flex {
		flex-wrap: wrap;
		flex-direction: row;
	}

	.footer_five_area .widget.widget5 {
		width: 50%;
	}
	.footer_five_area .widget.widget5:last-child {
		width: 100%;
	}
	.footer_five_area .widget.widget5 {
		margin-bottom: 50px;
	}
	.footer_five_area .widget.widget5 .widget5_about {
		padding-right: 20px;
	}
	.footer_five_area .footer_five_bottom_flex {
		flex-wrap: wrap-reverse;
		text-align: center;
	}
	.footer_four_bottom_left {
		width: 100%;
		margin-top: 16px;
	}
	.sn_pd_detail a {
		display: block;
	}
	.home5_video_right::after {
		left: 55%;
		top: 0;
		height: 128%;
	}
	.home5_offer_detail .home5_offer_detail_bg {
		display: none;
	}
	.home5_offer_inner {
		margin-left: 0;
	}
	.home5_offer_right {
		position: relative;
	}

	.home5_recent_slider .owl-nav {
		left: 0;
		width: 100%;
	}
	.section_title_four h2 {
		padding: 16px 20px;
		box-shadow: -1px -1px 5px rgba(0,0,0,0.05);
	}
	.home5_recent {
		padding-bottom: 80px;
		padding-top: 5px;
	}
	.home5_offer {
		padding: 0px 0 0px;
	}
	.home5_video {
		padding-bottom: 80px;
	}
	.home5_pricing {
		padding-bottom: 62px;
	}
	.call_to_action_green {
		padding: 80px 0 80px;
	}
	.footer_five_area .footer_five_top {
		padding-top: 74px;
	}

	.tp-banner-container {
		padding-top: 145px;
	}

	.footer_five_area .footer_five_bottom {
		padding: 0;
	}
}

@media only screen and (max-width: 575px) {
	.home5_offer_center h2 {
		font-size: 24px;
	}
	.tp-banner-container {
		padding-top: 167px;
	}
	.backtotop.backtotop_three {
		right: 0;
		margin-bottom: 0;
	}
	.banner_right {
		margin-left: 0;
	}

	.footer_five_area .footer_four_bottom_right {
		margin-right: 0;
	}

	.footer_five_top_right label {
		display: none;
	}
}

@media (max-width: 480px) {
}

@media (min-width: 400px) and (max-width: 575px) {
	.ingredient_slider_one .slick-prev,
	.ingredient_slider_one .slick-next {
		top: 386px;
	}
	.ingredient_slider_two .ingredient-img {
		padding: 17px 0;
	}
	.mobile-menu-inner {
		width: 70%;
	}
}

@media (min-width: 480px) and (max-width: 575px) {

}

@media (min-width: 320px) and (max-width: 479px) {
	.mobile-menu-inner {
		width: 100%;
	}
	.login-right {
		width: 100%;
		padding-left: 0;
	}
	.login-left {
		display: none;
	}
	.modal.show .modal-dialog {
		-webkit-transform: translateY(-32%);
		-ms-transform: translateY(-32%);
		transform: translateY(-32%);
	}
	.single_supp_ser {
		display: -webkit-box;
		display: -ms-flexbox;
		display: block;
		margin-bottom: 42px;
		padding-left: 0;
	}
	.supp_content {
		padding-left: 0;
		width: 100%;
	}
	.supp_icon {
		margin-bottom: 50px;
		margin-left: 15px;
	}
	.footer_top_two .widget {
		width: 100%;
	}
	// home one
	.widget.widget_three {
		width: 100%;
		margin-bottom: 50px;
	}
	.widget.widget_three:last-child {
		margin-bottom: 0;
	}
	.footer_three_area {
		padding: 80px 0;
	}
	.call_to_action_area_two::before {
		background: transparent;
	}
	.call_to_action_hello {
		height: auto;
		padding: 50px 0;
		display: block;
	}
	.call_to_action_left_two {
		margin-bottom: 15px;
	}
	.product_two_area {
		padding: 80px 0 56px;
	}
	.section_title_two h2 {
		font-size: 25px;
	}
	.video_three_area_bg {
		padding: 80px 0;
	}
	.why_use_area {
		padding: 80px 0;
	}
	.feature_benifit_area .card.active {
		padding: 15px;
	}
	.feature_benifit_area .card {
		padding: 0 20px;
	}
	#accordion {
		margin-bottom: 0;
	}
	.feature_benifit_area {
		padding-bottom: 80px;
	}
	.section_title_two {
		margin-bottom: 80px;
	}
	.carousel {
		overflow: hidden;
	}
	.accordion_btn {
		padding-bottom: 0px;
	}
	.banner_two_area .banner_left .banner_left_cell h1 {
		font-size: 35px;
		line-height: 35px;
	}
	.banner_two_area .banner_right {
		height: auto;
	}
	.banner_two_area .banner_slider {
		padding-top: 0px;
		padding-bottom: 0;
	}
	.banner_two_area .banner_left {
		position: relative;
	}
	.widget.widget_three:last-child {
		width: 100%;
	}

	// product detail page
	.call_to_action_hello {
		text-align: center;
	}
	.call_to_action_left_two {
		text-align: center;
	}
	.banner_two_area {
		height: 100vh;
	}
	.banner_two_area .sn_bnr_slide {
		height: 100vh;
	}

	.banner_two_area .owl-prev {
		width: 30px;
	}
	.banner_two_area .owl-next {
		width: 30px;
	}

	.modal.quickview-wrapper {
		width: 100%;
	}
	.product_two_slider {
		max-width: 280px;
		margin: 0 auto;
	}
	.portfolio_area_two .sin-portfolio .portfolio_details span {
		font-size: 12px;
		letter-spacing: 0;
	}
	.portfolio_area_two .sin-portfolio .portfolio_details h5,
	.portfolio_area_two .sin-portfolio .portfolio_details h5 > span {
		font-size: 13px;
		letter-spacing: 0;
	}
	.portfolio_area_two .sin-portfolio .portfolio_details {
		-webkit-transform: translate(-50%, 50%);
		-ms-transform: translate(-50%, 50%);
		transform: translate(-50%, 50%);
	}
	.page_content_two.section_padding_two {
		padding: 80px 0;
	}
	.footer_three_area {
		padding: 80px 0;
	}
	.sin-portfolio .portfolio_details h5,
	.sin-portfolio .portfolio_details h5 > span {
		font-size: 14px;
		letter-spacing: 0;
	}
	.banner_two_area .banner_left .banner_left_cell h1 {
		margin-top: 0;
	}

	.client_talk p {
		font-size: 15px;
	}
	.breadcrumb_area_two .page_header h1 {
		font-size: 29px;
		letter-spacing: 3px;
	}
	.footer_five_area .widget.widget5 {
		width: 100%;
	}
	.home_five_single_service_img {
		display: none;
	}
	.home_five_single_service_right {
		width: 100%;
	}
	.home_five_single_service:last-child .home_five_single_service_right {
		width: 100%;
	}
	.home_five_single_service_right {
		padding-top: 0;
		margin-bottom: 50px;
	}
	.home_five_single_service_inner {
		height: auto;
	}
	.home5_recent_slide {
		padding: 37px 15px 13px;
	}
	.home5_recent_slider .owl-nav {
		left: 0;
		width: 100%;
	}
	.home5_recent_slider .owl-nav .owl-prev,
	.home5_recent_slider .owl-nav .owl-next {
		width: auto;
		height: auto;
		line-height: auto;
		text-align: center;
		border: 2px solid transparent !important;
		background: transparent !important;
	}
	.home5_recent_slider .owl-nav .owl-prev:hover,
	.home5_recent_slider .owl-nav .owl-next:hover {
		border: 2px solid transparent !important;
	}
	.footer_five_area .footer_four_bottom_right a {
		padding: 0px 10px;
	}
	.footer_four_bottom_left {
		margin-top: 17px;
	}
	.section_title_four h2 {
		font-size: 24px;
	}
	.footer_five_area .footer_five_top .footer_five_top_right label {
		height: auto;
	}
	.footer_five_area .footer_five_top .footer_five_top_right label::after {
		display: none;
	}
	.footer_five_area .footer_five_top .footer_five_top_right label span {
		font-size: 12px;
	}
	.footer_five_area .footer_five_top .footer_five_top_right button {
		width: 40px;
	}
	.footer_five_area .footer_five_top .footer_five_top_right input {
		width: calc(100% - 42px);
	}
	.call_to_action_green .call_to_action_hello {
		padding: 0 15px;
	}
	.home5_video_right::after {
		left: 25%;
	}
	.home_five_single_service::after {
		top: 0;
		height: 100%;
	}
	.home_five_single_service:hover:before {
		height: 100%;
		top: 0;
	}
	.home_five_single_service {
		margin-right: 0;
	}
	.home5_pricing_single {
		padding-left: 30px;
		padding-right: 30px;
	}

	.tp-banner-container {
		padding-top: 197px;
	}
}
@media (max-width: 450px) {
	.banner_slider {
		padding-top: 150px;
	}

	.banner_right_cell {
		width: 50%;
	}

	.owl-carousel .owl-item img {
		width: 248px;
	}
}