.home_five_service {
	padding: 139px 0 92px;
}

.home_five_single_service {
	width: 31.9%;
	display: inline-block;
	margin-right: 30px;
	vertical-align: middle;
	background: #f5f5f5;
	padding: 20px;
	position: relative;

	&:hover {

		&:before {
			height: calc(100% + 180px);
			top: -160px;
			left: 0;
		}
	}

	&:last-child {
		margin-right: 0;
	}

	&:after, &:before {
		position: absolute;
		left: 0;
		top: -160px;
		width: 25px;
		height: 0;
		content: "";
		background: #fff;
		-webkit-transform-origin: top left;
		-ms-transform-origin: top left;
		transform-origin: bottom left;
		-webkit-transform: rotate(34deg);
		-ms-transform: rotate(34deg);
		transform: rotate(45deg);
	}

	&:before {
		height: 0;
		background: $home6_primary;
		z-index: 2;
		transition: 0.5s ease-in-out;
		top: 15%;
		left: 50%;
	}

	&:after {
		height: calc(100% + 180px);
	}
}
.home_five_single_service_inner {
	border: 5px solid #fff;
	position: relative;
	padding: 18px;
	height: 220px;
	z-index: 2;
	background: #f9f9f9;
}

.home_five_single_service_img {
	position: absolute;
	bottom: 0;
	left: 0;
}

.home_five_single_service_right {
	width: 55%;
	float: right;
	padding-top: 25px;

	h3 {
		font-size: 23px;
		color: $home6_primary2;
		letter-spacing: 2px;
		font-weight:500;
		margin-bottom: 13px;
		text-transform: capitalize;
	}

	p {
		font-size: 14px;
		color: $text_inner45;
		letter-spacing: 1.7px;
		line-height: normal;
	}


	a {
		padding: 0;
		border-radius: 0;
		width: 130px;
		height: 48px;
		background:#fff;
		text-align: center;
		position: absolute;
		bottom: 0;
		right: 0;
		font-size: 16px;
		color: $home6_primary3;
		font-weight: 900;
		text-transform: uppercase;
		letter-spacing: 2px;
		line-height: 52px;

		&:hover {
			color: $home6_primary;
		}

	}
}

.home_five_single_service:last-child {

	.home_five_single_service_right {
		width: 50%;
		float: left;
		padding-left: 15px;

		a {
			right: auto;
			left: 0;
		}
	}

	.home_five_single_service_img {
		left: auto;
		right: -12px;
	}
}

@media (min-width: 992px) and (max-width: 1199px) {
	.home_five_service {
		padding: 139px 0 40px;
	}
	.home5_pricing {
		padding-bottom: 90px;
	}
}

@media (max-width: 767px) {
	.home_five_single_service {
		margin-right: 0;
	}
}

@media (max-width: 575px) {
	.home_five_single_service_right h3 {
	    font-size: 22px;
	    letter-spacing: 2px;
	}
}