.map_two_area {
	padding-top: 140px;
	.map_two_area_top {
		padding-bottom: 112px;
	}

	.contact_details {
		padding-left: 43px;

		.section_title_four {
			margin-left: -115px;
			margin-bottom: 34px;

			h2 {
				letter-spacing: 8px;
			}
		}
	}
	.map {
		height: 95%;
	}
	.contact_address {
		margin-bottom: 22px;

		h3 {
			font-size: 24px;
			text-transform: capitalize;
			font-weight: 500;
			color: $home6_primary;
			font-family: $font_heading;
	    	letter-spacing: 2.9px;
	    	margin-bottom: 48px;
		}

		a {
			font-size: 20px;
			font-weight: 300;
			color: #8e8f8f;
			padding: 0;
			margin-bottom: 30px;
			display: inline-block;
			width: 100%;
			transition: 0.3s;

			&:hover {
				color: $home6_primary;
			}

			i {
				font-size: 18px;
				color: $home6_primary;
				width:35px;
				height:35px;
				display: inline-block;
				vertical-align: top;
				line-height: 54px;
			}

			span {
				display: inline-block;
				width: calc(100% - 40px);
				vertical-align: top;
			}
		}
	}

	.contact_address_img {
		margin-bottom: -41%;
		position: relative;
		z-index: 9;
		box-shadow: 22px 32px 70px rgba(0,0,0,0.20);
		margin-left: -30px;
		position: relative;

		img {
			width: 100%;
		}

		&:after {
			position: absolute;
			content: "";
			left: 35.5%;
			top: -58%;
			width: 24px;
			height: 197%;
			background: $home6_primary;
			-webkit-transform: rotate(45deg);
			-ms-transform: rotate(45deg);
			transform: rotate(44deg);
			-webkit-transform-origin: center;
			-ms-transform-origin: center;
			transform-origin: center;
			z-index: -1;
		}
	}
}

.map_two_area_bottom {
	position: relative;
	padding: 140px 0 140px;

	&:before {
		position: absolute;
		left: 0;
		width: 100%;
		height: 100%;
		content: "";
		background: url("../../media/images/home6/pattern-contact.png") repeat center;
		top: 0;
		z-index: -1;
	}

	&:after {
		position: absolute;
		content: "";
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: rgba(153,153,153,0.05);
		z-index: -1;
	}

	.section_title_four {
		margin-bottom: 37px;
	}

	.map_two_area_input {
		width: 100%;
		position: relative;
		margin-bottom: 20px;

		input {
			height: 60px;
			border: 1px solid #ededed;
			border-radius: 0;
			width: 100%;
			padding: 0 30px 0 70px;
			background: #fff;
			transition: 0.3s;
			font-size: 20px;
			font-weight: 300;

			&::placeholder {
				color: #c2c2c2;
				font-weight: 300;
				font-size: 16px;
			}

			&:focus {
				color: $home6_primary;
				border: 1px solid $home6_primary;
			}
		}

		&.active {

			label {
				border-right: 1px solid $home6_primary;

				i {
					color: $home6_primary;
				}
			}
		}

		label {
			position: absolute;
			left: 0;
			top: 17px;
			padding: 0 16px;
			border-right: 1px solid #ddd;
			margin-bottom: 0;
			color: #c2c2c2;
			font-size: 14px;
		}
	}

	.map_two_area_all_input {
		display: inline-block;
		width: calc(50% - 4px);
		vertical-align: top;
		padding-right: 27px;
		margin-bottom: 60px;
	}

	.map_two_area_textarea {
		width: 50%;
		display: inline-block;
		vertical-align: top;
		padding-left: 27px;

		textarea {
			height: 220px;
			background: #fff;
			border: 1px solid #ededed;
			resize: none;
			padding: 25px 27px;
			border-radius: 0;

			&::placeholder {
				color: #c2c2c2;
				font-weight: 300;
			}

			&:focus {
				border: 1px solid $home6_primary;
			}
		}
	}

	.map_two_area_submit {

		input {
			width: 130px;
			height: 60px;
			background: transparent;
			border-radius: 0;
			border: 2px solid $home6_primary;
			font-size: 14px;
			color: $home6_primary;
			font-weight: 700;
			text-transform: uppercase;
			padding: 0;
			line-height: 58px;

			&:hover {
				color: #fff;
				background: $home6_primary;
			}
		}
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	.map_two_area {
		padding-top: 100px;
	}

	.map_two_area .map_two_area_top {
		padding-bottom: 80px;
	}
	.map_two_area_bottom {
		padding: 100px 0;
	}
}

@media only screen and (max-width: 767px) {
	.contact_address_img:after {
		display: none;
	}
	.map_two_area .map {
		height: 300px;
	}
	.map_two_area .contact_details .section_title_four {
		margin-left: 17px;
		margin-bottom: 31px;
		margin-top: -37px;
	}
	.map_two_area .contact_details {
		padding-left: 0;
	}
	.map_two_area .contact_details .section_title_four h2 {
		padding: 16px;
	}
	.map_two_area .contact_address_img {
		margin-left: 0;
	}

	.map_two_area_bottom .map_two_area_all_input {
		width: 100%;
	}
	.map_two_area_bottom .map_two_area_textarea {
		width: 100%;
	}

	.map_two_area_bottom .map_two_area_textarea {
		padding-left: 0;
	}
	.map_two_area_bottom .map_two_area_all_input {
		padding-right: 0;
	}

	.map_two_area .contact_address_img {
		margin-bottom: 0;
	}
	.map_two_area_bottom .section_title_four {
		margin-bottom: 60px;
		margin-top: 0;
	}

	.map_two_area_bottom::before {
		width: 100%;
	}
	.map_two_area_bottom .map_two_area_all_input {
		margin-bottom: 0;
	}
	.map_two_area_bottom .map_two_area_textarea {
		margin-bottom: 30px;
	}
	.map_two_area {
	    padding-top: 80px;
	}
	.map_two_area .map_two_area_top {
	    padding-bottom: 80px;
	}
}

@media (min-width: 576px) and (max-width: 767px) {
	.map_two_area_bottom {
		padding: 80px 0;
	}
}

@media (max-width: 575px) {
	.map_two_area_bottom {
		padding: 80px 0;
	}
}