.privacy_area_content {

	p {
		color: $text_inner45;
		font-size: 20px;
		font-weight: 300;
		line-height: 30px;
		letter-spacing: 0.5px;
		margin-bottom: 29px;
	}

	span {
		display: block;
		margin-bottom: 30px;
		color: $home5_black;
		font-weight: 400;
	}
}

.privacy_collection {

	h3 {
		font-size: 24px;
		font-weight: 500;
		text-transform: capitalize;
		margin-bottom: 32px;
		letter-spacing: 0.7px;
	}

	p {
		margin-bottom: 0;
		margin-bottom: 90px;
	}
}

.type_of_data {

	h3 {
		font-size: 24px;
		font-weight: 500;
		text-transform: capitalize;
		margin-bottom: 34px;
		letter-spacing: 0.5px;
	}

	h4 {
		font-size: 20px;
		color: #464646;
		font-weight: 400;
		text-transform: capitalize;
		border-bottom: 1px solid #464646;
		display: inline-block;
		margin-bottom: 32px;
		letter-spacing: 0.5px;
	}
}

.personal_data ul {
	margin-bottom: 35px;

	li {
		font-size: 20px;
		font-weight: 300;
		line-height: 30px;
		position: relative;
		color: #767272;

		&:before {
			width: 5px;
			height: 5px;
			background: #767272;
			content: "";
			left: -15px;
			top: 13px;
			position: absolute;
			border-radius: 50%;
		}
	}
}

.example_of_cookies {

	ul {
		margin-bottom: 20px;

		li {
			font-size: 20px;
			font-weight: 300;
			line-height: 30px;
			color: #767272;
			letter-spacing: 0.5px;
		}
	}

	p {
		margin-bottom: 0;

		span {
			color: $text_inner45;
			font-weight: 300;
			margin-bottom: 30px;
		}
	}
}

.privacy_area {
	padding: 140px 0 132px;

	.section_title_four {
		margin-bottom: 110px;

		h2 {
			padding: 0;
			letter-spacing: 7.5px;
		}
	}
}

.usage_data p:last-child {
	margin-bottom: 35px;
}

.example_of_cookies h4 {
	letter-spacing: normal;
}
.example_of_cookies ul {
	padding-left: 20px;
	margin-bottom: 30px;
}

@media (min-width: 992px) and (max-width: 1199px) {

	.privacy_area .section_title_four {
		margin-bottom: 60px;
	}
	.privacy_area .section_title_four h2 {
		box-shadow: none;
	}
	.privacy_area {
		padding: 130px 0 132px;
	}
}

@media (min-width: 768px) and (max-width: 991px) {

	.privacy_area .section_title_four {
		margin-bottom: 60px;
	}
	.privacy_area .section_title_four h2 {
		box-shadow: none;
	}

	.privacy_area {
		padding: 95px 0 95px;
	}
}

@media (min-width: 576px) and (max-width: 767px) {
	.privacy_area .section_title_four {
		margin-bottom: 60px;
	}
	.privacy_area .section_title_four h2 {
		letter-spacing: 5px;
	}

	.privacy_area {
		padding: 75px 0 75px;
	}

	.privacy_area .section_title_four h2 {
		box-shadow: none;
	}
}

@media only screen and (max-width: 575px) {
	.privacy_area .section_title_four h2 {
		letter-spacing: 3px;
	}
	.privacy_area .section_title_four {
		margin-bottom: 30px;
	}
	.privacy_area {
		padding: 75px 0 75px;
	}
	.privacy_collection p {
		margin-bottom: 40px;
	}
	.privacy_area .section_title_four h2 {
		box-shadow: none;
	}
}