.faq_area {
    background: $secondary_theme_color;
}
#faq {
	padding-bottom: 6rem;
}
.faq_right_bg {
	text-align: right;
	position: relative;
	top: 50%;
	transform: translateY(-50%);
}

.question {
    border: 1px solid #ebebeb;
    background: transparent;

    span {
        display: block;
        font-size: 1rem;
        color: $main_theme_color;
        font-weight: 600;
        text-transform: uppercase;
        cursor: pointer;
        position: relative;
        line-height: 0.89;
        padding: 1.6rem 1.67rem;

        &::after {
            position: absolute;
            content: "\f068";
            font-family: 'Font Awesome 5 Free';
            right: 20px;
            top: 50%;
            font-size: 0.72rem;
            transform: translateY(-50%);
        }

        &.collapsed {
            color: $text_body_main;

            &.collapsed::after {
                content: "\f067";
            }
        }
    }
}

.ans_body {
    padding: 23px 55px;
    border: 1px solid #ebebeb;
    background: #fff;
    font-size: 0.89rem;
    color: #737373;
}

.ask_ans,
.ans_body {
    margin-top: -1px;
}