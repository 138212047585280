.ingredeint_section {

	p,h1,h2,h3,h4,h5,h6,a {
		font-family: $font_primary;
	}
}
.ingredient_slider_one .slick-slide {
	width: 110px !important;
	height: 136px;
	border: 1px solid #f5f5f5;
	border-bottom: 0;
	line-height: 136px;
}

.ingredeint2_section {
	padding: 140px 0 140px;
}

.ingredient_slider_one .slick-slide img {
	left: 50%;
	position: relative;
	transform: translate(-50%);
	top: 21px;
}
.ingredient_slider_one .slick-prev,
.ingredient_slider_one .slick-next {
	top: 83%;
	width: 50%;
	height: 53px;
	background: #f5f5f5;
	border-radius: 0;
	z-index: 2;
	transition: 0.3s;
}
.ingredient_slider_one .slick-prev {
    left: 0;

    &:hover:before {
    	color: $home6_primary;
    }
}
.ingredient_slider_one .slick-next {
    right: 0;
    border-left: 1px solid #ededed;

    &:hover:before {
    	color: $home6_primary;
    }

}
.ingredient_slider_one .slick-prev:before,
.ingredient_slider_one .slick-next:before {
    content: '\f107';
    font-family: 'Font Awesome 5 Free';
    font-size: 15px;
    font-weight: 900;
    line-height: 1;
    opacity: .75;
    color: $home5_black;
}
.ingredient_slider_one .slick-prev:before {
    content: '\f106';
}
.ingredient_slider_main {
	display: flex;
}
.ingredient_slider_two.slick-slider {
	width: calc(100% - 110px);
	box-shadow: 22px 20px 60px rgba(0,0,0,0.1);
}
.ingredient_slider_two .ingredient-img {
	border: 0px solid #fff;
	background: #fff;
	width: 100%;
}
.ingredient_slider_detail .product_price {
	border-bottom: 0px solid #f1b601;
}
.ingredient_slider_detail .product-cart {
	border-bottom: 0px solid #f5f5f5;
}
.product_ratting a {

	&:last-child {

		i {
			color: #b2b2b2;
			font-size:14px;
		}
	}

	i {
		color: $main_theme_color;
	}
}

.ingredient_slider_detail .product_ratting span {
	font-size: 14px;
	color: #5830B4;
	margin-right: 26px;
}
.ingredient_slider_detail .product_ratting .write_review {
	color: #b2b2b2;
}
.ingredient_slider_detail .product_desc p {
	font-size: 16px;
	color: #a6a6a6;
	line-height: 30px;
	margin-bottom: 34px;
}
.ingredient_slider_detail .product_quantity h4 {
	font-size: 16px;
	color: $home5_black;
	font-weight: 700;
	margin-bottom: 17px;
	letter-spacing: 0.5px;
}

.ingredient_slider_detail .product_quantity li {
	width: 99px;
	display: inline-block;
	font-size: 16px;
	color: $home5_black;
	font-weight: 500;
	text-align: center;
	background: #f5f5f5;
	border: 1px solid transparent;
	margin-right: 9px;
	line-height: 20px;
	padding: 6px 0;
	transition: 0.3s;
	cursor: pointer;
	height: 50px;

	&.border {
		border: 1px solid $main_theme_color !important;
	}

	&:hover {
		border: 1px solid #ededed;
	}

	span {
		text-transform: uppercase;
		font-size: 12px;
		display: block;
		font-weight: 400;
		line-height: normal;
	}
}
.ingredient_slider_detail .product_quantity ul {
	padding-left: 0;
}

.ingredient_slider_detail .product_quantity {
	margin-bottom: 30px;
}
.ingredient_slider_detail .product_price p {
	padding: 0 10px;
	height: 27px;
	line-height: 28px;
	background: #f5f5f5;
	color: #f99a00;
	font-size: 10px;
	display: inline-block;
	font-weight: 500;
	letter-spacing: 1.25px;
}
.ingredient_slider_detail .price ins span {
	font-weight: 300;
	color: $home5_black;
	font-size: 24px;
}
.ingredient_slider_detail .price del {
	color: #ff8b8b;

	span {
		font-size: 14px;
		font-weight: 300;
	}
}

.ingredient_slider_detail .quantity .looking {
	width: 24px;
	height: 24px;
	line-height: 22px;
	border-radius: 50%;
	border: 0px;
	background: #ebebeb;
	color: $home5_black;
	cursor: pointer;
	margin-right:0;

	&:hover {
		color: $home5_black;
	}
}
.ingredient_slider_detail {
	padding-left: 108px;
}
.ingredient_slider_detail .quantity span,
.ingredient_slider_detail .quantity input {
	margin-right: 10px;
}
.ingredient_slider_detail .quantity span i {
	font-size: 8px;
}

.ingredient_slider_detail .ingredient_slider_btn a {
	height: 50px;
	line-height: 48px;
	display: inline-block;
	border: 2px solid $home6_primary3;
	padding: 0 18px;
	border-radius: 0;
	font-size: 14px;
	color: $home6_primary3;
	font-weight: 700;
	letter-spacing: 2px;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
	margin-left: 5px;

	&:last-child {
	    margin-left: -6px;
	}

	&:hover {
		background: $home6_primary3;
		color: #fff;
	}
}
.ingredient_slider_detail .ingredient_slider_btn a.this_heart {
	margin-left: -6px;
	width: 50px;
	padding: 0;
	text-align: center;

	i {
		font-size: 20px;
		vertical-align: middle;
	}
}
.ingredient_slider_detail .quantity {
	display: block;
	float: none;
	margin-bottom: 50px;
}
.ingredient_slider_detail .ingredient_slider_btn {
	margin-left: -5px;
}
.ingredient_slider_detail .product-cart {
	padding-bottom: 0;
	margin-bottom: 0;
}
.ingredient_slider_detail .share-wrap a {
	font-size: 12px;
	padding: 0;
	color: $home5_black;
	border-bottom: 1px solid $home5_black;
	border-radius: 0;
	line-height: 14px;
	margin-right: 25px;
	transition: 0.3s;

	&:hover {
		color: #7d7d7d;
		border-bottom: 1px solid #a1a1a1;
	}
}
.ingredient_slider_detail .share-wrap a:last-child {
	color: #7d7d7d;
	border-bottom: 1px solid #7d7d7d;

	&:hover {
		color: $home5_black;
		border-bottom: 1px solid $home5_black;
	}
}

.ingredient_slider_detail h4 {
	font-size: 20px;
	text-transform: capitalize;
	color: $home5_black;
	font-weight: 500;
	margin-bottom: 20px;
}
.ingredeint2_section .ingredient-img img {
	display: inline-block;
	position: relative;
	top: 0;
	-webkit-transform: translateY(0);
	-ms-transform: translateY(0);
	transform: translateY(0);
}

.ingredient_slider_detail .product_price {
	border-bottom: 0px solid #f1b601;
	margin-bottom: 0;
	padding-bottom: 25px;
}

.ingredient_slider_detail .quantity input {
	width: 45px;
	height: 45px;
}

 .ingredient_slider_detail .quantity .looking {
 	width: 23px;
 	height: 23px;
 	line-height: 21px;
 }

 @media only screen and (max-width: 575px) {

	.ingredient_slider_detail .ingredient_slider_btn a {
		padding: 0 8px;
	}
 }
 @media (min-width: 1200px) and (max-width: 1499px) {
 	.ingredient_slider_detail {
		padding-left: 50px;
	}
 }
 @media (min-width: 768px) and (max-width: 991px) {
 	.ingredeint2_section {
		padding: 100px 0 100px;
	}
 }

 @media (max-width: 575px) {
 	.ingredeint2_section {
		padding: 80px 0px 30px;
	}
 }