.home5_offer_inner {
	margin-left: 70px;
	border: 30px solid #ebebeb;
	position: relative;
	background: #fff;
	box-shadow: 22px 32px 70px rgba(0,0,0,0.16);
}
.home5_offer_left {
	position: absolute;
	bottom: -81px;
	left: -175px;
}
.home5_offer_right {
	position: absolute;
	right: 0;
	bottom: 80px;

	span {
		width: 47px;
		height: 47px;
		background: $home6_primary;
		display: inline-block;
		border-radius: 50%;
		line-height: 47px;
		text-align: center;
		position: absolute;
		top: -23px;
		left: 25px;
		color: #fff;
	}
}
.home5_offer {
	padding: 0px 0 92px;
}
.home5_offer_center {
	width: 57%;
	text-align: left;
	margin: 0 auto;
	padding-top: 100px;
	padding-bottom: 100px;
	padding-left: 151px;

	h2 {
		font-size: 60px;
		color: $home6_primary2;
		font-weight: 700;
		font-family: $font_heading;
		margin-bottom: 5px;
		position: relative;
		z-index: 1;

		&:after {
			position: absolute;
			left: 19px;
			top: -77%;
			width: 16px;
			height: 176px;
			-webkit-transform: rotate(45deg);
			-ms-transform: rotate(45deg);
			transform: rotate(45deg);
			content: "";
			background: $home6_primary;
			-webkit-transform-origin: center;
			-ms-transform-origin: center;
			transform-origin: center;
			z-index: -1;
		}
	}

	p {
		font-size: 20px;
		color: #6c6c6c;
		font-weight: 300;
		letter-spacing: 0.25px;
		line-height: 30px;
		margin-bottom:38px;
	}
}


.home5_offer_detail {
	position: relative;
	padding: 70px 0;

	.home5_offer_detail_bg {
		position: absolute;
		left:0;
		top:0;
		height:100%;

		img {
			height: 100%;
		}
	}
}

.home5_offer_social {
	position: absolute;
	right: 0;
	top: 0;
	padding-top: 22px;
	padding-right: 35px;

	span {
		font-size: 10px;
		text-transform: uppercase;
		letter-spacing: 1px;
		color: #989797;
		margin-right: 8px;
	}

	a {
		padding: 0px 5px;
		font-size: 13px;
		color: #989797;

		&:hover {
			color: $home6_primary;
		}

		&:last-child {
			padding-right: 0;
		}
	}
}

@media (min-width: 1200px) and (max-width: 1499px) {
	.home5_offer_detail {
		padding: 57px 0;
	}
	.home5_offer {
		padding: 0px 0 93px;
	}
	.home5_offer_center {
		width: 57%;
		margin: 0 0 0 auto;
		padding-left: 0;
		padding-right: 50px;
	}
}

@media (min-width: 992px) and (max-width: 1199px) {
	.home5_offer_left {
		left: -154px;
	}
	.home5_offer_center {
		width: 52%;
		padding-right: 45px;
		padding-left: 0;
		margin: 0 0 0 auto;
	}
}

@media (min-width: 576px) and (max-width: 767px) {
	.home5_offer_center {
		padding-top: 56px;
	}
}

@media (max-width: 991px) {
	.home5_offer_social span {
	    color: #222;
	}
	.home5_offer_social a {
	    color: #222;
	}
	.home5_offer_right {
		display: none;
	}
	.home5_offer_detail .home5_offer_detail_bg img {
		height: 100%;
	}
}

@media (max-width: 767px) {
	.home5_offer_center {
		padding-bottom: 40px;
	}
}

@media (max-width: 575px) {
	.home5_offer_social {
		padding-top: 0;
		padding-right: 0;
	}
	.home5_offer_detail {
		padding: 40px 0 75px;
	}

	.home5_offer_center p {
		font-size: 17px;
		line-height: 28px;
	}
}

@media (max-width: 1500px) {
	.home5_offer_right {
		display: none;
	}
}