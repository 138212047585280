.error_content_two {
	p,h1,h2,h3,h4,h5,h6,a {
		font-family: $font_primary;
	}
	.error_cont_desc h1 span {
		color: #3734f0;
	}
	.accordion_btn {
		padding-left: 0;
	}
}
.error_content_area.section_padding {
	padding: 112px 0;
}