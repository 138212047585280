.map_hovarable{
	position: relative;
	height: 107px;
	transition: all 0.3s linear;

 	.map {
		height: 100%;
	}
}
.map_click_me {
	position: absolute;
	z-index: 9;
	width: 100%;
	text-align: center;
	box-shadow: 0 -38px 38px rgba(236,249,255,1) inset;
	width: 100%;
	height: 77px;
	background: #fff;
	text-align: center;
	left: 0;
	top: 0;
	cursor: pointer;

	&:hover i {
		transform: rotate(180deg);
	}

	p {
		line-height: 30px;
		letter-spacing: 1.4px;
		font-size: 12px;
		color: #a5aab0;
		margin-bottom: 0;
	}

	i {
		font-size: 29px;
		color: $home5_primary;
		transition: 0.3s;
	}
}
.map_hovarable.slideup{
	height: 500px;
}

