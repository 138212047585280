.supp_icon {
	width: 80px;
	height: 80px;
	text-align: center;
	line-height: 80px;
	border-radius: 50%;
	-webkit-box-shadow: 0px 2px 51px rgba(241, 182, 1, 0.25);
	box-shadow: 0px 2px 51px rgba(241, 182, 1, 0.25);
	position: relative;

	&:after {
	  position: absolute;
	  left: -10px;
	  top: -10px;
	  width: calc(100% + 20px);
	  border: 1px dashed #cccccc;
	  content: "";
	  height: calc(100% + 20px);
	  border-radius: 50%;
	}
}


.single_supp_ser {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 42px;
  margin-left: 10px;

  &:last-child {
	  margin-bottom: 0;
	}
}


.supp_content {
  padding-left: 43px;
  width: calc(100% - 90px);
	h5 {
	  text-transform: capitalize;
	  color: $main_theme_color;
	}
	p {
	  margin-bottom: 0;
	}
}


.supplement_service_img {
  text-align: right;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
}

.supp_ser_list {
  padding-right: 26px;
}

.supplement_service_area.section_padding {
	padding: 7.5rem 0;
}



