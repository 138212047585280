.ingredeint4_section {

	p,h1,h2,h3,h4,h5,h6,a {
		font-family: $font_primary;
	}

	.ingredient_slider_detail .product_price p,
	.product_ratting a i,
	.ingredient_slider_one .slick-next:hover::before,
	.ingredient_slider_one .slick-prev:hover::before {
		color: $home6_primary;;
	}

	.ingredient_slider_detail .product_quantity li.border {
		border: 1px solid #D01BD0 !important;
	}

	.ingredient_slider_detail .product_quantity li.active.border {
		border: 1px solid #D01BD0 !important;
	}

	.write_review i {
		margin-right: 5px;
	}
	.ingredient_slider_detail .ingredient_slider_btn:hover a {
		background: $home6_primary3;
		color: #fff;
		border: 2px solid #fff;
	}

	.product_ratting.woocommerce-product-rating {
		padding-bottom: 24px;
		border-bottom: 1px solid #ddd;
		margin-bottom: 18px;
		position: relative;

		&:after {
			position: absolute;
			content: "";
			left: 0;
			bottom: -1px;
			width: 50px;
			height: 1px;
			background: $home6_primary2;
		}

		a {
			margin-right: 4px;
		}
	}

	.ingredient_slider_detail .product_ratting span {
		color: #9c9c9c;
	}
	.ingredient_slider_detail .product_ratting span i {
		color: $text_inner74;
	}
	.ingredient_slider_detail .product_ratting > span {
		color: #686868;
	}
	.product_ratting a i {
		color: $home6_primary3;
	}

	.product_ratting.woocommerce-product-rating {
		border-bottom: 1px solid #ebebeb;
	}

	.ingredient_slider_detail .product_price p.in-stock {
		background: $home6_primary2;
		color: #fff;
		margin-bottom: 45px;
	}

	.ingredient_slider_detail .product_price p.out-stock {
		background: #b6b6b6;
		color: #fff;
		margin-bottom: 45px;
	}

	.ingredient_slider_detail .quantity .looking:hover{
		background: $home5_black;
		border: 1px solid $home5_black;
		color: #fff;
	}

	.ingredient_slider_detail .quantity .looking {
		background: transparent;
		color: #b2b2b2;
		border: 1px solid #b2b2b2;
	}
	.ingredient_slider_btn{

		&:hover {
			p {
				transform: scaleX(1);
			}
		}

		p {
			display: inline-block;
			padding-left: 7px;
			font-size: 10px;
			color: #9c9c9c;
			text-transform: uppercase;
			margin-top: 20px;
			transform: scaleX(0);
			transition: 0.3s;
			transform-origin: left;


			i {
				padding-right: 5px;
				font-size: 20px;
				color:  $home6_primary;
			}
		}
	}
}

.product_desc.woocommerce-product-details__short-description p {
	font-size: 14px;
	color: $text_inner54;;
	letter-spacing: 0.25px;
	line-height: 24px;
}
.product_share {
	padding: 60px 0px 0px;
}
.product_share ul {
	display: flex;
	padding-left: 0;
	margin-bottom: 0;

	li {
		width: 25%;
		border: 1px solid #ededed;
		text-align: center;
		height: 100px;
		line-height: 100px;
		position: relative;
		font-size: 18px;

		a span {
			opacity: 0;
			transition: 0.5s;
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			text-align: center;
			font-size: 10px;
			color: #b6b6b6;
			letter-spacing: 1.2px;

			i {
				font-size: 16px;
			}
		}

		a > i {
			transition: 0.3s;
		}

		a {
			display: inline-block;
			padding: 0;
		}

		&.facebook a, &.facebook a span i{
			color: #3b5998;
		}

		&.twitter a, &.twitter a span i {
			color: #1da1f3;
		}

		&.instagram a, &.instagram a span i {
			color: #e56969;
		}

		&.gplus a, &.gplus a span i {
			color: #e04a39;
		}

		&:hover {

			a > i{
				opacity: 0;
			}

			a span {
				opacity: 1;
			}
		}
	}
}

.ingredient_slider_flex {
	display: flex;
}

.ingredient_slider_main {
	width: 687px;
}


@media (min-width: 992px) and (max-width: 1199px) {
	.ingredient_slider_main {
		width: 50%;
	}
	.ingredient_slider_detail {
		padding-left: 50px;
	}
}


@media (min-width: 768px) and (max-width: 991px) {
	.ingredient_slider_flex {
		flex-wrap: wrap;
	}
	.ingredient_slider_main {
		width: 100%;
	}
	.product_share ul li a span {
		letter-spacing: normal;
	}
}


@media (min-width: 576px) and (max-width: 767px) {
	.ingredient_slider_flex {
		flex-wrap: wrap;
	}
	.ingredient_slider_main {
		width: 100%;
	}
}

@media only screen and (max-width: 575px) {
	.ingredient_slider_flex {
		flex-wrap: wrap;
	}
	.ingredient_slider_main {
		width: 100%;
	}
	.page_header_list li {
		padding: 0px 0px;
		border-right: 0px solid #7f7f7f;
		padding-right: 10px;
	}
	.product_share ul li:hover a > i {
		opacity: 0.5;
	}
}