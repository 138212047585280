.ingredeint_section_three {
	p,h1,h2,h3,h4,h5,h6,a {
		font-family: $font_primary;
	}

	.link_overlay::after {
		border: 2px solid #3734f0;
	}
	.ingredient_content h5::after {
		display: none;
	}
	.ingredient_content_heading h5::after {
		background: #3734f0;
	}
	.ingridient_list h6 span {
		color: inherit;
	}
	.gredient_tab .slick-next,
	.gredient_tab .slick-prev {
		color: #3734f0;
		display: none !important;
	}
	.gredient_tab .slick-slide img {
		transform: scale(0.8);
	}
	.gredient_tab li {
		background: #f8f8f8;
	}
	.ingredient-img {
		background: #f8f8f8;
		height: 100%;
		box-shadow: none;
		border: none;
	}
	.ingredient_content h5 {
		position: relative;
		margin-bottom: 23px;
		color: #3734f0;
		font-size: 36px;
	}
	.ingredient_content .mini-des ul li {
		color: #9e9c9c;
		font-size: 14px;
	}
	.ingredient_content > p {
		font-size: 16px;
		color: #9e9c9c;
	}
	.ingredient-img img {
		display: inline-block;
		position: relative;
		top: 50%;
		transform: translateY(-50%);
	}
	.ingredient_content .mini-des p {
		font-size: 14px;
	}
	.ingridient_list h6 {
		font-size: 14px;
	}
}
