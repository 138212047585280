.ingredients_key_area {
	padding: 110px 0 0px;

	.section_title_four {
		text-align: center;
		margin-bottom: 59px;
	}
}

.ingredients_key_features_all {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.ingredients_key_single_feature {
	width: 27.4%;
	background: #fff;
	border: 1px solid #efefef;
	padding: 45px;
	-webkit-box-shadow: 22px 32px 70px rgba(0, 0, 0, 0.1);
	box-shadow: 22px 32px 70px rgba(0, 0, 0, 0.1);
	justify-content: space-between;
	margin: 34px 0;
	position: relative;

	&:nth-child(even) {
		margin-left: 100px;

		span {
			left: auto;
			right: -25px;
		}
	}

	&:nth-child(odd) {
		margin-right: 100px;
	}

	h3 {
		font-size: 18px;
		letter-spacing: 2.9px;
		font-weight: 600;
		color: $home5_black;
		font-family: $font_heading;
		margin-bottom: 16px;
	}

	p {
		font-size: 16px;
		color: #6e6e6e;
		line-height: 24px;
	}

	&.active {
		h3 {
			color: $home6_primary;
		}

		p {
			color: #202020;
		}

		span {
			transform: scale(1);
		}
	}

	span {
		position: absolute;
		left: 0;
		top: 50%;
		width: 50px;
		height: 50px;
		background: $home6_primary;
		text-align: center;
		left: -25px;
		line-height: 50px;
		color: #fff;
		border-radius: 50%;
		-webkit-transform: scale(0);
		-ms-transform: scale(0);
		transform: scale(0);
		-webkit-transition: all 0.3s linear;
		-o-transition: all 0.3s linear;
		transition: all 0.3s linear;
		font-size: 26px;
		margin-top: -25px;
	}
}

.ingredients_key_featurs {
	position: relative;
}

.ingredients_key_featurs_menu {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%,-50%);
	width: 230px;
	z-index: 3;

	.ingredients_key_featurs_img {
		position: absolute;
		left: 18px;
		top: -50%;
		z-index: -1;
	}

	ul {
		padding: 0;
		margin: 0;

		li:nth-child(2n) {
			float: right;
			width: 50%;
			text-align: right;
		}

		li:nth-child(2n-1) {
			float: left;
			width: 50%;
			text-align: left;
		}

		li {
			margin: 17px 0;

			a {
				width: 42px;
				height: 42px;
				padding: 0;
				line-height: 0;
				text-align: center;
				background: rgba(0,0,0,0.7);
				border-radius: 50%;
				position: relative;
				z-index: 1;
				border: 4px solid #fff;

				&.active {
					background: $home6_primary;
					i {
						opacity: 1;
					}
				}

				i {
					line-height: 34px;
					opacity: 0;
					color: #fff;
				}
			}

			&:nth-child(1) {

				a {

					i {
						transform: rotate(-50deg);
					}
				}
			}
			&:nth-child(3) {

				a {

					i {
						transform: rotate(-90deg);
					}
				}
			}
			&:nth-child(5) {

				a {

					i {
						transform: rotate(-140deg);
					}
				}
			}
			&:nth-child(2) {

				a {

					i {
						transform: rotate(50deg);
					}
				}
			}
			&:nth-child(4) {

				a {

					i {
						transform: rotate(90deg);
					}
				}
			}
			&:nth-child(6) {

				a {

					i {
						transform: rotate(140deg);
					}
				}
			}
		}
	}
}

.ingredients_key_single_feature:nth-child(1),
.ingredients_key_single_feature:nth-child(5) {
	margin-left: 50px;
}
.ingredients_key_single_feature:nth-child(2),
.ingredients_key_single_feature:nth-child(6) {
	margin-right: 50px;
}
@media (min-width: 992px) and (max-width: 1199px) {
	.ingredients_key_single_feature {
		padding: 30px;
	}
}


@media (min-width: 768px) and (max-width: 991px) {
	.ingredients_key_single_feature:nth-child(2n+1) {
		margin-right: 0;
	}

	.ingredients_key_single_feature {
		width: 34%;
	}

	.ingredients_key_featurs_menu {
		width: 153px;
	}

	.ingredients_key_featurs_menu .ingredients_key_featurs_img {
		left: 0;
		top: -20%;
	}
	.ingredients_key_single_feature {
		padding: 30px;
	}
	.ingredients_key_single_feature h3 {
		font-size: 16px;
	}
	.ingredients_key_single_feature p {
		font-size: 14px;
	}

}

@media only screen and (max-width: 767px) {
	.ingredients_key_featurs_menu {
		display: none;
	}
	.ingredients_key_single_feature:nth-child(2n+1) {
		margin-right: 0;
	}
	.ingredients_key_single_feature:nth-child(1),
	.ingredients_key_single_feature:nth-child(5) {
		margin-left: 0px;
	}
	.ingredients_key_single_feature {
		width: 100%;
	}
	.ingredients_key_single_feature:nth-child(2n) {
		margin-left: 0;
	}
	.ingredients_key_single_feature:nth-child(2), .ingredients_key_single_feature:nth-child(6) {
		margin-right: 0px;
	}

	.ingredients_key_single_feature {
		width: 100%;
		padding: 30px;
	}
	.ingredients_key_single_feature:hover h3 {
		color: $home6_primary;
	}
	.ingredients_key_single_feature:hover span {
		-webkit-transform: scale(1);
		-ms-transform: scale(1);
		transform: scale(1);
	}
	.ingredients_key_single_feature:nth-child(2n+1) {
		margin-left: 0px;
	}
	.ingredients_key_area .section_title_four {
		text-align: left;

		h2 {
			padding: 14px 26px;
		}
	}
	.ingredients_key_single_feature:nth-child(2n) {
	    margin-right: 0;
	    margin-left: 0px;
	}
	.ingredients_key_single_feature:nth-child(even) span {
	    left: -25px;
	    right: auto;
	}
	.ingredients_key_single_feature h3 {
		font-size: 18px;
		letter-spacing: normal;
	}
	.ingredients_key_single_feature p {
		font-size: 14px;
	}
	.ingredients_key_area {
		.section_title_four h2 {
		    font-size: 24px;
		}
	}
	.ingredients_key_area .section_title_four {
	    margin-bottom: 0;
	}

	.ingredients_key_single_feature {
		margin: 20px 0;
	}

	.ingredients_key_area {
	    padding: 80px 0 0px;
	}
	.ingredients_key_single_feature:last-child {
		margin-bottom: 0;
	}
}