.combine_area_two {
	position: relative;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	background-attachment: fixed;

	.parallax-all {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 50%;
		z-index: -9;
	}

	.combine_two_left {
		position: fixed;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
	}
	.combine_two_right {
		position: fixed;
		top: 24%;
		right: 0;
		transform: translateY(-30%);
	}
}

@media (min-width: 992px) and (max-width: 1200px) {
	.combine_area_two .combine_two_right {
		top: 20%;
	}
}