.blog_hm_five_slider {

	.owl-stage-outer {
		overflow: visible;
	}

    .owl-item {
	   opacity: 0;
	   transition: opacity 100ms;
	}

	.owl-item.active {
	  	opacity: 1;
	}

	.owl-nav {
		position: absolute;
		right: -35px;
		top: calc(50% - 72px);
		width: 64px;

		button {
			width: 64px;
			height: 64px;
			background: $home5_primary !important;
			text-align: center;
			line-height: 64px;
			display: block;
			border-radius: 50%;
			margin-bottom: 10px;

			&:hover {
				i {
					font-size: 20px;
				}
			}

			i {
				color: #fff;
				font-size: 16px;
				line-height: 64px;
				transition: 0.3s;
			}
		}
	}
}

.blog_hm_five_post {
	padding: 25px 19px;
	box-shadow: 18px 18px 17px rgba(222,222,222,1);
	background: #fff;

	.blog_hm_five_title {
		width: 80%;
		margin-bottom: 20px;

		h4 {

			a {
				padding: 0;
				color: $home5_secondary;
				letter-spacing: -0.2px;
				line-height: 26px;
				text-transform: initial;
				font-weight: 500;
				font-size: 16px;
				transition: 0.3s;

				&:hover {
					color: $home5_primary;
				}
			}
		}
	}

	.blog_hm_five_thumb {
		margin-bottom: 20px;
	}

	.blog_hm_five_meta{
		a {
			font-size: 12px;
			color: #a5aab0;
			letter-spacing: 1.4px;
			line-height: 30px;
			padding:0;
			width: 100%;
			position: relative;

			&:hover {
				color: $home5_primary;
			}

			&:last-child {
				// margin-left: -9px;
			}

			i {
				font-size: 16px;
				color: $home5_primary;
				position: absolute;
				right: 0;
				top: 9px;
			}
		}
	}
}

.Blog_home_five_slider_part {
	width: 78%;
	float: left;

	.owl-stage-outer {
		position: relative;
		overflow: hidden;
		-webkit-transform: translate3d(0, 0, 0);
		padding: 50px;
		padding-right: 0;
		width: 110%;
		margin-left: -50px;
	}
}
.Blog_home_five_btn_part {
	position: absolute;
	left: 80%;
	bottom: 50px;
	z-index: 1;

	a {
		padding: 0;
		padding-left: 72px;
		padding-bottom: 25px;
		font-size: 16px;
		font-weight: 700;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.88;
		letter-spacing: 1.9px;
		text-align: left;
		color: $home5_primary;
		text-transform: uppercase;

		&:hover {
			i {
				padding-left: 15px;
			}
		}

		i {
			color: $home5_black;
			padding-left: 9px;
			font-size: 23px;
			vertical-align: middle;
			margin-top: -3px;
			transition: 0.3s;
		}
	}
}
.Blog_home_five {
	overflow: hidden;
	padding: 44px 0 62px;

	.vigo_container_two {
		position: relative;
	}
}

.faq3_area {
	padding-bottom: 0;

	.vigo_container_two {
		background: url(../../media/images/home3/girl.png) no-repeat scroll right 109px bottom/contain;
	}

	.faq3_left_area {
		width: 54%;

		.faq3_accordion {
			box-shadow: 18px 18px 38px rgba(222,222,222,1);
			background: #fff;
		}

		.question span {
			font-size: 16px;
			text-transform: capitalize;
			color: #424e5a;
			font-weight: 500;
			padding: 22px 24px;
			-webkit-transition: all 2s linear;
			-o-transition: all 2s linear;
			transition: all 2s linear;
			letter-spacing: normal;
			text-transform: inherit;

			&:after {
				font-weight: 900;
				content: "\f056";
				color: $home5_primary;
				font-size: 14px;
				transition: 0.3s;
			}

			&.collapsed.collapsed::after {
				content: "\f055";
				color: #dddddd;
			}
		}

		.collapse {

			.ans_body:after {
				height: calc(100% - 50px);
			}
		}

		.ans_body {
			font-size: 16px;
			color: #434f5b;
			font-weight: 300;
			line-height: 25px;
			padding-left: 47px;
			position: relative;
			letter-spacing: 0.4px;

			&:after {
				position: absolute;
				content: "";
				left: 25px;
				width: 4px;
				background: $home5_primary;
				top: 25px;
				height: 0;
				transition: 0.3s linear;
			}
		}
	}
}

.call_to_action3 {

	.call_to_action_area_two {
		background: $home5_primary;
		background: -webkit-gradient(linear, right top, left top, from(#3db5e6), to(#0086c3));
		background: -o-linear-gradient(right, #3db5e6 0%, #0086c3 100%);
		background: linear-gradient(to left, #3db5e6 0%, #0086c3 100%);
		position: relative;
		padding: 50px 0px;
	}
	.call_to_action_hello {
		padding: 0px 79px 0 92px;
		border: 0px solid rgba(242, 168, 26, 0.6);
	}
}

.how_it_work_tab {
	position: relative;
	padding: 170px 0 116px;

	.tab-content {
		margin: 0;
		padding: 0;
		width: 275px;
		height: 275px;
		text-align: center;
		border: 10px solid #ddd;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
		position: relative;
		left: auto;
		top: 58px;
		float: right;
		right: 60px;

		img {
			padding-left: 15px;
		}

		.icon_menu {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			padding: 0;
			margin: 0;

			li {
				position: absolute;
				width: 80px;
				height: 80px;
				background: #ebebeb;
				line-height: 80px;
				border-radius: 50%;
				transform: rotate(-45deg);
				z-index: 9;

				&.active {
					background: $home5_primary;
					background: -webkit-gradient(linear, right top, left top, from(#3db5e6), to(#0086c3));
					background: -o-linear-gradient(right, #3db5e6 0%, #0086c3 100%);
					background: linear-gradient(to left, #3db5e6 0%, #0086c3 100%);
					transition: 0.5s;
				}

				i {
					color: #fff;
					font-size: 22px;
				}

				&:nth-child(3) {
					right: -45px;
					top: calc(50% - 40px);
				}

				&:first-child {
					left: -45px;
					top: calc(50% - 40px);
				}

				&:nth-child(2) {
					left: calc(50% - 40px);
					top: -40px;
				}

				&:nth-child(4) {
					left: calc(50% - 40px);
					top: calc(100% - 30px);
				}
			}
		}
	}

	.tab-content > .active {
		display: block;
	}

	.tab-pane {
		transform: rotate(-45deg);
		text-align: center;
		line-height: 275px;
		position:relative;

		&.fade {
			transition: 0.5s linear;
			transform: rotate(-45deg) scale(0);

			&.show {
				transform: rotate(-45deg) scale(1);
			}
		}
	}
}

.how_it_work_tab_menu {

	.section_title_three {
		margin-bottom: 27px;
	}

	a {
		transition: 0.5s ease;
		font-size: 16px;
		font-weight: 400;
		color: $home5_secondary;
		padding: 0;
		margin-bottom: 15px;

		&:last-child {
			margin-bottom: 0;
		}

		span {
			font-size: 28px;
			font-weight: 300;
			margin-right: 0;
			vertical-align: middle;
		}

		P {
			display: inline-block;
			width: calc(100% - 35px);
			vertical-align: middle;
			margin-bottom: 0;
			padding-left: 14px;
		}

		&.active {
			color: $home5_primary;
		}
	}
}

.combine_area {
	position: relative;
	background-size: cover;
	background-repeat: no-repeat;
	background-attachment: fixed;
}
.combine_bg_right {
	position: absolute;
	right: 0;
	top: 23%;
	z-index: -1;
}
.combine_bg_left {
	position: absolute;
	top: 25%;
	left: 0;
	z-index: -1;
}

@media (min-width: 1200px) and (max-width: 1499px) {
	.how_it_work_tab .tab-content {
		top: 73px;
	}
}

@media (min-width: 992px) and (max-width: 1199px) {
	.how_it_work_tab .tab-content {
		width: 270px;
		height: 270px;
		left: 10%;
	}

	.how_it_work_tab .tab-pane {
		line-height: 270px;
	}
	.section_title_three h2 {
		font-size: 33px;
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	.Blog_home_five_btn_part a {
		padding-left: 0;
	}
	.faq3_area .faq3_left_area {
		width: 100%;
		background: #fff;
	}
	.how_it_work_tab .tab-content {
		transform: translateX(-50%) rotate(45deg);
		left: 50%;
	}
	.how_it_work_tab .tab-content {
		width: 236px;
		height: 236px;
		top: 35%;
		left: 50%;
		transform: translate(-50%) rotate(45deg);
	}
	.how_it_work_tab .tab-content .icon_menu li {
		width: 70px;
		height: 70px;
		line-height: 70px;
	}
	.how_it_work_tab .tab-pane {
		line-height: 236px;
	}
	.how_it_work_tab {
		overflow: hidden;
	}

	.how_it_work_tab.section_padding_two {
		padding: 160px 0 100px;
	}

	.section_title_three p {
		width: 100%;
	}
	.blog_hm_five_post .blog_hm_five_title {
		width: 88%;
	}
	.blog_hm_five_slider .owl-nav button {
		width: 50px;
		height: 50px;
	}
	.blog_hm_five_slider .owl-nav button i {
		line-height: 50px;
	}

	.blog_hm_five_post {
		box-shadow: -2px 5px 10px #dedede;
	}

	.Blog_home_five_btn_part a {
		font-size: 13px;
	}
	.Blog_home_five_btn_part a i {
		padding-left: 6px;
		font-size: 17px;
	}
}

@media (min-width: 992px) and (max-width: 1199px) {
	.supplement_at_a_glance.section_padding_two {
		position: relative;
		z-index: 1;
	}
	.faq3_area .vigo_container_two {
		background: url(../../media/images/home3/girl.png) no-repeat scroll right 109px bottom/auto;
	}
	.faq3_area .faq3_left_area .faq3_accordion {
		background: #fff;
	}
	.Blog_home_five_btn_part a {
		padding-left: 25px;
	}
	.blog_hm_five_post {
		box-shadow: 5px 5px 7px #dedede;
	}
	.blog_hm_five_post .blog_hm_five_title {
		width: 90%;
	}
}

@media (min-width: 576px) and (max-width: 767px) {
	.how_it_work_tab .tab-content {
		-webkit-transform: translateX(-50%) rotate(45deg);
		-ms-transform: translateX(-50%) rotate(45deg);
		transform: translateX(-50%) rotate(45deg);
		position: relative;
		left: 50%;
		top: auto;
		bottom: 0;
		width: 300px;
		height: 300px;
	}
	.how_it_work_tab .tab-pane {
		line-height: 300px;
	}
	.faq3_area .faq3_left_area {
		width: 100%;
	}

	.faq3_area.section_padding_two {
		padding-bottom: 0;
	}

	.how_it_work_tab_menu {
		margin-bottom: 100px;
	}
	.how_it_work_tab.section_padding_two {
		padding-bottom: 130px;
		padding-top: 130px;
	}
	.Blog_home_five.section_padding_two {
		padding-top: 0;
	}
	.faq3_area .vigo_container_two {
		background: #fff;
	}
	.Blog_home_five_btn_part a:hover i {
		padding-left: 10px;
	}
	.Blog_home_five_slider_part .owl-stage-outer {
		width: 128%;
	}

	.Blog_home_five_slider_part {
		width: 100%;
	}
	.Blog_home_five_btn_part a {
		padding-left: 0;
	}
	.Blog_home_five_btn_part {
		position: static;
	}

	.blog_hm_five_post .blog_hm_five_content {
		width: 100%;
	}

	.blog_hm_five_slider .owl-nav button {
		width: 38px;
		height: 38px;
		line-height: 38px;
		border-radius: 0;
		margin-bottom: 3px;

		i {
			line-height: 38px;
		}
	}

	.blog_hm_five_slider .owl-nav {
		right: -19px;
		width: auto;
		top: calc(50% - 115px);
	}

	.how_it_work_tab.section_padding_two {
		padding-bottom: 141px;
	}

	#accordion {
		margin-bottom: 30px;
	}

	.blog_hm_five_post {
		-webkit-box-shadow: 4px -2px 17px #dedede;
		box-shadow: 4px -2px 17px #dedede;
	}
	.Blog_home_five.section_padding_two {
		padding-top: 30px;
		padding-bottom: 36px;
	}
}

@media only screen and (max-width: 575px) {
	.faq3_area .vigo_container_two {
		background: #fff;
	}
	.how_it_work_tab .tab-content {
		width: 220px;
		height: 220px;
		left: 50%;
		transform: translateX(-50%) rotate(45deg);
		margin-top: 50px;
	}
	.how_it_work_tab .tab-pane {
		line-height: 200px;
	}
	.how_it_work_tab .tab-content .icon_menu li {
		width: 60px;
		height: 60px;
		line-height: 68px;
	}

	.call_to_action3 .call_to_action_hello {
		padding: 0px 15px 0 15px;
	}
	.faq3_area .faq3_left_area {
		width: 100%;
	}
	.faq3_area .faq3_left_area .faq3_accordion {
		background: #fff;
	}

	.Blog_home_five_slider_part {
		width: 100%;
		padding-bottom: 0px;
	}

	.blog_hm_five_post .blog_hm_five_content {
		width: 100%;
	}

	.single_count_up {
		margin-bottom: 30px;
	}

	.how_it_work_tab.section_padding_two {
		padding: 115px 0 185px;
		overflow: hidden;
	}

	.Blog_home_five.section_padding_two {
		padding: 0 0 72px;
	}

	.blog_hm_five_slider .owl-nav button {
		width: 50px;
		height: 50px;
	}

	.blog_hm_five_slider .owl-nav button i {
		line-height: 50px;
	}
	.blog_hm_five_post .blog_hm_five_title {
		width: 90%;
	}
	.Blog_home_five_btn_part {
		display: none;
	}

	.Blog_home_five_slider_part .owl-stage-outer {
		width: 125%;
	}
	.Blog_home_five_slider_part .owl-stage-outer {
		width: 135%;
		padding-top: 80px;
	}
	.banner_area .owl-prev:hover {
		box-shadow: none;
	}
	.banner_area .owl-next:hover {
		box-shadow: none;
	}
	.blog_hm_five_post {
		-webkit-box-shadow: 2px 9px 17px #dedede;
		box-shadow: 2px 9px 17px #dedede;
	}
	.blog_hm_five_slider .owl-nav {
		position: static;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		width: auto;
		margin-top: 0;
	}
	.blog_hm_five_slider .owl-nav button {
		width: 30px;
		height: 30px;
		margin-bottom: 0;
		margin-right: 4px;
		border-radius: 0;

		i {
			line-height: 30px;
		}
	}
}