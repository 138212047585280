.related_product_slider.owl-carousel .owl-item img {
	width: auto;
	max-width: 100%;
	margin: 0 auto;
}

.related_product_slider.owl-carousel .owl-stage-outer {
	padding: 10px 0;
	margin-left: -1px;
}

.sn_related_product {
	padding: 30px;
	border: 1px solid #f5f5f5;
	transition: 0.5s;
	padding-bottom: 25px;

	&:hover {
		border: 1px solid transparent;
		box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.35);
	}
}

.related_product_slider .sn_related_product:hover {
	box-shadow: none;
	border: 1px solid rgba(58,216,46,0.25);
}

.sn_pd_detail a {
	margin-bottom: 0px;
	padding: 0;
}
.sn_pd_rating{
	margin-bottom: 8px;

	a {
		padding: 0;
		margin-right: 3.6px;

		i {
			font-size: 14px;
			color: $home6_primary3;
		}

		&:last-child {

			i {
				color: #b2b2b2;
			}
		}
	}
}

.sn_pd_detail{

	h5 {

		a {
			padding: 0;
			font-size: 13px;
			font-weight: 400;
			text-transform: capitalize;
			color: #000;
			transition: 0.3s;
			line-height: normal;
			font-family: 'Poppins', sans-serif;
		}
	}


	ins {
		font-size: 16px;
		color: $home6_primary2;
		font-weight: 700;
	}
	del {
		font-size: 13px;
		color: #ff8b8b;
	}
}
.related_product_title h1 {
	font-size: 36px;
	color: $home5_black;
	font-weight: 700;
	margin-top: 0;
	margin-bottom: 66px;
}

.related_product {
	padding: 135px 0 130px;
}

.related_product_slider .owl-nav {
	position: absolute;
	right: 0;
	top: -100px;

	button {
		margin-left: 7px;
		transition: 0.3s;

		i {
			color: $text_inner74;
		}

		&:hover {

			i {
				color: $home6_primary2;
			}
		}
	}
}

.related_product {

	p,h1,h2,h3,h4,h5,h6,a {
		font-family: $font_primary;
	}
}

.sn_pd_img {
	margin-bottom: 24px;

	a {
		padding: 0;
		margin: 0 auto;
		display: block;
		text-align: center;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.related_product {
		padding: 90px 0 65px;
	}
	.related_product_slider .owl-nav {
		top: -91px;
	}
}
@media (min-width: 576px) and (max-width: 767px) {
	.related_product_slider .owl-nav {
		top: -87px;
	}
}