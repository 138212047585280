.supplement_hot_selling_group-two {
	background: transparent;

	.supplement_what_we_say {
		background: transparent;
		padding-top: 74px;
	}
}

@media (min-width: 576px) and (max-width: 767px) {
	.supplement_hot_selling_group-two .supplement_what_we_say {
		padding-top: 80px;
	}
	.supplement_we_say_content .supplement_we_say_slide-img a {
		padding-left: 10px;
	}
}

@media (max-width: 575px) {
	.ingredients_key_single_feature span {
		display: none;
	}
}