/*--------------------------------------------------------------
  ##  Sign Up
  --------------------------------------------------------------*/
.sign-up-area {
    background: url(../../media/images/popup/background.jpg) no-repeat scroll 0 0/cover;
    padding-top: 137px;
    padding-bottom: 140px;
}

.sign-up-inner {
	max-width: 798px;
	margin: 0 auto;
	padding: 70px 40px;
	background: #fff;
	border: 6px solid rgba(0,0,0,0.03);
	box-shadow: 0px 44px 62px rgba(0,0,0,0.14);
}

.sign-up-section-title {
	text-align: center;
	margin-bottom: 40px;

	h4 {
		font-size: 24px;
		color: $home5_black;
		font-weight: 700;
		font-family: "Poppins", sans-serif;
		letter-spacing: 1.20px;
		position: relative;
		padding-bottom: 20px;
		margin-bottom: 20px;
		display: inline-block;

		&:after {
			position: absolute;
			content: "";
			left: 50%;
			bottom: 0;
			width: 10px;
			height: 2px;
			background: $home6_primary;
			margin-left: -5px;
		}
	}

	span {
		font-size: 20px;
		color: $home5_black;
		font-weight: 300;
		text-transform: capitalize;
		display: block;
	}
}

.sign-up-single-input {
	width: calc(50% - 15px);
	margin-bottom: 30px;

	input {
		width: 100%;
		height: 50px;
		border: 1px solid #ddd;
		border-radius: 0px;
		padding: 0 20px;

		&:focus {
			border: 1px solid $home6_primary;
		}
	}

	label {
		display: block;
		font-size: 12px;
		color: $home5_black;
		font-weight: 400;
		margin-bottom: 0;
		line-height: normal;
		margin-bottom: 10px;
		letter-spacing: 1.2px;
		text-transform: uppercase;
	}
}

.sign-up-form {

	form {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}
}

.sign-up-single-button {
	width: 210px;

	input {
		width: 100%;
		height: 52px;
		background: transparent;
		border: 2px solid $home6_primary;
		color: $home6_primary;
		border-radius: 0px;
		line-height: 46px;
		font-size: 14px;
		font-weight: 700;
		text-transform: uppercase;
		padding: 0;
		line-height: 50px;
		cursor: pointer;
		transition: 0.3s;

		&:hover {
			color: #fff;
			background: $home6_primary;
		}
	}
}

.sign-up-single-checkbox {
	width: calc(100% - 210px);
	padding-left: 30px;
	font-size: 14px;
	color: #9f9f9f;
	display: block;
	margin-bottom: 0;

	label {
		font-size: 14px;
		color: #9f9f9f;
		display: block;
		margin-bottom: 0;
	}

	a {
		padding: 0;
		font-size: 14px;
		color: #a3a3a3;
		font-weight: 700;
	}
}

.sign-up-single-checkbox input[type="checkbox"] {
	border: none;
	margin-right: 5px;
	vertical-align: middle;
}

.sign-up-form {
	margin-bottom: 36px;
}

.sign-up-social p {
	font-size: 14px;
	color: #222;
	font-weight: 400;
	line-height: normal;
	margin-bottom: 18px;
}

.sign-up-social-item {
	display: flex;
	justify-content: space-between;

	a {
		padding: 0;
		border: 2px solid #ebebeb;
		border-radius: 0;
		width: 220px;
		height: 52px;
		transition: 0.3s;

		i {
			width: 50px;
			height: 50px;
			line-height: 46px;
			text-align: center;
			border-right: 2px solid #ededed;
			font-size: 14px;
			color: #969696;
			vertical-align: middle;
			transition: 0.3s;
		}

		span {
			height: 50px;
			text-align: center;
			font-size: 14px;
			font-weight: 700;
			text-transform: uppercase;
			letter-spacing: 1.2px;
			line-height: 50px;
			width: calc(100% - 54px);
			display: inline-block;
			color: #969696;
			vertical-align: middle;
			transition: 0.3s;
		}

		&:hover {
			border: 2px solid #3473d2;

			i {
				color: #3473d2;
				border-right: 2px solid #3473d2;
			}
			span {
				color: #3473d2;
			}
		}
	}
}

/*--------------------------------------------------------------
  ##  Sign In
  --------------------------------------------------------------*/
.sign-in-area {

	.sign-in-inner {
		max-width: 422px;
		margin: 0 auto;
		background: #fff;
		padding: 75px 40px;
		box-shadow: 0px 44px 62px rgba(0,0,0,0.14);
		border: 6px solid rgba(0,0,0,0.03);
	}

	.sign-up-single-input {
		width: 100%;
		margin-bottom: 30px;
	}

	.sign-up-social-item {
		flex-wrap: wrap;

		a {
			width: 100%;
			margin-bottom: 10px;
		}
	}

	.sign-up-single-button {
		width: 100px;
	}
}

/*--------------------------------------------------------------
  ##  Reset Pass
  --------------------------------------------------------------*/
.reset-pass-area {

	.reset-pass-inner {
		max-width: 422px;
		margin: 0 auto;
		background: #fff;
		padding: 70px 40px;
		box-shadow: 0px 44px 62px rgba(0,0,0,0.14);
		border: 6px solid rgba(0,0,0,0.03);

		.sign-up-form {
			margin-bottom: 0;
		}
	}

	.sign-up-single-input {
		width: 100%;
		margin-bottom: 30px;
	}
}

.sign-up-magic-link {
	max-width: 422px;
	margin: 0 auto;
	background: #fff;
	padding: 130px 40px;
	box-shadow: 0px 44px 62px rgba(0,0,0,0.14);
	border: 6px solid rgba(0,0,0,0.03);
	margin-top: 108px;
	font-size: 14px;
	color: $home5_black;
	font-weight: 300;
	text-align: center;
}

/*--------------------------------------------------------------
  ##  Popup light
  --------------------------------------------------------------*/
#loading-subscribe1 .modal-dialog {
	width: 941px;
}
.loading-subscribe.loading-subscribe-white {
	min-width: 941px;
	background: #fff;
	margin: 0 auto;
	box-shadow: 22px 32px 70px rgba(0,0,0,0.1);
	padding: 45px;
	position: relative;
}
.loading-subscribe-inner {
	border: 7px solid rgba(0, 0, 0, 0.03);
	padding: 65px 55px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: end;
	-ms-flex-pack: end;
	justify-content: flex-end;
}
.loading-subscribe-img {
	width: 356px;
	position: absolute;
	left: 0;
	top: 50%;
	transform: translateY(-50%);
}

.loading-subscribe-detail {
	width: 60%;
}

.loading-subscribe-top {
	h1 {
		font-size: 42.2px;
		font-weight: 400;
		color: #026782;
		text-transform: capitalize;
		margin: 0;
		margin-bottom: 30px;

		span {
			color: $home6_primary;
			font-weight: 600;
			font-style: italic;
		}
	}

	p {
		font-size: 20px;
		color: #6f6f6f;
		margin-bottom: 30px;

		span {
			font-weight: 700;
			color: $home5_black;
		}
	}
}
.loading-subscribe-input {
	width: 100%;
	margin-bottom: 30px;

	input {
		width: 100%;
		height: 66px;
		border: 2px solid #026782;
		padding: 0 20px;
		border-radius: 0;
		color: rgba(57,57,57,0.6);

		&:focus {
			border: 2px solid $home6_primary;
		}
	}
}

.loading-subscribe-submit {

	button {
		border: none;
		height: 66px;
		border-radius: 0px;
		background: #3ad82e;
		font-size: 20px;
		font-weight: 700;
		color: #fff;
		letter-spacing: 2.2px;
		text-transform: uppercase;
		cursor: pointer;
		font-family: "Roboto", sans-serif;
		line-height: 69px;
		width: 253px;
		padding: 0;


		i {
			transition: 0.3s;
			transform: rotate(180deg);
			vertical-align: middle;
			font-size: 30px;
		}

		&:hover {

			i {
				margin-left: 10px;
			}
		}
	}
}

.modal-dialog {
	max-width: -moz-max-content;
	padding: 0;
	border: none;
}

.modal-backdrop.show {
	opacity: .75;
}
.loading-subscribe {
	position: relative;

	.remove {
		position: absolute;
		right: 0;
		top: -30px;
		cursor: pointer;
		z-index: -1;

		button {
			background: transparent;
			border: none;
			padding: 0;

			&:focus {
				outline: none;
			}
		}

		i {
			font-size: 20px;
			color: #fff;
		}
	}
}
/*--------------------------------------------------------------
  ##  Popup dark
  --------------------------------------------------------------*/
.loading-subscribe.loading-subscribe-dark {
	max-width: 941px;
	margin: 0 auto;
	background: url(../../media/images/popup/Dark.jpg) no-repeat scroll 0 0/cover;

	.loading-subscribe-top h1 {
		color: #fff;

		span {
			color: $home5_primary;
		}
	}

	p {
		span {
			font-weight: 500;
			color: $home5_primary;
		}
	}

	.loading-subscribe-input input {
		width: 100%;
		height: 66px;
		border: 2px solid rgba(255, 255, 255, 0.6);
		padding: 0 20px;
		border-radius: 0;
		color: rgba(255, 255, 255, 0.6);
		background: transparent;

		&:focus {
			border: 2px solid $home5_primary;
		}
	}

	.loading-subscribe-submit button {
		background: $home5_primary;
	}
}


/*--------------------------------------------------------------
  ##  Cookies
  --------------------------------------------------------------*/
.megamenu-cookies {
	position: fixed;
	width: 273px;
	height: auto;
	background: #fff;
	bottom: 0;
	border: 12px solid #ebebeb;
	padding: 36px 15px 30px;
	border-radius: 10px;
	left: 7.4%;
	-webkit-transform: scale(0);
	-ms-transform: scale(0);
	transform: scale(0);
	-webkit-transform-origin: left bottom;
	-ms-transform-origin: left bottom;
	transform-origin: left bottom;
	-webkit-box-shadow: 22px 32px 70px rgba(0, 0, 0, 0.13);
	box-shadow: 22px 32px 70px rgba(0, 0, 0, 0.13);
	font-size: 14px;
	font-weight: 400;
	color: $home5_black;
	line-height: 25px;
	z-index: 99;
	opacity: 0;

	a {
		padding: 0;
		color: $home6_primary;
	}

	a.agree {
		background: #026782;
		height: 54px;
		display: inline-block;
		line-height: 54px;
		width: 100%;
		text-align: center;
		margin-top: 10px;
		font-size: 14px;
		font-weight: 700;
		text-transform: uppercase;
		color: #fff;
		letter-spacing: 1.2px;
		transition: 0.5s;

		&:hover {
			letter-spacing: 4px;
		}
	}

	&.seen {
		opacity: 1;
		-webkit-transform: scale(1);
		-ms-transform: scale(1);
		transform: scale(1);
		-webkit-transition: all 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55) 3s;
		-o-transition: all 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55) 3s;
		transition: all 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55) 3s;

		.remove {
			cursor: pointer;
			position: absolute;
			top: 7px;
		}
	}
}
@media (min-width: 992px) and (max-width: 1199px) {
	.loading-subscribe.loading-subscribe-white {
		min-width: 550px;
		padding: 15px;
	}
	.modal-dialog {
		min-width: 550px;
	}

	#loading-subscribe1 .modal-dialog {
		width: 550px;
	}
	.loading-subscribe-inner {
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		padding: 20px;
		height: 350px;
		overflow-y: scroll;
	}
	.loading-subscribe-img {
		position: static;
		top: 0;
		transform: translateY(0);
		margin: 0 auto;
	}
	.loading-subscribe-detail {
		width: 100%;
		margin-top: 30px;
	}

	.loading-subscribe.loading-subscribe-dark {
		max-width: 550px;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.sign-up-inner {
		max-width: 80%;
	}

	.footer_five_area .footer_four_bottom_right a:first-child {
		padding-left: 0;
	}

	.loading-subscribe.loading-subscribe-dark {
		max-width: 550px;
	}
	.loading-subscribe.loading-subscribe-white {
		max-width: 100%;
		padding: 15px;
	}
	.modal-open .modal {
		overflow-y: scroll;
		width: 90%;
		margin: 0 auto;
	}
	.loading-subscribe-inner {
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		padding: 20px;
		height: 350px;
		overflow-y: scroll;
		background: #fff;
	}
	.loading-subscribe.loading-subscribe-dark .loading-subscribe-inner {
		background: #222;
	}
	.loading-subscribe-detail {
		padding-left: 80px;
		padding-right: 80px;
	}
	#loading-subscribe1.modal.show .modal-dialog {
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);
	}
	.loading-subscribe.loading-subscribe-white {
		margin: 0;
	}
	.loading-subscribe-img {
		position: static;
		left: 0;
		top: 0;
		transform: translateY(0);
		width: 100%;
		text-align: center;
	}
	.loading-subscribe-inner {
		flex-wrap: wrap;
		padding: 20px;
	}
	.loading-subscribe-detail {
		width: 100%;
		padding-top: 50px;
		padding-bottom: 80px;
	}

	#loading-subscribe1.modal.show .modal-dialog {
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);
	}
	.loading-subscribe.loading-subscribe-white {
		margin: 0;
	}
	.megamenu-cookies {
		left: 4%;
	}

	.loading-subscribe.loading-subscribe-white {
		min-width: 550px;
		padding: 15px;
	}
	.modal-dialog {
		min-width: 550px;
	}

	#loading-subscribe1 .modal-dialog {
		width: 550px;
	}
	.sign-up-area {
	    padding-top: 97px;
	    padding-bottom: 100px;
	}
}

@media (min-width: 576px) and (max-width: 767px) {
	.sign-up-social-item {
		flex-wrap: wrap;
	}
	.loading-subscribe.loading-subscribe-dark {
		max-width: 550px;
	}
	.sign-up-social-item a {
		margin-bottom: 10px;
	}

	.sign-up-inner {
		max-width: 90%;
	}

	.sign-up-area {
		padding-top: 87px;
		padding-bottom: 90px;
	}
	.loading-subscribe-detail {
		padding-bottom: 80px;
	}
	#loading-subscribe1 .modal-dialog {
		width: 500px;
	}
	.loading-subscribe.loading-subscribe-white {
		max-width: 100%;
		padding: 15px;
	}
	.modal-open .modal {
		overflow-y: scroll;
		width: 90%;
		margin: 0 auto;
	}
	.loading-subscribe-inner {
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		padding: 20px;
		height: 350px;
		overflow-y: scroll;
		background: #fff;
	}
	.loading-subscribe.loading-subscribe-dark .loading-subscribe-inner{
		background: #222;
	}
	#loading-subscribe1.modal.show .modal-dialog {
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);
	}
	.loading-subscribe.loading-subscribe-white {
		margin: 0;
	}
	.megamenu-cookies {
		left: 5%;
	}

	.loading-subscribe.loading-subscribe-white {
		min-width: 500px;
		padding: 15px;
	}
	.modal-dialog {
		min-width: auto;
	}

	.loading-subscribe-img {
		position: static;
		left: 0;
		top: 0;
		transform: translateY(0);
		width: 100%;
		text-align: center;
	}
	.loading-subscribe-inner {
		flex-wrap: wrap;
		padding: 20px;
	}
	.loading-subscribe-detail {
		width: 100%;
		padding-top: 50px;
	}

	.sign-up-social-item a {
		width: 51%;
	}
}

@media only screen and (max-width: 575px) {

	/*--------------------------------------------------------------
  ##  Sign Up
  --------------------------------------------------------------*/
    .sign-up-inner {
		max-width: 100%;
	}
	.sign-up-single-input {
		width: 100%;
	}
	.sign-up-single-button {
		width: 100%;
		margin-bottom: 30px;

	}
	.sign-up-single-checkbox {
		width: 100%;
		padding-left: 0;
	}

	.sign-up-social-item {
		flex-wrap: wrap;
	}

	.sign-up-social-item a {
		margin-bottom: 10px;
	}

	.loading-subscribe.loading-subscribe-white {
		min-width: 100%;
		padding: 15px;
	}
	#loading-subscribe1 .modal-dialog {
		width: 100%;
	}
	.modal-dialog {
		min-width: 100%;
	}
	.megamenu-cookies {
		left: 4%;
	}
	.footer_five_area .footer_five_top .footer_five_top_right label::after {
		-webkit-transform: skewX(0deg);
		-ms-transform: skewX(0deg);
		transform: skewX(0deg);
	}
	.modal-open .modal {
		overflow-y: scroll;
		width: 90%;
		margin: 0 auto;
	}
	.loading-subscribe-inner {
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		padding: 20px;
		height: 350px;
		overflow-y: scroll;
		background: #fff;
	}
	#loading-subscribe1.modal.show .modal-dialog {
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);
	}
	.loading-subscribe.loading-subscribe-white {
		margin: 0;
	}
	.loading-subscribe-img {
		position: static;
		left: 0;
		top: 0;
		transform: translateY(0);
		width: 100%;
		text-align: center;
	}
	.loading-subscribe-inner {
		flex-wrap: wrap;
		padding: 20px;
	}
	.loading-subscribe-detail {
		width: 100%;
		padding-top: 50px;
		padding-bottom: 80px;
	}

	.loading-subscribe-dark .loading-subscribe-inner {
		background: $home5_black;
	}

	.sign-up-area {
	    padding-top: 87px;
	    padding-bottom: 87px;
	}

	.sign-up-inner {
		padding: 70px 30px;
	}
	.footer_five_area .footer_five_bottom .backtotop {
		position: static;
		width: 100%;
		text-align: center;
	}
	.sign-in-area .sign-in-inner {
		margin: 0 15px;
		max-width: 100%;
	}
}

@media only screen and (max-width: 479px) {
	.loading-subscribe-top h1 {
		font-size: 30px;
	}
	.loading-subscribe-top p {
		font-size: 16px;
	}
	.loading-subscribe-submit button {
		padding: 0 30px;
		width: 100%;
		font-size: 16px;
	}
	.sign-up-section-title h4 {
		margin-bottom: 10px;
	}

	.sign-up-section-title span {
		font-size: 16px;
	}
	.sign-up-area {
		padding-top: 87px;
		padding-bottom: 87px;
	}
	.sign-up-magic-link {
		margin-top: 30px;
	}
}