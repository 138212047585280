.count_up {
	background: rgb(61,181,230);
	background: -moz-linear-gradient(right, rgba(61,181,230,1) 0%, rgba(0,134,195,1) 100%);
	background: -webkit-linear-gradient(right, rgba(61,181,230,1) 0%,rgba(0,134,195,1) 100%);
	background: linear-gradient(to left, rgba(61,181,230,1) 0%,rgba(0,134,195,1) 100%);
	padding: 52px 0;
}

.single_count_up {
	background: rgba(255,255,255,0.05);
	text-align: center;
	padding: 26px;
	padding-bottom: 20px;
}
.single_count_inner {
	display: inline-block;
	text-align: left;
}
.countup_icon{
	position: relative;
	display: inline-block;

	i {
		font-size: 22px;
		color: #fff;
		line-height: 24px;
	}
}
.counter {
	color: #fff;
	font-size: 36px;
	font-weight: 300;
	letter-spacing: 4.3px;
	margin-bottom: 5px;
}
.countup_desc {
	padding-left: 36px;

	span {
		font-size: 14px;
		color: $home5_black;
		font-weight: 500;
		opacity: 0.8;
		letter-spacing: 1.7px;
		line-height: 30px;
	}
}
.countup_icon {
	margin-bottom: 16px;
}

.countup_icon::after {
	position: absolute;
	left: calc(100% + 30px);
	top: 10px;
	width: 1px;
	opacity: 0.2;
	height: 66px;
	content: "";
	background: #ffff;
	-webkit-transform: rotate(-45deg) translateY(-50%);
	-ms-transform: rotate(-45deg) translateY(-50%);
	transform: rotate(45deg);
	z-index: 9;
	-webkit-transform-origin: center;
	-ms-transform-origin: center;
	transform-origin: top;
}

@media (min-width: 768px) and (max-width: 991px) {
	.single_count_up {
		margin-bottom: 30px;
	}

	#pick-three .col-lg-4.col-md-6:last-child .single_count_up {
		margin-bottom: 0;
	}
}

@media (min-width: 576px) and (max-width: 767px) {
	.single_count_up {
		margin-bottom: 20px;
	}
}

@media (max-width: 575px) {
	.count_up {
		padding: 52px 0 24px;
	}
}