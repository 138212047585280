@charset "UTF-8";
/*!
  Theme Name: Vaxin-Health suppliment single product affiliate marketing html template
  Theme URI: http://themeim.com/demo/vaxin
  Author: ThemeIM
  Description: Theme Description
  Version: 1.0.0
  License: GNU General Public License v2 or later
  License URI: http://www.gnu.org/licenses/gpl-2.0.html
  Tags: affiliate landing page, body enhancement, fitness, health, html, landing page, medicine, nutrition, product, responsive, small business, sports, supplement, vitamin, weight loss
  */
/*--------------------------------------------------------------
  >>> TABLE OF CONTENTS:
  ----------------------------------------------------------------
  # Base
  # Sections
    ## Header
    ## Banner
    ## Breadcrumb
    ## Top feature
    ## Top feature 2
    ## About area
    ## Product with carousel
    ## Features
    ## Services
    ## Services With background
    ## Services 2
    ## Feature 3
    ## Testimonial
    ## Testimonial  two
    ## Portfolio
    ## Video
    ## Video 2
    ## Pricing Table
    ## Work Process
    ## Product Detail
    ## Faq
    ## Blog
    ## Contact
    ## Footer
    ## Call to action
    ## Promote
    ## Package Plan
    ## Advertise
    ## Supplement service
    ## 404 Error content
    ## Contact page
    ## Footer 2
    ## Blog page
    ## Ingredient
    ## Responsive


  --------------------------------------------------------------*/
@font-face {
  font-family: theme-build-icons;
  src: url("../fonts/theme-build-icons.eot");
  src: url("../fonts/theme-build-icons.eot?#iefix") format("embedded-opentype"), url("../fonts/theme-build-icons.woff") format("woff"), url("../fonts/theme-build-icons.ttf") format("truetype"), url("../fonts/theme-build-icons.svg#theme-build-icons") format("svg");
  font-weight: normal;
  font-style: normal;
}

.icon:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  content: "";
  font-family: 'theme-build-icons';
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  speak: none;
  text-transform: none;
}

.icon.-facebook:before {
  content: "";
}

.icon.-instagram:before {
  content: "";
}

.icon.-twitter:before {
  content: "";
}

.icon.-viget:before {
  content: "";
}

/*--------------------------------------------------------------
  ##  Fontfamily
  --------------------------------------------------------------*/
/*--------------------------------------------------------------
  ##  Themecolor
  --------------------------------------------------------------*/
/* Fool-proof @font-face */
/* Based on http://coding.smashingmagazine.com/2013/02/14/setting-weights-and-styles-at-font-face-declaration/ */
/* Front */
a {
  display: inline-block;
  padding: 10px;
  text-decoration: none;
  background-color: orange;
  border-radius: 4px;
  -moz-transition: background-color 0.1s 0.1s, color 0.1s;
  -o-transition: background-color 0.1s 0.1s, color 0.1s;
  -webkit-transition: background-color 0.1s 0.1s, color 0.1s;
  transition: background-color 0.1s 0.1s, color 0.1s;
}

@-webkit-keyframes slide-down {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 0;
  }
}

@-moz-keyframes slide-down {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 0;
  }
}

@-ms-keyframes slide-down {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 0;
  }
}

@-o-keyframes slide-down {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 0;
  }
}

@keyframes slide-down {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 0;
  }
}

.element {
  width: 100px;
  height: 100px;
  background: black;
  -webkit-animation: slide-down 5s 3;
  -moz-animation: slide-down 5s 3;
  -ms-animation: slide-down 5s 3;
  -o-animation: slide-down 5s 3;
  animation: slide-down 5s 3;
}

.visually-hidden {
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  clip: rect(0, 0, 0, 0);
  position: absolute;
}

/*--------------------------------------------------------------
  ##  Normalize
  --------------------------------------------------------------*/
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: none;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
}

body {
  margin: 0;
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: none;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
}

h1 {
  margin: 0.67em 0;
}

hr {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-size: 1em;
}

a {
  background-color: transparent;
}

a,
a:hover {
  text-decoration: none;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

b,
strong {
  font-weight: bolder;
}

code,
kbd,
samp {
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

img {
  border-style: none;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

legend {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"],
[type="radio"] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

[hidden] {
  display: none;
}

a:focus,
input:focus,
button:focus,
-moz-button:focus {
  outline: none;
}

/*--------------------------------------------------------------
  ##  Typography
  --------------------------------------------------------------*/
html {
  font-size: 16px;
  font-family: "Roboto", sans-serif;
}

body {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.67rem;
  color: #666;
}

button,
input,
select,
optgroup,
textarea {
  line-height: 1.5em;
}

button:focus,
input:focus,
select:focus,
optgroup:focus,
textarea:focus {
  outline: none;
}

button[type="button"].owl-prev::-moz-focus-outer {
  border: 0px;
}

input::-moz-focus-outer {
  border: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  clear: both;
  font-weight: 700;
  text-transform: uppercase;
  color: #383838;
  font-family: 'Poppins', sans-serif;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.56rem;
}

h4 {
  font-size: 1.34rem;
}

h5 {
  font-size: 1.23rem;
}

h6 {
  font-size: 1.12rem;
}

dfn,
cite,
em,
i {
  font-style: italic;
}

blockquote {
  margin: 0 1.5em;
}

address {
  margin: 0 0 1.5em;
}

pre {
  font-size: 15px;
  font-size: 0.9375rem;
  line-height: 1.6;
  margin-bottom: 1.6em;
  max-width: 100%;
  overflow: auto;
  padding: 1.6em;
}

code,
kbd,
tt,
var {
  font-size: 15px;
  font-size: 0.9375rem;
}

abbr,
acronym {
  border-bottom: 1px dotted;
  cursor: help;
}

mark,
ins {
  text-decoration: none;
}

big {
  font-size: 125%;
}

/*--------------------------------------------------------------
  ##  Elements
  --------------------------------------------------------------*/
html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

hr {
  border: 0;
  height: 1px;
  margin-bottom: 1.5em;
}

ul,
ol,
li {
  list-style: none;
}

li > ul,
li > ol {
  margin-bottom: 0;
  margin-left: 1.5em;
  list-style: none;
}

dt {
  font-weight: bold;
}

dd {
  margin: 0 1.5em 1.5em;
}

img {
  height: auto;
  max-width: 100%;
}

figure {
  margin: 1em 0;
}

table {
  margin: 0 0 1.5em;
  width: 100%;
}

/*--------------------------------------------------------------
  ##  Forms
  --------------------------------------------------------------*/
button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  border: 1px solid;
  border-radius: 3px;
  color: rgba(0, 0, 0, 0.8);
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 1em;
  padding: .6em 1em .4em;
}

input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="number"],
input[type="tel"],
input[type="range"],
input[type="date"],
input[type="month"],
input[type="week"],
input[type="time"],
input[type="datetime"],
input[type="datetime-local"],
input[type="color"],
textarea {
  border-radius: 3px;
  padding: 3px;
}

textarea {
  width: 100%;
}

/*--------------------------------------------------------------
  ##  Sameclass
  --------------------------------------------------------------*/
.vigo_container_one,
.vigo_container_two {
  padding: 0px 15px;
  margin: 0 auto;
}

.vigo_container_one {
  max-width: 1650px;
}

.vigo_container_two {
  max-width: 1376px;
}

/*--------------------------------------------------------------
  ##  Btnone
  --------------------------------------------------------------*/
.btn_two {
  height: 40px;
  border: 2px solid #3db5e6;
  line-height: 36px;
  font-size: 1rem;
  color: #3db5e6;
  text-transform: uppercase;
  font-weight: 700;
  border-radius: 3px;
  position: relative;
  overflow: hidden;
  transition: all 0.5s ease;
  z-index: 1;
  background: transparent;
  width: 150px;
  padding: 0;
  text-align: center;
  height: 46px;
  line-height: 45px;
}

.btn_two:hover {
  color: #fff;
  background: #3db5e6;
}

.btn_three {
  width: 142px;
  padding: 0;
  height: 50px;
  border-radius: 0;
  text-align: center;
  line-height: 48px;
  color: #D01BD0;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 2.5px;
  text-transform: uppercase;
  position: relative;
  z-index: 1;
  transition: 0.5s linear;
  background: #fff;
  border: 2px solid #D01BD0;
}

.btn_three:hover {
  color: #fff;
  background: #D01BD0;
}

.btn_four {
  width: 142px;
  padding: 0;
  height: 50px;
  border-radius: 0;
  text-align: center;
  line-height: 50px;
  color: #D01BD0;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 2.5px;
  text-transform: uppercase;
  position: relative;
  z-index: 1;
  transition: 0.5s linear;
  background: #fff;
  border: 2px solid #fff;
}

.btn_four:hover {
  color: #fff;
  background: transparent;
}

/*--------------------------------------------------------------
  ##  Section Padding
  --------------------------------------------------------------*/
.section_padding {
  padding: 7.5rem 0;
}

.section_padding_less {
  padding: 7.5rem 0 6.05rem;
}

#contact {
  padding-bottom: 7.5rem;
}

.single_process h6 {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
  ##  Section Title
  --------------------------------------------------------------*/
.section_title {
  margin-bottom: 4.2rem;
}

.section_title > span {
  font-size: 1rem;
  color: #7b7b7a;
  text-transform: capitalize;
  font-weight: 500;
  font-family: "Hind", sans-serif;
  display: block;
  margin-bottom: 0.5rem;
  line-height: 0.89rem;
  letter-spacing: 1.5px;
}

.section_title h2 {
  text-transform: capitalize;
  color: #383838;
  margin-bottom: 0.5rem;
  margin-top: 0;
  line-height: 50px;
  font-size: 36px;
}

.section_title h2 span {
  color: #f99a00;
}

.section_title p {
  padding-top: 10px;
}

.section_title .svg {
  width: 193px;
  fill: #f99a00;
  stroke: #f99a00;
  height: 26px;
}

/*--------------------------------------------------------------
  ##  Padding / Margin
  --------------------------------------------------------------*/
.mb0 {
  margin-bottom: 0;
}

.padding_right {
  padding-right: 3.34rem;
}

.pl-60 {
  padding-left: 60px;
}

/*--------------------------------------------------------------
  ##  Transition
  --------------------------------------------------------------*/
.btn_one,
.btn_one:after,
.btn_one:before,
.process_icon span,
.single_process h6,
.process_icon,
.single_pkg_bottom {
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.pricing_head h6,
.single_pkg_plan .single_pkg_bottom h6,
.single_pkg_plan .single_pkg_bottom p,
.single_pkg_plan .single_pkg_bottom span,
.single_pkg_plan .single_pkg_bottom span::after {
  -o-transition: 0.3s;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.single_price,
.single_promote {
  -o-transition: 0.5s;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

/*--------------------------------------------------------------
  ##  Mobile menu
  --------------------------------------------------------------*/
.toggle-inner i {
  padding-top: 20px;
  font-size: 21px;
  color: #f99a00;
  padding-right: 0px;
}

#mobile-nav-wrap {
  display: none;
  background: #f8f8f8;
  border-bottom: 2px solid #e7ecf0;
}

#mobile-nav-wrap #mobile-logo {
  float: left;
  padding: 15px 0 3px;
}

#mobile-nav-wrap #mobile-logo a {
  padding: 0;
}

#mobile-nav-wrap.mnav-fixed {
  position: fixed;
  top: 0;
  width: 100%;
  left: 0;
  z-index: 9999;
  -webkit-animation: headerFixedTop .35s;
  animation: headerFixedTop .35s;
}

#mobile-nav-wrap.fixed {
  position: fixed;
  top: 0;
  -webkit-animation: headerFixedTop .35s;
  animation: headerFixedTop .35s;
  width: 100%;
  z-index: 9999;
  -webkit-box-shadow: 0 0px 5px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0px 5px rgba(0, 0, 0, 0.2);
}

#mobile-nav-wrap.fixed .mobile_toolbar {
  display: none;
}

.mobile_toolbar {
  background: #fff;
  overflow: hidden;
}

.mobile_toolbar .header_login a {
  padding: 0px 6px;
  font-size: 14px;
  color: #666;
  text-transform: capitalize;
}

.mobile_toolbar .header_login a:hover {
  color: #f99a00;
}

.mobile_toolbar .header_login a:first-child {
  padding-left: 0px;
}

.mobile_toolbar .header_login a:last-child {
  padding-right: 0px;
}

.mobile-menu-inner {
  width: 100%;
  text-align: center;
  background: #fff;
  position: fixed !important;
  left: -100%;
  -webkit-transition: all 0.7s cubic-bezier(0.65, -0.22, 0.35, 1.13);
  -o-transition: all 0.7s cubic-bezier(0.65, -0.22, 0.35, 1.13);
  transition: all 0.7s cubic-bezier(0.65, -0.22, 0.35, 1.13);
  height: 100vh !important;
  overflow-x: hidden;
  overflow-y: scroll;
  top: 0;
  z-index: 99999;
}

.mobile-menu-inner.mnav-fixed {
  position: fixed;
}

.mobile-menu-inner #mobile-logo {
  margin-bottom: 30px;
}

.mobile-menu-inner .mob-nav {
  margin: 0;
  padding: 0;
  text-align: left;
  list-style: none;
}

.mobile_accor_togo {
  height: 65px;
  background: #f8f8f8;
  padding: 0px 15px;
  border-bottom: 2px solid #e7ecf0;
}

.mobile_accor_logo {
  text-align: left;
}

.mobile_accor_logo a {
  padding: 0;
  padding-top: 18px;
  display: inline-block;
}

.accordion-menu {
  width: 100%;
  max-width: 350px;
  text-align: left;
  border-radius: 4px;
}

.accordion-menu > li {
  position: relative;
}

.accordion-menu > li.single-link {
  margin: 3px 0;
}

.accordion-menu > li .dropdownlink:before {
  content: "\f105";
  position: absolute;
  right: 10px;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  font-size: 16px;
}

.accordion-menu > li.open .dropdownlink:before {
  content: "\f107";
  position: absolute;
  right: 10px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  font-size: 16px;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}

.accordion-menu > li > a {
  font-weight: 500;
  font-size: 17px;
  text-decoration: none;
  text-transform: capitalize;
  padding: 10px 0;
  color: #3e414d;
}

.accordion-menu .accordion-menu li.open .dropdownlink {
  color: #3d0e03;
}

.accordion-menu .accordion-menu li.open .dropdownlink .fa-angle-down {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.accordion-menu .accordion-menu li:last-child .dropdownlink {
  border-bottom: 0;
}

.accordion-menu .dropdownlink {
  cursor: pointer;
  display: block;
  font-size: 17px;
  position: relative;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.accordion-menu .dropdownlink i {
  position: absolute;
  top: 13px;
  left: 16px;
  font-size: 14px;
}

.accordion-menu .dropdownlink .fa-angle-down {
  right: 12px;
  left: auto;
}

.accordion-menu .dropdownlink:last-child {
  border-bottom: none;
}

.accordion-menu .submenuItems {
  display: none;
  background: transparent;
  padding-left: 0;
  margin-left: 5px;
}

.accordion-menu .submenuItems li:last-child {
  border-bottom: none;
}

.accordion-menu .submenuItems a {
  display: block;
  color: #666;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  text-decoration: none;
  font-size: 17px;
  line-height: 17px;
  padding: 8px 0;
}

#moble-search input[type="text"] {
  background: #fff;
  height: 45px;
  color: #3e414d;
  border: 0;
  padding: 0 10px;
  width: 100%;
  outline: 0;
  border-radius: 30px;
  padding-left: 20px;
  border: 1px solid #3e414d;
}

#moble-search input[type="text"]::-webkit-input-placeholder {
  color: #3e414d;
}

#moble-search input[type="text"]:-ms-input-placeholder {
  color: #3e414d;
}

#moble-search input[type="text"]::-ms-input-placeholder {
  color: #3e414d;
}

#moble-search input[type="text"]::placeholder {
  color: #3e414d;
}

#moble-search button[type="submit"] {
  background: transparent;
  border: 0;
  color: #3e414d;
  position: absolute;
  right: 15px;
  line-height: 45px;
  top: 0;
  padding: 0;
  font-size: 1rem;
  cursor: pointer;
}

@-webkit-keyframes headerFixedTop {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes headerFixedTop {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.active .mobile-menu-inner {
  left: 0;
  background: #fff;
}

.close-menu {
  position: absolute;
  right: 20px;
  top: 18px;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.close-menu span {
  position: absolute;
  top: 0px;
}

.close-menu span:before {
  width: 2px;
  height: 25px;
  background: #666;
  z-index: 999;
  position: absolute;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  content: "";
}

.close-menu span::after {
  position: absolute;
  width: 2px;
  height: 25px;
  background: #666;
  top: 0;
  content: "";
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.mobile_toolbar .top_toolbar_right {
  text-align: left;
}

.mobile_toolbar .header_login {
  display: inline-block;
  line-height: 36px;
  width: 47%;
  float: right;
  text-align: right;
}

.header_login a {
  color: #fff;
}

.mobile_toolbar .header_social {
  text-align: right;
}

.close-menu .bar {
  display: block;
  position: relative;
  margin-bottom: 3px;
  width: 100%;
  height: 2px;
  background-color: #fff;
  -webkit-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

.footer-social-link li {
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border: 1px solid #fff;
  border-radius: 50%;
  color: #fff;
  margin: 0px 5px;
}

.footer-social-link {
  margin-top: 30px;
  padding-bottom: 50px;
  padding-left: 0;
}

.footer-social-link li a {
  color: #666;
}

.whc_widget.whc_top {
  padding-left: 100px;
}

.header_social a {
  color: #666;
  font-size: 14px;
  padding: 0px 4px;
}

.mobile-menu-inner .accordion-menu {
  padding: 20px;
}

#moble-search {
  margin: 0px 20px;
  position: relative;
}

.close-menu .bar:first-child {
  margin-bottom: 5px;
  -webkit-transform: rotate(-45deg) translate(-6px, 6px);
  -ms-transform: rotate(-45deg) translate(-6px, 6px);
  transform: rotate(-45deg) translate(-6px, 6px);
}

.close-menu .bar:last-child {
  -webkit-transform: rotate(45deg) translate(1px, 1px);
  -ms-transform: rotate(45deg) translate(1px, 1px);
  transform: rotate(45deg) translate(1px, 1px);
}

.toggle-inner {
  width: 50px;
  background: transparent;
  text-align: right;
  float: right;
  cursor: pointer;
}

.toggle-inner.green::after,
.close-menu.green i {
  color: #70c041;
}

.close-menu i {
  color: #3e414d;
  line-height: 88px;
  top: 0;
  position: relative;
  font-size: 27px;
}

/*--------------------------------------------------------------
  ##  modal
  --------------------------------------------------------------*/
.modal {
  z-index: 99999;
}

.modal-dialog {
  background: #ffffff;
  padding: 30px;
  pointer-events: visible;
  border: 2px solid #f99a00;
  margin: 0 auto;
  min-width: 941px;
  z-index: 99999;
  top: 50%;
}

.modal.show .modal-dialog {
  -webkit-transform: translate(0, 0);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.modal-body {
  padding: 0;
}

.modal-body .nav {
  margin-bottom: 35px;
}

.modal-body .nav li {
  padding: 0px 25px;
  border-right: 1px solid #dfdfdf;
}

.modal-body .nav li a {
  font-size: 16px;
  font-weight: 700;
  color: #dfdfdf;
}

.modal-body .nav li i {
  color: #fff;
  margin-right: 10px;
}

.modal-body .nav li:last-child {
  border-right: 0px;
}

.modal-body .nav li:first-child {
  padding-left: 0px;
}

.modal-body .nav li.active i {
  color: #f68b1f;
}

.login-title h3 {
  font-size: 20px;
  color: #737373;
  font-weight: 700;
  margin-bottom: 30px;
}

.login-right,
.login-left {
  display: inline-block;
  vertical-align: top;
  margin: 0px -3px;
}

.login-social ul {
  padding-left: 0;
}

.login-left {
  width: 40%;
}

.login-right {
  width: 60%;
  padding-left: 1.67rem;
}

.login-social li {
  height: 52px;
  border-radius: 5px;
  margin-bottom: 20px;
  line-height: 52px;
  text-align: center;
  font-size: 20px;
}

.login-social li.facebook {
  background: #3648cc;
}

.login-social li.twitter {
  background: #1da1f2;
}

.login-social li.pinterest {
  background: #f82b43;
}

.login-social li a {
  display: block;
  color: #fefefe;
  padding: 0;
}

.login-social li a i {
  float: left;
  color: #fff;
  height: 52px;
  width: 52px;
  line-height: 52px;
  text-align: center;
  position: relative;
}

.login-social li a i:after {
  position: absolute;
  right: 0;
  content: "";
  width: 2px;
  height: 100%;
  -webkit-box-shadow: 0px 0px 6px rgba(40, 31, 3, 0.5);
  box-shadow: 0px 0px 6px rgba(40, 31, 3, 0.5);
}

.login_main_form input {
  width: 100%;
  height: 52px;
  background: transparent;
  border: 1px solid #9e9e9e;
  margin-bottom: 20px;
  padding-left: 15px;
  color: #737373;
}

.login_main_form input[type="checkbox"] {
  width: auto;
  height: auto;
  margin-right: 10px;
}

.login_main_form input[type="submit"] {
  width: 160px;
  height: 50px;
  background: #f99a00;
  color: #fff;
  border: 2px solid #fff;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 700;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 0;
}

.select_wrapper {
  position: relative;
}

.select_wrapper select {
  width: 100%;
  height: 51px;
  padding: 0px 15px;
  border: 1px solid #9e9e9e;
  background: transparent;
  color: #737373;
  opacity: 0.5;
  -moz-appearance: none;
  -webkit-appearance: none;
  margin-bottom: 20px;
  position: relative;
}

.select_wrapper span {
  float: right;
  color: #f68b1f;
}

.select_wrapper::before {
  position: absolute;
  right: 15px;
  top: 12px;
  content: "\f0d7";
  font-family: "Font Awesome 5 Free";
  color: #f99a00;
  pointer-events: none;
  font-weight: 900;
}

.Switcher__control {
  background-image: none;
}

.mask-overlay {
  content: '';
  position: fixed;
  background: rgba(0, 0, 0, 0.8);
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
}

/*--------------------------------------------------------------
  ##  Preloader
  --------------------------------------------------------------*/
#loader-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #f6fbfe;
  top: 0;
  left: 0;
  z-index: 999999;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 200px;
}

.loader-dot {
  animation-name: loader;
  animation-timing-function: ease-in-out;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  border-radius: 100%;
  background-color: black;
  position: absolute;
  border: 2px solid white;
  height: 20px;
  width: 20px;
}

.loader-dot:first-child {
  background-color: #f1b601;
  animation-delay: 0.5s;
}

.loader-dot:nth-child(2) {
  background-color: #2acb35;
  animation-delay: 0.4s;
}

.loader-dot:nth-child(3) {
  background-color: #ff704d;
  animation-delay: 0.3s;
}

@keyframes loader {
  15% {
    transform: translateX(0);
  }
  45% {
    transform: translateX(180px);
  }
  65% {
    transform: translateX(180px);
  }
  95% {
    transform: translateX(0);
  }
}

#colors {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
  ##  color
  --------------------------------------------------------------*/
.footer_top_two .widget .widget_title h5 {
  color: #363636;
}

.widget_post_list li a span i,
.widget_about_two .widget_address li i,
.footer_bottom_two .widget_payment a i,
.footer_bottom_two .widget_payment span b,
.call_to_action_right .btn_wrapper .btn_one {
  color: #f99a00;
}

.footer_bottom_two .copyright span, .footer_bottom_two .widget_payment span {
  color: #666;
}

.footer_top_two .widget, .footer_top_two .widget a {
  color: #666;
}

.widget_store_links .widget_links_list li i {
  color: #121212;
}

.call_to_action_left h2,
.call_to_action_left p {
  color: #ffffff;
}

/*--------------------------------------------------------------
  ##  border
  --------------------------------------------------------------*/
.widget.widget_instafeed .widget_insta_list ul li {
  border-style: solid;
  border-color: #ffffff;
}

.widget.widget_instafeed .widget_insta_list ul li {
  border-width: 3px;
}

/*--------------------------------------------------------------
  ##  background
  --------------------------------------------------------------*/
.footer_top_two {
  background: #f8f8f8;
}

.footer_top_two .widget .widget_title h5::after,
.call_to_action_area {
  background: #f99a00;
}

.call_to_action_right .btn_wrapper .btn_one {
  color: #fff;
}

.call_to_action_right .btn_wrapper .btn_one:before {
  border: 1px solid #fff;
}

.call_to_action_right .btn_wrapper .btn_one:hover {
  color: #f99a00;
}

.call_to_action_right .btn_wrapper .btn_one:hover:after {
  background: #fff;
}

/*--------------------------------------------------------------
  ##  box-shadow
  --------------------------------------------------------------*/
.widget_instafeed .widget_insta_list ul li {
  -webkit-box-shadow: 0 9px 21px rgba(72, 72, 72, 0.14);
  -moz-box-shadow: 0 9px 21px rgba(72, 72, 72, 0.14);
  box-shadow: 0 9px 21px rgba(72, 72, 72, 0.14);
}

/*--------------------------------------------------------------
  ##  svg
  --------------------------------------------------------------*/
.footer_top_two .widget_logo .svg .st1,
.footer_top_two .widget_logo .svg .st0 {
  fill: #f99a00;
  stroke: #f99a00;
}

/*--------------------------------------------------------------
  ##  Color
  --------------------------------------------------------------*/
.single_feature_two span {
  color: #f99a00;
}

.single_feature_two:hover h5 {
  color: #f99a00;
}

.about_sub h6 {
  color: #f99a00;
}

.light_style.section_title h2 {
  color: #fff;
}

.light_style.section_title > span {
  color: #fff;
}

.ingredient_content h5 {
  color: #383838;
}

.ingredient_content .mini-des p {
  color: #383838;
}

.ingridient_list table tr {
  color: #666;
}

.single_service_two span {
  color: #f99a00;
}

.single_service_two h6 {
  color: #505253;
}

.single_feature_3 span {
  color: #f99a00;
}

.single_feature_3 h6 {
  color: #505253;
}

.single_feature_3 a {
  color: #f99a00;
}

.single-servie span {
  color: #f99a00;
}

.single-servie:hover h6 {
  color: #f99a00;
}

/*--------------------------------------------------------------
  ##  Background
  --------------------------------------------------------------*/
.single_feature_two span {
  background: #fff;
}

.ingredient_content .mini-des ul li {
  color: #666;
}

.ingredient_content > p {
  color: #666;
}

.ingredient-img {
  background: #eff8ff;
  border: 6px solid #fff;
}

.feature_three {
  background: #eff8ff;
}

.single_feature_3 {
  background: #fff;
}

.about_image .image_one {
  background: #e3f1fb;
}

.about_image .image_two {
  background: #f99a00;
}

/*--------------------------------------------------------------
  ##  Border color
  --------------------------------------------------------------*/
.single_feature_two {
  border: 1px solid #ebebeb;
}

/*--------------------------------------------------------------
  ##  Header
  --------------------------------------------------------------*/
.header_area {
  position: relative;
  z-index: 9;
  background: #ffffff;
  box-shadow: 0px 0px 7.28px 0.72px rgba(72, 72, 72, 0.08);
  -webkit-box-shadow: 0px 0px 7.28px 0.72px rgba(72, 72, 72, 0.08);
  -moz-box-shadow: 0px 0px 7.28px 0.72px rgba(72, 72, 72, 0.8);
}

.header_nav {
  max-height: 80px;
}

.header_logo .svg {
  width: 116px;
  padding: 35px 0 0;
}

.header_logo .svg .st1,
.header_logo .svg .st0 {
  fill: #f99a00;
  stroke: #f99a00;
}

#mobile-logo .svg {
  width: 90px;
}

#mobile-logo .svg .st1,
#mobile-logo .svg .st0 {
  fill: #f99a00;
  stroke: #f99a00;
}

.mobile_accor_logo .svg {
  width: 116px;
}

.mobile_accor_logo .svg .st1,
.mobile_accor_logo .svg .st0 {
  fill: #f99a00;
  stroke: #f99a00;
}

.top_toolbar {
  background: #363636;
  height: 2.5rem;
  transition: 0.5s;
}

.toolbar_left ul {
  padding-left: 0;
  margin-bottom: 0;
}

.toolbar_left ul li {
  display: inline-block;
  font-size: 0.9rem;
  font-weight: 400;
  color: #e5e2e2;
  padding: 0 1.39rem;
  position: relative;
  line-height: 2.50rem;
}

.toolbar_left ul li:last-child {
  float: right;
  padding-right: 0;
}

.toolbar_left ul li span {
  color: #f99a00;
  font-size: 0.9rem;
  margin-right: 5px;
}

.toolbar_left ul li span.fa {
  margin-right: 0.28rem;
}

.toolbar_left ul li a {
  color: #e5e2e2;
  padding: 0;
  font-size: 0.9rem;
  text-transform: capitalize;
}

.toolbar_left ul li a:hover {
  color: #f99a00;
}

.toolbar_left ul li:first-child {
  padding-left: 0;
}

.toolbar_left ul li:first-child:after {
  position: absolute;
  content: "";
  right: 0;
  top: 50%;
  width: 0.06rem;
  height: 0.84rem;
  background: #514e4e;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.submenuItems i {
  float: left;
  font-size: 9px;
  margin-top: 3px;
  padding-right: 6px;
}

.phone_number {
  width: 52%;
  float: left;
  text-align: left;
  line-height: 36px;
}

.phone_number span {
  color: #f99a00;
  font-size: 0.89rem;
  margin-right: 5px;
}

.phone_number a {
  color: #666;
  padding: 0;
  font-size: 0.89rem;
}

.header_nav {
  margin-top: 0;
  transition: 0.5s;
}

.header_nav.fixed {
  position: fixed;
  top: 0;
  -webkit-animation: headerFixedTop .35s;
  animation: headerFixedTop .35s;
  width: 100%;
  z-index: 9999;
  background: #fff;
  -webkit-box-shadow: 0 0px 5px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0px 5px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0px 5px rgba(0, 0, 0, 0.2);
}

.hdr_btn_wrapper {
  padding: 20px 0;
}

@keyframes headerFixedTop {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.header_logo a {
  padding: 0;
}

.header_logo a .logo-white {
  display: none;
}

.main_menu {
  font-family: "Roboto", sans-serif;
  padding: 18px 0;
}

.main_menu > ul {
  margin-bottom: 0;
}

.main_menu > ul > li {
  display: inline-block;
  position: relative;
}

.main_menu > ul > li > a {
  font-size: 1rem;
  text-transform: uppercase;
  color: #363636;
  font-weight: 500;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  line-height: 1rem;
  display: block;
  padding: 14px 28px;
}

.main_menu > ul > li > a.current_page_item {
  color: #f99a00;
}

.main_menu > ul > li > a:hover {
  color: #f99a00;
}

.Switcher__control {
  top: -1px;
  border-radius: 0;
  width: 40px;
  height: 40px;
  margin-left: -40px;
}

.Switcher__control i {
  color: #f99a00;
}

.Switcher {
  top: 142px;
}

.headroom--not-top .header_nav {
  height: 5rem;
}

.headroom--not-top .header_nav .main_menu {
  padding: 1.3rem 0 1.3rem;
}

.headroom--not-top .header_nav .header_logo .svg {
  padding: 23px 0 0;
}

.headroom--not-top .header_nav .btn_wrapper {
  margin: 24px 0;
  height: 2.2rem;
}

.headroom--not-top .header_nav .btn_wrapper .btn_one {
  line-height: 1.2rem;
}

.headroom--pinned {
  -ms-transform: translateY(0);
  -webkit-transform: translateY(0);
  transform: translateY(0);
  position: fixed;
  -webkit-animation-name: headerSlideDown;
  animation-name: headerSlideDown;
}

.header-area {
  background: #fff;
}

#header {
  z-index: 999;
  position: fixed;
  width: 100%;
  left: 0;
}

.headroom--pinned {
  -ms-transform: translateY(0);
  -webkit-transform: translateY(0);
  transform: translateY(0);
  position: fixed;
  -webkit-animation-name: headerSlideDown;
  animation-name: headerSlideDown;
}

.headroom {
  -webkit-transition: -webkit-transform 200ms linear;
  transition: -webkit-transform 200ms linear;
  -o-transition: transform 200ms linear;
  transition: transform 200ms linear;
  transition: transform 200ms linear, -webkit-transform 200ms linear;
  -webkit-animation-duration: .5s;
  animation-duration: .5s;
}

.headroom--unpinned {
  -webkit-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);
  position: fixed;
}

@-webkit-keyframes headerSlideDown {
  0% {
    margin-top: -150px;
  }
  100% {
    margin-top: 0;
  }
}

.animated.slideUp {
  -webkit-animation-name: slideUp;
  animation-name: slideUp;
}

.hide-topbar .top_toolbar {
  transform: scaleY(0);
  transform-origin: top left;
}

.hide-topbar .header_nav {
  margin-top: -45px;
}

/*--------------------------------------------------------------
  ##  Header
  --------------------------------------------------------------*/
.sub-menu {
  position: absolute;
  z-index: 9;
  background-color: #fff;
  width: 220px;
  -webkit-box-shadow: -0.764px 0.804px 7px 0px rgba(102, 101, 101, 0.22);
  box-shadow: -0.764px 0.804px 7px 0px rgba(102, 101, 101, 0.22);
  top: calc(100% + 30px);
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  padding: 0;
  margin: 0;
  -webkit-box-shadow: 0 11px 21px rgba(0, 0, 0, 0.25);
  box-shadow: 0 11px 21px rgba(0, 0, 0, 0.25);
  padding: 26px;
  left: calc(50% - 110px);
  top: calc(100% + 25px);
}

.sub-menu:after {
  position: absolute;
  content: "";
  left: calc(50% - 6px);
  top: -5px;
  width: 10px;
  height: 10px;
  background: #fff;
  z-index: -2;
  transform: rotate(45deg);
  border-radius: 2px;
}

.sub-menu li {
  width: 100%;
}

.sub-menu li a {
  padding: 7px 0px;
  width: 100%;
  display: block;
  color: #5830B4;
  font-size: .88rem;
  font-weight: 400;
  border-radius: 0;
  text-transform: uppercase;
  position: relative;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  font-size: 14px;
  font-weight: 500;
  border-bottom: 1px solid #ededed;
}

.sub-menu li:first-child a {
  padding-top: 0;
}

.sub-menu li a i {
  margin-right: 10px;
  font-size: 8px;
  position: absolute;
  left: 11px;
  top: 6px;
}

.main_menu li:hover .sub-menu {
  visibility: visible;
  opacity: 1;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  left: calc(50% - 110px);
}

.main_menu .sub-menu a:hover,
.main_menu .sub-menu a.current_page_item {
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  color: #f99a00;
}

/*--------------------------------------------------------------
  ##  Header
  --------------------------------------------------------------*/
.header_logo .svg {
  width: 116px;
  padding: 18px 0;
}

.header_logo .svg .st1,
.header_logo .svg .st0 {
  fill: #f99a00;
  stroke: #f99a00;
}

#mobile-logo .svg {
  width: 90px;
}

#mobile-logo .svg .st1,
#mobile-logo .svg .st0 {
  fill: #f99a00;
  stroke: #f99a00;
}

.mobile_accor_logo .svg {
  width: 116px;
}

.mobile_accor_logo .svg .st1,
.mobile_accor_logo .svg .st0 {
  fill: #f99a00;
  stroke: #f99a00;
}

.top_toolbar {
  background: #363636;
  height: 2.75rem;
}

.toolbar_left ul {
  padding-left: 0;
  margin-bottom: 0;
}

.toolbar_left ul li {
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  color: #e5e2e2;
  padding: 0 1.39rem;
  position: relative;
  line-height: 2.75rem;
}

.toolbar_left ul li:last-child {
  float: right;
  padding-right: 0;
}

.toolbar_left ul li span {
  color: #f99a00;
  font-size: 14px;
  margin-right: 5px;
}

.toolbar_left ul li span.fa {
  margin-right: 0.28rem;
}

.toolbar_left ul li a {
  color: #e5e2e2;
  padding: 0;
  font-size: 14px;
  text-transform: capitalize;
}

.toolbar_left ul li a:hover {
  color: #f99a00;
}

.toolbar_left ul li:first-child {
  padding-left: 0;
}

.toolbar_left ul li:first-child:after {
  position: absolute;
  content: "";
  right: 0;
  top: 50%;
  width: 0.06rem;
  height: 0.84rem;
  background: #514e4e;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.submenuItems i {
  float: left;
  font-size: 9px;
  margin-top: 3px;
  padding-right: 6px;
}

.phone_number {
  width: 52%;
  float: left;
  text-align: left;
  line-height: 36px;
}

.phone_number span {
  color: #f99a00;
  font-size: 0.89rem;
  margin-right: 5px;
}

.phone_number a {
  color: #666;
  padding: 0;
  font-size: 0.89rem;
}

.header_nav.fixed {
  position: fixed;
  top: 0;
  -webkit-animation: headerFixedTop .35s;
  animation: headerFixedTop .35s;
  width: 100%;
  z-index: 9999;
  background: #fff;
  -webkit-box-shadow: 0 0px 5px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0px 5px rgba(0, 0, 0, 0.2);
}

@keyframes headerFixedTop {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.Switcher__control {
  top: -1px;
  border-radius: 0;
  width: 40px;
  height: 40px;
  margin-left: -40px;
}

.Switcher__control i {
  color: #f99a00;
}

.Switcher::before, .Switcher__control {
  position: absolute;
  left: calc(100% + 40px);
  background-color: #fff;
}

.Switcher {
  top: 124px;
  left: 0px;
  width: 208px;
  margin-left: -208px;
  transition: 0.5s;
}

.Switcher.Switcher--show {
  margin-left: 0;
}

.Switcher::before {
  display: none;
}

.headroom--pinned {
  -ms-transform: translateY(0);
  -webkit-transform: translateY(0);
  transform: translateY(0);
  position: fixed;
  -webkit-animation-name: headerSlideDown;
  animation-name: headerSlideDown;
}

.header-area {
  background: #fff;
}

#header {
  z-index: 999;
  position: fixed;
  width: 100%;
  left: 0;
}

.headroom--pinned {
  -ms-transform: translateY(0);
  -webkit-transform: translateY(0);
  transform: translateY(0);
  position: fixed;
  -webkit-animation-name: headerSlideDown;
  animation-name: headerSlideDown;
}

.headroom {
  -webkit-transition: -webkit-transform 200ms linear;
  transition: -webkit-transform 200ms linear;
  -o-transition: transform 200ms linear;
  transition: transform 200ms linear;
  transition: transform 200ms linear, -webkit-transform 200ms linear;
  -webkit-animation-duration: .5s;
  animation-duration: .5s;
}

.headroom--unpinned {
  -webkit-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);
  position: fixed;
}

@-webkit-keyframes headerSlideDown {
  0% {
    margin-top: -150px;
  }
  100% {
    margin-top: 0;
  }
}

.animated.slideUp {
  -webkit-animation-name: slideUp;
  animation-name: slideUp;
}

/*--------------------------------------------------------------
  ##  Header
  --------------------------------------------------------------*/
/*--------------------------------------------------------------
  ##  Header2
  --------------------------------------------------------------*/
.hdr_area_two {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  background: transparent;
  box-shadow: none;
  font-family: "Roboto", sans-serif;
}

.hdr_area_two p, .hdr_area_two h1, .hdr_area_two h2, .hdr_area_two h3, .hdr_area_two h4, .hdr_area_two h5, .hdr_area_two h6, .hdr_area_two a {
  font-family: "Roboto", sans-serif;
}

.hdr_area_two.headroom--not-top .header_nav {
  background: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.hdr_area_two.headroom--not-top .header_nav .main_menu .sub-menu a.current_page_item {
  color: #45D638;
}

.hdr_area_two.headroom--not-top .header_nav .btn_three {
  color: #45D638;
  height: 45px;
  line-height: 42px;
  background: transparent;
}

.hdr_area_two.headroom--not-top .header_nav .btn_three:before {
  border: 2px solid #fff;
}

.hdr_area_two.headroom--not-top .header_nav .btn_three:hover {
  color: #fff;
  background: #45D638;
}

.hdr_area_two.headroom--not-top .header_nav .btn_three:hover:before {
  border: 2px solid #fff;
  opacity: 1;
}

.hdr_area_two.headroom--not-top .header_nav .main_menu {
  padding: 25px 0 23px;
}

.hdr_area_two .sub-menu a:hover {
  color: #cf3815;
}

.hdr_area_two .main_menu {
  padding: 26px 0;
}

.hdr_area_two .main_menu > ul > li > a {
  color: #fff;
  border-radius: 3px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  letter-spacing: 0.25px;
  height: 32px;
  padding: 0 18px;
  line-height: 32px;
}

.hdr_area_two .main_menu .sub-menu a:hover,
.hdr_area_two .main_menu .sub-menu a.current_page_item {
  color: #D01BD0;
}

.hdr_area_two .btn_one, .hdr_area_two .btn_two {
  border: 2px solid #fff;
  color: #fff;
  letter-spacing: 2.2px;
  padding: 0;
  width: 143px;
  text-align: center;
}

.hdr_area_two .header_logo .svg .st1,
.hdr_area_two .header_logo .svg .st0 {
  fill: #fff;
  stroke: #fff;
}

.hdr_area_two .header_logo .svg {
  fill: #fff;
  stroke: #fff;
}

.top_toolbar_new {
  height: 45px;
  background: #2e2c97;
  font-family: "Roboto", sans-serif;
  position: relative;
}

.top_toolbar_new .toolbar_left {
  display: flex;
}

.top_toolbar_new .toolbar_left p {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  line-height: 45px;
}

.top_toolbar_new .toolbar_left p span {
  color: #f24400;
  font-weight: 500;
}

.top_toolbar_new .toolbar_right {
  text-align: right;
  line-height: 44px;
}

.top_toolbar_new .toolbar_right ul {
  margin-bottom: 0;
}

.top_toolbar_new .toolbar_right ul li {
  display: inline-block;
  font-size: 14px;
  color: #fff;
  font-weight: 400;
  padding: 0 10px;
  position: relative;
  cursor: pointer;
}

.top_toolbar_new .toolbar_right ul li > i {
  font-size: 14px;
  color: #fff;
}

.top_toolbar_new .toolbar_right ul li.cart {
  padding-right: 10px;
}

.top_toolbar_new .toolbar_right ul li:last-child {
  padding-right: 0;
  padding-left: 20px;
}

.top_toolbar_new .toolbar_right ul li:last-child i {
  margin-right: 5px;
}

.top_toolbar_new .toolbar_right ul li:first-child {
  padding: 0px 8px;
}

.top_toolbar_new .toolbar_right ul li:first-child:after {
  display: none;
}

.top_toolbar_new .toolbar_right ul li:after {
  position: absolute;
  left: 0;
  top: 10px;
  height: calc(100% - 20px);
  width: 1px;
  background: rgba(255, 255, 255, 0.1);
  content: "";
}

.top_toolbar_new .toolbar_right ul li.cart:before {
  position: absolute;
  content: "";
  right: 6px;
  top: 25%;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #45D638;
}

.top_toolbar_new .toolbar_right ul li > a {
  padding: 0;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
}

.top_toolbar_new .toolbar_right ul li.search {
  position: inherit;
}

#count_down {
  display: inline-block;
}

#count_down p {
  line-height: 44px;
}

#count_down p span {
  font-size: 20px;
  color: #fff;
  background: rgba(0, 0, 0, 0.3);
  display: inline-block;
  padding: 0px 16px;
  margin: 0 7px;
  vertical-align: middle;
  line-height: 45px;
  margin-right: 3px;
}

.cart_detail {
  position: absolute;
  width: 311px;
  background: #fff;
  text-align: left;
  right: 50%;
  transform: translateX(30%);
  padding: 37px;
  border-radius: 3px;
  z-index: 9;
  font-family: "Roboto", sans-serif;
  top: calc(100% + 3px);
  visibility: hidden;
  transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
}

.cart_detail:after {
  position: absolute;
  content: "";
  left: calc(50% - 5px);
  top: -5px;
  width: 10px;
  height: 10px;
  background: #fff;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  border-radius: 2px;
}

.cart_detail p, .cart_detail h1, .cart_detail h2, .cart_detail h3, .cart_detail h4, .cart_detail h5, .cart_detail h6 {
  font-family: "Roboto", sans-serif;
}

.single_cart {
  padding: 13px 0;
  border-bottom: 1px solid #ddd;
}

.single_cart:first-child {
  padding-top: 0;
}

.cart_left {
  width: 60px;
  background: #ebebeb;
  text-align: center;
  padding: 10px;
  vertical-align: middle;
  display: inline-block;
}

.cart_right {
  display: inline-block;
  width: calc(100% - 65px);
  vertical-align: middle;
  padding-left: 10px;
}

.cart_right h3 {
  font-size: 14px;
  color: #171723;
  font-weight: 500;
  margin-bottom: 0;
  text-transform: capitalize;
}

.cart_right p {
  font-size: 24px;
  color: #D01BD0;
  margin-bottom: 0;
}

.cart_right p sup {
  font-size: 12px;
}

.cart_more {
  text-align: center;
  line-height: 14px;
  padding-top: 26px;
}

.cart_more a {
  font-size: 14px;
  color: #5830B4;
  font-weight: 700;
  text-transform: uppercase;
  padding: 0;
  letter-spacing: 1.8px;
  transition: 0.5s;
}

.cart_more a:hover i {
  margin-left: 10px;
  color: #45D638;
}

.cart_more a i {
  font-size: 15.02px;
  color: #45D638 !important;
  transition: 0.3s;
}

.hdr_area_three .cart_more a {
  color: #3db5e6;
}

.hdr_area_three .cart_more a:hover i {
  margin-left: 10px;
}

.hdr_area_three .cart_more a i {
  color: #3db5e6 !important;
}

.toolbar_right li:hover .cart_detail {
  visibility: visible;
  transform: translateX(48%);
  transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
}

.header_logo {
  line-height: 80px;
  margin-left: -5px;
}

.search_detail_two {
  position: absolute;
  width: 100%;
  text-align: left;
  left: 0;
  padding: 0px;
  border-radius: 3px;
  z-index: 9;
  font-family: "Roboto", sans-serif;
  top: calc(100% + 3px);
  transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  top: 0;
  height: 100%;
  border: none;
  background: #ededed;
  opacity: 0;
  z-index: -1;
}

.search_detail_two form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 15px;
  max-width: 80%;
  margin: 0 auto;
  justify-content: center;
  height: 100%;
}

.search_detail_two form button {
  display: inline-block;
  border: none;
  border-radius: 0;
  color: #ededed;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
  background: transparent;
  padding: 0;
  color: #121212;
}

.search_detail_two form input {
  display: inline-block;
  border: none;
  padding: 0 15px;
  border-radius: 0;
  width: 100%;
  background: #ededed;
  color: #121212;
  background: transparent;
  font-size: 16px;
}

.search_detail_two.active {
  opacity: 1;
  z-index: 1;
}

.search_detail_two_close {
  position: absolute;
  left: 8%;
  top: 50%;
  transform: translateY(-50%);
  font-size: 24px;
  padding-right: 12px;
  border-right: 2px solid #ddd;
  cursor: pointer;
  color: #121212;
}

.search_detail_two_close i {
  margin-top: 5px;
}

/*--------------------------------------------------------------
  ##  Header2
  --------------------------------------------------------------*/
.hdr_area_three .sub-menu li a {
  color: #121212;
}

.hdr_area_three .hdr_btn_wrapper a {
  color: #121212;
}

.hdr_area_three .top_toolbar_new .toolbar_right ul li.cart::before {
  right: 0;
}

.hdr_area_three .top_toolbar_new .toolbar_right ul li.cart {
  padding-right: 0;
}

.hdr_area_three .top_toolbar_new .toolbar_right ul li:hover a, .hdr_area_three .top_toolbar_new .toolbar_right ul li:hover i {
  color: #3db5e6;
}

.hdr_area_three .top_toolbar_new .toolbar_right ul li:last-child::after {
  left: 4px;
  background: rgba(51, 51, 51, 0.2);
}

.hdr_area_three .cart_more a i {
  color: #3db5e6 !important;
}

.hdr_area_three.headroom--not-top.hdr_area_two #mobile-nav-wrap {
  background: #3db5e6;
}

.hdr_area_three.headroom--not-top.headroom--not-top .header_nav {
  background: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.hdr_area_three .btn_one:hover,
.hdr_area_three .btn_two:hover {
  color: #fff;
}

.hdr_area_three .btn_one:hover:before,
.hdr_area_three .btn_two:hover:before {
  background: #3db5e6;
}

.hdr_area_three .top_toolbar_new {
  background: #f2f2f2;
}

.hdr_area_three .top_toolbar_new .toolbar_left p {
  color: #121212;
}

.hdr_area_three .top_toolbar_new .toolbar_left p span {
  color: #121212;
}

.hdr_area_three .top_toolbar_new .toolbar_right ul li > i {
  color: #121212;
}

.hdr_area_three .top_toolbar_new .toolbar_right ul li.cart:before {
  background: #3db5e6;
}

.hdr_area_three .top_toolbar_new .toolbar_right ul li.cart:after {
  background: rgba(51, 51, 51, 0.2);
}

.hdr_area_three .top_toolbar_new .toolbar_right ul li a {
  color: #121212;
}

.hdr_area_three .main_menu > ul > li > a {
  color: #121212;
  font-size: 14px;
  letter-spacing: normal;
  line-height: normal;
  height: auto;
  padding: 0px 19.2px;
}

.hdr_area_three .main_menu > ul > li:hover > a,
.hdr_area_three .main_menu > ul > li > a.current_page_item {
  background: transparent;
  color: #3db5e6;
}

.hdr_area_three .main_menu .sub-menu a:hover,
.hdr_area_three .main_menu .sub-menu a.current_page_item {
  color: #3db5e6;
}

.hdr_area_three .header_nav {
  background: #fff;
}

.hdr_area_three .btn_one,
.hdr_area_three .btn_two {
  border: 2px solid #3db5e6;
  color: #3db5e6;
  box-shadow: 18px 18px 38px rgba(222, 222, 222, 0.01);
}

.hdr_area_three .header_logo a img {
  margin-top: 0px;
  margin-left: 0;
}

.hdr_area_three.hdr_area_two #mobile-nav-wrap {
  background: #3db5e6;
}

.mobile-menu-inner-four.mobile-menu-inner-two .mobile_accor_togo {
  background: #3db5e6;
}

.main_menu > ul > li.inherit {
  position: inherit;
}

.main_menu > ul > li.inherit:hover .megamenu {
  visibility: visible;
  opacity: 1;
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  left: 15px;
  width: 100%;
}

.col-auto.no-position {
  position: inherit;
}

.vigo_container_two,
.vigo_container_one {
  position: relative;
}

.megamenu {
  position: absolute;
  left: 15px;
  width: calc(100% - 30px);
  max-width: calc(100% - 30px);
  background: #fff;
  top: 100%;
  opacity: 0;
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  visibility: hidden;
}

.megamenu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 0 auto;
  border-bottom: 11px solid #5830B4;
  padding: 70px;
  box-shadow: 0px 19px 21px rgba(0, 0, 0, 0.25);
  background: url(../../media/images/popup/Cookies.png) no-repeat scroll bottom right/contain;
  background-size: 13%;
  background-color: white;
}

.megamenu .column:nth-child(2n+1) {
  width: 43%;
}

.megamenu .column:first-child {
  width: 35%;
}

.megamenu .column:nth-child(2n) {
  width: 27%;
  text-align: left;
}

.megamenu .column {
  position: relative;
}

.megamenu .column:after {
  position: absolute;
  content: "";
  right: 0;
  top: 0;
  width: 1px;
  height: 92%;
  background: #ddd;
}

.megamenu .column:last-child {
  border-right: 0;
  padding-left: 80px;
  padding-right: 150px;
}

.megamenu .column:last-child:after {
  display: none;
}

.megamenu .column:last-child .mm-header {
  margin-bottom: 40px;
}

.megamenu .column ul {
  padding-left: 0;
}

.megamenu .column ul li {
  line-height: normal;
  margin-bottom: 25px;
}

.megamenu .column ul a {
  padding: 0;
  font-size: 14px;
  color: #121212;
  font-weight: 400;
  line-height: normal;
}

.megamenu .column ul i {
  vertical-align: middle;
}

.megamenu .product-highlight-slider.owl-carousel .owl-item img {
  display: inline-block;
  width: auto;
}

.megamenu .sn_related_product {
  border: 1px solid transparent;
  margin-right: 15px;
  padding-left: 0;
}

.megamenu .sn_related_product:hover {
  box-shadow: none;
}

.megamenu .column-inner {
  text-align: left;
  margin: 0 auto;
  display: inline-block;
  width: auto;
  padding-left: 80px;
}

.megamenu .video-popup {
  margin-bottom: 70px;
}

.megamenu .video-popup a {
  width: 60px;
  height: 60px;
  padding: 0;
  text-align: center;
  background: #45D638;
  border-radius: 0px;
  transition: 0.5s;
}

.megamenu .video-popup a:hover {
  background: #222;
}

.megamenu .video-popup a:hover i {
  color: #45D638;
}

.megamenu .video-popup a i {
  color: #121212;
  font-size: 35px;
  line-height: 60px;
  transition: 0.5s;
}

.megamenu .sharing-caring a {
  width: 44px;
  height: 44px;
  padding: 0;
  display: inline-block;
  background: #ebebeb;
  text-align: center;
  line-height: 44px;
  border-radius: 50%;
  transition: 0.3s;
}

.megamenu .sharing-caring a i {
  font-size: 14px;
  color: #121212;
}

.megamenu .sharing-caring a:hover {
  background: #45D638;
}

.megamenu .sharing-caring a:hover i {
  color: #fff;
}

.megamenu .sn_pd_img {
  position: relative;
}

.megamenu .sn_pd_img a {
  display: inline-block;
}

.megamenu .sn_pd_img span {
  width: 45px;
  height: 45px;
  background: red;
  display: inline-block;
  border-radius: 50%;
  text-align: center;
  line-height: 45px;
  position: absolute;
  right: 20%;
  bottom: 0;
  color: #fff;
  background: #45D638;
  font-size: 16px;
}

.megamenu .sn_pd_rating a:last-child i {
  color: #b2b2b2;
}

.megamenu .sn_pd_rating a i {
  font-size: 14px;
  color: #D01BD0;
}

.megamenu .sn_pd_detail a h5 {
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
  color: #000;
  letter-spacing: 0.5px;
}

.mm-header h3 {
  font-size: 20px;
  color: #121212;
  font-weight: 500;
  letter-spacing: 1.2px;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
}

.mm-header h3:after {
  position: absolute;
  left: 0;
  bottom: 0;
  content: "";
  width: 10px;
  height: 2px;
  background: #45D638;
}

.mm-header p {
  font-size: 14px;
  color: #121212;
  font-weight: 300;
  letter-spacing: 0.25px;
}

.megamenu .sn_pd_detail a h5 {
  transition: 0.3s;
}

.megamenu .sn_pd_detail a h5:hover {
  color: #45D638;
}

.megamenu .column ul li {
  transition: 0.3s;
}

.megamenu .column ul li a, .megamenu .column ul li i {
  transition: 0.3s;
  color: #121212;
  letter-spacing: 0.5px;
}

.megamenu .column ul li i {
  margin-right: 3px;
}

.megamenu .column ul li:hover {
  margin-left: 15px;
}

.megamenu .column ul li:hover i {
  color: #45D638;
}

.megamenu-two .mm-header h3::after {
  background: #3db5e6;
}

.megamenu-two.megamenu .sn_pd_img span {
  background: #3db5e6;
}

.megamenu-two.megamenu .sn_pd_rating a i {
  color: #3db5e6;
}

.megamenu-two.megamenu .sn_pd_detail a h5:hover {
  color: #3db5e6;
}

.megamenu-two.megamenu .column ul li a:hover, .megamenu-two.megamenu .column ul li i {
  color: #3db5e6;
}

.megamenu-two.megamenu .video-popup a {
  background: #3db5e6;
}

.megamenu-two.megamenu .video-popup a:hover {
  background: #222;
}

.megamenu-two.megamenu .video-popup a:hover i {
  color: #3db5e6;
}

.megamenu-two.megamenu .sharing-caring a:hover {
  background: #3db5e6;
}

.megamenu-two.megamenu {
  border-bottom: 11px solid #3db5e6;
}

.megamenu-two .megamenu.sn_related_product {
  padding: 10px;
}

.hdr_area_three.hdr_area_two.headroom--not-top .header_nav .main_menu .sub-menu a.current_page_item {
  color: #3db5e6;
}

.hdr_area_three .main_menu > ul > li > a {
  position: relative;
}

.hdr_area_three .main_menu > ul > li > a:after {
  position: absolute;
  content: "";
  width: 0px;
  height: 1px;
  background: #121212;
  opacity: 1;
  z-index: 1;
  left: 20px;
  top: calc(100% + 5px);
  transition: 0.3s;
}

.hdr_area_three .main_menu > ul > li > a:hover:after, .hdr_area_three .main_menu > ul > li > a.current_page_item:after {
  width: 30px;
}

.hdr_area_three .hdr_btn_wrapper a:hover {
  color: #3db5e6;
}

.hdr_area_three .hdr_btn_wrapper a:hover i {
  color: #3db5e6;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .hdr_area_three .main_menu > ul > li > a {
    font-size: 12px;
  }
  .megamenu .sn_related_product {
    padding: 0;
  }
  .megamenu .column-inner {
    width: auto;
  }
  .megamenu .column:last-child {
    padding-left: 30px;
    padding-right: 0;
  }
  .megamenu .column-inner {
    padding-left: 30px;
  }
}

@media (min-width: 1200px) and (max-width: 1500px) {
  .hdr_area_three .main_menu > ul > li > a {
    padding: 0px 15px;
  }
}

/*--------------------------------------------------------------
  ##  Header2
  --------------------------------------------------------------*/
.hdr_area_four .top_toolbar_new {
  background: #5830B4;
  position: relative;
}

.hdr_area_four .top_toolbar_new .toolbar_left p {
  color: #ffffff;
}

.hdr_area_four .top_toolbar_new .toolbar_left p span {
  color: #ffffff;
}

.hdr_area_four .top_toolbar_new #count_down span {
  background: #45D638;
  margin-top: 0;
  line-height: 45px;
}

.hdr_area_four .top_toolbar_new .toolbar_right ul li {
  color: #ffffff;
}

.hdr_area_four .top_toolbar_new .toolbar_right ul li > i {
  color: #ffffff;
}

.hdr_area_four .top_toolbar_new .toolbar_right ul li > i:hover {
  color: #45D638;
}

.hdr_area_four .top_toolbar_new .toolbar_right ul li > a {
  color: #ffffff;
}

.hdr_area_four .top_toolbar_new .toolbar_right ul li > a:hover {
  color: #45D638;
}

.hdr_area_four .main_menu > ul > li > a {
  font-size: 14px;
  color: #5830B4;
  position: relative;
  padding: 0 19px;
  transition: 0.3s;
}

.hdr_area_four .main_menu > ul > li > a:hover, .hdr_area_four .main_menu > ul > li > a.current_page_item {
  background: transparent;
  color: #D01BD0;
}

.hdr_area_four .main_menu > ul > li > a:hover:after, .hdr_area_four .main_menu > ul > li > a.current_page_item:after {
  color: #D01BD0;
  left: 0;
}

.hdr_area_four .main_menu > ul > li > a:after {
  position: absolute;
  left: 6px;
  top: 0;
  content: "/";
  transform: rotate(20deg);
  transition: 0.3s;
}

.hdr_area_four .header_nav {
  background: #f5f5f5;
}

.hdr_btn_wrapper {
  padding: 26px 0;
}

.hdr_btn_wrapper a {
  padding: 0;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  color: #D01BD0;
  letter-spacing: 2px;
  vertical-align: middle;
  transition: 0.3s;
}

.hdr_btn_wrapper a:hover i {
  color: #45D638;
}

.hdr_btn_wrapper a i {
  font-size: 20px;
  vertical-align: middle;
  transition: 0.3s;
  margin-top: -2px;
}

@media (min-width: 1200px) and (max-width: 1499px) {
  .hdr_btn_wrapper a {
    font-size: 16px;
  }
  .hdr_area_four .main_menu > ul > li > a {
    padding: 0 20px;
    padding-right: 5px;
  }
}

/*--------------------------------------------------------------
  ##  Banner
  --------------------------------------------------------------*/
.banner_area {
  background: #fff;
  height: 730px;
  font-family: "Roboto", sans-serif;
  overflow: hidden;
}

.banner_area a, .banner_area p, .banner_area h1, .banner_area h2, .banner_area h3, .banner_area h4, .banner_area h5, .banner_area h6 {
  font-family: "Roboto", sans-serif;
}

.banner_area .owl-carousel .owl-stage-outer {
  width: 100%;
}

.banner_area .owl-item {
  opacity: 0;
}

.banner_area .owl-item.active {
  opacity: 1;
}

.banner_area .banner_two_area .owl-nav {
  display: block;
}

.banner_area .owl-prev {
  top: calc(50% - 55px);
  left: -83px;
  position: absolute;
  width: 110px;
  height: 110px;
  background: #3db5e6 !important;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  border-radius: 50%;
  text-align: right;
}

.banner_area .owl-prev:hover {
  box-shadow: 0px 0px 10px #3db5e6;
}

.banner_area .owl-prev i {
  margin-right: 12px;
  color: #fff;
}

.banner_area .owl-next {
  top: calc(50% - 55px);
  right: -83px;
  position: absolute;
  width: 110px;
  height: 110px;
  background: #3db5e6 !important;
  text-align: left;
  transition: 0.3s;
  border-radius: 50%;
}

.banner_area .owl-next:hover {
  box-shadow: 0px 0px 10px #3db5e6;
}

.banner_area .owl-next i {
  margin-left: 10px;
  color: #fff;
}

.banner_area .owl-dots {
  position: absolute;
  bottom: 115px;
  left: 14.5%;
}

.banner_area .owl-dots .owl-dot span {
  width: 12px;
  height: 12px;
  background: #ebebeb;
  display: block;
  margin: 0 2.5px;
  border-radius: 50%;
}

.banner_area .owl-dots .owl-dot.active span {
  background: #3db5e6;
}

.banner_left {
  height: 77.5vh;
  display: table;
  width: 100%;
  align-items: center;
  overflow: hidden;
  position: relative;
  overflow: hidden;
  padding-right: 50px;
  padding-top: 135px;
}

.banner_left .banner_left_cell {
  display: table-cell;
  vertical-align: middle;
  text-align: left;
}

.banner_left .banner_left_cell h1 {
  font-size: 50px;
  color: #121212;
  animation-delay: 1s;
  font-weight: 700;
  line-height: 1.2;
  text-transform: capitalize;
  margin-top: 0;
  margin-bottom: 20px;
  margin-top: -35px;
}

.banner_left .banner_left_cell h1 span {
  color: #3db5e6;
  text-transform: uppercase;
  margin-bottom: 25px;
  display: block;
}

.banner_left .banner_left_cell p {
  margin-bottom: 55px;
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #424e5a;
}

.home5_banner .btn_two {
  letter-spacing: 2px;
  box-shadow: 18px 18px 38px #dedede;
}

.home5_banner .btn_two:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #3db5e6;
  background: #fff;
}

.home5_banner .btn_two.active {
  background: #3db5e6;
  color: #fff;
  margin-right: 10px;
}

.banner_right {
  z-index: 9;
  position: relative;
  overflow: hidden;
  height: 77.5vh;
  display: table;
  margin-left: -53px;
  padding-top: 85px;
}

.banner_right_cell {
  display: table-cell;
  vertical-align: middle;
  position: relative;
}

.banner_right_cell .bnr_secondary_img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin-top: 71px;
}

.megamenu-cookies.blue a {
  color: #3db5e6;
}

@media (min-width: 1200px) and (max-width: 1499px) {
  .supplement_at_a_glance.section_padding_two {
    position: relative;
    z-index: 3;
  }
  .banner_area .owl-next:hover {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .banner_area .owl-prev:hover {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}

@media only screen and (max-width: 1199px) {
  .banner_area .owl-dots {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .btn_one, .btn_two {
    width: 133px;
  }
  .sn_bnr_slide {
    padding-top: 0px;
    height: 598px;
  }
  .banner_area .owl-dots {
    display: none;
  }
  .banner_slider {
    padding: 0;
    height: 598px;
  }
  .banner_area {
    height: 598px;
  }
  .banner_right {
    position: static;
  }
  .banner_left,
  .banner_right {
    height: 598px;
  }
  .banner_left {
    padding-top: 116px;
  }
  .banner_right {
    padding-top: 90px;
  }
}

@media only screen and (max-width: 575px) {
  .banner_left .banner_left_cell p {
    font-size: 17px;
  }
  .banner_area .owl-dots {
    display: none;
  }
  .banner_area .owl-next {
    background: transparent !important;
  }
  .banner_area .owl-next:hover {
    background: transparent !important;
  }
  .banner_area .owl-next i {
    color: #3db5e6;
  }
  .banner_area .owl-prev {
    background: transparent !important;
  }
  .banner_area .owl-prev:hover {
    background: transparent !important;
  }
  .banner_area .owl-prev i {
    color: #3db5e6;
  }
  .banner_left .banner_left_cell p {
    margin-bottom: 38px;
  }
  .home5_banner .btn_two {
    height: 40px;
    line-height: 40px;
  }
  .home5_banner .slr_btn_wrapper .btn_two {
    width: 100px;
    font-size: 12px;
  }
}

/*--------------------------------------------------------------
  ##  Banner5
  --------------------------------------------------------------*/
.banner_static {
  background: #f5f5f5;
}

.banner_static_flex {
  display: flex;
  height: 100vh;
  align-items: center;
}

.banner_static_left {
  width: 45%;
  transition: all 0.5s ease;
}

.banner_static_flex {
  display: flex;
  height: 100vh;
  align-items: center;
  position: relative;
}

.banner_static_left h1 {
  font-size: 94px;
  font-weight: 700;
  color: #5830B4;
  margin: 0;
  line-height: 100px;
  margin-bottom: 38px;
  padding-top: 30px;
  position: relative;
  z-index: 1;
  margin-left: -5px !important;
}

.banner_static_left h1 span {
  color: #3ad82e;
  display: block;
}

.banner_static_wonder {
  margin-bottom: 50px;
}

.banner_static_wonder p {
  display: inline-block;
  margin-right: 38px;
  color: #222;
  font-size: 24px;
  margin-bottom: 0;
  letter-spacing: 3px;
}

.banner_static_download p {
  color: #4f4f4f;
  font-size: 14px;
  margin-bottom: 8px !important;
  letter-spacing: 0.25px !important;
}

.banner_static_download a {
  padding: 0;
  height: 77px;
  border-radius: 0;
  font-size: 24px;
  text-transform: uppercase;
  color: #D01BD0;
  font-weight: 700;
  position: relative;
  letter-spacing: 3px;
  line-height: 69px !important;
  background: #fff;
  border: 3px solid #D01BD0;
  padding: 0 20px;
}

.banner_static_download a:hover i {
  -webkit-animation-name: banner_static_download_anim;
  animation-name: banner_static_download_anim;
  -webkit-animation-duration: .5s;
  animation-duration: .5s;
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
}

.banner_static_download a i {
  color: #D01BD0;
  font-size: 30px;
  vertical-align: middle;
  padding-left: 20px;
  position: relative;
  height: 72px;
  line-height: 72px;
  margin-left: 4px;
}

.banner_static_download a i:after {
  position: absolute;
  content: "";
  left: 0;
  top: -1px;
  width: 3px;
  height: 100%;
  background: #D01BD0;
}

.banner_static_img {
  position: absolute;
  transition: all 0.8s ease;
}

.banner_static_img:first-child {
  right: 0;
  top: 126px;
  z-index: 2;
  -webkit-animation: banner_static_left 20s infinite;
  animation: banner_static_left 20s infinite;
  transition: all 0.8s ease;
}

.banner_static_img:nth-child(2) {
  right: 24%;
  top: 60px;
  z-index: 1;
  -webkit-animation: banner_static_top 15s infinite;
  animation: banner_static_top 15s infinite;
  transition: all 0.8s ease;
}

.banner_static_img:nth-child(3) {
  right: 167px;
  bottom: 57px;
  z-index: 3;
  -webkit-animation: slide-down-left 18s infinite;
  animation: slide-down-left 18s infinite;
  transition: all 0.8s ease;
}

.banner_static_img:nth-child(4) {
  right: 200px;
  top: auto;
  bottom: 24px;
  z-index: 4;
  -webkit-animation: slide-down-left 18s infinite;
  animation: slide-down-left 18s infinite;
  transition: all 0.8s ease;
}

.banner_static_right {
  width: 55%;
  height: 80%;
  position: relative;
  transition: 0.5s;
}

@-webkit-keyframes banner_static_download_anim {
  from {
    line-height: 74px;
  }
  to {
    line-height: 60px;
  }
}

@keyframes banner_static_download_anim {
  from {
    line-height: 74px;
  }
  to {
    line-height: 60px;
  }
}

@keyframes banner_static_left {
  0% {
    right: 0px;
  }
  50% {
    right: 25px;
  }
  75% {
    right: 50px;
  }
  100% {
    right: 0px;
  }
}

@keyframes banner_static_top {
  0% {
    top: 60px;
  }
  50% {
    top: 120px;
  }
  75% {
    top: 80px;
  }
  100% {
    top: 60px;
  }
}

@keyframes slide-down {
  0% {
    bottom: 150px;
  }
  50% {
    bottom: 75px;
  }
  100% {
    bottom: 150px;
  }
}

@keyframes slide-down-left {
  0% {
    right: 200px;
  }
  50% {
    right: 150px;
  }
  75% {
    right: 120px;
  }
  100% {
    right: 200px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .banner_static_left h1 {
    font-size: 58px;
    line-height: 65px;
    padding-top: 60px;
  }
  .banner_static_wonder {
    margin-bottom: 25px;
  }
  .banner_static_wonder p {
    margin-right: 25px;
    font-size: 18px;
    letter-spacing: 2px;
  }
  .banner_static_img:nth-child(4) {
    width: 40%;
  }
  .home5_recent_view_cart a i {
    margin-right: 5px;
  }
  .home5_recent_view_cart a {
    letter-spacing: normal;
  }
}

@media (min-width: 1500px) and (max-width: 1650px) {
  .banner_static_left h1 {
    font-size: 86px;
  }
}

@media (min-width: 1200px) and (max-width: 1499px) {
  .banner_static_left h1 {
    font-size: 70px;
    line-height: 66px;
    padding-top: 70px;
  }
}

@media (min-width: 992px) and (max-width: 1400px) {
  .banner_static_img:nth-child(2) {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .banner_left {
    padding-top: 133px;
  }
}

@media (max-width: 991px) {
  .banner_static_left h1 {
    font-size: 40px;
    line-height: normal;
    margin-bottom: 20px;
    margin-top: 55px;
  }
  .banner_static_flex {
    height: 600px;
  }
  .banner_static_img:nth-child(4) {
    width: 40%;
  }
  .banner_static_wonder {
    margin-bottom: 20px;
  }
  .banner_static_wonder p {
    font-size: 20px;
    letter-spacing: 2px;
  }
  .banner_static_wonder p i {
    font-size: 20px;
    vertical-align: middle;
  }
  .banner_static_download a {
    height: 60px;
    line-height: 57px !important;
    font-size: 18px;
  }
  .banner_static_download a i {
    height: 56px;
    line-height: 56px;
    vertical-align: middle;
    margin-top: -3px;
    font-size: 18px;
  }
  .banner_static_wonder {
    margin-bottom: 20px;
    display: none;
  }
  .banner_static_img:nth-child(2) {
    display: none;
  }
  .banner_static_right:hover {
    -webkit-animation: initial;
    animation: initial;
  }
}

@media (max-width: 767px) {
  .banner_static_wonder {
    display: block;
  }
  .banner_static_left {
    width: 100%;
    text-align: center;
  }
  .banner_static_left h1 {
    font-size: 60px;
  }
  .banner_static_download a i {
    font-size: 20px;
  }
  .banner_static_wonder p {
    font-size: 16px;
  }
  .banner_static_wonder p:last-child {
    margin-right: 0;
  }
}

@media (max-width: 575px) {
  .banner_left .banner_left_cell {
    text-align: center;
  }
  .owl-carousel .owl-item img {
    width: 85%;
    margin: 0 auto;
  }
}

@media (max-width: 480px) {
  .banner_static_flex {
    height: 480px;
  }
  .banner_static_wonder {
    display: none;
  }
  .banner_static_left h1 {
    font-size: 40px;
  }
}

/*--------------------------------------------------------------
  ##  Why use
  --------------------------------------------------------------*/
.layer_two {
  right: 0;
  position: absolute;
}

.layer_one {
  position: absolute;
  left: 0;
}

.why_use_area {
  position: relative;
  background: #fff;
  padding: 120px 0 120px;
  text-align: center;
  font-family: "Roboto", sans-serif;
}

.section_title_two {
  margin-bottom: 115px;
  text-align: center;
}

.section_title_two h2 {
  font-size: 36px;
  color: #3734f0;
  font-weight: 600;
  line-height: 28px;
  padding-bottom: 30px;
  margin-bottom: 0;
}

.section_title_two p {
  font-size: 20px;
  color: #4f4f4f;
  line-height: 30px;
  margin-bottom: 0;
  letter-spacing: 0.5px;
}

.section_title_two img {
  margin-bottom: 45px;
}

.why_use_slider .owl-stage-outer {
  padding: 100px 0;
}

.why_use_slider .owl-item.active.center {
  transform: scale(1.3);
  z-index: 9;
}

.why_use_slider .owl-item.active.medium {
  transform: scale(1.1);
  z-index: 7;
}

.why_use_slider .owl-item.active.small {
  transform: scale(0.9);
  z-index: 5;
}

.carousel {
  position: relative;
  margin: 0 auto;
  width: 100%;
  height: auto;
  border: none;
  overflow: visible;
}

.carousel .slides {
  margin: 0;
  padding: 0;
  border: none;
  list-style: none;
  position: relative;
  overflow: visible;
  left: 50%;
  transform: translateX(-50%);
}

.carousel .slides .slideItem {
  position: absolute;
  cursor: pointer;
  overflow: visible;
  padding: 0;
  margin: 0;
  border: none;
}

.carousel .slides .slideItem a {
  display: block;
  text-decoration: none;
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  position: relative;
  transition: 0.5s;
}

.carousel .slides .slideItem img {
  margin: 0;
  padding: 0;
  border: none;
  width: 100%;
  height: 100%;
}

.carousel_screenshot {
  display: none;
}

/*--------------------------------------------------------------
  ##  Feature benifit
  --------------------------------------------------------------*/
.feature_benifit_area {
  font-family: "Roboto", sans-serif;
  padding-bottom: 120px;
}

.feature_benifit_area p, .feature_benifit_area h1, .feature_benifit_area h2, .feature_benifit_area h3, .feature_benifit_area h4, .feature_benifit_area h5, .feature_benifit_area h6, .feature_benifit_area a {
  font-family: "Roboto", sans-serif;
}

.feature_benifit_area .card {
  border: 0;
  padding: 0 45px;
  width: 100%;
  margin-bottom: 20px;
  position: relative;
}

.feature_benifit_area .card.active {
  border: 0px;
  padding: 50px 47px;
  border: 1px solid #f4f4f4;
  box-shadow: 0px 6px 10px rgba(23, 23, 118, 0.08);
  width: 100%;
  margin-bottom: 35px;
}

.feature_benifit_area .card.active:after {
  position: absolute;
  content: "";
  right: -6px;
  top: calc(50% - 6px);
  width: 12px;
  height: 12px;
  background: #fff;
  transform: rotate(45deg);
  border-right: 1px solid #f4f4f4;
  border-top: 1px solid #f4f4f4;
}

.feature_benifit_area .card .card-header {
  padding: 0;
  margin-bottom: 0;
  background-color: transparent;
  border-bottom: 0;
  cursor: pointer;
}

.feature_benifit_area .card .card-body {
  font-size: 16px;
  color: #4f4f4f;
  font-weight: 400;
  line-height: 26px;
  padding: 0 26px;
  letter-spacing: 0.3px;
  padding-top: 12px;
}

.feature_benifit_area .accordion_btn {
  padding-left: 45px;
}

.card-header h5 {
  position: relative;
  font-size: 18px;
  color: #3734f0;
  font-weight: 500;
  padding-left: 25px;
  text-transform: capitalize;
  margin-bottom: 0px;
  transition: 0.5s;
}

.card-header h5:before {
  position: absolute;
  content: "\f46b";
  font-family: 'Font Awesome\ 5 Free';
  font-weight: 700;
  font-size: 16px;
  left: 0;
  transition: 0.5s;
}

.card-header h5.collapsed {
  color: #4f4f4f;
  margin-bottom: 0;
  transition: 0.5s;
}

.card-header h5.collapsed:before {
  content: "\f058";
  color: #cfcfcf;
  transition: 0.5s;
}

.accordion_btn a {
  font-size: 16px;
  color: #cf3815;
  text-transform: uppercase;
  letter-spacing: 1.8px;
  padding: 0;
  display: block;
  width: 100%;
}

.accordion_btn a i {
  font-size: 21px;
  vertical-align: middle;
  color: #cf3815;
  margin-left: 10px;
  margin-top: -4px;
  transition: 0.5s;
}

.accordion_btn a:hover i {
  transform: rotate(-180deg);
}

.tab-content {
  margin-left: 47px;
}

.feature_benifit_area .fade {
  opacity: 0;
  transform: scaleX(0);
  transition: 0.3s;
}

.feature_benifit_area .fade.show {
  opacity: 1;
  transform: scaleX(1);
}

.feature_benifit_area #accordion {
  margin-bottom: 27px;
}

/*--------------------------------------------------------------
  ##  Breadcrumb
  --------------------------------------------------------------*/
.breadcrumb_area {
  background: url("../../media/images/home6/breadcrumb-pattern.png") repeat 48px 20px;
  padding-top: 124px;
  height: 323px;
  display: table;
  width: 100%;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
}

.breadcrumb_area .vigo_container_two {
  display: table-cell;
  vertical-align: middle;
}

.page_header {
  text-align: center;
}

.page_header h1 {
  text-transform: uppercase;
  color: #383838;
  margin-bottom: 0;
  margin-top: 0;
  padding-bottom: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 2.9px;
  text-align: center;
  color: #121212;
  position: relative;
  display: inline-block;
}

.page_header h1:after {
  position: absolute;
  left: calc(50% - 5px);
  top: 100%;
  content: "";
  width: 10px;
  height: 2px;
  background: #45D638;
}

.breadcrumb_area_list {
  height: auto;
  background: url("../../media/images/home6/breadcrumb-pattern.png") repeat center;
  padding-top: 124px;
  display: flex;
  width: 100%;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
}

.breadcrumb_area_list .vigo_container_two {
  width: 100%;
}

.breadcrumb_area_list .page_header {
  padding: 80px 0 20px;
}

.page_header_list ul {
  padding: 0;
  margin: 0;
  padding: 10px 20px;
  background: #fff;
  display: inline-block;
}

.page_header_list li {
  color: #5830B4;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 0.25px;
  display: inline-block;
  line-height: normal;
  padding: 0px 12px;
  border-right: 1px solid #7f7f7f;
}

.page_header_list li:first-child {
  padding-left: 0;
}

.page_header_list li:last-child {
  border-right: 0px;
  padding-right: 0;
}

.page_header_list li a {
  padding: 0;
  color: #5830B4;
}

.page_header_list li a:hover {
  color: #45D638;
}

@media (min-width: 1200px) and (max-width: 1499px) {
  .breadcrumb_area_list .page_header {
    padding: 65px 0 50px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .breadcrumb_area_list .page_header {
    padding: 50px 0 50px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .breadcrumb_area_list .page_header h1 {
    font-size: 25px;
  }
  .breadcrumb_area_list .page_header {
    padding: 50px 0 57px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .breadcrumb_area_list .page_header {
    padding: 30px 0 30px;
  }
  .page_header h1 {
    font-size: 1.6rem;
  }
}

@media (max-width: 575px) {
  .breadcrumb_area_list .page_header h1 {
    font-size: 18px;
    line-height: normal;
  }
  .breadcrumb_area_list .page_header {
    padding: 50px 0 68px;
  }
}

/*--------------------------------------------------------------
  ##  Breadcrumb2
  --------------------------------------------------------------*/
.breadcrumb_area_two {
  background: #f06b00;
  background: linear-gradient(to top, #f06b00 0%, #f09e00 100%);
  background: -moz-linear-gradient(bottom, #f06b00 0%, #f09e00 100%);
  background: -webkit-linear-gradient(bottom, #f06b00 0%, #f09e00 100%);
}

.breadcrumb_area_two p, .breadcrumb_area_two h1, .breadcrumb_area_two h2, .breadcrumb_area_two h3, .breadcrumb_area_two h4, .breadcrumb_area_two h5, .breadcrumb_area_two h6, .breadcrumb_area_two a {
  font-family: "Roboto", sans-serif;
}

.breadcrumb_area_two .breadcrumb_inner {
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.breadcrumb_area_two .page_header {
  padding-top: 120px;
}

.breadcrumb_area_two .page_header h1 {
  color: #fff;
  font-size: 44px;
  letter-spacing: 5px;
}

.breadcrumb_area_two .breadcrumb_text p {
  color: #fff;
}

.breadcrumb_area_two .breadcrumb_text a {
  padding: 0;
  color: #fff;
}

/*--------------------------------------------------------------
  ##  Top feature
  --------------------------------------------------------------*/
.top_feature_area {
  background: #f99a00;
  padding: 30px 0;
}

.top_feature_border {
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 30px;
}

.single_top_feature {
  color: #fff;
  margin-top: 0px;
}

.single_top_feature span {
  font-size: 2.0rem;
  color: #fff;
}

.single_top_feature .tf_icon {
  display: block;
  text-align: left;
  height: auto;
  margin-bottom: 15px;
}

.single_top_feature .tf_content h5 {
  color: #fff;
  margin-bottom: 0;
  margin-bottom: 5px;
}

.single_top_feature .tf_content p {
  color: #fff;
  font-size: 18px;
  margin-bottom: 0;
}

.tf_image img {
  max-width: 100%;
}

/*--------------------------------------------------------------
  ##  Top feature Two
  --------------------------------------------------------------*/
.top_feature_area_two {
  padding-bottom: 116px;
  padding-top: 136px;
}

.top_feature_area_two p, .top_feature_area_two h1, .top_feature_area_two h2, .top_feature_area_two h3, .top_feature_area_two h4, .top_feature_area_two h5, .top_feature_area_two h6, .top_feature_area_two a {
  font-family: "Roboto", sans-serif;
}

.single_feature_two {
  text-align: center;
  padding: 39px 34px 20px;
  position: relative;
  border-radius: 3px;
}

.single_feature_two span {
  font-size: 40px;
  position: absolute;
  top: -25px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 70px;
  line-height: 50px;
  border: 1px solid #fff;
  border-radius: 5px;
}

.single_feature_two h5 {
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  color: #505253;
}

/*--------------------------------------------------------------
  ##  Top feature Two
  --------------------------------------------------------------*/
.top_feature_area.top_feature_three {
  background: #2e2c97;
}

.top_feature_area.top_feature_three .single_top_feature span {
  color: #fff;
}

.top_feature_area.top_feature_three .single_top_feature .tf_content h5 {
  color: #fff;
  opacity: 1;
}

.top_feature_area.top_feature_three .single_top_feature .tf_content p {
  opacity: 1;
  color: #fff;
}

.top_feature_area.top_feature_three .single_top_feature {
  margin-top: 30px;
}

/*--------------------------------------------------------------
  ##  About area
  --------------------------------------------------------------*/
.about_area {
  padding: 140px 0 140px;
  background: #fff;
}

.about_right_side {
  position: relative;
  height: 100%;
  padding-left: 15px;
}

.about_right_side .section_title_four {
  margin-bottom: 60px;
  left: -15%;
  position: relative;
  z-index: 1;
}

.about_right_side .section_title_four h2 {
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.1);
}

.about_right_side h3 {
  font-size: 24px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 2.9px;
  text-align: left;
  color: #45D638;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  margin-bottom: 30px;
}

.about_right_side p {
  font-size: 20px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #454545;
  padding-right: 0;
}

.about_right_side .about_right_img {
  position: absolute;
  bottom: -47%;
  right: 0;
  z-index: 3;
}

.about_area_bottom {
  background: #f5f5f5;
  padding: 140px 0;
  position: relative;
}

.about_area_bottom .section_title_four h2 {
  padding: 32px 28px;
}

.about_area_bottom_left {
  padding-right: 100px;
}

.about_area_bottom_left p {
  font-size: 20px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #454545;
  margin-bottom: 54px;
}

.about_area_bottom_video a {
  padding: 0;
  background: #45D638;
  width: 78px;
  height: 80px;
  text-align: center;
  border-radius: 0;
  position: relative;
}

.about_area_bottom_video a i {
  font-size: 45px;
  color: #121212;
  line-height: 80px;
}

.about_area_bottom_video a:hover h4 {
  left: 110%;
}

.about_area_bottom_video a h4 {
  position: absolute;
  left: calc(100% - 23px);
  bottom: 5px;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: 6.25;
  letter-spacing: normal;
  text-align: left;
  color: #121212;
  width: 120px;
  line-height: normal;
  margin-bottom: 0;
  font-family: "Poppins", sans-serif;
  transition: 0.5s;
}

.about_bottom_right_side {
  position: absolute;
  bottom: -140px;
  box-shadow: 22px 32px 70px rgba(0, 0, 0, 0.2);
  z-index: 1;
  margin-left: 15px;
  margin-right: 15px;
}

.about_bottom_right_side img {
  height: 503px;
  object-fit: cover;
}

.about_bottom_right_side:after {
  position: absolute;
  content: "";
  left: 75px;
  top: -107px;
  height: 412px;
  width: 25px;
  background: #45D638;
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  z-index: -1;
}

.call_to_action_green {
  padding: 150px 0 130px;
}

.about_left_side {
  position: relative;
  z-index: 1;
}

.about_left_side:after {
  position: absolute;
  content: "";
  left: 10px;
  top: -107px;
  background: #45D638;
  width: 25px;
  height: 412px;
  transform-origin: center;
  transform: rotate(45deg);
  z-index: -1;
}

@media (min-width: 1200px) and (max-width: 1499px) {
  .about_right_side {
    padding-top: 60px;
  }
  .about_area_bottom_left {
    padding-right: 50px;
  }
  .about_right_side .about_right_img {
    bottom: -62%;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .about_right_side {
    padding-top: 0;
    padding-left: 0;
  }
  .about_area_bottom_left {
    padding-right: 0;
    padding-left: 0;
  }
  .about_right_side p {
    padding-right: 0;
  }
  .about_right_side .about_right_img {
    bottom: -82%;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .about_right_side .section_title_four {
    left: 0;
  }
  .about_bottom_right_side {
    margin-left: 0;
    margin-right: 0;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .about_right_side {
    padding-top: 0;
    padding-left: 0;
  }
  .about_area_bottom_left {
    padding-right: 0;
    padding-left: 0;
  }
  .about_right_side p {
    padding-right: 0;
  }
  .about_right_side .about_right_img .section_title_four {
    top: 30px;
  }
  .about_bottom_right_side {
    bottom: 0;
  }
  .about_right_side .section_title_four {
    margin-bottom: 30px;
  }
  .about_right_side h3 {
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .about_right_side {
    padding-top: 80px;
    padding-left: 0;
  }
  .about_right_side p {
    padding-right: 3px;
  }
  .about_area_bottom_left {
    padding-right: 0;
    padding-left: 0;
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .about_right_side .about_right_img .section_title_four h2 {
    padding: 15px;
    letter-spacing: 4px;
  }
  .about_area_bottom .section_title_four h2 {
    padding: 16px 28px;
  }
  .about_bottom_right_side {
    position: static;
  }
  .about_right_side .about_right_img {
    position: static;
    padding-top: 40px;
  }
  .about_right_side .about_right_img .section_title_four {
    top: auto;
    bottom: 50px;
  }
  .about_area_bottom {
    padding: 20px 0 80px;
  }
  .about_area_bottom .section_title_four {
    bottom: -200px;
  }
  .about_area {
    padding: 80px 0 80px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .about_area {
    padding: 100px 0 80px;
  }
  .about_right_side .about_right_img {
    bottom: -76%;
  }
  .about_area_bottom_left p {
    margin-bottom: 35px;
  }
  .about_bottom_right_side img {
    height: auto;
  }
  .about_area_bottom {
    padding: 100px 0;
  }
}

@media (max-width: 575px) {
  .about_area_bottom {
    margin-bottom: 0;
  }
  .about_right_side .section_title_four {
    left: 0;
  }
  .about_bottom_right_side img {
    height: auto;
  }
}

/*--------------------------------------------------------------
  ##  Product with carousel
  --------------------------------------------------------------*/
.sn_pd_two_slide {
  display: inline-block;
  border: 1px solid #f4f4f4;
  -webkit-box-shadow: 0px 11px 21px rgba(221, 221, 221, 0.31);
  box-shadow: 0px 11px 21px rgba(221, 221, 221, 0.31);
  margin: 0;
  padding: 44px 37px;
  width: 100%;
  position: relative;
  border-radius: 3px;
}

.product_two_slider .owl-nav {
  position: absolute;
  left: calc(50% - 23px);
  top: -80px;
}

.product_two_slider .owl-nav .owl-prev,
.product_two_slider .owl-nav .owl-next {
  font-size: 15px !important;
  color: #afafaf !important;
  margin: 0 4px;
}

.product_two_slider .owl-nav .owl-prev:hover,
.product_two_slider .owl-nav .owl-next:hover {
  color: #e3e3e3 !important;
}

.product_two_slider img {
  margin-bottom: 10px;
}

.product_two_area {
  padding: 120px 0 94px;
}

.product_two_area p, .product_two_area h1, .product_two_area h2, .product_two_area h3, .product_two_area h4, .product_two_area h5, .product_two_area h6, .product_two_area a {
  font-family: "Roboto", sans-serif;
}

.product_two_area .owl-carousel .owl-stage-outer {
  margin-left: -1px;
}

.sn_pd_two_detail h4 {
  font-size: 16px;
  color: #121212;
  font-weight: 400;
  display: inline-block;
  margin-bottom: 0;
  vertical-align: middle;
  transition: 0.3s;
}

.sn_pd_two_detail a {
  padding: 0;
  color: #121212;
}

.sn_pd_two_detail a:last-child {
  float: right;
}

.sn_pd_two_detail:hover h4 {
  color: #f06b00;
}

.sn_pd_two_detail:hover a i {
  font-size: 18px;
  transition: 0.3s;
}

.sn_pd_two_offer {
  height: 86px;
  width: 86px;
  overflow: hidden;
  border-radius: 50%;
  position: absolute;
  right: 43px;
  top: 112px;
  text-align: center;
  background: rgba(247, 247, 247, 0.95);
}

.sn_pd_two_offer .sn_pd_two_offer_icon,
.sn_pd_two_offer .sn_pd_two_offer_desc {
  transform-style: preserve-3d;
  backface-visibility: hidden;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: table;
  position: absolute;
  transition: all 1.5s ease 0s;
}

.sn_pd_two_offer .sn_pd_two_offer_desc {
  transform: rotateY(0deg);
  z-index: 1;
}

.sn_pd_two_offer .sn_pd_two_offer_desc h3 {
  font-size: 24px;
  color: #121212;
  font-weight: 400;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
}

.sn_pd_two_offer .sn_pd_two_offer_desc h3 span {
  font-size: 14px;
  display: block;
}

.sn_pd_two_offer:hover .sn_pd_two_offer_desc {
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
}

.sn_pd_two_offer:hover .sn_pd_two_offer_icon {
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
}

.sn_pd_two_offer .sn_pd_two_offer_icon {
  background: rgba(247, 247, 247, 0.3);
  transform: rotateY(180deg);
  z-index: 0;
}

.sn_pd_two_offer .sn_pd_two_offer_icon i {
  font-size: 20px;
  color: #121212;
  display: table-cell;
  vertical-align: middle;
  font-weight: 700;
  cursor: pointer;
}

.quickview-wrapper.open {
  visibility: visible;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  opacity: 1;
  overflow: visible;
  z-index: 99999;
}

.quickview-wrapper {
  position: fixed;
  width: 1000px;
  height: 590px;
  border-radius: 5px;
  top: 50%;
  left: 50%;
  opacity: 0;
  -webkit-transform: translate(-50%, -60%);
  -ms-transform: translate(-50%, -60%);
  transform: translate(-50%, -60%);
  visibility: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: inline-block;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background: transparent;
  z-index: 1050;
}

.quickview-wrapper {
  visibility: hidden;
}

.quickview-wrapper .share-wrap {
  padding: 0;
}

.quickview-wrapper .ingredient_slider_btn {
  margin-bottom: 30px;
}

.quickview-wrapper .col-xl-7, .quickview-wrapper .col-xl-5 {
  padding: 0;
}

.modal {
  display: block;
  visibility: hidden;
}

.mask-overlay2 {
  content: '';
  position: fixed;
  background: rgba(0, 0, 0, 0.8);
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
}

.close-qv {
  position: absolute;
  right: 18px;
  cursor: pointer;
  z-index: 99999;
  cursor: pointer;
  right: 0;
  top: -40px;
}

.close-qv i {
  font-size: 25px;
  color: #fff;
}

.modal.quickview-wrapper.open {
  background: #fff;
}

.quickview {
  background: #fff;
}

.quickview .ingredient_slider_main {
  width: 60%;
}

.quickview .ingredient_slider_detail {
  width: 40%;
}

.quickview .ingredient_slider_flex {
  width: 1000px;
}

.quickview-wrapper .ingredient_slider_two.slick-slider {
  width: calc(100% - 118px);
  -webkit-box-shadow: none;
  box-shadow: none;
}

.quickview-wrapper .ingredient_slider_detail {
  margin: 20px;
  border: 1px solid #ddd;
  padding: 30px;
  margin-left: 0;
}

.quickview-wrapper .ingredient_slider_one .slick-prev,
.quickview-wrapper .ingredient_slider_one .slick-next {
  top: 105%;
}

.quickview-wrapper .ingredient_slider_one .slick-slide {
  border: 1px solid #f5f5f5;
  cursor: pointer;
  width: 118px !important;
}

.quickview-wrapper .ingredient_slider_two .ingredient-img {
  padding: 98px 100px 86px;
}

.quickview-wrapper .ingredient_slider_one.slick-slider {
  height: 541px;
  background: #fff;
}

.modal.quickview-wrapper::before {
  position: absolute;
  content: "";
  right: 0;
  top: 0;
  width: calc(100% - 120px);
  height: 100%;
  background: #fff;
  z-index: -1;
  box-shadow: 22px 32px 70px rgba(0, 0, 0, 0.1);
}

.quickview-wrapper .product_ratting {
  margin-bottom: 60px;
}

.quickview-wrapper .product_price {
  padding-bottom: 3rem;
  margin-bottom: 0;
}

.quickview-wrapper .ingredient_slider_detail .quantity {
  margin-bottom: 70px;
}

.quickview-wrapper .ingredient_slider_detail {
  margin: 20px;
  border: 1px solid #ddd;
  padding: 30px;
  margin-left: 0;
}

.quickview-wrapper .product_ratting a i {
  color: #D01BD0;
}

.quickview-wrapper .ingredient_slider_detail .product_price p {
  color: #fff;
}

.quickview-wrapper .ingredient_slider_detail .product_price p.in-stock {
  background: #5830B4;
}

.quickview-wrapper .ingredient_slider_detail .product_price p.out-stock {
  background: #b6b6b6;
}

.quickview-wrapper .ingredient_slider_detail .product_price .price {
  margin-top: 28px;
}

.modal.show {
  visibility: visible;
}

@media only screen and (max-width: 991px) {
  .quickview .ingredient_slider_detail,
  .quickview .ingredient_slider_main {
    width: 400px;
  }
  .quickview-wrapper .ingredient_slider_one {
    width: 110px;
  }
  .quickview .ingredient_slider_flex {
    width: 400px;
    padding-top: 30px;
  }
  .quickview-wrapper .ingredient_slider_two.slick-slider {
    width: calc(100% - 112px);
  }
  .quickview-wrapper .ingredient_slider_one .slick-slide {
    width: 110px !important;
  }
  .modal.quickview-wrapper.open {
    width: 400px;
  }
  .close-qv {
    right: 5px;
    top: 5px;
  }
  .close-qv i {
    color: #222;
  }
}

@media only screen and (max-width: 575px) {
  .quickview .ingredient_slider_detail,
  .quickview .ingredient_slider_main {
    width: 100%;
  }
  .quickview .ingredient_slider_flex {
    width: 100%;
    padding-top: 30px;
  }
}

@media (min-width: 320px) and (max-width: 479px) {
  .modal.quickview-wrapper.open {
    width: 300px;
  }
}

/*--------------------------------------------------------------
  ##  Product collection
  --------------------------------------------------------------*/
.product_all_collection.woocommerce {
  padding: 140px 0 130px;
}

.product .product_single_collection {
  padding-top: 70px;
  padding: 20px;
  padding-top: 70px;
  transition: all 0.5s linear;
}

.product .product_single_collection .woocommerce-LoopProduct-link {
  padding: 0;
  display: block;
}

.product .product_single_collection .product-thumb {
  margin-bottom: 12px;
}

.products {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.column-3 .products .product {
  width: calc(33.33% - 40px);
  margin-bottom: 60px;
  -webkit-box-shadow: -2px -1px 35px rgba(0, 0, 0, 0.06);
  box-shadow: -2px -1px 35px rgba(0, 0, 0, 0.06);
  text-align: center;
  transition: 0.5s;
}

.column-3 .products .product:hover {
  box-shadow: -2px -1px 50px rgba(0, 0, 0, 0.16);
}

.woocommerce-loop-product__title:hover span {
  background: #fff;
  -webkit-transform: translate3d(0px, 0px, -30px) rotateX(90deg);
  -moz-transform: translate3d(0px, 0px, -30px) rotateX(90deg);
  -ms-transform: translate3d(0px, 0px, -30px) rotateX(90deg);
  transform: translate3d(0px, 0px, -30px) rotateX(90deg);
}

.woocommerce-loop-product__title {
  color: #121212;
  display: block;
  text-decoration: none;
  overflow: hidden;
  vertical-align: top;
  -webkit-perspective: inherit;
  -moz-perspective: inherit;
  -ms-perspective: inherit;
  perspective: inherit;
  -webkit-perspective-origin: 50% 50%;
  -moz-perspective-origin: 50% 50%;
  -ms-perspective-origin: 50% 50%;
  perspective-origin: 50% 50%;
  font-family: "Poppins", sans-serif;
  line-height: normal;
}

.woocommerce-loop-product__title span {
  display: block;
  position: relative;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  -webkit-transform-origin: 50% 0%;
  -ms-transform-origin: 50% 0%;
  transform-origin: 50% 0%;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  padding: 40px 0 50px;
  background: #fff;
  font-size: 22px;
  font-weight: 500;
  color: #000;
  line-height: 30px;
}

.woocommerce-loop-product__title span .after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  color: #5830B4;
  background: #fff;
  -webkit-transform-origin: 50% 0%;
  -ms-transform-origin: 50% 0%;
  transform-origin: 50% 0%;
  -webkit-transform: translate3d(0px, 105%, 0px) rotateX(-90deg);
  -ms-transform: translate3d(0px, 105%, 0px) rotateX(-90deg);
  transform: translate3d(0px, 100%, 0px) rotateX(-90deg);
  font-weight: 500;
  width: 100%;
  height: 100%;
  font-size: 16px;
  padding: 40px 50px;
  letter-spacing: 1.8px;
  line-height: 30px;
  font-family: "Roboto", sans-serif;
  line-height: 26px;
}

.woocommerce-loop-product__title span .after i {
  display: block;
  font-size: 14px;
  margin-top: 10px;
  transition: 0.3s;
}

.woocommerce-loop-product__title span .after i:hover {
  color: #D01BD0;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .woocommerce-loop-product__title span .after {
    padding: 40px 0;
  }
  .column-3 .products .product {
    width: calc(33.33% - 30px);
    margin-bottom: 50px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .column-3 .products .product {
    width: calc(50% - 30px);
    margin: 0 auto 60px;
  }
  .woocommerce-loop-product__title span .after {
    padding: 40px 0;
  }
  .woocommerce .blog_list_pagination {
    padding-left: 16px;
    padding-right: 16px;
  }
  .product_all_collection.woocommerce {
    padding: 100px 0 90px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .product_all_collection.woocommerce {
    padding: 80px 0 70px;
  }
}

@media only screen and (max-width: 767px) {
  .column-3 .products .product {
    width: 100%;
    margin: 0 auto 60px;
  }
  .woocommerce-loop-product__title span .after {
    padding: 40px;
  }
}

@media only screen and (max-width: 480px) {
  .column-3 .products .product {
    width: 100%;
    margin: 0 auto 60px;
  }
  .woocommerce-loop-product__title span .after {
    font-size: 13px;
    line-height: 22px;
  }
}

/*--------------------------------------------------------------
  ##  Product all collection
  --------------------------------------------------------------*/
.product_all_collection.with-sidebar {
  padding: 65px 0 132px;
}

.woocommerce-ordering {
  margin-bottom: 30px;
}

.woocommerce-ordering .short {
  position: relative;
}

.woocommerce-ordering .short select {
  height: 46px;
  padding: 0 9px;
  font-size: 14px;
  color: #5e5e5e;
  font-weight: 300;
  -moz-appearance: none;
  border: 1px solid #ddd;
}

.woocommerce-ordering .short select:focus {
  border: 1px solid #45D638;
}

.woocommerce-ordering .short p {
  font-size: 12px;
  color: #5e5e5e;
  font-weight: 300;
  display: inline-block;
  margin-bottom: 0;
  margin-right: 20px;
}

.woocommerce-ordering .short:after {
  position: absolute;
  content: "\f0d8";
  right: 18px;
  top: 7px;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  color: #5e5e5e;
  font-size: 12px;
  pointer-events: none;
}

.woocommerce-ordering .short:before {
  position: absolute;
  content: "\f0d7";
  right: 18px;
  bottom: 7px;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  color: #5e5e5e;
  font-size: 12px;
  pointer-events: none;
}

.woocommerce .sn_related_product {
  margin-bottom: 30px;
  box-shadow: 22px 7px 41px rgba(0, 0, 0, 0.06);
  border: 1px solid #efefef;
  padding: 30px 30px 22px;
}

.woocommerce .sn_related_product .woocommerce-loop-product__title {
  font-size: 13px;
  color: #000;
  font-weight: 400;
  letter-spacing: normal;
  text-transform: capitalize;
}

.woocommerce .sn_related_product .sn_pd_img.product-thumb {
  text-align: center;
  height: 120px;
}

.woocommerce .woocommerce-LoopProduct-link {
  padding: 0;
  display: block;
}

.woocommerce .star-rating {
  margin-bottom: 5px;
}

.woocommerce .star-rating i {
  font-size: 14px;
  color: #D01BD0;
  margin-right: 3px;
}

.woocommerce .star-rating i:last-child {
  color: #b2b2b2;
}

.product_sidebar .widget {
  padding-right: 1.33rem;
  margin-bottom: 87px;
}

.product_sidebar #product-price h3 {
  margin-bottom: 11px;
}

.product_sidebar .widget.widget_search {
  position: relative;
}

.product_sidebar .widget.widget_search input {
  width: 100%;
  height: 49px;
  border: 1px solid #ddd;
  padding: 0px 15px;
  color: #4c4c4c;
  border-radius: 0;
}

.product_sidebar .widget.widget_search input:focus {
  border: 1px solid #45D638;
}

.product_sidebar .widget.widget_search button {
  position: absolute;
  content: "";
  right: 0px;
  top: 0;
  color: #222;
  font-size: 16px;
  line-height: 49px;
  cursor: pointer;
  margin-top: 0;
}

.product_sidebar .widget.widget_search button i {
  margin-top: 0;
}

.product_sidebar .widget.widget_price {
  margin-bottom: 90px;
}

.product_sidebar .widget.widget_catergories h3 {
  margin-bottom: 12px;
}

.product_sidebar .widget_title {
  font-size: 16px;
  color: #121212;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  border-bottom: 0px;
}

.product_sidebar .widget_title:after {
  display: none;
}

.product_sidebar .widget_catgories li a {
  font-size: 16px;
  color: #7b7b7b;
  padding: 0;
  display: block;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
  border-radius: 0;
  font-weight: 300;
  transition: 0.5s;
}

.product_sidebar .widget_catgories li a:hover {
  color: #45D638;
  padding-left: 8px;
  font-weight: 400;
}

.product_sidebar .widget_catgories li:first-child a {
  padding-top: 0;
}

.product_sidebar {
  padding-top: 78px;
}

.product_sidebar .widget {
  padding-right: 0;
}

.product_sidebar .whc_range_item {
  display: inline-block;
  font-size: 10px;
  color: #7b7b7b;
}

.product_sidebar .whc_range_item.item2 {
  float: right;
}

.product_sidebar .whc_range_plan {
  margin-bottom: 15px;
}

.product_sidebar .price-filter span {
  margin-bottom: 6px;
  display: inline-block;
  font-size: 14px;
  font-weight: 300;
  color: #7b7b7b;
}

.product_all_collection_flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
}

.product_all_collection_flex_item {
  width: 245px;
  height: 85vh;
  min-height: 715px;
  position: sticky;
  position: -webkit-sticky;
  top: 10%;
  transition: all 0.5s ease;
}

.product_all_collection_flex_item_big {
  width: calc(100% - 275px);
}

@media (min-width: 576px) and (max-width: 767px) {
  .product_all_collection.with-sidebar {
    padding: 80px 0 70px;
  }
}

@media only screen and (max-width: 991px) {
  .product_all_collection_flex {
    flex-wrap: wrap;
  }
  .product_all_collection_flex_item {
    width: 60%;
    margin: 0 auto;
  }
  .product_all_collection_flex_item_big {
    width: 100%;
  }
  .product_all_collection_flex_item {
    position: static;
    margin-bottom: 70px;
  }
  .text-right {
    text-align: left;
    display: inline-block;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .product_sidebar {
    padding-top: 70px;
  }
  .product_all_collection.with-sidebar {
    padding: 30px 0 90px;
  }
}

@media only screen and (max-width: 767px) {
  .product_all_collection_flex_item {
    width: 70%;
    margin: 0 auto;
  }
  .product_all_collection_flex_item_big {
    width: 100%;
  }
  .product_all_collection_flex_item {
    position: static;
    margin-bottom: 0px;
  }
  .product_sidebar {
    padding-top: 0px;
  }
}

@media only screen and (max-width: 575px) {
  .product_all_collection_flex_item {
    width: 100%;
  }
  .product_all_collection_flex_item_big {
    width: 100%;
  }
  .product_all_collection.woocommerce {
    padding: 80px 0 75px;
  }
  .product_all_collection.with-sidebar {
    padding: 80px 0 70px;
  }
}

.rangeslider--horizontal {
  height: 7px;
  width: 100%;
  border-radius: 0;
  box-shadow: none;
}

.whc_range_control {
  width: calc(100% - 60px);
  margin: 0 auto;
  position: relative;
}

.whc_range_control:after {
  position: absolute;
  content: "";
  left: -30px;
  width: calc(100% + 60px);
  top: 0;
  height: 7px;
  background: #e6e6e6;
  z-index: -1;
}

.whc_range_control:before {
  position: absolute;
  content: "";
  left: 0;
  width: 13px;
  height: 24px;
  background: #fff;
  top: -10px;
  border: 1px solid #ddd;
  z-index: 1;
}

.whc_range_control .rangeslider__handle {
  border: 1px solid #ddd;
  width: 13px;
  height: 24px;
  border-radius: 0;
  box-shadow: none;
  z-index: 9;
  background-image: none;
}

.whc_range_control .rangeslider__handle:after {
  display: none;
}

.whc_range_control .rangeslider__fill {
  background: #45D638;
  position: absolute;
  border-radius: 0;
}

/*--------------------------------------------------------------
  ##  Product all collection
  --------------------------------------------------------------*/
.privacy_area_content p {
  color: #454545;
  font-size: 20px;
  font-weight: 300;
  line-height: 30px;
  letter-spacing: 0.5px;
  margin-bottom: 29px;
}

.privacy_area_content span {
  display: block;
  margin-bottom: 30px;
  color: #121212;
  font-weight: 400;
}

.privacy_collection h3 {
  font-size: 24px;
  font-weight: 500;
  text-transform: capitalize;
  margin-bottom: 32px;
  letter-spacing: 0.7px;
}

.privacy_collection p {
  margin-bottom: 0;
  margin-bottom: 90px;
}

.type_of_data h3 {
  font-size: 24px;
  font-weight: 500;
  text-transform: capitalize;
  margin-bottom: 34px;
  letter-spacing: 0.5px;
}

.type_of_data h4 {
  font-size: 20px;
  color: #464646;
  font-weight: 400;
  text-transform: capitalize;
  border-bottom: 1px solid #464646;
  display: inline-block;
  margin-bottom: 32px;
  letter-spacing: 0.5px;
}

.personal_data ul {
  margin-bottom: 35px;
}

.personal_data ul li {
  font-size: 20px;
  font-weight: 300;
  line-height: 30px;
  position: relative;
  color: #767272;
}

.personal_data ul li:before {
  width: 5px;
  height: 5px;
  background: #767272;
  content: "";
  left: -15px;
  top: 13px;
  position: absolute;
  border-radius: 50%;
}

.example_of_cookies ul {
  margin-bottom: 20px;
}

.example_of_cookies ul li {
  font-size: 20px;
  font-weight: 300;
  line-height: 30px;
  color: #767272;
  letter-spacing: 0.5px;
}

.example_of_cookies p {
  margin-bottom: 0;
}

.example_of_cookies p span {
  color: #454545;
  font-weight: 300;
  margin-bottom: 30px;
}

.privacy_area {
  padding: 140px 0 132px;
}

.privacy_area .section_title_four {
  margin-bottom: 110px;
}

.privacy_area .section_title_four h2 {
  padding: 0;
  letter-spacing: 7.5px;
}

.usage_data p:last-child {
  margin-bottom: 35px;
}

.example_of_cookies h4 {
  letter-spacing: normal;
}

.example_of_cookies ul {
  padding-left: 20px;
  margin-bottom: 30px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .privacy_area .section_title_four {
    margin-bottom: 60px;
  }
  .privacy_area .section_title_four h2 {
    box-shadow: none;
  }
  .privacy_area {
    padding: 130px 0 132px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .privacy_area .section_title_four {
    margin-bottom: 60px;
  }
  .privacy_area .section_title_four h2 {
    box-shadow: none;
  }
  .privacy_area {
    padding: 95px 0 95px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .privacy_area .section_title_four {
    margin-bottom: 60px;
  }
  .privacy_area .section_title_four h2 {
    letter-spacing: 5px;
  }
  .privacy_area {
    padding: 75px 0 75px;
  }
  .privacy_area .section_title_four h2 {
    box-shadow: none;
  }
}

@media only screen and (max-width: 575px) {
  .privacy_area .section_title_four h2 {
    letter-spacing: 3px;
  }
  .privacy_area .section_title_four {
    margin-bottom: 30px;
  }
  .privacy_area {
    padding: 75px 0 75px;
  }
  .privacy_collection p {
    margin-bottom: 40px;
  }
  .privacy_area .section_title_four h2 {
    box-shadow: none;
  }
}

/*--------------------------------------------------------------
  ## Home Service 5
  --------------------------------------------------------------*/
.home_five_service {
  padding: 139px 0 92px;
}

.home_five_single_service {
  width: 31.9%;
  display: inline-block;
  margin-right: 30px;
  vertical-align: middle;
  background: #f5f5f5;
  padding: 20px;
  position: relative;
}

.home_five_single_service:hover:before {
  height: calc(100% + 180px);
  top: -160px;
  left: 0;
}

.home_five_single_service:last-child {
  margin-right: 0;
}

.home_five_single_service:after, .home_five_single_service:before {
  position: absolute;
  left: 0;
  top: -160px;
  width: 25px;
  height: 0;
  content: "";
  background: #fff;
  -webkit-transform-origin: top left;
  -ms-transform-origin: top left;
  transform-origin: bottom left;
  -webkit-transform: rotate(34deg);
  -ms-transform: rotate(34deg);
  transform: rotate(45deg);
}

.home_five_single_service:before {
  height: 0;
  background: #45D638;
  z-index: 2;
  transition: 0.5s ease-in-out;
  top: 15%;
  left: 50%;
}

.home_five_single_service:after {
  height: calc(100% + 180px);
}

.home_five_single_service_inner {
  border: 5px solid #fff;
  position: relative;
  padding: 18px;
  height: 220px;
  z-index: 2;
  background: #f9f9f9;
}

.home_five_single_service_img {
  position: absolute;
  bottom: 0;
  left: 0;
}

.home_five_single_service_right {
  width: 55%;
  float: right;
  padding-top: 25px;
}

.home_five_single_service_right h3 {
  font-size: 23px;
  color: #5830B4;
  letter-spacing: 2px;
  font-weight: 500;
  margin-bottom: 13px;
  text-transform: capitalize;
}

.home_five_single_service_right p {
  font-size: 14px;
  color: #454545;
  letter-spacing: 1.7px;
  line-height: normal;
}

.home_five_single_service_right a {
  padding: 0;
  border-radius: 0;
  width: 130px;
  height: 48px;
  background: #fff;
  text-align: center;
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 16px;
  color: #D01BD0;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 2px;
  line-height: 52px;
}

.home_five_single_service_right a:hover {
  color: #45D638;
}

.home_five_single_service:last-child .home_five_single_service_right {
  width: 50%;
  float: left;
  padding-left: 15px;
}

.home_five_single_service:last-child .home_five_single_service_right a {
  right: auto;
  left: 0;
}

.home_five_single_service:last-child .home_five_single_service_img {
  left: auto;
  right: -12px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .home_five_service {
    padding: 139px 0 40px;
  }
  .home5_pricing {
    padding-bottom: 90px;
  }
}

@media (max-width: 767px) {
  .home_five_single_service {
    margin-right: 0;
  }
}

@media (max-width: 575px) {
  .home_five_single_service_right h3 {
    font-size: 22px;
    letter-spacing: 2px;
  }
}

/*--------------------------------------------------------------
  ## Home Service 5
  --------------------------------------------------------------*/
.home5_recent {
  background: transparent;
  position: relative;
  overflow: hidden;
  padding-bottom: 140px;
  padding-top: 190px;
}

.home5_recent:after {
  position: absolute;
  top: 47px;
  content: "";
  left: 0;
  width: 100%;
  height: calc(100% - 251px);
  background: #f5f5f5;
  z-index: -1;
}

.home5_recent .section_title_four {
  margin-bottom: 30px;
  position: absolute;
  left: 15px;
  top: -198px;
}

.home5_recent_slide {
  position: relative;
}

.home5_recent_slide .off {
  position: absolute;
  right: 43px;
  color: #ff8b8b;
  top: 23px;
}

.home5_recent_slider:hover .owl-nav {
  opacity: 1;
}

.home5_recent_slider .owl-stage-outer {
  overflow: visible;
}

.home5_recent_slider.owl-carousel .owl-item img {
  display: inline-block;
  width: auto;
  max-width: 100%;
}

.home5_recent_slider.owl-carousel .owl-item {
  opacity: 0;
}

.home5_recent_slider.owl-carousel .owl-item.active {
  opacity: 1;
}

.home5_recent_slider .owl-nav {
  position: absolute;
  top: 127px;
  left: -40px;
  width: calc(100% + 80px);
  opacity: 0;
  transition: all 0.5s linear;
}

.home5_recent_slider .owl-nav .owl-prev, .home5_recent_slider .owl-nav .owl-next {
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  border: 2px solid #dadada !important;
  border-radius: 0;
  background: #fff !important;
  transition: all 0.3s ease;
}

.home5_recent_slider .owl-nav .owl-prev i, .home5_recent_slider .owl-nav .owl-next i {
  font-size: 30px;
  color: #dadada;
  transition: all 0.3s ease;
  line-height: 80px;
}

.home5_recent_slider .owl-nav .owl-prev:hover, .home5_recent_slider .owl-nav .owl-next:hover {
  border: 2px solid #000 !important;
}

.home5_recent_slider .owl-nav .owl-prev:hover i, .home5_recent_slider .owl-nav .owl-next:hover i {
  color: #121212;
}

.home5_recent_slider .owl-nav .owl-next {
  float: right;
}

.section_title_four {
  margin-bottom: 30px;
}

.section_title_four h2 {
  font-size: 60px;
  color: #5830B4;
  font-weight: 600;
  display: inline-block;
  background: #fff;
  padding: 31px 26px;
  margin-bottom: 0;
  line-height: 43px;
  letter-spacing: 1.5px;
}

.home5_recent_appreciate {
  margin-bottom: 8px;
}

.home5_recent_appreciate a {
  padding: 0;
  font-size: 14px;
  margin-right: 3px;
}

.home5_recent_appreciate a i {
  color: #D01BD0;
}

.home5_recent_view_cart {
  overflow: hidden;
}

.home5_recent_view_cart a {
  padding: 0;
  padding: 0;
  font-size: 12px;
  color: #7474BA;
  letter-spacing: 2px;
  vertical-align: middle;
}

.home5_recent_view_cart a:last-child {
  float: right;
  padding-right: 13px;
  margin-top: 2px;
}

.home5_recent_view_cart a:last-child i {
  font-size: 14px;
  margin-top: -4px;
}

.home5_recent_view_cart a i {
  font-size: 16px;
  margin-right: 10px;
  vertical-align: middle;
  color: #7474BA;
}

.home5_recent_view_cart a:hover {
  color: #000;
}

.home5_recent_view_cart a:hover i {
  color: #45D638;
}

.home5_recent_slide {
  background: #fff;
  padding: 37px 39px;
  box-shadow: 0px 5px 18px rgba(0, 0, 0, 0.06);
  padding-bottom: 20px;
}

.home5_recent_slide .home5_recent_thumb {
  margin-bottom: 20px;
  position: relative;
  text-align: center;
}

.home5_recent_slide .home5_recent_thumb span {
  position: absolute;
  bottom: 0;
  left: 57px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #45D638;
  line-height: 50px;
  color: #fff;
  text-align: center;
}

.home5_recent_title h3 {
  font-size: 16px;
  color: #000;
  font-weight: 500;
  padding-bottom: 22px;
  margin-bottom: 15px;
  border-bottom: 1px solid #ddd;
  text-transform: capitalize;
  letter-spacing: 0.3px;
}

.home5_recent_title h3 a {
  font-size: 16px;
  color: #000;
  font-weight: 500;
  padding: 0;
}

.home5_recent_title h3:hover a {
  color: #45D638;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .section_title_four {
    padding-top: 5px;
  }
  .section_title_four h2 {
    box-shadow: 0px -1px 5px rgba(0, 0, 0, 0.05);
  }
  .home5_recent_slide {
    padding: 37px 30px;
  }
  .home5_recent_view_cart a {
    font-size: 10px;
  }
  .home5_recent_view_cart a i {
    margin-right: 0;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .home5_recent_view_cart a {
    font-size: 10px;
  }
  .home5_recent_slider .owl-nav {
    left: -39px;
    width: calc(100% + 78px);
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .home5_recent_slider {
    width: 80% !important;
    margin: 0 auto;
  }
  .home5_recent_slider .owl-nav {
    left: -51px;
    width: calc(100% + 102px);
    opacity: 1;
  }
  .home5_recent_slide .home5_recent_thumb span {
    left: 124px;
  }
}

@media only screen and (max-width: 575px) {
  .home5_recent_slider .owl-nav {
    opacity: 1;
  }
  .home5_recent_view_cart a i {
    margin-right: 0;
  }
}

/*--------------------------------------------------------------
  ## Home Service 5
  --------------------------------------------------------------*/
.home5_feature:after {
  background: url(../../media/images/home6/feature-bg.jpg) no-repeat fixed 0 0/cover;
}

/*--------------------------------------------------------------
  ## Home Service 5
  --------------------------------------------------------------*/
.home5_offer_inner {
  margin-left: 70px;
  border: 30px solid #ebebeb;
  position: relative;
  background: #fff;
  box-shadow: 22px 32px 70px rgba(0, 0, 0, 0.16);
}

.home5_offer_left {
  position: absolute;
  bottom: -81px;
  left: -175px;
}

.home5_offer_right {
  position: absolute;
  right: 0;
  bottom: 80px;
}

.home5_offer_right span {
  width: 47px;
  height: 47px;
  background: #45D638;
  display: inline-block;
  border-radius: 50%;
  line-height: 47px;
  text-align: center;
  position: absolute;
  top: -23px;
  left: 25px;
  color: #fff;
}

.home5_offer {
  padding: 0px 0 92px;
}

.home5_offer_center {
  width: 57%;
  text-align: left;
  margin: 0 auto;
  padding-top: 100px;
  padding-bottom: 100px;
  padding-left: 151px;
}

.home5_offer_center h2 {
  font-size: 60px;
  color: #5830B4;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  margin-bottom: 5px;
  position: relative;
  z-index: 1;
}

.home5_offer_center h2:after {
  position: absolute;
  left: 19px;
  top: -77%;
  width: 16px;
  height: 176px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  content: "";
  background: #45D638;
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  z-index: -1;
}

.home5_offer_center p {
  font-size: 20px;
  color: #6c6c6c;
  font-weight: 300;
  letter-spacing: 0.25px;
  line-height: 30px;
  margin-bottom: 38px;
}

.home5_offer_detail {
  position: relative;
  padding: 70px 0;
}

.home5_offer_detail .home5_offer_detail_bg {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
}

.home5_offer_detail .home5_offer_detail_bg img {
  height: 100%;
}

.home5_offer_social {
  position: absolute;
  right: 0;
  top: 0;
  padding-top: 22px;
  padding-right: 35px;
}

.home5_offer_social span {
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #989797;
  margin-right: 8px;
}

.home5_offer_social a {
  padding: 0px 5px;
  font-size: 13px;
  color: #989797;
}

.home5_offer_social a:hover {
  color: #45D638;
}

.home5_offer_social a:last-child {
  padding-right: 0;
}

@media (min-width: 1200px) and (max-width: 1499px) {
  .home5_offer_detail {
    padding: 57px 0;
  }
  .home5_offer {
    padding: 0px 0 93px;
  }
  .home5_offer_center {
    width: 57%;
    margin: 0 0 0 auto;
    padding-left: 0;
    padding-right: 50px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .home5_offer_left {
    left: -154px;
  }
  .home5_offer_center {
    width: 52%;
    padding-right: 45px;
    padding-left: 0;
    margin: 0 0 0 auto;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .home5_offer_center {
    padding-top: 56px;
  }
}

@media (max-width: 991px) {
  .home5_offer_social span {
    color: #222;
  }
  .home5_offer_social a {
    color: #222;
  }
  .home5_offer_right {
    display: none;
  }
  .home5_offer_detail .home5_offer_detail_bg img {
    height: 100%;
  }
}

@media (max-width: 767px) {
  .home5_offer_center {
    padding-bottom: 40px;
  }
}

@media (max-width: 575px) {
  .home5_offer_social {
    padding-top: 0;
    padding-right: 0;
  }
  .home5_offer_detail {
    padding: 40px 0 75px;
  }
  .home5_offer_center p {
    font-size: 17px;
    line-height: 28px;
  }
}

@media (max-width: 1500px) {
  .home5_offer_right {
    display: none;
  }
}

/*--------------------------------------------------------------
  ## Home Service 5
  --------------------------------------------------------------*/
.video_three_area {
  background: #f06b00;
  background: -moz-linear-gradient(right, #f06b00 0%, #f09e00 100%);
  background: -webkit-linear-gradient(right, #f06b00 0%, #f09e00 100%);
  background: linear-gradient(to left, #f06b00 0%, #f09e00 100%);
  font-family: "Roboto", sans-serif;
}

.video_three_area .section_title_two {
  margin-bottom: 85px;
}

.video_three_area .section_title_two h2 {
  color: #fff;
}

.video_three_area .section_title_two p {
  color: #fff;
}

.video_three_area_bg {
  padding: 100px 0;
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
}

.video_three_area_bg .video-btn.pop-up {
  padding: 0;
}

.vido_three_inner {
  position: relative;
  display: block;
  width: 220px;
  height: 112px;
  background: url(../../media/images/banner-two/video-three-ppup.png) no-repeat scroll 0 0/cover;
  border-radius: 6px;
  background-color: #cd4526;
  background-blend-mode: multiply;
  margin-bottom: 10px;
}

.vido_three_inner .video_three_play {
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%);
  -ms-transform: translate(-50%);
  transform: translate(-50%);
  top: 11px;
}

.video-btn.pop-up p {
  font-size: 16px;
  font-weight: 500;
  color: #121212;
  opacity: 0.7;
  border-bottom: 1px solid rgba(0, 0, 0, 0.7);
  display: inline-block;
  margin-bottom: 0;
  opacity: 0.7;
}

@supports (-ms-ime-align: auto) {
  .vido_three_inner {
    background: url(../../media/images/banner-two/video-three-ppup-edge.png) no-repeat scroll 0 0/cover;
    position: relative;
    border-radius: 6px;
    -webkit-border-radius: 6px;
  }
  .vido_three_inner:after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    width: 100%;
    height: 100%;
    background: rgba(117, 18, 7, 0.8);
  }
}

/*--------------------------------------------------------------
  ## Home Service 5
  --------------------------------------------------------------*/
.video_three_area.video5 {
  background: #272727;
  position: relative;
}

.video_three_area.video5 .vigo_container_two {
  position: inherit;
}

.video_three_area.video5 .video_bg_left {
  position: absolute;
  left: 0;
  bottom: -104px;
}

.video_three_area.video5 .video_bg_right {
  position: absolute;
  right: 0;
  top: -104px;
}

.video_three_area.video5 .video_three_area_bg {
  padding: 107px 0 119px;
}

.video_three_area.video5 .section_title_two {
  margin-bottom: 0;
  padding: 0 50px;
}

.video_three_area.video5 .video_three_content {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  height: 158px;
  border: 23px solid #fff;
  bottom: -78px;
  z-index: 9;
}

.video_three_area.video5 .vido_three_inner {
  margin-bottom: 0;
  border-radius: 0px;
  background: #3db5e6;
  background: -o-linear-gradient(bottom, #3db5e6 0%, #0086c3 100%);
  background: -webkit-gradient(linear, right top, left top, from(#3db5e6), to(#0086c3));
  background: linear-gradient(to top, #3db5e6 0%, #0086c3 100%);
  width: 200px;
  transition: 0.5s;
}

.video_three_area.video5 .vido_three_inner:hover {
  background: -o-linear-gradient(top, #3db5e6 0%, #0086c3 100%);
  background: -webkit-gradient(linear, right bottom, left top, from(#3db5e6), to(#0086c3));
  background: linear-gradient(to bottom, #3db5e6 0%, #0086c3 100%);
}

.video_three_area.video5 .vido_three_inner:hover .video_three_play i {
  -webkit-box-shadow: 0px 5px 10px rgba(255, 255, 255, 0.3);
  box-shadow: 0px 5px 10px rgba(255, 255, 255, 0.3);
}

.video_three_area.video5 .vido_three_inner .video_three_play {
  top: 50%;
  transform: translate(-50%, -50%);
}

.video_three_area.video5 .vido_three_inner .video_three_play i {
  font-size: 45px;
  color: #fff;
  transition: 0.3s;
  border-radius: 50%;
}

@media only screen and (max-width: 575px) {
  .video_three_area.video5 .section_title_two {
    padding: 0 0px;
  }
  .video_three_area.video5 .vido_three_inner {
    width: 150px;
  }
  .video_three_area.video5 .video_three_content {
    border: 13px solid #fff;
    height: 138px;
  }
}

/*--------------------------------------------------------------
  ## Home Service 5
  --------------------------------------------------------------*/
.home5_video_total {
  background: #3ad82e url(../../media/images/home6/video5-bg.png) no-repeat scroll right top/contain;
  padding: 68px 80px 68px;
  position: relative;
}

.home5_video_total .section_title_four {
  position: absolute;
  top: 68px;
  margin-bottom: 0;
  margin-left: -16%;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
}

.home5_video_total .section_title_four h2 {
  margin-top: 0;
}

.home5_video_left {
  width: calc(100% - 720px);
  display: inline-block;
  vertical-align: middle;
  padding-right: 123px;
  padding-top: 117px;
}

.home5_video_left p {
  font-size: 20px;
  color: #121212;
  letter-spacing: .25px;
  font-weight: 400;
  margin-bottom: 45px;
  line-height: 30px;
  opacity: 0.7;
}

.home5_video_right {
  display: inline-block;
  position: relative;
  width: 715px;
  cursor: pointer;
  vertical-align: middle;
  z-index: 1;
}

.home5_video_right i {
  position: absolute;
  bottom: 52px;
  width: 78px;
  height: 78px;
  background: #D01BD0;
  text-align: center;
  line-height: 78px;
  right: -25px;
  color: #ffffff;
  font-size: 45px;
  transition: 0.3s;
}

.home5_video_right i:hover {
  color: #45D638;
  background: #fff;
}

.home5_video_right i.pause {
  display: none;
}

.home5_video_right:after {
  position: absolute;
  content: "";
  left: 60%;
  top: -112px;
  width: 25px;
  height: calc(100% + 230px);
  content: "";
  background: #fff;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  z-index: -1;
}

.youtube-wrapper .youtube-poster {
  height: 402px;
  background-position: center;
  background-size: cover;
  border: 22px solid #5830B4;
  box-shadow: 22px 32px 70px rgba(0, 0, 0, 0.35);
}

.youtube-wrapper iframe {
  height: calc(100% - 44px);
  width: calc(100% - 44px);
  position: absolute;
  top: 22px;
  left: 22px;
  border: none;
  visibility: hidden;
}

.youtube-wrapper.reveal iframe {
  visibility: visible;
}

.home5_video_social a {
  padding: 0;
  font-size: 14px;
  color: #5830B4;
  padding: 0;
  height: 30px;
  width: 30px;
  text-align: center;
  border: 1px solid transparent;
  border-radius: 0;
}

.home5_video_social a:hover {
  border: 1px solid #2ead25;
}

.home5_video_social span {
  font-size: 10px;
  color: #5830B4;
  letter-spacing: 1.3px;
}

.home5_video {
  padding-bottom: 140px;
}

.youtube-wrapper.home5_video_right.reveal i.play {
  display: none;
}

.youtube-wrapper.home5_video_right.reveal i.pause {
  display: block;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .home5_video_total .section_title_four {
    margin-left: -35px;
  }
}

@media (min-width: 768px) and (max-width: 1499px) {
  .home5_video_total {
    padding: 68px 35px 72px;
  }
  .home5_video_left {
    padding-top: 90px;
  }
  .home5_video_left p {
    margin-bottom: 30px;
  }
}

@media (max-width: 575px) {
  .home5_video_total .section_title_four {
    margin-left: -20px;
  }
  .home5_video_left p {
    font-size: 18px;
  }
  .home5_video_right i {
    right: 0;
  }
  .home5_video_total .section_title_four {
    top: 70px;
  }
}

/*--------------------------------------------------------------
  ## Home Service 5
  --------------------------------------------------------------*/
.home5_pricing {
  padding-bottom: 140px;
}

.home5_pricing_single {
  background: #f5f5f5;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 83px 0 72px;
  padding-left: 89px;
  padding-right: 0;
  position: relative;
}

.home5_pricing_single i {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 68px;
  height: 68px;
  text-align: center;
  line-height: 66px;
  border-top: 3px solid #fff;
  border-left: 3px solid #fff;
  color: #D01BD0;
  font-size: 28px;
  transition: 0.5s;
}

.home5_pricing_single h3 {
  font-size: 24px;
  font-weight: 600;
  color: #5830B4;
  letter-spacing: 2.5px;
  margin-bottom: 0;
  position: relative;
  z-index: 1;
  transition: 0.5s;
  margin-bottom: 5px;
}

.home5_pricing_single h3:after {
  position: absolute;
  left: 7px;
  top: -19px;
  width: 10px;
  height: 67px;
  background: #fff;
  content: "";
  transform: rotate(45deg);
  z-index: -2;
}

.home5_pricing_single span {
  font-size: 14px;
  color: #7474BA;
  font-weight: 500;
  letter-spacing: 1.8px;
}

.home5_pricing_single:hover h3 {
  color: #121212;
}

.home5_pricing_single:hover i {
  color: #45D638;
}

@media (max-width: 575px) {
  .home5_pricing_single span {
    font-size: 14px;
  }
}

/*--------------------------------------------------------------
  ## Features
  --------------------------------------------------------------*/
.features_area_home6_all {
  display: flex;
  flex-wrap: wrap;
}

.features_area_home6.section_padding {
  padding-bottom: 0;
}

.single_feature_home6 {
  width: calc(50% - 20px);
  background: #fff;
  border: 1px solid #efefef;
  -webkit-box-shadow: 22px 32px 70px rgba(0, 0, 0, 0.15);
  box-shadow: 22px 32px 70px rgba(0, 0, 0, 0.15);
  margin-bottom: 50px;
}

.single_feature_home6:nth-child(odd) {
  z-index: 9;
}

.single_feature_home6:nth-child(odd) .after {
  position: absolute;
  left: 30%;
  top: -10%;
  height: 100%;
  width: 100%;
}

.single_feature_home6:nth-child(odd) .before {
  position: absolute;
  left: 40%;
  bottom: -27%;
  height: 100%;
  width: 100%;
}

.single_feature_home6:first-child .after {
  top: -43%;
  left: 28%;
}

.single_feature_home6 .single_feature_home6_bottom_icon {
  position: absolute;
  right: -35px;
  bottom: -58px;
}

.single_feature_home6 .single_feature_home6_bottom_icon i {
  font-size: 210px;
  opacity: 0.1;
  color: #a5a5a5;
}

.grid-item.single_feature_home6:nth-child(2) {
  margin-top: 449px;
}

.grid-item.single_feature_home6:nth-child(2n) {
  margin-left: 20px;
  z-index: 99;
}

.grid-item.single_feature_home6:nth-child(2n-1) {
  margin-right: 20px;
}

.grid.features_area_home6_all {
  width: calc(100% - 100px);
  margin-left: 50px;
  margin-top: -80px;
}

.single_feature_home6_content {
  position: relative;
  padding: 134px 90px 110px 108px;
  overflow: hidden;
  background: #fff;
  z-index: 999;
}

.single_feature_home6_content:hover .single_feature_home6_icon i {
  color: #ebebeb;
}

.single_feature_home6_content:hover .single_feature_home6_desc h3:before {
  background: #45D638;
}

.single_feature_home6_content .single_feature_home6_icon {
  position: absolute;
  left: 81px;
  top: 116px;
}

.single_feature_home6_content .single_feature_home6_icon i {
  color: #45D638;
  font-size: 26px;
  transition: 0.5s;
}

.single_feature_home6_desc {
  padding: 20px;
}

.single_feature_home6_desc h3 {
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 2.9px;
  text-align: left;
  color: #121212;
  font-family: "Poppins", sans-serif;
  text-transform: capitalize;
  margin-bottom: 0;
  padding-bottom: 25px;
  margin-bottom: 25px;
  position: relative;
}

.single_feature_home6_desc h3:before {
  position: absolute;
  left: 5px;
  top: -50%;
  width: 15px;
  height: 146px;
  content: "";
  transform: rotate(45deg);
  transform-origin: center;
  background: #ebebeb;
  z-index: -1;
  transition: 0.5s;
}

.single_feature_home6_desc h3:after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  width: 10px;
  height: 2px;
  background: #45D638;
}

.single_feature_home6_desc p {
  font-size: 20px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.8;
  letter-spacing: normal;
  text-align: left;
  color: #454545;
}

.features_area_home6 {
  padding: 80px 0 0px;
  margin-bottom: -40px;
}

.features_area_home6 .section_title_four {
  margin-bottom: 49px;
}

.features_area_home6 .section_title_four h2 {
  font-family: Poppins;
  font-size: 60px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: center;
}

@media (min-width: 1200px) and (max-width: 1499px) {
  .single_feature_home6:last-child .before {
    display: none;
  }
  .single_feature_home6_content {
    padding: 75px;
  }
  .single_feature_home6_content .single_feature_home6_icon {
    left: 60px;
    top: 70px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .single_feature_home6_content {
    padding: 40px 30px 40px 40px;
  }
  .single_feature_home6_content .single_feature_home6_icon {
    left: 30px;
    top: 30px;
  }
  .grid-item.single_feature_home6:nth-child(2) {
    margin-top: 300px;
  }
  .single_feature_home6:last-child .before {
    display: none;
  }
  .features_area_home6 .section_title_four h2 {
    box-shadow: none;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .grid.features_area_home6_all {
    width: 100%;
    margin-left: 0;
  }
  .single_feature_home6_content {
    padding: 68px 15px 60px 15px;
  }
  .single_feature_home6_content .single_feature_home6_icon {
    left: 15px;
    top: 50px;
  }
  .grid-item.single_feature_home6:nth-child(2) {
    margin-top: 300px;
  }
  .single_feature_home6:nth-child(2n+1) .after {
    left: 36%;
    top: 0;
  }
  .single_feature_home6:first-child .after {
    top: -15%;
    left: 0;
  }
  .single_feature_home6:last-child .before {
    display: none;
  }
  .features_area_home6 .section_title_four h2 {
    box-shadow: none !important;
  }
  .features_area_home6 {
    padding: 60px 0 0px;
    margin-bottom: -80px;
  }
}

@media only screen and (max-width: 767px) {
  .features_area_home6 .section_title_four h2 {
    line-height: normal;
    padding: 0;
    font-size: 24px;
    box-shadow: none;
  }
  .features_area_home6 {
    padding: 70px 0 0px;
    margin-bottom: -50px;
  }
  .single_feature_home6_content {
    padding: 30px 20px;
  }
  .single_feature_home6_desc p {
    font-size: 19px;
  }
  .single_feature_home6 {
    width: 100%;
  }
  .features_area_home6.section_padding {
    padding-bottom: 0;
  }
  .grid.features_area_home6_all {
    width: 100%;
    margin-left: 0;
    margin-top: 0px;
  }
  .grid-item.single_feature_home6:nth-child(2n) {
    margin-left: 0;
  }
  .single_feature_home6_desc {
    padding: 0;
  }
  .single_feature_home6 .after,
  .single_feature_home6 .before {
    display: none;
  }
  .grid-item.single_feature_home6 {
    opacity: 1;
  }
  .grid-item.single_feature_home6:nth-child(2) {
    margin-top: 0;
  }
  .single_feature_home6_content .single_feature_home6_icon {
    position: static;
    margin-bottom: 10px;
  }
  .single_feature_home6_desc h3 {
    font-size: 20px;
    letter-spacing: normal;
  }
  .single_feature_home6_desc h3:before {
    display: none;
  }
}

/*--------------------------------------------------------------
  ## Features
  --------------------------------------------------------------*/
.home5-most-sold {
  background-position: center;
  background-attachment: fixed;
  padding: 140px 0 110px;
}

.home5-most-sold .section_title_four {
  position: absolute;
  left: 0;
  top: -140px;
  margin-bottom: 0;
}

.home5-most-sold .section_title_four h2 {
  margin-top: -51px;
}

.home5-most-sold .sn_related_product {
  background: #fff;
  border: none;
  margin-bottom: 30px;
  padding: 29px;
  padding-bottom: 21px;
}

.home5-most-sold .sn_related_product:hover {
  border: none;
}

.home5-most-sold .sn_related_product .sn_pd_img {
  text-align: center;
}

.home5-most-sold .sn_related_product .sn_pd_rating a i {
  color: #D01BD0;
}

.home5-most-sold .sn_related_product .sn_pd_rating a:last-child i {
  color: #b2b2b2;
}

@media (max-width: 575px) {
  .home5-most-sold .sn_related_product {
    padding-right: 29px;
  }
}

/*--------------------------------------------------------------
  ##  Product Detail
  --------------------------------------------------------------*/
.product_detail_area.section_padding {
  padding: 7rem 0 13rem;
}

.product_detail_cont {
  position: relative;
}

.product_detail_slider {
  width: calc(50% + 117px);
  background: #f99a00;
  text-align: center;
  -webkit-box-shadow: 1px 0px 9px rgba(72, 72, 72, 0.2);
  -moz-box-shadow: 1px 0px 9px rgba(72, 72, 72, 0.2);
  box-shadow: 1px 0px 9px rgba(72, 72, 72, 0.2);
  border: 5px solid #fff;
  padding: 4.45rem 0 2.23rem;
}

.product_detail_slider .owl-nav {
  position: absolute;
  right: 20px;
  top: 40px;
}

.product_detail_slider .owl-nav button {
  display: inline-block;
  position: relative;
  border-right: 1px solid #f2cb47 !important;
  border-radius: 0;
}

.product_detail_slider .owl-nav button:last-child {
  border-right: none !important;
}

.product_detail_slider .owl-nav button span {
  font-size: 30px;
  color: #eff8ff;
  padding: 0px 30px;
}

.sn_pd_slide {
  display: inline-block;
}

.product_detail_desc {
  position: absolute;
  right: 0;
  top: 5.55rem;
  width: 50%;
  z-index: 1;
  background: #f8f8f8;
  -webkit-box-shadow: 1px 0px 9px rgba(72, 72, 72, 0.2);
  -moz-box-shadow: 1px 0px 9px rgba(72, 72, 72, 0.2);
  box-shadow: 1px 0px 9px rgba(72, 72, 72, 0.2);
  border: 5px solid #fff;
  padding: 4.45rem 3.35rem;
  height: 100%;
}

.product_detail_desc h4 span {
  color: #f99a00;
}

.product_detail_desc .btn_wrapper {
  margin-top: 0;
}

.product_ratting a {
  color: #f8f8f8;
  padding: 0;
}

.product_ratting a i {
  font-size: 14px;
}

.product_ratting span {
  color: #6a6868;
  font-size: 0.73rem;
  font-weight: 500;
}

.quantity {
  display: inline-block;
  margin-right: 1.12rem;
  vertical-align: middle;
}

.quantity .looking:hover {
  background: #f99a00;
  color: #ffffff;
}

.quantity input, .quantity .looking {
  width: 2.39rem;
  display: inline-block;
  text-align: center;
  height: 2.39rem;
  line-height: 2.39rem;
  border: 1px solid #ddd;
  vertical-align: middle;
  cursor: pointer;
  padding: 0;
  font-size: 1rem;
  border-radius: 0;
}

.quantity .looking {
  margin-left: -1px;
}

.quantity input {
  margin-right: -4px;
}

.quantity span i {
  font-size: 0.8rem;
}

.product_detail_desc .btn_wrapper {
  margin-top: 0;
  vertical-align: middle;
  display: inline-block;
  height: 50px;
}

.product_price {
  padding-bottom: 2rem;
  border-bottom: 7px solid #f99a00;
  margin-bottom: 1.67rem;
}

.product_price b {
  font-weight: 600;
  color: #3c3c3c;
  text-transform: uppercase;
  vertical-align: middle;
}

.product_price span {
  font-size: 26px;
  color: #ff8b8b;
  font-weight: 700;
  vertical-align: middle;
}

.woocommerce-product-details__short-description p {
  margin-bottom: 3rem;
}

.product-cart {
  padding-bottom: 3rem;
  border-bottom: 1px solid #ddd;
  margin-bottom: 0;
}

.share-wrap {
  padding: 3rem 0 0;
  overflow: hidden;
}

.share-wrap span {
  display: inline-block;
}

.share-wrap .product-share-link {
  margin-bottom: 0;
  display: inline-block;
  float: right;
  padding-right: 0;
}

.share-wrap .product-share-link a {
  padding: 0;
  color: #646464;
  font-size: 18px;
  margin-left: 17px;
}

.share-wrap .product-share-link a:hover {
  color: #f99a00;
}

/*--------------------------------------------------------------
  ##  Faq
  --------------------------------------------------------------*/
.faq_area {
  background: #f8f8f8;
}

#faq {
  padding-bottom: 6rem;
}

.faq_right_bg {
  text-align: right;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.question {
  border: 1px solid #ebebeb;
  background: transparent;
}

.question span {
  display: block;
  font-size: 1rem;
  color: #f99a00;
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;
  position: relative;
  line-height: 0.89;
  padding: 1.6rem 1.67rem;
}

.question span::after {
  position: absolute;
  content: "\f068";
  font-family: 'Font Awesome 5 Free';
  right: 20px;
  top: 50%;
  font-size: 0.72rem;
  transform: translateY(-50%);
}

.question span.collapsed {
  color: #666;
}

.question span.collapsed.collapsed::after {
  content: "\f067";
}

.ans_body {
  padding: 23px 55px;
  border: 1px solid #ebebeb;
  background: #fff;
  font-size: 0.89rem;
  color: #737373;
}

.ask_ans,
.ans_body {
  margin-top: -1px;
}

/*--------------------------------------------------------------
  ##  Faq
  --------------------------------------------------------------*/
.faq_custom_single_post_more_text {
  max-height: 0;
  overflow: hidden;
  -moz-transition: all ease 0.5s;
  -o-transition: all ease 0.5s;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.show-more .faq_custom_single_post_more_text {
  max-height: 1000px;
}

.faq_custom_area {
  padding: 140px 0 33px;
}

.faq_custom_area .section_title_four {
  margin-bottom: 100px;
}

.faq_custom_area .section_title_four h2 {
  padding: 0;
  letter-spacing: 7.5px;
}

.faq_custom_single_post_link {
  display: block;
  text-align: center;
  border: 1px solid #ccc;
  padding: 3px;
  background-color: #ddd;
  color: #121212;
  position: absolute;
  top: 87px;
  height: calc(100% - 80px);
  width: 1px;
  border: none;
  background: transparent;
  border-radius: 0;
  cursor: pointer;
  transition: all ease 0.5s;
}

.faq_custom_single_post_link:after {
  position: absolute;
  content: "";
  left: 6px;
  top: 0;
  width: 1px;
  height: calc(100% + 15px);
  background: #e1e1e1;
  z-index: -1;
  -webkit-transition: all ease 0.5s;
  -o-transition: all ease 0.5s;
  transition: all ease 0.5s;
  padding: 0;
}

.faq_custom_single_post_link:hover {
  background-color: transparent;
}

.faq_custom_single_post_link:hover:before {
  color: #45D638;
}

.faq_custom_single_post_link:before {
  content: "\f055";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  position: absolute;
  bottom: -25px;
  font-size: 14px;
  left: -1px;
  border-radius: 50%;
  z-index: 9;
  color: #121212;
  width: 15px;
  height: 15px;
  line-height: 15px;
  transition: 0.3s;
  transition: all ease 0.5s;
}

.show-more .faq_custom_single_post_link:before {
  content: "\f056";
  color: #45D638;
}

.faq_custom_single_post_link.no-more:before {
  background: #121212;
  content: "";
  width: 15px;
  height: 15px;
}

.faq_custom_single_post:nth-child(2n) .faq_custom_single_post_img {
  padding-right: 0;
  padding-left: 154px;
}

.faq_custom_single_post:nth-child(2n) .faq_custom_single_post_container {
  padding-left: 11px;
}

.faq_custom_single_post {
  position: relative;
  margin-bottom: 124px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
}

.faq_custom_single_post:nth-child(even) {
  flex-direction: row-reverse;
}

.faq_custom_single_post:nth-child(even) .faq_custom_single_post_img img {
  transform: rotate(180deg);
}

.faq_custom_single_post:last-child {
  margin-bottom: 0;
}

.faq_custom_single_post_content {
  width: 60%;
}

.faq_custom_single_post_img {
  padding-right: 154px;
}

.faq_custom_single_post_img img {
  padding-right: 19px;
  padding-top: 8px;
}

.faq_custom_single_post_container h4 {
  font-size: 24px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 31px;
  letter-spacing: 0.6px;
  text-align: left;
  color: #121212;
  font-family: "Poppins", sans-serif;
  text-transform: inherit;
  transition: 0.5s;
  margin-bottom: 43px;
}

.faq_custom_single_post_container.show-more h4 {
  color: #D01BD0;
}

.faq_custom_single_post_container p {
  font-size: 20px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: 0.5px;
  text-align: left;
  color: #454545;
  padding-left: 30px;
}

.faq_custom_single_post_inner {
  position: relative;
}

@media (min-width: 1200px) and (max-width: 1499px) {
  .faq_custom_single_post_img {
    padding-right: 50px;
  }
  .faq_custom_single_post:nth-child(2n) .faq_custom_single_post_img {
    padding-left: 50px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .faq_custom_single_post:nth-child(2n) .faq_custom_single_post_img {
    padding-left: 0;
  }
  .faq_custom_single_post_img {
    padding-right: 0;
  }
  .faq_custom_area .section_title_four h2 {
    box-shadow: none;
  }
  .faq_custom_single_post_container h4 {
    font-size: 22px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .faq_custom_single_post:nth-child(2n) .faq_custom_single_post_img {
    padding-left: 0;
  }
  .faq_custom_single_post_img {
    padding-right: 0;
  }
  .faq_custom_single_post_link {
    top: 140px;
    height: calc(100% - 140px);
  }
  .faq_custom_area .section_title_four h2 {
    box-shadow: none;
  }
  .faq_custom_area {
    padding: 90px 0 26px;
  }
  .faq_custom_area .section_title_four {
    margin-bottom: 65px;
  }
}

@media only screen and (max-width: 767px) {
  .faq_custom_single_post {
    flex-wrap: wrap;
  }
  .faq_custom_area .section_title_four h2 {
    box-shadow: none;
  }
  .faq_custom_single_post_content {
    width: 100%;
  }
  .faq_custom_single_post_container h4 {
    font-size: 20px;
  }
  .faq_custom_single_post_img {
    display: none;
  }
  .faq_custom_single_post_link {
    top: 80px;
    height: calc(100% - 80px);
  }
  .faq_custom_area {
    padding: 90px 0 33px;
  }
  .faq_custom_area .section_title_four h2 {
    font-size: 30px;
  }
  .faq_custom_area .section_title_four {
    margin-bottom: 40px;
  }
  .faq_custom_area {
    padding: 75px 0 25px;
  }
  .faq_custom_single_post:nth-child(2n) {
    margin-left: -10px;
  }
  .faq_custom_single_post {
    margin-bottom: 80px;
  }
}

@media only screen and (max-width: 575px) {
  .faq_custom_single_post_link {
    top: 127px;
    height: calc(100% - 127px);
  }
  .faq_custom_area .section_title_four h2 {
    letter-spacing: normal;
  }
  .faq_custom_area .section_title_four {
    margin-bottom: 60px;
  }
}

@media (min-width: 1200px) and (max-width: 1499px) {
  .faq_custom_single_post_content {
    width: 68%;
  }
}

/*--------------------------------------------------------------
  ##  Blog
  --------------------------------------------------------------*/
.blog_single_list_content {
  width: calc(100% - 55.66%);
  margin-left: -62px;
  background: #fff;
  z-index: 9;
  position: relative;
  padding: 36px 40px;
  line-height: 30px;
  font-weight: 600;
  color: #121212;
  padding-right: 0;
  padding-bottom: 0;
  height: 175px;
}

.blog_single_list_content:before {
  position: absolute;
  content: "";
  left: 0;
  top: -5px;
  height: 7px;
  width: 62px;
  background: #45D638;
  transition: 0.5s;
}

.blog_single_list_content h3 {
  margin-bottom: 18px;
}

.blog_single_list_content h3 a {
  font-size: 20px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: 0.5px;
  text-align: left;
  color: #121212;
  font-family: "Poppins", sans-serif;
  padding: 0;
  transition: 0.3s;
  text-transform: capitalize;
}

.blog_single_list_content .blog_meta a {
  padding: 0;
}

.blog_single_list_content .blog_meta a span {
  text-transform: capitalize;
  display: block;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.57;
  letter-spacing: 1.2px;
  text-align: left;
  color: #5830B4;
}

.blog_single_list_img {
  position: relative;
  width: 55.66%;
}

.blog_single_list_img .post-thumbnail {
  transition: 0.5s;
  position: relative;
}

.blog_single_list_img .post-thumbnail img {
  opacity: 1;
  transition: 0.5s;
}

.blog_single_list_img .post-thumbnail:before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(58, 216, 46, 0.1);
  opacity: 0;
  transition: 0.5s;
}

.blog_single_list {
  display: flex;
  align-items: center;
  padding: 60px 0;
  border-bottom: 1px solid #ddd;
}

.blog_single_list:first-child {
  padding-top: 0;
}

.blog_single_list:hover .post-thumbnail:before {
  opacity: 1;
}

.blog_single_list:hover .blog_single_list_btn {
  left: 0;
}

.blog_single_list:hover .blog_single_list_btn span {
  opacity: 1;
}

.blog_single_list:hover .blog_single_list_content:before {
  transform: rotate(-45deg);
  transform-origin: top;
  z-index: -1;
  left: 10px;
  top: 50px;
}

.blog_single_list_btn {
  height: 49px;
  line-height: 48px;
  padding: 0 15px;
  position: absolute;
  bottom: 0;
  background: #fff;
  border-radius: 0;
  left: -41px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

.blog_single_list_btn:hover span {
  margin-right: 10px;
}

.blog_single_list_btn span {
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.14;
  letter-spacing: 1.7px;
  text-align: center;
  color: #D01BD0;
  text-transform: uppercase;
  transition: 0.5s;
  opacity: 0;
}

.blog_single_list_btn i {
  font-size: 16px;
  color: #D01BD0;
  vertical-align: middle;
}

.blog_list_pagination {
  padding-top: 27px;
  padding-left: 2px;
  line-height: normal;
}

.blog_list_nav_links {
  padding-left: 0;
  margin-bottom: 0;
  display: inline-block;
  line-height: normal;
}

.blog_list_nav_links.two {
  float: right;
}

.blog_list_nav_links.two li:last-child a {
  padding-right: 0;
}

.blog_list_nav_links li {
  display: inline-block;
}

.blog_list_nav_links li a {
  padding: 0;
  font-size: 14px;
  font-weight: 400;
  color: #121212;
  padding: 0px 9px;
  transition: 0.3s;
  border-radius: 0;
}

.blog_list_nav_links li a:hover {
  color: #45D638;
}

.blog_list_nav_links li a.prev, .blog_list_nav_links li a.next {
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 3.86;
  letter-spacing: 7px;
  text-align: left;
  color: #D01BD0;
  text-transform: uppercase;
  transition: 0.3s;
}

.blog_list_nav_links li a.prev i, .blog_list_nav_links li a.next i {
  transition: 0.3s;
}

.blog_list_nav_links li a.prev:hover i, .blog_list_nav_links li a.next:hover i {
  color: #45D638;
}

.blog_list_nav_links li a.prev {
  margin-left: 21px;
  padding-right: 6px;
  line-height: normal;
}

.blog_list_nav_links li a.next {
  padding-left: 23px;
  line-height: normal;
  border-left: 1px solid #ddd;
}

.blog_list_nav_links li:first-child a {
  padding-left: 0;
}

.widget_social_list li {
  margin-right: 5px;
  margin-bottom: -1px;
}

.widget_social_list li a {
  width: 55px;
  height: 55px;
  line-height: 55px;
  border: 1px solid #ddd;
  border-radius: 0px;
  text-align: center;
  padding: 0;
}

.widget_social_list li a.active i, .widget_social_list li a:hover i {
  color: #1954ab;
}

.widget_social_list li a i {
  font-size: 16px;
  color: #bababa;
}

.blog_list_sidebar .widget_facebook iframe {
  width: 246px;
  height: 326px;
  border: none;
}

.blog_list_sidebar .widget {
  padding-right: 0;
  margin-bottom: 66px;
}

.blog_list_sidebar .widget_social_content.tab-content {
  margin-left: 0;
  text-align: center;
  border: 1px solid #ddd;
  padding: 20px 0;
}

.blog_list_sidebar .embedly-card {
  padding: 20px;
  height: 346px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.blog_list_sidebar .widget.widget_categories {
  margin-bottom: 50px;
}

.blog_list_sidebar .widget.widget_categories .widget-title {
  color: #121212;
  font-weight: 500;
  font-size: 20px;
  padding-bottom: 15px;
  margin-bottom: 16px;
  border-bottom: 5px solid #ebebeb;
}

.blog_list_sidebar .widget.widget_categories ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.blog_list_sidebar .widget.widget_categories ul .cat-item {
  width: 128px;
  height: 120px;
  text-align: center;
  background: #f3f3f3;
  margin-bottom: 19px;
  padding: 22px 15px;
  transition: 0.3s;
}

.blog_list_sidebar .widget.widget_categories ul .cat-item:hover {
  background: #45D638;
}

.blog_list_sidebar .widget.widget_categories ul .cat-item:hover i, .blog_list_sidebar .widget.widget_categories ul .cat-item:hover p {
  color: #fff;
}

.blog_list_sidebar .widget.widget_categories ul .cat-item i {
  width: 100%;
  font-size: 22px;
  color: #D01BD0;
  margin-bottom: 19px;
  transition: 0.3s;
}

.blog_list_sidebar .widget.widget_categories ul .cat-item p {
  line-height: 20px;
  margin-bottom: 0;
  color: #222;
  transition: 0.3s;
  font-size: 14px;
  font-weight: 500;
}

.blog_list_sidebar .widget.widget_search form {
  height: 57px;
  padding: 0;
  border: 1px solid #ddd;
  padding: 14px 0;
  transition: 0.3s;
}

.blog_list_sidebar .widget.widget_search form label {
  margin-bottom: 0;
  padding-left: 20px;
}

.blog_list_sidebar .widget.widget_search form label i {
  padding-right: 10px;
  border-right: 2px solid #bababa;
  margin-right: 10px;
}

.blog_list_sidebar .widget.widget_search form label .search-field {
  background: transparent;
  border: none;
  padding: 0;
  width: auto;
  color: #7a7a7a;
}

.blog_list_sidebar .widget.widget_search form .search-submit {
  font-size: 14px;
  color: #7a7a7a;
  padding-right: 18px;
  padding-left: 0;
  cursor: pointer;
}

.blog_list_sidebar .widget.widget_search form:hover {
  border: 1px solid #45D638;
}

.blog_list_sidebar .widget.widget_advertise a {
  padding: 0;
  padding-top: 5px;
}

.blog_list_flex {
  display: flex;
  padding-top: 140px;
}

.blog_list_flex_item {
  max-width: 275px;
}

.blog_list_flex_item_big {
  width: calc(100% - 275px);
  padding-right: 134px;
}

.blog_list_nav_links li a span {
  padding: 0 6.5px;
}

@media (min-width: 1200px) and (max-width: 1500px) {
  .blog_list_flex_item_big {
    padding-right: 50px;
  }
  .megamenu .column-inner {
    width: auto;
  }
  .blog_single_list_content {
    width: calc(100% - 43.66%);
  }
  .blog_list_sidebar.sidebar .widget:last-child {
    margin-bottom: 0;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .blog_single_list_content {
    width: 80%;
  }
  .blog_single_list_content {
    padding: 6px 23px;
    height: auto;
    padding-bottom: 0;
  }
  .blog_single_list_content h3 a {
    font-size: 19px;
  }
  .blog_list_flex_item_big {
    margin-right: 30px;
  }
  .widget:last-child {
    margin-bottom: 0;
  }
  .blog_single_list:hover .blog_single_list_content::before {
    left: 7px;
    top: 18px;
  }
  .blog_list_flex_item_big {
    margin-right: 0;
    padding-right: 30px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .blog_single_list {
    flex-wrap: wrap;
  }
  .blog_single_list_img {
    width: 100%;
  }
  .blog_single_list_content {
    width: 100%;
    margin-left: 0;
  }
  .blog_list_flex_item_big {
    width: calc(100% - 275px);
    margin-right: 30px;
  }
  .blog_list_nav_links li a.prev {
    margin-left: 0;
    padding-right: 5px;
  }
  .blog_list_nav_links li a {
    padding: 0px 3px;
  }
  .blog_list_nav_links li a.next {
    padding-left: 0;
    border-left: 0;
  }
  .blog_single_list:hover .post-thumbnail {
    opacity: 1;
  }
  .blog_list_flex {
    padding-top: 100px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .blog_list_nav_links li a {
    padding: 0px 7px;
  }
}

@media only screen and (max-width: 767px) {
  .blog_list_flex_item_big {
    width: 100%;
    margin-right: 0;
    padding-right: 0;
  }
  .blog_list_nav_links li a {
    padding: 0px 7px;
  }
  .blog_list_flex, .blog_single_list {
    flex-wrap: wrap;
  }
  .blog_single_list_content {
    width: 100%;
    margin-left: 0;
    height: 140px;
  }
  .blog_single_list_img {
    width: 100%;
  }
  .blog_single_list:hover .post-thumbnail {
    opacity: 1;
  }
  .blog_list_nav_links li a.prev {
    margin-left: 0;
  }
  .blog_list_pagination {
    text-align: center;
  }
  .blog_list_sidebar.sidebar {
    margin-top: 54px;
    max-width: 275px;
  }
  .blog_list_flex {
    padding-top: 80px;
  }
  .blog_list_flex_item {
    max-width: 100%;
    width: 100%;
    margin-top: 50px;
  }
  .widget.widget_advertise {
    margin-bottom: 0;
  }
}

@media (max-width: 575px) {
  .blog_list_sidebar.sidebar {
    max-width: 100%;
  }
}

/*--------------------------------------------------------------
  ##  Blog list detail
  --------------------------------------------------------------*/
.blog-details .blog_list_nav_links {
  width: 100%;
}

.blog-details .blog-title {
  font-size: 34px;
  font-weight: 600;
  text-transform: capitalize;
  padding-right: 73px;
  margin-bottom: 32px;
  letter-spacing: 2.3px;
  color: #222;
}

.blog-details .meta {
  margin: 0;
  padding: 16px 0;
  line-height: normal;
  border-top: 1px solid rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.03);
  margin-bottom: 24px;
}

.blog-details .meta li {
  display: inline-block;
  line-height: normal;
  padding: 0 30px;
}

.blog-details .meta li:first-child {
  padding-left: 0;
  padding-right: 0;
}

.blog-details .meta li span, .blog-details .meta li a {
  color: #8c8c8c;
  font-size: 14px;
  font-weight: 300;
  padding: 0;
  line-height: normal;
  letter-spacing: 1.2px;
  text-transform: capitalize;
}

.blog-details .meta li span i, .blog-details .meta li a i {
  color: #D01BD0;
}

.blog-details .meta li span:hover, .blog-details .meta li a:hover {
  color: #121212;
}

.blog-details .meta li sup {
  text-transform: lowercase;
}

.blog-details .meta li > span {
  padding: 0 30px;
  position: relative;
}

.blog-details .meta li > span:after {
  position: absolute;
  right: 0;
  content: "-";
  top: 0;
}

.blog-details .meta li > span:first-child {
  padding-left: 0;
}

.blog-details .entry-content {
  font-size: 20px;
  color: #545454;
  font-weight: 300;
  line-height: 38px;
  padding-bottom: 48px;
  border-bottom: 1px solid #ddd;
  letter-spacing: 0.5px;
}

.blog-details .entry-content blockquote {
  margin: 64px 0;
  padding: 20px 35px;
  border: 10px solid #f7f7f7;
  font-size: 20px;
  font-weight: 500;
  color: #554e4e;
  font-style: italic;
  line-height: 36px;
  letter-spacing: 0.5px;
}

.blog-details .entry-header {
  margin-top: 32px;
}

.blog-details .blog_list_pagination {
  padding: 70px 0;
  margin-bottom: 62px;
}

.blog-details .blog_list_nav_links li a.prev {
  margin-left: 0;
}

.blog-details .blog_list_nav_links li:last-child {
  float: right;
}

.blog-details .blog_list_nav_links li a {
  height: 60px;
  line-height: 60px !important;
  display: inline-block;
  background: transparent;
  border-radius: 0;
  text-align: center;
  padding: 0;
  margin: 0;
  border: 2px solid #7474BA;
  font-weight: 400;
  color: #D01BD0;
  padding: 0px 30px;
}

.blog-details .blog_list_nav_links li a:hover {
  background: #D01BD0;
  color: #fff;
  border: 2px solid #D01BD0;
}

.blog-details .blog_list_nav_links li a:hover i {
  color: #fff;
}

.blog-details .comment-meta {
  display: flex;
  align-items: baseline;
  margin-bottom: 5px;
}

.blog-details .comment-metadata a {
  font-size: 12px;
  color: #8c8c8c;
  font-weight: 400;
}

.blog-details .comments-title,
.blog-details .comment-reply-title {
  font-size: 24px;
  color: #393737;
  font-weight: 500;
  border-bottom: 0px;
}

.blog-details .comments-title:after,
.blog-details .comment-reply-title:after {
  display: none;
}

.blog-details .comment-author b a {
  font-size: 16px;
  color: #121212;
  font-weight: 500;
  padding-right: 6px;
  text-transform: capitalize;
  letter-spacing: 0.25px;
}

.blog-details .comment-author b a:hover {
  color: #45D638;
}

.blog-details .comment-content {
  margin-left: 66px;
  display: flex;
  justify-content: space-between;
  letter-spacing: 0.3px;
}

.blog-details .comment-content span {
  padding-top: 0;
  width: 74%;
  font-size: 16px;
  color: #4f4f4f;
  line-height: 30px;
}

.blog-details .comment-content .reply {
  text-align: center;
  position: static;
  padding-right: 8px;
  letter-spacing: 1.2px;
}

.blog-details .comment-content .reply a {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  color: #222;
  padding: 0;
  letter-spacing: 1.7px;
}

.blog-details .comment-content .reply a:hover {
  color: #45D638;
}

.blog-details .children {
  padding-left: 0px;
  margin-left: 0;
}

.blog-details .comment-list article {
  padding-left: 0;
  padding-right: 180px;
  width: 100%;
  padding-bottom: 51px;
  margin-bottom: 56px;
  position: relative;
}

.blog-details .comment-list article:after {
  position: absolute;
  content: "";
  right: 0;
  bottom: 0;
  width: calc(100%);
  height: 1px;
  background: #ddd;
}

.blog-details .comment-author img {
  margin-right: 19px;
}

.blog-details .comment-list .children article {
  width: calc(100% - 70px);
  margin-left: 70px;
}

.blog-details .comment-list .children .children article {
  width: calc(100% - 100px);
  margin-left: 100px;
}

.blog-details .comment-body {
  padding-bottom: 0;
}

.blog-details .comment-author {
  position: relative;
}

.blog-details .comment-author:after {
  position: absolute;
  content: "";
  left: 70px;
  bottom: 10px;
  width: 12px;
  background: #45D638;
  height: 2px;
}

.comment-form-comment textarea {
  width: 100%;
  height: 148px;
  border: 1px solid #ddd;
  resize: none;
  border-radius: 0;
  padding: 15px;
}

.comment-form-comment textarea:focus {
  border: 1px solid #45D638;
}

#commentform label {
  display: block;
  font-size: 16px;
  font-weight: 400;
  color: #4f4f4f;
}

#commentform label .required {
  color: #45D638;
}

.comment-form-author input, .comment-form-email input {
  width: 100%;
  height: 50px;
  border: 1px solid #ddd;
  border-radius: 0;
  padding: 0 15px;
}

.comment-form-author input:focus, .comment-form-email input:focus {
  border: 1px solid #45D638;
}

.comment-form-comment,
.comment-form-author,
.comment-form-email {
  margin-bottom: 34px;
}

p.form-submit {
  margin-top: 40px;
}

.form-submit input[type="submit"] {
  font-size: 14px;
  border: 2px solid #45D638;
  padding: 0px 33px;
  text-transform: uppercase;
  font-weight: 700;
  cursor: pointer;
  background: transparent;
  color: #45D638;
  font-weight: 700;
  height: 50px;
  line-height: 46px;
  border-radius: 0;
  transition: 0.3s;
  letter-spacing: 0.6px;
}

.form-submit input[type="submit"]:hover {
  background: #45D638;
  color: #fff;
}

.blog-details .comment-content .reply a {
  position: relative;
}

.blog-details .comment-content .reply a:after {
  position: absolute;
  content: "";
  left: -10px;
  top: 47%;
  width: 1px;
  height: 26px;
  background: #e6e6e6;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.blog-details .comment-content .reply a:before {
  position: absolute;
  content: "\f104";
  font-family: 'Font Awesome 5 Free';
  top: 50%;
  left: -26px;
  color: red;
  font-size: 13px;
  color: #45D638;
  transform: translateY(-50%);
}

.blog-details .comments-title {
  margin-bottom: 43px;
}

.blog-details .comment-list {
  margin-bottom: 0;
  padding-bottom: 40px;
}

.blog-details #reply-title {
  margin-bottom: 36px;
  letter-spacing: 0.7px;
}

.blog_list_social_fixed {
  position: fixed;
  top: 50%;
  left: 0;
  padding-top: 0;
  z-index: 9;
}

.blog_list_social_fixed ul {
  padding-left: 0;
  margin-bottom: 0;
}

.blog_list_social_fixed ul li {
  width: 50px;
  height: 50px;
  text-align: center;
  border: 1px solid #959595;
  margin-top: -1px;
  background: #fff;
  transition: 0.3s;
  margin-left: -1px;
}

.blog_list_social_fixed ul li:hover {
  background: #121212;
}

.blog_list_social_fixed ul li:hover a {
  color: #45D638;
}

.blog_list_social_fixed ul li a {
  font-size: 14px;
  color: #121212;
  transition: 0.3s;
}

.blog_list_social_fixed-mobile > i {
  font-size: 20px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  text-align: left;
  margin-left: -1px;
  cursor: pointer;
  display: none;
  color: #222;
}

.blog_list_social_fixed-mobile > i:hover {
  color: #45D638;
  text-align: center;
}

.blog_list_area.single-post {
  position: relative;
}

.blog_list_area.single-post .blog-title {
  position: relative;
}

.blog_list_area.single-post .blog-title:after {
  position: absolute;
  content: "";
  left: 3px;
  top: -25px;
  width: 10px;
  height: 87px;
  background: #45D638;
  transform: rotate(45deg);
  z-index: -1;
}

@media (max-width: 575px) {
  .blog-details .meta li {
    display: block;
    text-align: center;
  }
  .blog_list_social_fixed-mobile > i {
    height: 50px;
    width: 50px;
    text-align: center;
    border: 1px solid #eee;
    line-height: 49px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .blog-details .comment-list article {
    padding-right: 0;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .blog_list_flex_item_big {
    width: calc(100% - 300px);
    margin-right: 0px;
    padding-right: 30px;
  }
  .blog-details .blog-title {
    font-size: 26px;
    padding-right: 0;
    letter-spacing: 2px;
  }
  .blog-details .meta li > span {
    padding: 0;
    display: block;
  }
  .blog-details .meta li > span::after {
    display: none;
  }
  .blog-details .meta li {
    display: block;
    padding: 0;
  }
  .blog-details .blog_list_nav_links li a {
    width: 175px;
  }
  .blog_list_nav_links li a.prev,
  .blog_list_nav_links li a.next {
    letter-spacing: 3px;
  }
  .blog-details .comment-list article {
    padding-right: 0;
  }
  .blog-details .comment-content {
    margin-left: 0;
  }
  .blog-details .comment-list .children .children article {
    width: calc(100% - 50px);
    margin-left: 50px;
  }
  .blog-details .comment-list .children article {
    width: calc(100% - 35px);
    margin-left: 35px;
  }
  .blog-details .meta li > span {
    display: inline-block;
    padding-right: 10px;
    margin-bottom: 10px;
  }
  .blog-details .comment-content span {
    width: 70%;
  }
}

@media only screen and (max-width: 991px) {
  .blog_list_social_fixed ul {
    transform: scaleX(0);
    transition: 0.5s;
    transform-origin: left;
  }
  .blog_list_social_fixed-mobile > i {
    display: block;
  }
  .blog_list_social_fixed-mobile:hover ul {
    transform: scaleX(1);
  }
  .blog-details .blog_list_pagination {
    margin-bottom: 0;
  }
  .blog-details .comment-list {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 767px) {
  .call_to_action_green {
    padding: 80px 0 80px;
  }
  .blog-details .blog-title {
    padding-right: 0;
  }
  .blog_list_nav_links {
    text-align: left;
  }
  .blog_list_nav_links a {
    padding: 0px 7px;
  }
  .blog_list_nav_links.two {
    float: none;
    margin-top: 10px;
  }
  .blog-details .blog-title {
    font-size: 27px;
    letter-spacing: 2px;
  }
  .blog-details .meta li > span {
    padding: 0 13px;
  }
  .blog-details .meta li {
    padding: 0 13px;
  }
  .blog-details .comment-list article {
    padding-right: 0;
  }
  .call_to_action_green.not-top-padding {
    margin-top: 180px;
  }
}

@media (max-width: 991px) {
  .blog_list_social_fixed {
    display: none;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .blog_list_sidebar.sidebar {
    max-width: 83%;
  }
}

@media only screen and (max-width: 575px) {
  .blog-details .blog-title {
    font-size: 20px;
  }
  .blog-details .meta li > span {
    padding: 0;
    display: block;
  }
  .blog-details .meta li > span::after {
    display: none;
  }
  .blog-details .meta li {
    display: block;
    padding: 0;
  }
  .blog-details .entry-content blockquote {
    padding: 20px 20px;
  }
  .blog-details .comment-list article {
    padding-left: 0;
    padding-right: 0;
  }
  .blog-details .comment-content {
    margin-left: 0;
    flex-wrap: wrap;
  }
  .blog-details .comment-content .reply {
    width: 100%;
    text-align: left;
    margin-top: 20px;
    padding-left: 26px;
  }
  .blog-details .comment-list .children article {
    width: 100%;
    margin-left: 0;
  }
  .blog-details .comment-list .children .children article {
    width: 100%;
    margin-left: 0;
  }
  .blog-details .comment-content span {
    width: 97%;
  }
  .blog-details .comments-title, .blog-details .comment-reply-title {
    font-size: 20px;
  }
  .blog-details .blog_list_nav_links li a {
    padding: 0px 20px;
  }
  .blog-details .meta li span, .blog-details .meta li a {
    margin-bottom: 6px;
  }
  .blog-details .meta li:last-child a {
    margin-bottom: 0;
  }
  .blog_list_flex_item {
    margin-top: 0;
  }
}

/*--------------------------------------------------------------
  ##  Contact
  --------------------------------------------------------------*/
.contact_area {
  position: relative;
  background: #fff;
  overflow: hidden;
}

.contact_area .section_title {
  margin-bottom: 3.35rem;
}

.map_main {
  position: static;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding-left: 15px;
}

.map {
  height: 100%;
  width: 100%;
}

.contact_content {
  padding: 5rem 2.89rem;
  background: #f8f8f8;
  margin-right: 15px;
  padding-right: 15px;
}

.form_group {
  position: relative;
  margin-bottom: 20px;
}

.form_group.half {
  width: 50%;
  display: inline-block;
  margin: 0 -0.139rem 1.12rem;
  padding: 0 0.84rem;
}

.form_group.half:first-child {
  padding-left: 0;
}

.form_group.half:nth-child(2) {
  padding-right: 0;
}

.form_group.half:nth-child(2) input[required] + label {
  left: 1.67rem;
}

.form_group input {
  width: 100%;
  height: 2.78rem;
  border: none;
  padding: 0 0.84rem;
  box-shadow: none;
  color: #9f9f9f;
}

.form_group input[required] + label {
  color: #999;
  position: absolute;
  left: 0.84rem;
  top: 0.62rem;
  font-size: 0.84rem;
  color: #9f9f9f;
  pointer-events: none;
}

.form_group input[required] + label:after {
  content: '*';
  color: #f99a00;
  font-size: 0.84rem;
}

.form_group input[required]:invalid + label {
  display: inline-block;
  opacity: 1;
}

.form_group input[required]:valid + label {
  opacity: 0;
}

.form_group textarea {
  height: 7.56rem;
  border: none;
  padding: 0.84rem;
  background: #fff;
  resize: none;
  font-size: 0.84rem;
  color: #9f9f9f;
}

.form_group textarea::placeholder {
  opacity: 1;
}

.form_group:last-child {
  margin-bottom: 0;
  text-align: center;
}

.form_group .btn_wrapper {
  margin-top: 0;
}

.contact_form p {
  margin-bottom: 0;
}

#contact .btn_wrapper {
  text-align: left;
}

#contact.contact_area.section_padding {
  position: relative;
  z-index: 1;
  padding-top: 0;
}

.no_padding {
  padding: 0;
}

/*--------------------------------------------------------------
  ##  Contact2
  --------------------------------------------------------------*/
.contact_area_two .section_title_two {
  text-align: left;
}

.contact_area_two .btn_one, .contact_area_two .btn_two {
  border: 2px solid #121212;
  color: #121212;
}

.contact_area_two .btn_one:hover, .contact_area_two .btn_two:hover {
  border: 2px solid #3734f0;
  color: #fff;
}

.contact_area_two .btn_one:hover:before, .contact_area_two .btn_two:hover:before {
  background: #3734f0;
}

.contact_area_two .section_title_two img {
  margin-bottom: 0;
}

.contact_area_two .section_title_two {
  margin-bottom: 60px;
}

/*--------------------------------------------------------------
  ##  Footer
  --------------------------------------------------------------*/
.footer_area {
  background-color: #363636;
  background-image: url(../../assets/img/footer-bg.png);
  font-family: "Poppins", sans-serif;
}

.footer_top {
  padding: 7.5rem 0;
  border-bottom: 2px solid #616161;
}

.footer_bottom {
  padding: 1.89rem 0;
}

.footer_top .widget {
  padding-right: 1.33rem;
  margin-bottom: 0;
}

.footer_top .widget .widget_logo {
  margin-bottom: 1.5rem;
}

.footer_top .widget .widget_logo a {
  padding: 0;
}

.footer_top .widget .widget_logo .svg {
  width: 112px;
}

.footer_top .widget .widget_logo .svg .st1, .footer_top .widget .widget_logo .svg .st0 {
  fill: #f99a00;
  stroke: #f99a00;
}

.footer_top .widget .widget_logo .svg path {
  fill: #ffffff;
}

.footer_top .widget .widget_desc {
  margin-bottom: 1.23rem;
}

.footer_top .widget .widget_desc p {
  color: #bebebe;
}

.footer_top .widget .widget_social > span {
  display: block;
  font-size: 0.89rem;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  margin-bottom: 0.56rem;
}

.footer_top .widget .widget_social > span b {
  color: #f99a00;
  font-weight: 600;
}

.footer_top .widget .widget_social a {
  width: 1.78rem;
  height: 1.78rem;
  border: 1px solid #ffffff;
  border-radius: 50%;
  text-align: center;
  padding: 0;
  margin-right: 0.33rem;
}

.footer_top .widget .widget_social a:hover {
  border: 1px solid #f99a00;
}

.footer_top .widget .widget_social a:hover span {
  color: #f99a00;
}

.footer_top .widget .widget_social a span {
  color: #ffffff;
  font-size: 0.78rem;
  line-height: 1.78rem;
}

.footer_top .widget .widget_social a:last-child {
  margin-right: 0;
}

.footer_top .widget .widget_title {
  margin-bottom: 2.35rem;
}

.footer_top .widget .widget_title h5 {
  color: #ffffff;
  text-transform: uppercase;
  margin-bottom: 0px;
}

.footer_top .widget .widget_address ul {
  padding-left: 0;
}

.footer_top .widget .widget_address ul li {
  font-size: 1rem;
  color: #e1e1e0;
  margin-bottom: 0.84rem;
}

.footer_top .widget .widget_address ul li i {
  width: 25px;
  color: #f99a00;
  display: inline-block;
  vertical-align: middle;
}

.footer_top .widget .widget_address ul li span {
  width: calc(100% - 40px);
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
}

.footer_top .widget .widget_address ul li span a {
  display: block;
  color: #e1e1e0;
  padding: 0;
  line-height: 1.67rem;
}

.footer_top .widget .widget_address ul li span a:hover {
  color: #f99a00;
}

.footer_top .widget .widget_address ul li:nth-child(2) i {
  -webkit-transform: rotate(95deg);
  -ms-transform: rotate(95deg);
  transform: rotate(95deg);
  margin-left: -3px;
}

.footer_top .widget .widget_form {
  margin-bottom: 2rem;
}

.footer_top .widget .widget_form p {
  color: #bebebe;
  margin-bottom: 1.23rem;
}

.footer_top .widget .widget_form form {
  position: relative;
  font-size: 0.84rem;
}

.footer_top .widget .widget_form form input[type="text"] {
  width: 100%;
  height: 2.46rem;
  border: 1px solid #b1b0ac;
  background: transparent;
  padding: 0 5rem 0 1.12rem;
  color: #bebebe;
}

.footer_top .widget .widget_form form input[type="text"] ::placeholder {
  color: #bebebe;
}

.footer_top .widget .widget_form form button {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 80px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  background: #f99a00;
  color: #ffffff;
  border: none;
  cursor: pointer;
}

.footer_top .widget .widget_payment span {
  font-size: 0.89rem;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  margin-bottom: 0.56rem;
  margin-right: 1.12rem;
}

.footer_top .widget .widget_payment span b {
  color: #f99a00;
  font-weight: 600;
}

.footer_top .widget .widget_payment a {
  padding: 0;
  vertical-align: middle;
  margin-right: 0.33rem;
}

.footer_top .widget .widget_payment a:hover i {
  color: #f99a00;
}

.footer_top .widget .widget_payment a i {
  font-size: 2rem;
  color: #fefefe;
}

.copyright {
  text-align: center;
}

.copyright span {
  color: #e1e1e0;
  text-transform: uppercase;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  font-size: 0.89rem;
}

.copyright span a {
  color: #121212;
  padding: 0;
  text-transform: initial;
}

#footer .copyright span a {
  color: #f99a00;
}

#footer .widget {
  padding-right: 0px;
}

#footer .widget.widget_about {
  padding-right: 50px;
}

#footer .widget.widget_about_two {
  padding-right: 35px;
}

/*--------------------------------------------------------------
  ##  Call to action
  --------------------------------------------------------------*/
.call_to_action_area {
  padding: 4.5rem 0;
  background: #f99a00;
}

.call_to_action_left {
  display: inline-block;
  text-align: left;
}

.call_to_action_left h2, .call_to_action_left p {
  margin-bottom: 0;
}

.call_to_action_left p {
  width: 80%;
  color: #ffffff;
}

.call_to_action_right {
  float: right;
  margin-top: 22px;
}

.call_to_action_right .btn_one {
  border: 1px solid #fff;
}

.call_to_action_right .btn_two:hover {
  color: #f1b601;
}

/*--------------------------------------------------------------
  ##  Vaxine all fact
  --------------------------------------------------------------*/
.product_review_tab .nav li {
  height: 62px;
  width: 25%;
  border: 1px solid #ededed;
  margin-left: -1px;
  text-align: center;
  line-height: 62px;
  font-weight: 500;
}

.product_review_tab .nav li a {
  padding: 0;
  color: #121212;
  display: block;
  position: relative;
  letter-spacing: 2px;
}

.product_review_tab .nav li a:after {
  position: absolute;
  left: calc(50% - 21px);
  top: 97%;
  content: "";
  width: 0;
  height: 1px;
  background: #121212;
  transition: 0.3s;
}

.product_review_tab .nav li a.active {
  color: #f99a00;
}

.product_review_tab .nav li a.active:after {
  width: 42px;
}

.product_review_tab .tab-content {
  padding: 68px 60px;
  border: 1px solid #ededed;
  border-top: 0px;
  width: 99.6%;
  margin-left: -1px;
}

.product_review_tab .tab-content #description p {
  color: #666;
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 35px;
  letter-spacing: 0.38px;
}

.product_review_tab .tab-content #description p:last-child {
  margin-bottom: 0;
}

.supplement_nutrition_fact h3 {
  font-size: 24px;
  color: #666;
  font-weight: 400;
  text-transform: capitalize;
  margin-bottom: 30px;
}

.supplement_nutrition_fact p {
  margin-bottom: 70px;
}

.shipping_info_detail h4 {
  font-size: 24px;
  color: #222;
  font-weight: 400;
  text-transform: capitalize;
  margin-bottom: 20px;
}

.single_shipping_info {
  margin-bottom: 40px;
}

.single_shipping_info > span {
  font-size: 22px;
  color: #666;
  font-weight: 500;
  margin-bottom: 30px;
  display: block;
}

.single_shipping_info ul {
  padding-left: 0;
}

.single_shipping_info li {
  font-size: 16px;
  color: #666;
  line-height: 30px;
}

.sn_review_left {
  width: 50px;
  height: 50px;
  border-radius: 3px;
  display: inline-block;
  vertical-align: top;
}

.sn_review_right {
  display: inline-block;
  width: calc(100% - 55px);
  vertical-align: top;
  padding-bottom: 50px;
  border-bottom: 1px solid #f5f5f5;
  margin-bottom: 50px;
  padding-left: 20px;
}

.sn_review_right a {
  padding: 0;
  font-size: 16px;
  color: #666;
  font-weight: 500;
}

.sn_review_right a span {
  font-size: 12px;
  color: #9c9c9c;
}

.sn_review_right .sn_review_icon a {
  font-size: 14px;
  color: #f99a00;
}

.sn_review_right .sn_review_icon a:last-child {
  color: #b2b2b2;
}

.sn_review_right .sn_review_desc p {
  font-size: 16px;
  color: #666;
  line-height: 30px;
  margin-bottom: 0;
}

.review_form h5 {
  font-size: 16px;
  color: #121212;
  font-weight: 500;
  margin-bottom: 50px;
}

.review_form h5 span {
  color: #9c9c9c;
  font-size: 14px;
  text-transform: capitalize;
}

.product_rating {
  margin-bottom: 30px;
}

.product_rating h5 {
  font-size: 16px;
  color: #4f4f4f;
  text-transform: capitalize;
  font-weight: 400;
}

.product_rating a {
  padding: 0;
  color: #b8b8b8;
  font-size: 16px;
}

.sn_review_input label {
  font-size: 16px;
  color: #666;
  font-weight: 400;
  display: block;
  border-radius: 0;
}

.sn_review_input textarea {
  width: 45%;
  height: 140px;
  border: 1px solid #f5f5f5;
  resize: none;
  border-radius: none;
  margin-bottom: 40px;
  padding: 15px;
  border-radius: 0px;
}

.sn_review_input textarea:focus {
  border: 1px solid #45D638;
}

.sn_review_input input {
  width: 45%;
  border: none;
  height: 49px;
  border: 1px solid #f5f5f5;
  border-radius: 0;
  margin-bottom: 40px;
  padding: 15px;
}

.sn_review_input input:focus {
  border: 1px solid #45D638;
}

.sn_review_input label {
  transition: 0.3s;
}

.sn_review_input:hover label {
  color: #000;
}

@media (min-width: 576px) and (max-width: 767px) {
  .product_share ul li:hover a span {
    display: none;
  }
  .product_share ul li:hover a > i {
    opacity: 1;
  }
}

@media only screen and (max-width: 575px) {
  .product_review_tab .nav li a {
    font-size: 12px;
  }
  .product_share ul li:hover a span {
    display: none;
  }
  .product_share ul li:hover a > i {
    opacity: 1;
  }
}

/*--------------------------------------------------------------
  ##  Vaxine all fact2
  --------------------------------------------------------------*/
.vaxine_all_fact2 p, .vaxine_all_fact2 h1, .vaxine_all_fact2 h2, .vaxine_all_fact2 h3, .vaxine_all_fact2 h4, .vaxine_all_fact2 h5, .vaxine_all_fact2 h6, .vaxine_all_fact2 a {
  font-family: "Roboto", sans-serif;
}

.vaxine_all_fact2 .product_review_tab .nav li a.active,
.vaxine_all_fact2 .sn_review_right .sn_review_icon a,
.vaxine_all_fact2 .sn_pd_rating a i {
  color: #D01BD0;
}

.vaxine_all_fact2 .accordion_btn {
  padding-left: 0px;
}

.vaxine_all_fact2 .product_review_tab .tab-content #description p {
  color: #454545;
  letter-spacing: 0.38px;
  margin-bottom: 10px;
}

.vaxine_all_fact2 .supplement_nutrition_fact p {
  color: #454545;
}

.vaxine_all_fact2 .single_shippin45455g_info li {
  color: #454545;
}

.vaxine_all_fact2 .sn_review_right .sn_review_desc p {
  color: #454545;
}

.vaxine_all_fact2 .btn_one, .vaxine_all_fact2 .btn_two {
  border: 2px solid #D01BD0;
  color: #D01BD0;
}

.vaxine_all_fact2 .btn_two::before {
  background: #D01BD0;
}

.vaxine_all_fact2 .btn_two:hover {
  border: 2px solid #D01BD0;
  color: #fff;
  background: #D01BD0;
}

.vaxine_all_fact2 .btn_two:hover {
  border: 2px solid #D01BD0;
  color: #fff;
}

#description h5 {
  margin-bottom: 30px;
  color: #222;
  line-height: 28px;
  font-weight: 500;
  text-transform: capitalize;
}

.entry-content-list h4 {
  margin-bottom: 30px;
  font-size: 20px;
  text-transform: capitalize;
  font-weight: 500;
}

.entry-content-list li {
  margin-bottom: 15px;
}

.entry-content-list {
  margin-top: 30px;
  margin-bottom: 25px;
}

.entry-content-list ul {
  padding-left: 0;
}

/*--------------------------------------------------------------
  ##  Call to action2
  --------------------------------------------------------------*/
.call_to_action_area_two {
  background: #f06b00;
  background: -moz-linear-gradient(right, #f06b00 0%, #f09e00 100%);
  background: -webkit-linear-gradient(right, #f06b00 0%, #f09e00 100%);
  background: linear-gradient(to left, #f06b00 0%, #f09e00 100%);
  position: relative;
  padding: 40px 0px;
}

.call_to_action_area_two:before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  background: url(../../media/images/banner-two/garlic-pills.png) no-repeat scroll top left/contain;
}

.call_to_action_area_two:after {
  position: absolute;
  content: "";
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: url(../../media/images/banner-two/garlic-pill-reflect.png) no-repeat scroll top right/contain;
}

.call_to_action_hello {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: auto;
  padding: 40px;
  border: 2px solid rgba(242, 168, 26, 0.6);
}

.call_to_action_left_two p {
  margin-bottom: 0;
  font-size: 20px;
  color: #121212;
  opacity: 0.8;
  letter-spacing: 0.2px;
}

.call_to_action_left_two h2 {
  margin-bottom: 0;
  font-size: 36px;
  color: #fff;
  font-weight: 300;
  letter-spacing: 6px;
  line-height: normal;
}

.call_to_action_right_two {
  height: 46px;
}

.call_to_action_right_two .btn_two {
  border: 2px solid #fff;
  color: #fff;
  letter-spacing: 2.2px;
}

.call_to_action_right_two .btn_two:hover {
  color: #3db5e6;
  background: #fff;
}

.call_to_action_right_two .btn_two:before {
  background: #fff;
}

/*--------------------------------------------------------------
  ##  Call to action2
  --------------------------------------------------------------*/
.call_to_action_green {
  padding: 140px 0;
}

.call_to_action_green .call_to_action_area_two::after {
  display: none;
}

.call_to_action_green .call_to_action_area_two {
  background: #3ad82e;
  background: -moz-linear-gradient(right, #3ad82e 0%, #32b228 100%);
  background: -webkit-linear-gradient(right, #3ad82e 0%, #32b228 100%);
  background: linear-gradient(to left, #3ad82e 0%, #32b228 100%);
  position: relative;
  padding: 50px 0px;
  box-shadow: 22px 32px 70px rgba(0, 0, 0, 0.15);
}

.call_to_action_green .call_to_action_hello {
  padding: 0;
  border: none;
  padding: 0px 79px 0 92px;
}

.call_to_action_green .btn_three {
  border: 2px solid #fff;
  color: #121212;
  background: #fff;
  line-height: 50px;
}

.call_to_action_green .call_to_action_right_two {
  height: auto;
}

.call_to_action_green .call_to_action_right_two .btn_four:hover {
  color: #fff;
  background: #D01BD0;
  border-color: #D01BD0;
}

.call_to_action_green.not-top-padding {
  background: #fff;
  position: relative;
  z-index: 9;
  padding-top: 0;
  margin-top: 130px;
}

@media only screen and (max-width: 767px) {
  .call_to_action_green {
    padding: 80px 0 80px;
  }
  .call_to_action_green.not-top-padding {
    margin-top: 180px;
  }
}

@media (max-width: 575px) {
  .call_to_action_green .call_to_action_area_two {
    padding: 50px 0px 55px;
  }
}

/*--------------------------------------------------------------
  ##  Call to action2
  --------------------------------------------------------------*/
.call_to_action_grey {
  padding: 140px 0;
}

.call_to_action_grey .call_to_action_left_two p {
  font-size: 20px;
  opacity: 1;
}

.call_to_action_grey .call_to_action_area_two::after {
  display: none;
}

.call_to_action_grey .call_to_action_area_two {
  background: #ebebeb;
  position: relative;
  padding: 50px 0px;
  box-shadow: 22px 32px 70px rgba(0, 0, 0, 0.15);
}

.call_to_action_grey .call_to_action_left_two h2 {
  color: #D01BD0;
}

.call_to_action_grey .call_to_action_hello {
  padding: 0;
  border: none;
  padding: 0px 79px 0 92px;
}

.call_to_action_grey .btn_three {
  border: 2px solid #fff;
  color: #121212;
  background: #fff;
  line-height: 50px;
}

.call_to_action_grey .call_to_action_right_two {
  height: auto;
}

.call_to_action_grey .btn_four {
  color: #D01BD0;
  border: 2px solid #fff;
}

.call_to_action_grey .btn_four:hover {
  background: #D01BD0;
  border: 2px solid #D01BD0;
  color: #fff;
}

@media (min-width: 768px) and (max-width: 991px) {
  .call_to_action_grey {
    padding: 100px 0 100px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .call_to_action_grey {
    padding: 80px 0 90px;
  }
}

@media only screen and (max-width: 767px) {
  .call_to_action_grey {
    padding: 70px 0 90px;
  }
}

@media only screen and (max-width: 575px) {
  .call_to_action_grey .call_to_action_hello {
    padding: 0 15px;
  }
  .call_to_action_grey {
    padding: 60px 0 80px;
  }
}

/*--------------------------------------------------------------
  ##  Package Plan
  --------------------------------------------------------------*/
.single_pkg_plan {
  text-align: center;
  position: relative;
  height: 492px;
  margin-bottom: 0;
  margin-top: 0;
}

.single_pkg_plan figure {
  margin-bottom: 0;
}

.single_pkg_plan:hover .single_pkg_img::before {
  top: 0;
}

.single_pkg_plan span {
  font-size: 36px;
  color: #121212;
  font-weight: 400;
  position: relative;
  display: inline-block;
  margin-bottom: 1.38rem;
}

.single_pkg_img {
  height: 270px;
}

.single_pkg_img img {
  height: 100%;
}

.single_pkg_bottom {
  background: #f8f8f8;
  padding: 60px 15px;
  bottom: 0;
  position: absolute;
  width: 100%;
  transition: 0.8s;
}

.single_pkg_bottom p {
  margin-bottom: 1.8rem;
}

.single_pkg_bottom h6 {
  padding-bottom: 0;
  font-size: 16px;
  font-weight: 400;
  height: 0;
  transform: scaleY(0);
  transform-origin: bottom left;
  transition: 0.8s;
  color: #f99a00;
}

.single_pkg_bottom h6:after {
  position: absolute;
  left: 50%;
  height: 3px;
  background: #121212;
  bottom: 20px;
  content: "";
  width: 30px;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.single_pkg_plan:hover .single_pkg_bottom h6 {
  height: 83px;
  transform: scaleY(1);
}

/*--------------------------------------------------------------
  ##  Advertise
  --------------------------------------------------------------*/
.advertise_area .vigo_container_one {
  background: #eff8ff;
  padding: 28px;
}

.advertise_area .row.background_white {
  background: #fff;
  margin: 0;
  border-radius: 10px;
}

.advertise_text_right {
  float: right;
  margin-top: 18px;
}

.advertise_text_left {
  display: inline-block;
}

.advertise_text_left span {
  color: #f99a00;
}

.advertise_all {
  display: table;
  width: 100%;
  height: 100%;
}

.advertise_cell {
  display: table-cell;
  vertical-align: middle;
  height: 100%;
}

.advertise_area {
  padding-bottom: 110px;
}

.advertise_text_left p {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
  ##  Package Plan2
  --------------------------------------------------------------*/
.package_plan_two p, .package_plan_two h1, .package_plan_two h2, .package_plan_two h3, .package_plan_two h4, .package_plan_two h5, .package_plan_two h6, .package_plan_two a {
  font-family: "Roboto", sans-serif;
}

.package_plan_two .accordion_btn {
  padding-left: 0;
}

.package_plan_two .single_pkg_plan span {
  color: #3734f0;
}

.package_plan_two .single_pkg_plan span:after {
  background: #3734f0;
}

.package_plan_two .single_pkg_plan:hover .single_pkg_bottom:before {
  background: #f06b00;
  background: -moz-linear-gradient(right, #f06b00 0%, #f09e00 100%);
  background: -webkit-linear-gradient(right, #f06b00 0%, #f09e00 100%);
  background: linear-gradient(to left, #f06b00 0%, #f09e00 100%);
}

.package_plan_two .single_pkg_plan:hover .accordion_btn a {
  color: #fff;
}

.package_plan_two .single_pkg_plan:hover .accordion_btn a i {
  color: #fff;
}

/*--------------------------------------------------------------
  ##  Supplement service
  --------------------------------------------------------------*/
.supp_icon {
  width: 80px;
  height: 80px;
  text-align: center;
  line-height: 80px;
  border-radius: 50%;
  -webkit-box-shadow: 0px 2px 51px rgba(241, 182, 1, 0.25);
  box-shadow: 0px 2px 51px rgba(241, 182, 1, 0.25);
  position: relative;
}

.supp_icon:after {
  position: absolute;
  left: -10px;
  top: -10px;
  width: calc(100% + 20px);
  border: 1px dashed #cccccc;
  content: "";
  height: calc(100% + 20px);
  border-radius: 50%;
}

.single_supp_ser {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 42px;
  margin-left: 10px;
}

.single_supp_ser:last-child {
  margin-bottom: 0;
}

.supp_content {
  padding-left: 43px;
  width: calc(100% - 90px);
}

.supp_content h5 {
  text-transform: capitalize;
  color: #f99a00;
}

.supp_content p {
  margin-bottom: 0;
}

.supplement_service_img {
  text-align: right;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.supp_ser_list {
  padding-right: 26px;
}

.supplement_service_area.section_padding {
  padding: 7.5rem 0;
}

/*--------------------------------------------------------------
  ##  Supplement service2
  --------------------------------------------------------------*/
.section_padding_two {
  padding: 116px 0;
}

.supplement_service_area_two p, .supplement_service_area_two h1, .supplement_service_area_two h2, .supplement_service_area_two h3, .supplement_service_area_two h4, .supplement_service_area_two h5, .supplement_service_area_two h6, .supplement_service_area_two a {
  font-family: "Roboto", sans-serif;
}

.supplement_service_area_two .supp_content h5 {
  color: #3734f0;
  font-size: 20px;
}

.supplement_service_area_two .supp_content p {
  color: #9e9c9c;
}

.supplement_service_area_two .supp_icon {
  -webkit-box-shadow: 0px 2px 51px rgba(55, 52, 240, 0.25);
  box-shadow: 0px 2px 51px rgba(55, 52, 240, 0.25);
}

.supplement_service_area_two .supplement_service_img {
  -webkit-transform: translateY(-56%);
  -ms-transform: translateY(-56%);
  transform: translateY(-56%);
}

.supplement_service_area_two .supp_ser_list {
  padding-right: 0;
}

.single_supp_ser:hover .supp_content h5 {
  color: #383434;
}

@media (min-width: 768px) and (max-width: 991px) {
  .faq3_area.section_padding_two {
    padding: 96px 0 0;
  }
}

/*--------------------------------------------------------------
  ##  Supplement service2
  --------------------------------------------------------------*/
.supplement_select_item {
  display: flex;
  justify-content: space-between;
  padding: 70px 0;
  padding-left: 46px;
  align-items: center;
}

.supplement_select_item .quantity {
  margin-bottom: 12px;
}

.supplement_select_area {
  padding-top: 120px;
}

.supplement_select_content {
  background: #f9f9f9 url(../../media/images/home3/guy.png) no-repeat scroll left 42px bottom/contain;
  position: relative;
  z-index: 1;
  border: 1px solid #fff;
}

.supplement_select_content .supplement_select_item_bg {
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
  height: auto;
  z-index: -1;
}

.section_title_three {
  margin-bottom: 40px;
}

.section_title_three h2 {
  font-size: 36px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 36px;
  letter-spacing: normal;
  text-align: left;
  color: #3db5e6;
  margin-bottom: 16px;
  text-transform: uppercase;
}

.section_title_three p {
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #424e5a;
  margin-bottom: 0;
  width: 75%;
}

.supplement_select_area .section_title_three {
  margin-bottom: 31px;
}

.supplement_select_area .product-cart {
  padding-bottom: 0;
  border-bottom: 0px;
}

.supplement_select_area .quantity .looking {
  border-radius: 50%;
  width: 26px;
  height: 26px;
  line-height: 23px;
  border: 0px;
  margin-left: 5px;
}

.supplement_select_area .quantity .looking:hover {
  background: #3db5e6;
}

.supplement_select_area .woocommerce-Price-amount {
  font-size: 36px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.92;
  letter-spacing: normal;
  text-align: left;
  color: #121212;
  margin-bottom: 9px;
}

.supplement_select_area .woocommerce-Price-amount span {
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 4.93;
  letter-spacing: normal;
  text-align: left;
  color: #424e5a;
}

.supplement_select_area .btn_two {
  border: 2px solid #3db5e6;
  color: #3db5e6;
  background: #fff;
  letter-spacing: 1.9px;
}

.supplement_select_area .btn_two:hover {
  color: #fff;
  background: #3db5e6;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .supplement_select_content {
    background: #f9f9f9;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .supplement_select_item_product {
    display: none;
  }
  .supplement_select_content {
    background: #f1f1f1;
  }
  .supplement_select_item_detail {
    width: 68%;
  }
  .supplement_select_area {
    padding-top: 100px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .supplement_select_content .supplement_select_item_bg {
    display: none;
  }
  .supplement_select_content {
    background: #f1f1f1;
  }
  .section_title_three p {
    width: 100%;
  }
  .supplement_select_area {
    padding-top: 80px;
  }
  .supplement_benefit_item {
    width: calc(50% - 1px);
    margin-bottom: 1px;
  }
}

@media only screen and (max-width: 575px) {
  .supplement_select_item {
    padding: 30px;
    padding-left: 30px;
    background: #f1f1f1;
  }
  .supplement_select_item_product {
    display: none;
  }
  .supplement_select_content .supplement_select_item_bg {
    display: none;
  }
  .section_title_three h2 {
    font-size: 27px;
  }
  .section_title_three p {
    width: 100%;
  }
  .supplement_select_content {
    background: transparent;
    border: 1px solid transparent;
  }
  .supplement_at_a_glance.section_padding_two {
    position: relative;
    z-index: 1;
  }
  .supplement_at_item_img {
    display: none;
  }
  .supplement_select_area {
    padding: 50px 0 80px;
  }
}

@media (min-width: 480px) and (max-width: 575px) {
  .supplement_select_area {
    padding: 50px 0 80px;
  }
}

/*--------------------------------------------------------------
  ##  Supplement service2
  --------------------------------------------------------------*/
.supplement_at_item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: #f9f9f9;
  padding: 25px;
  margin-bottom: 30px;
  position: relative;
  border-right: 5px solid #dfdfdf;
  transition-delay: 0.5s ease;
  transition: 0.3s;
  align-items: center;
}

.supplement_at_item:after {
  position: absolute;
  content: "";
  right: -5px;
  width: 5px;
  height: 0;
  top: 0;
  background: #3db5e6;
  transition: 0.5s linear;
}

.supplement_at_item:hover {
  background: #fff;
  box-shadow: 18px 18px 38px #dedede;
}

.supplement_at_item:hover:after {
  height: 100%;
}

.supplement_at_item:hover .supplement_at_item_content a i {
  color: #3db5e6;
}

.supplement_at_item_img {
  width: 139px;
  height: 139px;
  text-align: center;
  line-height: 144px;
  background: #fff;
  transition: 0.5s;
}

.supplement_at_item_content {
  width: calc(100% - 144px);
  padding-left: 25px;
  padding-right: 20px;
}

.supplement_at_item_content h3 {
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
  color: #424e5a;
  text-transform: capitalize;
  line-height: normal;
  margin-bottom: 7px;
  margin-top: 5px;
}

.supplement_at_item_content p {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #424e5a;
  margin-bottom: 13px;
}

.supplement_at_item_content a {
  padding: 0;
}

.supplement_at_item_content a i {
  font-size: 16px;
  color: #c6c6c6;
  transition: 0.3s;
  transition-delay: 0.5s;
}

.supplement_at_a_glance.section_padding_two {
  padding-bottom: 90px;
  padding-top: 120px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .supplement_at_item {
    align-items: center;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .supplement_at_a_glance.section_padding_two {
    position: relative;
    z-index: 1;
  }
  .supplement_at_a_glance.section_padding_two {
    padding-top: 100px;
    padding-bottom: 70px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .supplement_at_a_glance.section_padding_two {
    position: relative;
    z-index: 1;
  }
  .supplement_at_item_content {
    width: calc(100% - 50px);
  }
  .supplement_at_a_glance.section_padding_two {
    padding-bottom: 50px;
    padding-top: 80px;
  }
}

@media only screen and (max-width: 575px) {
  .supplement_at_item_content {
    width: 100%;
    padding-left: 0;
  }
  .supplement_at_a_glance.section_padding_two {
    padding-bottom: 50px;
    padding-top: 0px;
  }
}

/*--------------------------------------------------------------
  ##  Supplement benifit
  --------------------------------------------------------------*/
.supplement_benefit_item {
  text-align: center;
  width: 33.33%;
  background: #f1f1f1;
  margin-right: 1px;
  transition: 0.5s;
  position: relative;
  z-index: 1;
}

.supplement_benefit_item:last-child {
  margin-right: 0;
}

.supplement_benefit_item i {
  width: 45px;
  height: 45px;
  background: #fff;
  line-height: 45px;
  border-radius: 50%;
  font-size: 16px;
  color: #3db5e6;
  margin-bottom: 32px;
}

.supplement_benefit_item p {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
  line-height: 26px;
  color: #424e5a;
}

.supplement_benefit_item:hover p {
  color: #fff;
}

.supplement_benefit_item:hover:before {
  opacity: 1;
}

.supplement_benefit_all_items {
  display: flex;
}

.supplement_benefit {
  position: relative;
  z-index: 1;
}

.supplement_benefit_item {
  color: #000;
  display: inline-block;
  text-decoration: none;
  overflow: hidden;
  vertical-align: top;
  -webkit-perspective: inherit;
  -moz-perspective: inherit;
  -ms-perspective: inherit;
  perspective: inherit;
  -webkit-perspective-origin: 50% 50%;
  -moz-perspective-origin: 50% 50%;
  -ms-perspective-origin: 50% 50%;
  perspective-origin: 50% 50%;
}

.supplement_benefit_item:hover .supplement_benefit_inner {
  background: #f1f1f1;
  -webkit-transform: translate3d(0px, 0px, -30px) rotateX(90deg);
  -moz-transform: translate3d(0px, 0px, -30px) rotateX(90deg);
  -ms-transform: translate3d(0px, 0px, -30px) rotateX(90deg);
  transform: translate3d(0px, 0px, -30px) rotateX(90deg);
}

.supplement_benefit_item .supplement_benefit_inner {
  padding: 47px 90px 43px !important;
  display: block;
  position: relative;
  padding: 0 3px;
  -webkit-transition: all 0.8s ease;
  -moz-transition: all 0.8s ease;
  -ms-transition: all 0.8s ease;
  transition: all 0.8s ease;
  -webkit-transform-origin: 50% 0%;
  -moz-transform-origin: 50% 0%;
  -ms-transform-origin: 50% 0%;
  transform-origin: 50% 0%;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
  z-index: 1;
}

.supplement_benefit_item .supplement_benefit_inner .after {
  content: "";
  z-index: -1;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 0 3px;
  color: #fff;
  background: -o-linear-gradient(top, #3db5e6 0%, #0086c3 100%);
  background: -webkit-gradient(linear, right top, left top, from(#3db5e6), to(#0086c3));
  background: linear-gradient(to bottom, #3db5e6 0%, #0086c3 100%);
  -webkit-transform-origin: 50% 0%;
  -moz-transform-origin: 50% 0%;
  -ms-transform-origin: 50% 0%;
  transform-origin: 50% 0%;
  -webkit-transform: translate3d(0px, 105%, 0px) rotateX(-90deg);
  -moz-transform: translate3d(0px, 105%, 0px) rotateX(-90deg);
  -ms-transform: translate3d(0px, 105%, 0px) rotateX(-90deg);
  transform: translate3d(0px, 105%, 0px) rotateX(-90deg);
  padding: 47px 90px 43px !important;
}

@media (min-width: 1200px) and (max-width: 1500px) {
  .supplement_benefit_item .supplement_benefit_inner {
    padding: 47px 55px 43px !important;
  }
  .supplement_benefit_item .supplement_benefit_inner .after {
    padding: 47px 55px 43px !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .supplement_benefit_item .supplement_benefit_inner {
    padding: 47px 25px 43px !important;
  }
  .supplement_benefit_item .supplement_benefit_inner .after {
    padding: 47px 25px 43px !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .supplement_benefit_item {
    width: calc(50% - 1px);
    margin-bottom: 1px;
  }
  .supplement_benefit_all_items {
    flex-wrap: wrap;
  }
  .supplement_benefit_item .supplement_benefit_inner {
    padding: 47px 82px 43px !important;
  }
  .supplement_benefit_item .supplement_benefit_inner .after {
    padding: 47px 82px 43px !important;
  }
}

@media only screen and (max-width: 767px) {
  .supplement_benefit_item {
    width: calc(50% - 1px);
    margin-bottom: 1px;
  }
  .supplement_benefit_all_items {
    flex-wrap: wrap;
  }
  .supplement_benefit_item .supplement_benefit_inner {
    padding: 47px 35px 43px !important;
  }
  .supplement_benefit_item .supplement_benefit_inner .after {
    padding: 47px 35px 43px !important;
  }
}

@media only screen and (max-width: 575px) {
  .supplement_benefit_item .supplement_benefit_inner {
    padding: 47px 26px 43px !important;
  }
  .supplement_benefit_item .supplement_benefit_inner .after {
    padding: 47px 26px 43px !important;
  }
  .supplement_benefit_item {
    margin-bottom: 1px;
  }
  .supplement_benefit {
    padding-top: 0;
  }
}

@media only screen and (max-width: 480px) {
  .supplement_benefit_item {
    width: 100%;
    margin-bottom: 30px;
  }
  .supplement_benefit_all_items {
    flex-wrap: wrap;
  }
}

/*--------------------------------------------------------------
  ##  Supplement benifit
  --------------------------------------------------------------*/
.supplement_benefit_two {
  padding-bottom: 140px;
}

.supplement_benefit_two .supplement_benefit_item {
  transition: all 0.5s linear;
}

.supplement_benefit_two .supplement_benefit_item i {
  color: #45D638;
}

.supplement_benefit_two .supplement_benefit_item:hover .supplement_benefit_inner {
  background: #45D638;
  background: #f1f1f1;
}

.supplement_benefit_two .supplement_benefit_item .supplement_benefit_inner .after {
  background: -o-linear-gradient(right, #32c027 0%, #3ad82e 100%);
  background: -webkit-gradient(linear, right top, left top, from(#32c027), to(#3ad82e));
  background: linear-gradient(to left, #32c027 0%, #3ad82e 100%);
}

@media only screen and (max-width: 767px) {
  .supplement_benefit_two {
    padding-bottom: 94px;
    padding-top: 0;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .supplement_benefit_two {
    padding-bottom: 126px;
    padding-top: 0;
  }
}

/*--------------------------------------------------------------
  ##  Supplement benifit
  --------------------------------------------------------------*/
.supplement_more {
  position: relative;
  z-index: 1 .supplement_more;
}

.supplement_more :after {
  position: absolute;
  content: "";
  left: 15px;
  top: 0;
  width: calc(100% - 30px);
  height: 200px;
  background: #ffffff url(../../media/images/home6/supp-more.jpg) no-repeat;
  z-index: -1;
}

.supplement_more .sn_related_product {
  background: #fff;
  margin-right: 30px;
  box-shadow: 22px 32px 70px rgba(0, 0, 0, 0.15);
}

.supplement_more .sn_related_product:last-child {
  margin-right: 0;
}

.supplement_more .section_title_four h2 {
  margin-top: -53px;
}

.supplement_more .supplement_more_related_products {
  display: flex;
  flex-wrap: initial;
}

.supplement_more .sn_pd_rating a i {
  color: #D01BD0;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .supplement_more .sn_related_product {
    margin-right: 5px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .supplement_more .sn_related_product {
    width: 28%;
    margin-bottom: 20px;
  }
  .supplement_more .supplement_more_related_products {
    flex-wrap: wrap;
  }
  .supplement_more .sn_related_product {
    width: calc(33% - 28px);
    margin-bottom: 30px;
    margin-left: 15px;
    margin-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .supplement_more .supplement_more_related_products {
    flex-wrap: wrap;
  }
  .supplement_more .sn_related_product {
    width: 44%;
    margin-bottom: 20px;
  }
  .supplement_more .sn_related_product {
    width: 48%;
    margin-right: 0;
  }
  .supplement_more .supplement_more_related_products {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

@media (max-width: 575px) {
  .supplement_more .sn_related_product {
    width: 47%;
    margin-right: 0;
  }
  .supplement_more .sn_related_product:last-child {
    margin-right: auto;
  }
}

@media only screen and (max-width: 479px) {
  .supplement_more .sn_related_product {
    margin: 0 auto 20px;
    width: 100%;
  }
}

/*--------------------------------------------------------------
  ##  Supplement benifit
  --------------------------------------------------------------*/
p {
  margin-bottom: 0;
}

.supplement_hot_selling_group {
  padding-bottom: 220px;
  background: url(../../media/images/home6/group-bg.png);
  background-position: right top;
  background-size: inherit;
  background-repeat: no-repeat;
}

.supplement_hot_selling_group .supplement_we_say_content_slide {
  height: 526px !important;
}

.supplement_we_say_content {
  display: flex;
  position: relative;
}

.supplement_we_say_content:before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 80%;
  height: 100%;
  background: #f5f5f5;
}

.supplement_we_say_content .slider.supplement_we_say_content_for {
  width: calc(100% - 30%);
  padding-left: 30px;
  height: 100%;
  transform: translateY(80px);
}

.supplement_we_say_content .slider.supplement_we_say_content_for:after {
  position: absolute;
  content: "";
  left: 24px;
  top: 0;
  width: 6px;
  height: 145px;
  background: rgba(0, 0, 0, 0.3);
}

.supplement_we_say_content .slider.supplement_we_say_content_nav {
  width: 30%;
  height: 524px;
  overflow: hidden;
  padding-left: 60px;
  margin-bottom: 0;
  padding-top: 50px;
}

.supplement_we_say_content .supplement_we_say_slide-img {
  display: flex;
  align-items: center;
}

.supplement_we_say_content .supplement_we_say_slide-img img {
  border: 4px solid #fff;
}

.supplement_we_say_content .supplement_we_say_slide-img a {
  font-size: 16px;
  color: #D01BD0;
  font-weight: 600;
  letter-spacing: 1.2px;
  padding: 0;
  padding-left: 10px;
  transition: 0.3s;
  font-family: "Poppins", sans-serif;
}

.supplement_we_say_content .supplement_we_say_slide-img a:hover {
  color: #45D638;
}

.supplement_we_say_content .supplement_we_say_slide-img a:hover span {
  color: #121212;
}

.supplement_we_say_content .supplement_we_say_slide-img a span {
  display: block;
  font-size: 10px;
  font-weight: 500;
  color: #121212;
}

.supplement_we_say_content .supplement_we_say_slide {
  margin-right: 20px;
  padding-top: 25px;
  padding-bottom: 30px;
}

.supplement_we_say_content .supplement_we_say_slide.slick-active.slick-current {
  border-bottom: 1px solid #fff;
}

.supplement_we_say_content .slick-list {
  padding: 0 !important;
}

.supplement_we_say_slide_content {
  letter-spacing: 0.25px;
  color: #454545;
  font-weight: 300;
}

.supplement_what_we_say .section_title_four {
  text-align: center;
  margin-bottom: 80px;
}

.supplement_hot_selling {
  position: relative;
}

.supplement_hot_selling .section_title_four {
  position: absolute;
  top: -45px;
}

.supplement_hot_selling .supplement_hot_selling_content {
  margin-right: -15px;
}

.supplement_hot_selling:before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 51%;
  height: 100%;
  background: #f5f5f5;
}

.supplement_we_say_slide-author {
  width: 85px;
  height: 85px;
}

.supplement_hot_selling_slider:hover .owl-nav {
  opacity: 1;
}

.supplement_hot_selling_slider .owl-nav {
  position: absolute;
  left: 0;
  bottom: 50%;
  width: 100%;
  opacity: 0;
  transition: 0.5s;
}

.supplement_hot_selling_slider .owl-nav .owl-next {
  float: right;
  margin-right: -37px;
}

.supplement_hot_selling_slider .owl-nav .owl-prev {
  margin-left: -37px;
}

.supplement_hot_selling_slider .owl-nav .owl-next,
.supplement_hot_selling_slider .owl-nav .owl-prev {
  width: 75px;
  height: 75px;
  border: 3px solid #ededed !important;
  border-radius: 0px;
  text-align: center;
  line-height: 70px;
  background: #fff !important;
  transition: 0.3s;
}

.supplement_hot_selling_slider .owl-nav .owl-next:hover,
.supplement_hot_selling_slider .owl-nav .owl-prev:hover {
  border: 3px solid #000 !important;
}

.supplement_hot_selling_slider .owl-nav .owl-next:hover i,
.supplement_hot_selling_slider .owl-nav .owl-prev:hover i {
  color: #121212;
}

.supplement_hot_selling_slider .owl-nav .owl-next i,
.supplement_hot_selling_slider .owl-nav .owl-prev i {
  font-size: 30px;
  color: #dadada;
  line-height: 71px;
  transition: 0.3s;
}

.supplement_hot_selling_slider .owl-item img {
  display: block;
  width: auto;
  max-width: 100%;
}

.supplement_hot_selling_slider .owl-stage-outer {
  padding: 90px 0 97px;
  margin-right: -15px;
}

.supplement_what_we_say {
  padding-top: 110px;
}

.supplement_we_say_content_slide .youtube-wrapper .youtube-poster {
  height: 100%;
  border: none;
}

.supplement_we_say_content_slide .home5_video_right {
  height: 100%;
  width: 100%;
}

.supplement_we_say_content_slide .home5_video_right i {
  right: auto;
  left: 0;
  z-index: 9;
  bottom: 80px;
  background: #7474BA;
}

.supplement_we_say_slide-img {
  margin-bottom: 10px;
}

.supplement_hot_selling_slider .owl-item img {
  display: block;
  width: auto;
  max-width: 100%;
  margin: 0 auto;
}

@media (min-width: 1200px) and (max-width: 1499px) {
  .supplement_we_say_slide_content {
    font-size: 14px;
  }
  .supplement_we_say_content .slider.supplement_we_say_content_nav {
    padding-left: 40px;
    padding-top: 40px;
  }
  .supplement_we_say_content .slider.supplement_we_say_content_nav {
    width: 100%;
  }
  .supplement_we_say_content .supplement_we_say_slide {
    margin-right: 0;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .supplement_we_say_content .slider.supplement_we_say_content_nav {
    width: 50%;
  }
  .supplement_we_say_content .slider.supplement_we_say_content_for {
    width: 50%;
  }
  .supplement_hot_selling_slider .owl-nav .owl-next {
    margin-right: 0;
  }
  .supplement_hot_selling_slider .owl-nav .owl-prev {
    margin-left: 0;
  }
  .supplement_we_say_content_slide .youtube-wrapper .youtube-poster {
    background-position: 84%;
  }
  .supplement_what_we_say {
    padding-top: 100px;
  }
  .supplement_what_we_say .section_title_four {
    margin-bottom: 40px;
  }
  .supplement_what_we_say .section_title_four h2 {
    box-shadow: none;
  }
  .supplement_hot_selling_group {
    padding-bottom: 140px;
  }
  .supplement_we_say_content .slider.supplement_we_say_content_for {
    transform: translateY(0);
  }
  .supplement_hot_selling::before {
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .supplement_we_say_content .slider.supplement_we_say_content_for {
    width: 50%;
    -webkit-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px);
  }
  .supplement_we_say_content .slider.supplement_we_say_content_nav {
    width: 100%;
    padding-left: 30px;
  }
  .supplement_we_say_content_nav.slick-dotted.slick-slider {
    margin-bottom: 0;
  }
  .supplement_we_say_content_nav .slick-list {
    padding-top: 0 !important;
  }
  .supplement_we_say_content_slide .youtube-wrapper .youtube-poster {
    background-position: 84%;
  }
  .supplement_hot_selling_slider .owl-nav .owl-prev {
    margin-left: 0;
  }
  .supplement_hot_selling_slider .owl-nav .owl-next {
    margin-right: 0;
  }
  .supplement_hot_selling_group {
    padding-bottom: 130px;
  }
  .supplement_what_we_say {
    padding-top: 100px;
  }
  .supplement_what_we_say .section_title_four {
    text-align: left;
  }
  .supplement_hot_selling_slider .owl-stage-outer {
    margin-right: 0;
  }
  .supplement_hot_selling .supplement_hot_selling_content {
    margin-right: 0;
  }
  .supplement_what_we_say .section_title_four {
    margin-bottom: 64px;
  }
  .supplement_hot_selling::before {
    width: 100%;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .supplement_what_we_say {
    padding-top: 80px;
  }
  .supplement_hot_selling_slider .owl-nav .owl-next {
    margin-right: 0;
  }
  .supplement_hot_selling_slider .owl-nav .owl-prev {
    margin-left: 0;
  }
  .supplement_what_we_say .section_title_four {
    text-align: left;
  }
  .supplement_hot_selling::before {
    background: transparent;
  }
  .supplement_hot_selling_group {
    padding-bottom: 130px;
  }
}

@media only screen and (max-width: 767px) {
  .supplement_hot_selling_slider {
    margin: 0 auto;
  }
  .supplement_we_say_content .slider.supplement_we_say_content_nav {
    width: 100%;
    height: auto;
    padding-left: 0px;
  }
  .supplement_we_say_content::before {
    width: 100%;
  }
  .supplement_we_say_content {
    flex-wrap: wrap;
  }
  .supplement_we_say_content .slider.supplement_we_say_content_for {
    width: 100%;
    padding-left: 0;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  .supplement_we_say_content_nav .slick-list {
    padding-top: 0 !important;
  }
  .supplement_we_say_content .slider.supplement_we_say_content_nav {
    padding-left: 30px;
    padding-right: 30px;
    margin-bottom: 0;
  }
  .supplement_hot_selling_slider .owl-stage-outer {
    padding: 90px 0 0px;
    margin-right: 0;
  }
  .supplement_hot_selling .supplement_hot_selling_content {
    margin-right: 0;
  }
  .supplement_what_we_say .section_title_four {
    margin-bottom: 40px;
  }
  .supplement_we_say_content_slide .youtube-wrapper .youtube-poster {
    background-position: 84%;
  }
  .supplement_hot_selling_group-two .supplement_what_we_say {
    padding-top: 40px;
  }
}

@media only screen and (max-width: 575px) {
  .supplement_hot_selling .supplement_hot_selling_content {
    margin-right: 0;
  }
  .supplement_hot_selling_slider .owl-nav .owl-next i, .supplement_hot_selling_slider .owl-nav .owl-prev i {
    line-height: 43px;
  }
  .supplement_hot_selling_slider .owl-nav .owl-next, .supplement_hot_selling_slider .owl-nav .owl-prev {
    width: 45px;
    height: 45px;
    line-height: 45px;
  }
  .supplement_hot_selling_group {
    padding-bottom: 111px;
  }
  .supplement_hot_selling_slider .owl-nav .owl-prev {
    margin-left: 0;
  }
  .supplement_hot_selling_slider .owl-nav .owl-next {
    margin-right: 0;
  }
  .supplement_hot_selling::before {
    width: auto;
  }
  .supplement_we_say_content .supplement_we_say_slide-img {
    flex-wrap: wrap;
  }
  .supplement_we_say_content .supplement_we_say_slide-img a {
    padding-left: 0;
    padding-left: 10px;
  }
  .supplement_we_say_content .supplement_we_say_slide {
    margin-right: 0;
  }
  .supplement_hot_selling_group-two .supplement_what_we_say {
    padding-top: 60px;
  }
}

@media (max-width: 575px) {
  .supplement_what_we_say {
    padding-top: 80px;
  }
  .supplement_what_we_say .section_title_four {
    text-align: left;
  }
  .supplement_hot_selling_group {
    padding-bottom: 110px;
  }
  .supplement_hot_selling_slider .owl-stage-outer {
    padding: 63px 0 0px;
  }
}

@media (max-width: 358px) {
  .supplement_we_say_content .supplement_we_say_slide-img a {
    padding-left: 0;
    margin-top: 10px;
  }
}

/*--------------------------------------------------------------
  ##  Supplement benifit
  --------------------------------------------------------------*/
.supplement_hot_selling_group-two {
  background: transparent;
}

.supplement_hot_selling_group-two .supplement_what_we_say {
  background: transparent;
  padding-top: 74px;
}

@media (min-width: 576px) and (max-width: 767px) {
  .supplement_hot_selling_group-two .supplement_what_we_say {
    padding-top: 80px;
  }
  .supplement_we_say_content .supplement_we_say_slide-img a {
    padding-left: 10px;
  }
}

@media (max-width: 575px) {
  .ingredients_key_single_feature span {
    display: none;
  }
}

/*--------------------------------------------------------------
  ##  Supplement page top
  --------------------------------------------------------------*/
.supplement_page_top_left {
  position: relative;
}

.supplement_page_top_left:before {
  position: absolute;
  left: 44px;
  top: -27%;
  width: 27px;
  height: 86%;
  content: "";
  background: #45D638;
  transform: rotate(45deg);
  z-index: -1;
}

.supplement_page_top_left .section_title_four {
  position: absolute;
  left: 0;
  top: -42px;
}

.supplement_page_top_right {
  padding-left: 0px;
}

.supplement_page_top_right h3 {
  font-size: 24px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 30px;
  letter-spacing: 2.9px;
  text-align: left;
  color: #222;
  font-family: "Poppins", sans-serif;
  text-transform: inherit;
  margin-bottom: 48px;
}

.supplement_page_top_right p {
  font-size: 20px;
  font-size: 20px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #454545;
  padding-bottom: 52px;
  margin-bottom: 0;
}

.supplement_page_right_slider {
  padding: 90px 0px 0px;
}

.supplement_page_right_slider .owl-nav {
  padding-left: 0px;
  margin-left: 2px;
}

.supplement_page_right_slider .owl-prev,
.supplement_page_right_slider .owl-next {
  width: 40px;
  height: 40px;
  border: 3px solid #000 !important;
  display: inline-block;
  line-height: 32px;
  border-radius: 0;
}

.supplement_page_right_slider .owl-prev:hover i,
.supplement_page_right_slider .owl-next:hover i {
  color: #45D638;
}

.supplement_page_right_slider .owl-prev i,
.supplement_page_right_slider .owl-next i {
  line-height: 33px;
  font-size: 15px;
}

.supplement_page_right_slider .owl-next {
  margin-left: -3px;
}

.supplement_page_right_slider .supplement_page_top_left_img {
  padding-right: 50px;
}

.supplement_page_top {
  padding: 140px 0;
}

@media (min-width: 1200px) and (max-width: 1499px) {
  .supplement_page_right_slider {
    padding: 35px 0px 0px;
  }
  .home5_recent_view_cart a i {
    margin-right: 0;
  }
  .home5_recent_view_cart a {
    font-size: 10px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .section_title_four h2 {
    font-size: 40px;
  }
  .supplement_page_right_slider {
    padding: 0;
  }
  .supplement_page_right_slider .owl-nav {
    margin-left: 10px;
  }
  .supplement_page_top_left::before {
    left: 60px;
    top: -33%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .section_title_four h2 {
    font-size: 30px;
    padding: 13px 33px;
    box-shadow: -1px -1px 5px rgba(0, 0, 0, 0.05);
  }
  .supplement_page_right_slider {
    padding: 0;
  }
  .supplement_page_top_right h3 {
    font-size: 22px;
    letter-spacing: 2px;
  }
  .supplement_page_right_slider .owl-nav {
    margin-left: 12px;
  }
  .supplement_page_top {
    padding: 100px 0 100px;
  }
  .supplement_page_top_left:before {
    display: none;
  }
  .supplement_page_top_left .section_title_four {
    position: static;
  }
}

@media only screen and (max-width: 767px) {
  .supplement_page_top {
    padding: 124px 0 53px;
  }
  .supplement_page_right_slider {
    padding: 50px 0px 0px;
  }
  .supplement_page_right_slider .owl-nav {
    margin-left: 10px;
  }
  .supplement_page_top_left::before {
    display: none;
  }
}

/*--------------------------------------------------------------
  ## 404 Error content
  --------------------------------------------------------------*/
.error_cont_desc {
  text-align: center;
}

.error_cont_desc {
  text-align: center;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.error_cont_desc h1 {
  font-size: 150px;
  font-weight: 500;
  line-height: 150px;
  margin: 0;
}

.error_cont_desc h1 span {
  color: #f99a00;
}

.error_cont_desc p {
  margin-bottom: 40px;
  font-size: 20px;
}

.error_content_area.section_padding {
  padding: 112px 0;
}

/*--------------------------------------------------------------
  ## 404 Error content2
  --------------------------------------------------------------*/
.error_content_two p, .error_content_two h1, .error_content_two h2, .error_content_two h3, .error_content_two h4, .error_content_two h5, .error_content_two h6, .error_content_two a {
  font-family: "Roboto", sans-serif;
}

.error_content_two .error_cont_desc h1 span {
  color: #3734f0;
}

.error_content_two .accordion_btn {
  padding-left: 0;
}

.error_content_area.section_padding {
  padding: 112px 0;
}

/*--------------------------------------------------------------
  ##  Contact page
  --------------------------------------------------------------*/
.sn_contact_feature {
  width: 33%;
  text-align: center;
  display: inline-block;
  margin-bottom: 60px;
  padding: 0 3.89rem;
}

.sn_contact_icon {
  margin-bottom: 1.12rem;
}

.sn_contact_icon i {
  font-size: 35px;
  color: #f99a00;
  display: inline-block;
  line-height: 35px;
}

.sn_contact_content h6 {
  font-weight: 600;
  color: #505253;
}

.sn_contact_content a {
  display: block;
  padding: 0;
  color: #666;
}

.contact_page_form {
  padding-bottom: 120px;
}

.contact_page_form .form_group input,
.contact_page_form .form_group textarea {
  border: 1px solid #e4ecf3;
}

.contact_form .btn_one {
  padding: 0;
}

.contact_content_area .form_group {
  margin-bottom: 2rem;
}

.contact_content_area .form_group:last-child {
  margin-bottom: 0;
}

.contact_content_area.section_padding {
  padding-bottom: 0;
}

.contact_content_area .map_main {
  position: static;
  width: 100%;
  height: 500px;
  padding-right: 0;
}

.contact_form .btn_one {
  cursor: pointer;
}

/*--------------------------------------------------------------
  ##  Contact page2
  --------------------------------------------------------------*/
.contact_content_two p, .contact_content_two h1, .contact_content_two h2, .contact_content_two h3, .contact_content_two h4, .contact_content_two h5, .contact_content_two h6, .contact_content_two a {
  font-family: "Roboto", sans-serif;
}

.contact_content_two.section_padding_two {
  padding-bottom: 0;
}

.contact_content_two .sn_contact_icon i {
  color: #3734f0;
}

.contact_content_two .sn_contact_content p {
  color: #9e9c9c;
}

.contact_content_two .sn_contact_content a {
  color: #9e9c9c;
}

.contact_content_two .form_group input[required] + label::after {
  color: #3734f0;
}

.contact_content_two .btn_one, .contact_content_two .btn_two {
  border: 2px solid #121212;
  color: #121212;
}

.contact_content_two .btn_two:hover {
  color: #ffffff;
  border-color: #3734f0;
}

.contact_content_two .btn_two::before {
  background: #3734f0;
}

.contact_content_two .btn_two:hover:before {
  background: #3734f0;
}

.contact_content_two .form_group input {
  height: 60px;
  padding: 0 30px;
  font-size: 1rem;
}

.contact_content_two .form_group input[required] + label {
  left: 30px;
  top: 1.1rem;
  font-size: 1rem;
}

.contact_content_two .form_group.half:nth-child(2) input[required] + label {
  left: 43px;
  font-size: 1rem;
}

.contact_content_two .form_group textarea {
  height: 13rem;
  border: none;
  padding: 15px 30px;
  background: #fff;
  resize: none;
  font-size: 1rem;
  color: #9f9f9f;
  border: 1px solid #e4ecf3;
}

/*--------------------------------------------------------------
  ##  Footer 4
  --------------------------------------------------------------*/
.footer_four_area {
  background: url(../../media/images/home3/footer-bg.jpg) no-repeat scroll 0 0/cover;
  position: relative;
  overflow: hidden;
  padding: 68px 0;
}

.footer_four_area a {
  padding: 0;
}

.footer_four_area a, .footer_four_area h1, .footer_four_area h2, .footer_four_area h3, .footer_four_area h4, .footer_four_area h5, .footer_four_area h6, .footer_four_area p {
  font-family: "Roboto", sans-serif;
  margin: 0;
  padding: 0;
  letter-spacing: 0.25px;
}

.footer_four_area ul, .footer_four_area li {
  padding: 0;
  margin: 0;
}

.footer_four_area .footer_four_top {
  border-bottom: 1px solid #2d2d2d;
  padding-bottom: 30px;
}

.footer_four_area .footer_four_left {
  float: left;
}

.footer_four_area .footer_four_left a {
  margin-top: 6px;
}

.footer_four_area .footer_four_right {
  float: right;
}

.footer_four_area .footer_four_right p {
  display: inline-block;
  font-size: 14px;
  color: #ffffff;
  letter-spacing: 1.5px;
  margin-right: 18px;
}

.footer_four_area .footer_four_right a {
  width: 42px;
  height: 42px;
  background: #ffffff;
  text-align: center;
  line-height: 42px;
  border-radius: 50%;
  margin-right: 4px;
}

.footer_four_area .footer_four_right a:hover {
  background: #3db5e6;
}

.footer_four_area .footer_four_right a:hover i {
  color: #ffffff;
}

.footer_four_area .footer_four_right a i {
  color: #121212;
  font-size: 14px;
  transition: 0.3s;
}

.footer_four_area .footer_four_middle {
  padding: 41px 0 37px;
  border-bottom: 1px solid #2d2d2d;
}

.footer_four_area .footer_four_middle .widget.widget4 {
  width: 20.4%;
  float: left;
  margin-bottom: 0;
  padding-right: 0;
}

.footer_four_area .footer_four_middle .widget.widget4 .widget4_title h3 {
  font-size: 20px;
  letter-spacing: 2.2px;
  font-weight: 300;
  color: #3db5e6;
  border-bottom: 0;
  margin-bottom: 0;
  padding-bottom: 25px;
}

.footer_four_area .footer_four_middle .widget.widget4 .widget4_title h3:after {
  display: none;
}

.footer_four_area .footer_four_middle .widget.widget4 .widget4_desc a {
  display: block;
  font-size: 16px;
  color: #fff;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.25px;
}

.footer_four_area .footer_four_middle .widget.widget4 .widget4_desc a:hover i {
  color: #3db5e6;
}

.footer_four_area .footer_four_middle .widget.widget4 .widget4_desc a i {
  margin-right: 6px;
}

.footer_four_area .footer_four_middle .widget.widget4 .widget4_desc .widget4_brand a {
  display: inline-block;
  margin-left: 6px;
}

.footer_four_area .footer_four_middle .widget.widget4 .widget4_address a {
  font-size: 20px;
  font-weight: 700;
  color: #3db5e6;
  letter-spacing: 2.2px;
  margin-bottom: 15px;
}

.footer_four_area .footer_four_middle .widget.widget4 .widget4_address p {
  margin-bottom: 32px;
}

.footer_four_area .footer_four_middle .widget.widget4 .widget4_address p span {
  display: block;
  color: #fff;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0.4px;
}

.footer_four_area .footer_four_middle .widget.widget4:last-child {
  width: calc(59.6% - 9px);
  text-align: right;
}

.footer_four_area .footer_four_bottom_left {
  float: left;
}

.footer_four_area .footer_four_bottom_left:hover a {
  color: #3db5e6;
}

.footer_four_area .footer_four_bottom_left p {
  font-size: 14px;
  color: #717171;
  letter-spacing: 0.7px;
}

.footer_four_area .footer_four_bottom_left p a {
  color: #fff;
}

.footer_four_area .footer_four_bottom {
  padding-top: 35px;
}

.footer_four_area .footer_four_bottom_right {
  float: right;
  padding-right: 73px;
  position: relative;
}

.footer_four_area .footer_four_bottom_right ul li {
  display: inline-block;
}

.footer_four_area .footer_four_bottom_right ul li a {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  padding: 0 10px;
  position: relative;
  letter-spacing: 0.5px;
}

.footer_four_area .footer_four_bottom_right ul li a:hover {
  color: #3db5e6;
}

.footer_four_area .footer_four_bottom_right ul li a:after {
  position: absolute;
  left: 100%;
  top: 0;
  content: "/";
}

.footer_four_area .footer_four_bottom_right ul li:last-child a:after {
  display: none;
}

.backtotop.backtotop_three {
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: pointer;
  transition: 0.3s;
}

.backtotop.backtotop_three:hover {
  bottom: 10px;
}

.backtotop.backtotop_three i {
  font-size: 16px;
  color: #fff;
}

@media (min-width: 768px) and (max-width: 991px) {
  .footer_four_area .footer_four_bottom_right {
    padding-right: 0;
  }
  .footer_four_area .footer_four_middle .widget.widget4 {
    width: 27%;
  }
  .footer_four_area .footer_four_middle .widget.widget4:last-child {
    width: 42%;
  }
  .footer_four_area .footer_four_middle .widget.widget4 .widget4_desc .widget4_brand a {
    margin-bottom: 4px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .footer_four_area .footer_four_middle .widget.widget4 {
    width: 50%;
  }
  .footer_four_area .footer_four_middle .widget.widget4:last-child {
    margin-top: 50px;
  }
  .footer_four_area .footer_four_middle .widget.widget4:last-child {
    text-align: left;
    width: calc(80% - 9px);
  }
  .footer_four_area .footer_four_bottom_right {
    float: left;
    padding-right: 0;
    width: 100%;
    position: relative;
  }
  .footer_four_area .footer_four_bottom {
    text-align: left;
  }
  .footer_four_area .footer_four_right p {
    display: block;
  }
  .footer_four_area {
    padding: 70px 0;
  }
  .footer_four_area .footer_four_middle {
    padding: 41px 0 80px;
  }
}

@media (min-width: 480px) and (max-width: 575px) {
  .footer_four_area .footer_four_right p {
    display: inline-block;
  }
  .footer_four_area .footer_four_right {
    float: right;
  }
}

@media only screen and (max-width: 575px) {
  .footer_four_area .footer_four_middle .widget.widget4:last-child {
    width: 100%;
    text-align: left;
    margin-bottom: 0;
  }
  .footer_four_area .footer_four_middle .widget.widget4 {
    width: 100%;
    margin-bottom: 60px;
  }
  .footer_four_area .footer_four_bottom_right {
    float: left;
    padding-right: 0;
    position: relative;
    width: 100%;
  }
  .footer_four_area .footer_four_bottom_right ul li:first-child a {
    padding-left: 0;
  }
  .footer_four_area .footer_four_right p {
    display: block;
  }
  .footer_four_area .footer_four_middle .widget.widget4 .widget4_desc .widget4_brand a {
    margin-bottom: 5px;
  }
  .footer_four_area .footer_four_middle {
    padding: 41px 0 74px;
  }
  .footer_four_area {
    padding: 74px 0 30px;
  }
  .footer_four_area .footer_four_bottom {
    padding-top: 0;
  }
}

@media (min-width: 480px) and (max-width: 575px) {
  .footer_four_area .footer_four_right {
    float: right;
    margin-top: 0px;
    width: auto;
  }
}

@media only screen and (max-width: 479px) {
  .footer_four_area .footer_four_right {
    float: left;
    margin-top: 14px;
    width: 100%;
  }
  .footer_four_area .footer_four_right p {
    margin-bottom: 10px;
  }
}

/*--------------------------------------------------------------
  ##  Footer 4
  --------------------------------------------------------------*/
.footer_five_area {
  background: url(../../media/images/home6/footer-bg.png) no-repeat scroll 0 0/cover;
  position: relative;
  z-index: 1;
}

.footer_five_area .widget.widget5 {
  width: 17%;
  margin-bottom: 0;
  padding-right: 0;
}

.footer_five_area .widget.widget5:last-child {
  width: 32%;
}

.footer_five_area .widget.widget5 .widget_gmail {
  padding: 0;
  margin-top: 20px;
  color: #7474BA;
  font-size: 16px;
}

.footer_five_area .widget.widget5 .widget_gmail i {
  margin-right: 5px;
  color: #fff;
  font-size: 13px;
}

.footer_five_area .widget.widget5 .widget_gmail:hover {
  color: #fff;
}

.footer_five_area .widget.widget5 .widget5_about {
  padding-right: 80px;
  margin-top: -11px;
}

.footer_five_area .widget.widget5 .widget5_about p {
  font-weight: 300;
  line-height: 28px;
  letter-spacing: 0.4px;
  text-align: left;
  color: #b0b0b0;
}

.footer_five_area .widget.widget5 .widget5_about p span {
  color: #fff;
}

.footer_five_area .widget.widget5 .widget5_about p b {
  font-weight: normal;
}

.footer_five_area .widget.widget5 .widget-logo {
  margin-bottom: 37px;
}

.footer_five_area .widget.widget5 .widget-logo a {
  padding: 0;
  width: 35%;
}

.footer_five_area .footer_five_top_flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-line-pack: justify;
  justify-content: space-between;
}

.footer_five_area .footer_five_top_flex .footer_five_top_left a {
  padding: 0;
  line-height: 45px;
}

.footer_five_area .footer_five_top_flex .footer_five_top_right label {
  height: 50px;
  background: #353535;
  line-height: 50px;
  padding: 0px 20px;
  position: relative;
  margin-bottom: 0;
  vertical-align: middle;
  padding-right: 46px;
  z-index: 1;
}

.footer_five_area .footer_five_top_flex .footer_five_top_right label:after {
  position: absolute;
  content: "";
  left: 0px;
  width: 50px;
  height: 50px;
  background: #353535;
  top: 0;
  -webkit-transform: skewX(-45deg);
  -ms-transform: skewX(-45deg);
  transform: skewX(-45deg);
  -webkit-transform-origin: top right;
  -ms-transform-origin: top right;
  transform-origin: top right;
  z-index: -1;
}

.footer_five_area .footer_five_top_flex .footer_five_top_right label i {
  font-size: 10px;
  color: #7474BA;
  margin-right: 10px;
}

.footer_five_area .footer_five_top_flex .footer_five_top_right label span {
  font-size: 14px;
  color: #ffffff;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1.7px;
}

.footer_five_area .footer_five_top_flex .footer_five_top_right input {
  height: 50px;
  border: none;
  border-radius: 0;
  padding: 0px 40px;
  margin-left: -4px;
  background: #2b2b2b;
  font-size: 14px;
  font-weight: 300;
  color: #ffffff;
  vertical-align: middle;
  width: 356px;
  letter-spacing: 0.25px;
}

.footer_five_area .footer_five_top_flex .footer_five_top_right button {
  width: 50px;
  height: 50px;
  vertical-align: middle;
  border-radius: 0;
  background: #ffffff;
  border: none;
  margin-left: -3px;
  color: #7474BA;
  font-size: 20px;
  padding: 0;
  cursor: pointer;
  transition: 0.3s;
}

.footer_five_area .footer_five_top_flex .footer_five_top_right button:focus, .footer_five_area .footer_five_top_flex .footer_five_top_right button:hover {
  background: #45D638;
  color: #fff;
}

.footer_five_area .footer_five_top_flex .footer_five_top_right button i {
  font-size: 20px;
  line-height: 50px;
  margin-right: -3px;
}

.footer_five_area .footer_five_top_flex .footer_five_top_left h3 {
  font-size: 32px;
  margin-bottom: 0;
  color: #fff;
  line-height: 50px;
  text-transform: capitalize;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  font-family: "Poppins", sans-serif;
}

.footer_five_area .footer_five_top_flex .footer_five_top_left h3 span {
  color: #45D638;
}

.footer_five_area .footer_five_bottom_flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0px;
  position: relative;
  border-top: 1px solid #313131;
}

.footer_five_area .footer_five_bottom_flex .backtotop {
  position: absolute;
  right: 0;
  top: 53%;
  cursor: pointer;
  transform: translateY(-50%);
  line-height: normal;
}

.footer_five_area .footer_five_bottom_flex .backtotop i {
  color: #fff;
}

.footer_five_area .footer_four_bottom_left p {
  margin-bottom: 0;
  padding: 0;
  font-size: 14px;
  color: #b0b0b0;
  letter-spacing: 0.4px;
}

.footer_five_area .footer_four_bottom_left p:hover a {
  color: #45D638;
}

.footer_five_area .footer_four_bottom_left p a {
  padding: 0;
  font-size: 14px;
  color: #d6d6d6;
  font-weight: 500;
}

.footer_five_area .footer_four_bottom_right {
  margin-right: 30px;
}

.footer_five_area .footer_four_bottom_right a {
  padding: 0px 9px;
  transition: 0.3s;
}

.footer_five_area .footer_four_bottom_right a img {
  opacity: 0.3;
}

.footer_five_area .footer_four_bottom_right a:hover img {
  opacity: 0.7;
}

.footer_five_area .footer_five_middle_flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.footer_five_area .widget5_social {
  margin-top: 32px;
}

.footer_five_area .widget5_social a {
  width: 37px;
  height: 37px;
  padding: 0;
  line-height: 35px;
  background: #fff;
  border-radius: 50%;
  text-align: center;
  background: #353535;
  margin-right: 10px;
}

.footer_five_area .widget5_social a i {
  font-size: 10px;
  color: #fff;
}

.footer_five_area .widget5_social a:hover {
  background: #45D638;
}

.footer_five_area .widget5_title h3 {
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 20px;
  letter-spacing: 2.4px;
  text-align: left;
  color: #45D638;
  border-bottom: 0px;
  margin-bottom: 14px;
  transition: 0.3s;
}

.footer_five_area .widget5_title h3:after {
  display: none;
}

.footer_five_area .widget5_desc a {
  padding: 0;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.75;
  letter-spacing: 0.4px;
  text-align: left;
  color: #9b9b9b;
  display: block;
  transition: 0.3s;
  margin-bottom: 7px;
}

.footer_five_area .widget5_desc a:last-child {
  margin-bottom: 0;
}

.footer_five_area .widget5_desc a i {
  transition: 0.3s;
}

.footer_five_area .widget5_desc a:hover {
  color: #fff;
  margin-left: 6px;
}

.footer_five_area .widget5_desc a:hover i {
  color: #45D638;
}

#twitter_feed {
  position: relative;
  padding-top: 0px;
}

#twitter_feed ul {
  margin-bottom: 0;
}

#twitter_feed .user {
  padding-left: 0;
}

#twitter_feed .user a, #twitter_feed .user div {
  padding: 0;
  display: inline-block;
  vertical-align: top;
  color: #fff;
  margin-bottom: 10px;
  font-size: 16px;
  line-height: normal;
}

#twitter_feed .user > div {
  padding-left: 16px;
}

#twitter_feed .user > a {
  width: 42px;
  height: 42px;
  margin-top: 5px;
}

#twitter_feed .tweet {
  color: #9b9b9b;
  font-size: 16px;
  position: relative;
  left: 62px;
  top: -27px;
  margin-right: 62px;
}

#twitter_feed .tweet a {
  color: #7474BA;
  padding: 0;
  display: block;
  word-wrap: break-word;
}

#twitter_feed .interact {
  display: none;
}

.widget.widget5:hover .widget5_title h3 {
  color: #45D638;
}

#twitter_feed li {
  margin-bottom: 0px;
  position: relative;
}

.footer_five_top {
  padding: 77px 0;
  position: relative;
  z-index: 1;
}

.footer_five_top:before {
  position: absolute;
  content: "";
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.15);
  z-index: -2;
}

.footer_five_top:after {
  position: absolute;
  content: "";
  left: -104px;
  top: 0;
  width: calc(38% + 104px);
  height: 100%;
  background: rgba(0, 0, 0, 0.15);
  transform: skewX(-45deg);
  z-index: -1;
}

.footer_five_middle {
  padding: 90px 0 60px;
}

@media (min-width: 1500px) and (max-width: 1600px) {
  .footer_five_area .footer_five_top_flex .footer_five_top_left h3 {
    font-size: 23px;
  }
  .home_five_single_service {
    width: 31.6%;
  }
}

@media (min-width: 1200px) and (max-width: 1500px) {
  .footer_five_area .widget5_desc a {
    margin-bottom: 10px;
  }
  .footer_five_area .footer_five_top .footer_five_top_right input {
    width: 266px;
  }
  .footer_five_area .footer_five_top_flex {
    justify-content: center;
  }
  .footer_five_top_left {
    display: none;
  }
  .footer_five_area .widget5_title h3 {
    font-size: 17px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .footer_five_top_right {
    text-align: right;
  }
  .footer_five_area .footer_five_top .footer_five_top_right input {
    width: 245px;
  }
  .footer_five_area .footer_five_bottom .backtotop {
    top: 52%;
  }
  .footer_five_area .footer_five_top_flex {
    justify-content: center;
  }
  .footer_five_top_left {
    display: none;
  }
  .footer_five_area .footer_five_top_flex .footer_five_top_right label::after {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .footer_five_area .footer_five_top_flex .footer_five_top_left h3 {
    font-size: 25px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .footer_five_top_right {
    width: 100%;
  }
  .footer_five_area .footer_five_middle_flex {
    flex-wrap: wrap;
  }
  .footer_five_area .footer_five_bottom_flex {
    flex-wrap: wrap-reverse;
  }
}

@media only screen and (max-width: 767px) {
  .footer_five_area .footer_five_top .footer_five_top_right label::after {
    display: none;
  }
  .footer_five_area .footer_five_top .footer_five_top_right input {
    margin-left: 0px;
    width: calc(100% - 52px);
  }
  .footer_five_top_left {
    margin-bottom: 20px;
  }
  .backtotop {
    margin-bottom: 13px;
  }
  .footer_five_area .footer_four_bottom_right a {
    padding: 0px 10px;
    margin-bottom: 5px;
  }
  .footer_four_bottom_left {
    margin-top: 16px;
  }
  .footer_five_top_right label {
    display: none;
  }
}

@media (max-width: 575px) {
  .footer_five_area .footer_five_bottom {
    padding: 50px 0px 40px;
  }
  .footer_five_top::after {
    display: none;
  }
  .footer_five_area .footer_five_top_flex .footer_five_top_left h3 {
    font-size: 23px;
    line-height: normal;
  }
}

/*--------------------------------------------------------------
  ##  Blog page
  --------------------------------------------------------------*/
.sin_post {
  margin-top: 0;
  border-bottom: 1px solid #f1f1f1;
  padding-bottom: 50px;
  margin-bottom: 60px;
}

.sin_post h3 {
  margin-bottom: 10px;
}

.sin_post h3 a {
  color: #383838;
  padding: 0;
}

.sin_post h3 a:hover {
  color: #f99a00;
}

.sin_post .post_image {
  border: 3px solid #ffffff;
  box-shadow: 0px 0px 7.28px 0.72px rgba(72, 72, 72, 0.14);
  margin-bottom: 1.7rem;
}

.sin_post .post_image a {
  padding: 0;
}

.sin_post .post_image img {
  margin-bottom: 0;
}

.sin_post a {
  color: #f99a00;
  padding: 0;
}

.sin_post p strong {
  font-weight: 500;
  color: #5c5b5b;
  font-style: italic;
  padding: 10px 0;
  display: block;
}

.sin_post h6 {
  font-size: 1rem;
  color: #383838;
  margin-top: 30px;
}

.sin_post blockquote {
  text-align: center;
  padding: 30px 50px;
  text-transform: uppercase;
}

.sin_post blockquote p {
  font-weight: 300;
  color: #6b6b6b;
}

.sin_post blockquote p i {
  padding: 0 15px;
  color: #f99a00;
  position: relative;
  top: 12px;
}

.sin_post blockquote p i:first-child {
  top: -10px;
}

.page_content {
  padding: 7.5rem 0;
}

.post_meta {
  margin-bottom: 2rem;
}

.post_meta p a {
  font-size: 14px;
  color: #848383;
  text-transform: capitalize;
  font-weight: 500;
  padding: 0 0.89rem;
  border-radius: 0;
}

.post_meta p a:first-child {
  padding-left: 0;
  position: relative;
}

.post_meta p a:first-child:after {
  position: absolute;
  content: "|";
  right: -3px;
  font-size: .9rem;
  font-weight: 300;
  top: -1px;
}

.post_meta p a:nth-child(2) {
  position: relative;
}

.post_meta p a:nth-child(2):after {
  position: absolute;
  content: "|";
  right: -3px;
  font-size: .9rem;
  font-weight: 300;
  top: -1px;
}

.post_meta p a span.admin {
  color: #f99a00;
}

.post_meta a {
  font-size: 0.89rem;
  color: #f99a00;
  text-transform: uppercase;
  padding: 0;
  font-weight: 600;
}

.post_meta a.continue {
  margin-top: 0.56rem;
}

.pagination ul {
  padding-left: 0;
  margin-bottom: 0;
}

.pagination ul li {
  display: inline-block;
  margin-right: 30px;
}

.pagination ul li a {
  font-size: 14px;
  color: #aeadad;
  font-weight: 500;
  padding: 3px 8px;
}

.pagination ul li a:hover {
  color: #f99a00;
}

.pagination ul li a.active {
  color: #f99a00;
}

.pagination ul li:first-child {
  border: 1px solid #ededed;
  padding: 5px 25px;
  border-radius: 5px;
}

.pagination ul li:first-child a {
  color: #f99a00;
}

.pagination ul li:first-child:hover {
  background: #f99a00;
  border: 1px solid #f99a00;
}

.pagination ul li:first-child:hover a {
  color: #fff;
}

.pagination ul li:last-child {
  border: 1px solid #ededed;
  padding: 5px 25px;
  border-radius: 5px;
}

.pagination ul li:last-child a {
  color: #f99a00;
}

.pagination ul li:last-child:hover {
  background: #f99a00;
  border: 1px solid #f99a00;
}

.pagination ul li:last-child:hover a {
  color: #fff;
}

.widget h3 {
  font-size: 1rem;
  width: 100%;
  display: block;
  padding-bottom: 18px;
  border-bottom: 1px solid #e3eaef;
  color: #383838;
  position: relative;
  margin-bottom: 35px;
}

.widget h3 span {
  color: inherit;
}

.widget h3:after {
  content: '';
  position: absolute;
  left: 9px;
  top: 38px;
  background: #f99a00;
  width: 40px;
  height: 5px;
}

.search-submit {
  border: none;
  background: no-repeat;
  font-size: 19px;
  padding: 0;
  float: right;
  margin-top: 5px;
  padding: 0 12px;
  color: #f99a00;
}

.search-field {
  border: none;
  width: 87%;
  font-size: 14px;
}

.search-form {
  border-bottom: 1px dotted #ddd;
}

.widget {
  padding-right: 1.33rem;
  margin-bottom: 4.2rem;
}

.widget ul {
  padding: 0;
}

.widget_single_post {
  display: block;
}

.widget_single_post .post_thumb {
  float: left;
  width: 100px;
  float: left;
  width: 25%;
}

.widget_single_post .wid_post_content {
  width: 75%;
  overflow: hidden;
  padding-left: 1rem;
  padding-top: 4px;
}

.widget_single_post .wid_post_content h6 {
  font-size: 14px;
}

.widget_single_post .wid_post_content h6 a {
  color: #383838;
  padding: 0;
}

.widget_single_post .wid_post_content h6 a:hover {
  color: #f99a00;
}

.widget_single_post .wid_post_content .post_att {
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
}

.widget_single_post .wid_post_content .post_att i {
  color: #f99a00;
}

.widget_single_post .wid_post_content .post_att span {
  color: #f99a00;
}

.widget_post h3 {
  margin-bottom: 40px;
}

.widget_post ul li {
  overflow: hidden;
  border-top: 1px dotted #ddd;
  border-bottom: 1px dotted #ddd;
  padding: 22px 0px;
}

.widget_post ul li:first-child {
  border-top: none;
  padding-top: 0;
  border-bottom: none;
}

.widget_post ul li:last-child {
  border-top: none;
  padding-bottom: 0;
  border-bottom: none;
}

.widget_category h3 {
  margin-bottom: 35px;
}

.widget_category ul {
  margin-bottom: 0;
}

.widget_category ul li {
  width: 100%;
  border-bottom: 1px dotted #ddd;
  padding: 10px 0;
}

.widget_category ul li a {
  font-size: 16px;
  color: #383838;
  font-weight: 500;
  padding: 0;
}

.widget_category ul li a:hover {
  color: #f99a00;
}

.widget_category ul li span {
  float: right;
  font-size: 16px;
  color: #f99a00;
  padding: 0;
}

.widget_category ul li:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.widget_category ul li:first-child {
  padding-top: 0;
}

.widget_archive ul {
  margin-bottom: 0;
}

.widget_archive ul li {
  width: 100%;
  border-bottom: 1px dotted #ddd;
  padding: 10px 0;
}

.widget_archive ul li a {
  font-size: 16px;
  color: #383838;
  font-weight: 500;
  padding: 0;
}

.widget_archive ul li a:hover {
  color: #f99a00;
}

.widget_archive ul li span {
  float: right;
  font-size: 16px;
  color: #f99a00;
  padding: 0px;
}

.widget_archive ul li:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.widget_archive ul li:first-child {
  padding-top: 0;
}

.widget_tags h3 {
  margin-bottom: 40px;
}

.widget_tags ul li {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
}

.widget_tags ul li a {
  font-size: 16px;
  color: #737373;
  font-weight: 500;
  border: 1px solid #ddd;
  border-radius: 0;
  padding: 0px 11px;
  text-transform: uppercase;
  font-family: "Hind", sans-serif;
  line-height: 45px;
  height: 45px;
  transition: all 0.3s;
}

.widget_tags ul li a:hover {
  color: #f99a00;
  border: 1px solid #f99a00;
}

.widget_newsletter {
  text-align: left;
}

.widget_newsletter input[type="text"] {
  border: 1px solid #f1f1f1;
  background: #fbfbfb;
  color: #b4b4b4;
  width: 100%;
  height: 47px;
  padding-left: 10px;
}

.widget_newsletter input[type="submit"] {
  border: none;
  border-radius: 0;
  cursor: pointer;
  color: #fff;
  font-size: 16px;
}

.widget_newsletter .sidebar_newsletter {
  text-align: left;
  border: 1px solid #ddd;
  border-top: none;
  padding: 40px 25px;
}

.widget_newsletter .sidebar_newsletter .btn_wrapper {
  margin: 22px auto 0;
}

.widget_newsletter .sidebar_newsletter p {
  font-size: 1rem;
  color: #666;
  margin-top: 23px;
}

.widget.widget_newsletter h3 {
  font-size: 1rem;
  width: 100%;
  display: block;
  padding-bottom: 18px;
  border-bottom: 1px solid #e3eaef;
  color: #383838;
  position: relative;
  margin-bottom: 0;
}

/*--------------------------------------------------------------
  ##  Blog details
  --------------------------------------------------------------*/
.side_bar {
  padding-left: 20px;
}

.side_bar .widget {
  background: #f8f8f8;
  padding: 30px;
}

.side_bar .widget ul {
  margin-bottom: 0;
}

.side_bar .widget.widget_category {
  padding-bottom: 25px;
}

.side_bar .widget.widget_archive {
  padding-bottom: 25px;
}

.side_bar .widget.widget_tags {
  padding-bottom: 20px;
}

.comments-title {
  font-size: 24px;
  color: #383838;
  position: relative;
  width: 100%;
  padding-bottom: 8px;
  border-bottom: 1px solid #ddd;
  margin-bottom: 100px;
}

.comments-title::after {
  content: '';
  position: absolute;
  left: 9px;
  top: 38px;
  background: #f99a00;
  width: 40px;
  height: 5px;
  z-index: 99;
}

.sin_post_tag span {
  float: left;
  font-weight: 500;
}

.sin_post_tag span i {
  color: #f99a00;
}

.sin_post_tag ul {
  display: block;
  padding: 0px;
  overflow: hidden;
}

.sin_post_tag ul li {
  display: inline;
  margin-left: 22px;
}

.sin_post_tag ul li a {
  padding: 0;
  color: #6b6b6b;
  font-size: 16px;
}

.comment-list, .comment-list {
  list-style: outside none none;
}

.comment-list {
  margin-bottom: 25px;
  padding-bottom: 25px;
  padding-left: 0px;
}

.comment-list div.avatar {
  float: left;
  margin-right: 17px;
  overflow: hidden;
  display: block;
  border-radius: 50px;
}

.comment-list .comment-body {
  overflow: hidden;
  position: relative;
}

.children-comment {
  padding-left: 70px;
}

.com-name {
  color: #7b7b7b;
  font-size: 12px;
  font-weight: normal;
  margin-bottom: 0;
}

.comment-content {
  font-weight: 400;
}

.comment-content p {
  color: #9e9c9c;
  font-size: 16px;
  padding-top: 15px;
  line-height: 28px;
}

.comment-author b a {
  font-size: 16px;
  padding: 0;
  color: #383838;
  padding-bottom: 5px;
  text-transform: uppercase;
}

.comment-author img {
  float: left;
  border: 2px solid #fff;
  -webkit-box-shadow: 0px 0px 7.28px 0.72px rgba(72, 72, 72, 0.14);
  box-shadow: 0px 0px 7.28px 0.72px rgba(72, 72, 72, 0.14);
  margin-right: 25px;
}

.comment-metadata {
  line-height: 0;
}

.comment-metadata a {
  padding: 0;
  line-height: 0;
  color: #6d6d6d;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.comment-metadata span {
  color: #f99a00;
}

.comment-body .reply i {
  margin-right: 5px;
  font-size: 14px;
}

.children {
  padding-left: 100px;
}

.comment-reply-title {
  font-size: 1.34rem;
  position: relative;
  padding-bottom: 8px;
  border-bottom: 1px solid #ddd;
  margin-bottom: 100px;
}

.comment-reply-title:after {
  content: '';
  position: absolute;
  left: 9px;
  top: 35px;
  background: #f99a00;
  width: 40px;
  height: 5px;
  z-index: 99;
}

.comment-form .form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #d0d0d0;
  outline: 0;
  box-shadow: none;
}

.comment-form .form-control {
  padding: 10px 15px;
  border-color: #d0d0d0;
  border-radius: 3px;
}

.form-control::placeholder {
  color: #c6c6c6;
  font-size: 14px;
  font-weight: 300;
}

.sin_post_tag {
  margin-bottom: 90px;
}

.post_details {
  margin-bottom: 20px;
}

.widget_search .search-field {
  background: transparent;
}

p.form-submit {
  margin-bottom: 0;
}

.page_content_two.section_padding_two {
  padding: 115px 0;
}

.comment-form .form-control {
  resize: none;
}

.side_bar .widget:last-child {
  margin-bottom: 0;
}

.wid_post_content h6 {
  text-transform: capitalize;
  font-weight: 600;
}

/*--------------------------------------------------------------
  ##  Blog page2
  --------------------------------------------------------------*/
.page_content_two .comment-body .reply a {
  color: #363636;
}

.page_content_two p, .page_content_two h1, .page_content_two h2, .page_content_two h3, .page_content_two h4, .page_content_two h5, .page_content_two h6, .page_content_two a {
  font-family: "Roboto", sans-serif;
  letter-spacing: 0.25px;
}

.page_content_two .sin_post h3 a:hover,
.page_content_two .post_meta p a span.admin,
.page_content_two .pagination ul li:first-child a,
.page_content_two .pagination ul li a.active,
.page_content_two .pagination ul li:last-child a,
.page_content_two .pagination ul li a:hover,
.page_content_two .search-submit,
.page_content_two .widget_single_post .wid_post_content .post_att i,
.page_content_two .widget_single_post .wid_post_content .post_att span,
.page_content_two .widget_category ul li span,
.page_content_two .widget_archive ul li span,
.page_content_two .widget_single_post .wid_post_content h6 a:hover,
.page_content_two .widget_category ul li a:hover,
.page_content_two .widget_archive ul li a:hover,
.page_content_two .sin_post blockquote p i,
.page_content_two .sin_post_tag span i,
.page_content_two .comment-metadata span {
  color: #3734f0;
}

.page_content_two .sin_post a.read_more {
  color: #3734f0;
}

.page_content_two .pagination ul li:first-child:hover {
  background: #3734f0;
}

.page_content_two .pagination ul li:first-child:hover a {
  color: #fff;
}

.page_content_two .pagination ul li:last-child:hover {
  background: #3734f0;
}

.page_content_two .pagination ul li:last-child:hover a {
  color: #fff;
}

.page_content_two .widget h3::after {
  background: #3734f0;
}

.page_content_two .accordion_btn {
  padding-left: 0;
}

.page_content_two .accordion_btn button {
  font-size: 16px;
  color: #cf3815;
  text-transform: uppercase;
  letter-spacing: 1.8px;
  padding: 0;
  border: none;
  background: transparent;
  cursor: pointer;
}

.page_content_two .accordion_btn button i {
  font-size: 21px;
  vertical-align: middle;
  color: #cf3815;
  margin-top: -4px;
  margin-left: 10px;
  transition: 0.5s;
}

.page_content_two .accordion_btn button:hover i {
  transform: rotate(-180deg);
}

.page_content_two .widget_tags ul li a:hover {
  color: #3734f0;
  border: 1px solid #3734f0;
}

.page_content_two .comment-reply-title::after,
.page_content_two .comments-title::after {
  background: #3734f0;
}

.page_content_two .side_bar .widget {
  background: #f8f8f8;
}

.page_content_two .sin_post h3 {
  font-size: 20px;
}

.page_content_two .sin_post p {
  color: #9e9c9c;
}

.page_content_two .btn_one, .page_content_two .btn_two {
  border: 2px solid #121212;
  color: #121212;
}

.page_content_two .btn_one:hover::before,
.page_content_two .btn_two:hover::before {
  background-color: #3734f0;
}

.page_content_two .btn_one:hover, .page_content_two .btn_two:hover {
  color: #ffffff;
  border-color: #3734f0;
}

.page_content_two .btn_one::before, .page_content_two .btn_two::before {
  background: #3734f0;
}

.side_bar .widget:last-child {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
  ## Ingredient
  --------------------------------------------------------------*/
.ingredeint_section {
  padding-right: 20px;
}

.ingredeint_section .nav-tabs {
  border-bottom: 0px solid #dee2e6;
}

.gredient_tab {
  padding: 0;
  margin: -10px auto 0;
}

.gredient_tab li {
  text-align: center;
  padding: 5px 0;
  margin: 10px 0;
  position: relative;
  background: #f8f8f8;
}

.slick-slide img {
  max-height: 100%;
}

.gredient_tab .slick-slide img {
  display: inline;
}

.slick_slider_two {
  padding: 0px;
}

.gredient_tab .slick-prev::before {
  content: '\f107';
  font-family: 'Font Awesome 5 Free';
  font-size: 20px;
  font-weight: 900;
  line-height: 1;
  opacity: .75;
  color: #f99a00;
}

.gredient_tab .slick-next::before {
  content: '\f106';
  font-family: 'Font Awesome 5 Free';
  font-size: 20px;
  font-weight: 900;
  line-height: 1;
  opacity: .75;
  color: #f99a00;
}

.gredient_tab .slick-next.slick-arrow {
  display: none !important;
}

.gredient_tab .slick-prev.slick-arrow {
  display: none !important;
}

.ingredient_content h5 {
  position: relative;
  margin-bottom: 23px;
  font-size: 36px;
}

.ingredient_content .mini-des {
  padding-bottom: 30px;
  border-bottom: 1px solid #e8f0f6;
  margin-bottom: 40px;
}

.ingredient_content .mini-des p {
  font-size: .88rem;
  font-weight: 600;
}

.ingredient_content .mini-des ul {
  padding-left: 0;
}

.ingredient_content .mini-des ul li {
  font-size: 14px;
  margin-bottom: 10px;
}

.ingredient_content > p {
  font-size: 1rem;
}

.ingredient_content_heading h5 {
  position: relative;
  margin-bottom: 60px;
}

.ingredient_content_heading h5:after {
  content: '';
  width: 30px;
  height: 3px;
  background: #f99a00;
  position: absolute;
  left: 0;
  top: 40px;
}

.ingridient_list {
  margin-top: 50px;
}

.ingridient_list h6 {
  font-size: .88rem;
  margin-bottom: 30px;
}

.ingridient_list h6 span {
  color: inherit;
}

.ingridient_list table {
  width: 100%;
  margin-bottom: 0;
}

.ingridient_list table tr {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  font-size: .88rem;
  padding: 9px 0px;
}

.ingridient_list table tr td {
  padding: 9px 0;
}

.ingredient-img {
  position: relative;
  width: 99%;
  text-align: center;
  padding: 100px 0;
  height: 100%;
  background: #f8f8f8;
}

.slick_slider_two.slick-initialized.slick-slider {
  margin-bottom: 0;
}

.ingredeint_section_one .ingredient-img img {
  display: inline-block;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.link_overlay {
  visibility: hidden;
  opacity: 0;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.link_overlay::after {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border: 2px solid #f99a00;
  content: '';
  opacity: .8;
  z-index: 0;
  background: transparent !important;
}

.toolbar_left ul li span.flat {
  font-size: 1rem;
  vertical-align: middle;
}

.link_overlay a {
  color: #fff;
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.slick-current .link_overlay {
  visibility: visible;
  opacity: 1;
}

.slick-slide:hover .link_overlay {
  visibility: visible;
  opacity: 1;
}

.slick_slider_two .slick-prev::before {
  content: '\f104';
  font-family: 'Font Awesome 5 Free';
  font-size: 20px;
  font-weight: 900;
  line-height: 1;
  opacity: .75;
  color: #f99a00;
}

.slick_slider_two .slick-next::before {
  content: '\f105';
  font-family: 'Font Awesome 5 Free';
  font-size: 20px;
  font-weight: 900;
  line-height: 1;
  opacity: .75;
  color: #f99a00;
}

.slick_slider_two .slick-next.slick-arrow {
  position: absolute;
  top: 285px;
  left: 79%;
  margin-left: -24px;
  /* 	background-image: url(../../media/images/arrow_right.png); */
  border-radius: 50px;
  padding: 0;
  height: 64px;
  width: 44px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.slick_slider_two .slick-prev.slick-arrow {
  position: absolute;
  top: 285px;
  left: 22%;
  margin-left: -24px;
  border-radius: 50px;
  padding: 0;
  height: 64px;
  width: 44px;
  z-index: 5;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.ingredeint_section.section_padding {
  padding: 7.5rem 0;
}

/*--------------------------------------------------------------
  ## Ingredient2
  --------------------------------------------------------------*/
.ingredeint_section p, .ingredeint_section h1, .ingredeint_section h2, .ingredeint_section h3, .ingredeint_section h4, .ingredeint_section h5, .ingredeint_section h6, .ingredeint_section a {
  font-family: "Roboto", sans-serif;
}

.ingredient_slider_one .slick-slide {
  width: 110px !important;
  height: 136px;
  border: 1px solid #f5f5f5;
  border-bottom: 0;
  line-height: 136px;
}

.ingredeint2_section {
  padding: 140px 0 140px;
}

.ingredient_slider_one .slick-slide img {
  left: 50%;
  position: relative;
  transform: translate(-50%);
  top: 21px;
}

.ingredient_slider_one .slick-prev,
.ingredient_slider_one .slick-next {
  top: 83%;
  width: 50%;
  height: 53px;
  background: #f5f5f5;
  border-radius: 0;
  z-index: 2;
  transition: 0.3s;
}

.ingredient_slider_one .slick-prev {
  left: 0;
}

.ingredient_slider_one .slick-prev:hover:before {
  color: #45D638;
}

.ingredient_slider_one .slick-next {
  right: 0;
  border-left: 1px solid #ededed;
}

.ingredient_slider_one .slick-next:hover:before {
  color: #45D638;
}

.ingredient_slider_one .slick-prev:before,
.ingredient_slider_one .slick-next:before {
  content: '\f107';
  font-family: 'Font Awesome 5 Free';
  font-size: 15px;
  font-weight: 900;
  line-height: 1;
  opacity: .75;
  color: #121212;
}

.ingredient_slider_one .slick-prev:before {
  content: '\f106';
}

.ingredient_slider_main {
  display: flex;
}

.ingredient_slider_two.slick-slider {
  width: calc(100% - 110px);
  box-shadow: 22px 20px 60px rgba(0, 0, 0, 0.1);
}

.ingredient_slider_two .ingredient-img {
  border: 0px solid #fff;
  background: #fff;
  width: 100%;
}

.ingredient_slider_detail .product_price {
  border-bottom: 0px solid #f1b601;
}

.ingredient_slider_detail .product-cart {
  border-bottom: 0px solid #f5f5f5;
}

.product_ratting a:last-child i {
  color: #b2b2b2;
  font-size: 14px;
}

.product_ratting a i {
  color: #f99a00;
}

.ingredient_slider_detail .product_ratting span {
  font-size: 14px;
  color: #5830B4;
  margin-right: 26px;
}

.ingredient_slider_detail .product_ratting .write_review {
  color: #b2b2b2;
}

.ingredient_slider_detail .product_desc p {
  font-size: 16px;
  color: #a6a6a6;
  line-height: 30px;
  margin-bottom: 34px;
}

.ingredient_slider_detail .product_quantity h4 {
  font-size: 16px;
  color: #121212;
  font-weight: 700;
  margin-bottom: 17px;
  letter-spacing: 0.5px;
}

.ingredient_slider_detail .product_quantity li {
  width: 99px;
  display: inline-block;
  font-size: 16px;
  color: #121212;
  font-weight: 500;
  text-align: center;
  background: #f5f5f5;
  border: 1px solid transparent;
  margin-right: 9px;
  line-height: 20px;
  padding: 6px 0;
  transition: 0.3s;
  cursor: pointer;
  height: 50px;
}

.ingredient_slider_detail .product_quantity li.border {
  border: 1px solid #f99a00 !important;
}

.ingredient_slider_detail .product_quantity li:hover {
  border: 1px solid #ededed;
}

.ingredient_slider_detail .product_quantity li span {
  text-transform: uppercase;
  font-size: 12px;
  display: block;
  font-weight: 400;
  line-height: normal;
}

.ingredient_slider_detail .product_quantity ul {
  padding-left: 0;
}

.ingredient_slider_detail .product_quantity {
  margin-bottom: 30px;
}

.ingredient_slider_detail .product_price p {
  padding: 0 10px;
  height: 27px;
  line-height: 28px;
  background: #f5f5f5;
  color: #f99a00;
  font-size: 10px;
  display: inline-block;
  font-weight: 500;
  letter-spacing: 1.25px;
}

.ingredient_slider_detail .price ins span {
  font-weight: 300;
  color: #121212;
  font-size: 24px;
}

.ingredient_slider_detail .price del {
  color: #ff8b8b;
}

.ingredient_slider_detail .price del span {
  font-size: 14px;
  font-weight: 300;
}

.ingredient_slider_detail .quantity .looking {
  width: 24px;
  height: 24px;
  line-height: 22px;
  border-radius: 50%;
  border: 0px;
  background: #ebebeb;
  color: #121212;
  cursor: pointer;
  margin-right: 0;
}

.ingredient_slider_detail .quantity .looking:hover {
  color: #121212;
}

.ingredient_slider_detail {
  padding-left: 108px;
}

.ingredient_slider_detail .quantity span,
.ingredient_slider_detail .quantity input {
  margin-right: 10px;
}

.ingredient_slider_detail .quantity span i {
  font-size: 8px;
}

.ingredient_slider_detail .ingredient_slider_btn a {
  height: 50px;
  line-height: 48px;
  display: inline-block;
  border: 2px solid #D01BD0;
  padding: 0 18px;
  border-radius: 0;
  font-size: 14px;
  color: #D01BD0;
  font-weight: 700;
  letter-spacing: 2px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  margin-left: 5px;
}

.ingredient_slider_detail .ingredient_slider_btn a:last-child {
  margin-left: -6px;
}

.ingredient_slider_detail .ingredient_slider_btn a:hover {
  background: #D01BD0;
  color: #fff;
}

.ingredient_slider_detail .ingredient_slider_btn a.this_heart {
  margin-left: -6px;
  width: 50px;
  padding: 0;
  text-align: center;
}

.ingredient_slider_detail .ingredient_slider_btn a.this_heart i {
  font-size: 20px;
  vertical-align: middle;
}

.ingredient_slider_detail .quantity {
  display: block;
  float: none;
  margin-bottom: 50px;
}

.ingredient_slider_detail .ingredient_slider_btn {
  margin-left: -5px;
}

.ingredient_slider_detail .product-cart {
  padding-bottom: 0;
  margin-bottom: 0;
}

.ingredient_slider_detail .share-wrap a {
  font-size: 12px;
  padding: 0;
  color: #121212;
  border-bottom: 1px solid #121212;
  border-radius: 0;
  line-height: 14px;
  margin-right: 25px;
  transition: 0.3s;
}

.ingredient_slider_detail .share-wrap a:hover {
  color: #7d7d7d;
  border-bottom: 1px solid #a1a1a1;
}

.ingredient_slider_detail .share-wrap a:last-child {
  color: #7d7d7d;
  border-bottom: 1px solid #7d7d7d;
}

.ingredient_slider_detail .share-wrap a:last-child:hover {
  color: #121212;
  border-bottom: 1px solid #121212;
}

.ingredient_slider_detail h4 {
  font-size: 20px;
  text-transform: capitalize;
  color: #121212;
  font-weight: 500;
  margin-bottom: 20px;
}

.ingredeint2_section .ingredient-img img {
  display: inline-block;
  position: relative;
  top: 0;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.ingredient_slider_detail .product_price {
  border-bottom: 0px solid #f1b601;
  margin-bottom: 0;
  padding-bottom: 25px;
}

.ingredient_slider_detail .quantity input {
  width: 45px;
  height: 45px;
}

.ingredient_slider_detail .quantity .looking {
  width: 23px;
  height: 23px;
  line-height: 21px;
}

@media only screen and (max-width: 575px) {
  .ingredient_slider_detail .ingredient_slider_btn a {
    padding: 0 8px;
  }
}

@media (min-width: 1200px) and (max-width: 1499px) {
  .ingredient_slider_detail {
    padding-left: 50px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .ingredeint2_section {
    padding: 100px 0 100px;
  }
}

@media (max-width: 575px) {
  .ingredeint2_section {
    padding: 80px 0px 30px;
  }
}

/*--------------------------------------------------------------
  ## Ingredient3
  --------------------------------------------------------------*/
.ingredeint_section_three p, .ingredeint_section_three h1, .ingredeint_section_three h2, .ingredeint_section_three h3, .ingredeint_section_three h4, .ingredeint_section_three h5, .ingredeint_section_three h6, .ingredeint_section_three a {
  font-family: "Roboto", sans-serif;
}

.ingredeint_section_three .link_overlay::after {
  border: 2px solid #3734f0;
}

.ingredeint_section_three .ingredient_content h5::after {
  display: none;
}

.ingredeint_section_three .ingredient_content_heading h5::after {
  background: #3734f0;
}

.ingredeint_section_three .ingridient_list h6 span {
  color: inherit;
}

.ingredeint_section_three .gredient_tab .slick-next,
.ingredeint_section_three .gredient_tab .slick-prev {
  color: #3734f0;
  display: none !important;
}

.ingredeint_section_three .gredient_tab .slick-slide img {
  transform: scale(0.8);
}

.ingredeint_section_three .gredient_tab li {
  background: #f8f8f8;
}

.ingredeint_section_three .ingredient-img {
  background: #f8f8f8;
  height: 100%;
  box-shadow: none;
  border: none;
}

.ingredeint_section_three .ingredient_content h5 {
  position: relative;
  margin-bottom: 23px;
  color: #3734f0;
  font-size: 36px;
}

.ingredeint_section_three .ingredient_content .mini-des ul li {
  color: #9e9c9c;
  font-size: 14px;
}

.ingredeint_section_three .ingredient_content > p {
  font-size: 16px;
  color: #9e9c9c;
}

.ingredeint_section_three .ingredient-img img {
  display: inline-block;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.ingredeint_section_three .ingredient_content .mini-des p {
  font-size: 14px;
}

.ingredeint_section_three .ingridient_list h6 {
  font-size: 14px;
}

/*--------------------------------------------------------------
  ## Ingredient4
  --------------------------------------------------------------*/
.ingredeint4_section p, .ingredeint4_section h1, .ingredeint4_section h2, .ingredeint4_section h3, .ingredeint4_section h4, .ingredeint4_section h5, .ingredeint4_section h6, .ingredeint4_section a {
  font-family: "Roboto", sans-serif;
}

.ingredeint4_section .ingredient_slider_detail .product_price p,
.ingredeint4_section .product_ratting a i,
.ingredeint4_section .ingredient_slider_one .slick-next:hover::before,
.ingredeint4_section .ingredient_slider_one .slick-prev:hover::before {
  color: #45D638;
}

.ingredeint4_section .ingredient_slider_detail .product_quantity li.border {
  border: 1px solid #D01BD0 !important;
}

.ingredeint4_section .ingredient_slider_detail .product_quantity li.active.border {
  border: 1px solid #D01BD0 !important;
}

.ingredeint4_section .write_review i {
  margin-right: 5px;
}

.ingredeint4_section .ingredient_slider_detail .ingredient_slider_btn:hover a {
  background: #D01BD0;
  color: #fff;
  border: 2px solid #fff;
}

.ingredeint4_section .product_ratting.woocommerce-product-rating {
  padding-bottom: 24px;
  border-bottom: 1px solid #ddd;
  margin-bottom: 18px;
  position: relative;
}

.ingredeint4_section .product_ratting.woocommerce-product-rating:after {
  position: absolute;
  content: "";
  left: 0;
  bottom: -1px;
  width: 50px;
  height: 1px;
  background: #5830B4;
}

.ingredeint4_section .product_ratting.woocommerce-product-rating a {
  margin-right: 4px;
}

.ingredeint4_section .ingredient_slider_detail .product_ratting span {
  color: #9c9c9c;
}

.ingredeint4_section .ingredient_slider_detail .product_ratting span i {
  color: #7474BA;
}

.ingredeint4_section .ingredient_slider_detail .product_ratting > span {
  color: #686868;
}

.ingredeint4_section .product_ratting a i {
  color: #D01BD0;
}

.ingredeint4_section .product_ratting.woocommerce-product-rating {
  border-bottom: 1px solid #ebebeb;
}

.ingredeint4_section .ingredient_slider_detail .product_price p.in-stock {
  background: #5830B4;
  color: #fff;
  margin-bottom: 45px;
}

.ingredeint4_section .ingredient_slider_detail .product_price p.out-stock {
  background: #b6b6b6;
  color: #fff;
  margin-bottom: 45px;
}

.ingredeint4_section .ingredient_slider_detail .quantity .looking:hover {
  background: #121212;
  border: 1px solid #121212;
  color: #fff;
}

.ingredeint4_section .ingredient_slider_detail .quantity .looking {
  background: transparent;
  color: #b2b2b2;
  border: 1px solid #b2b2b2;
}

.ingredeint4_section .ingredient_slider_btn:hover p {
  transform: scaleX(1);
}

.ingredeint4_section .ingredient_slider_btn p {
  display: inline-block;
  padding-left: 7px;
  font-size: 10px;
  color: #9c9c9c;
  text-transform: uppercase;
  margin-top: 20px;
  transform: scaleX(0);
  transition: 0.3s;
  transform-origin: left;
}

.ingredeint4_section .ingredient_slider_btn p i {
  padding-right: 5px;
  font-size: 20px;
  color: #45D638;
}

.product_desc.woocommerce-product-details__short-description p {
  font-size: 14px;
  color: #545454;
  letter-spacing: 0.25px;
  line-height: 24px;
}

.product_share {
  padding: 60px 0px 0px;
}

.product_share ul {
  display: flex;
  padding-left: 0;
  margin-bottom: 0;
}

.product_share ul li {
  width: 25%;
  border: 1px solid #ededed;
  text-align: center;
  height: 100px;
  line-height: 100px;
  position: relative;
  font-size: 18px;
}

.product_share ul li a span {
  opacity: 0;
  transition: 0.5s;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 10px;
  color: #b6b6b6;
  letter-spacing: 1.2px;
}

.product_share ul li a span i {
  font-size: 16px;
}

.product_share ul li a > i {
  transition: 0.3s;
}

.product_share ul li a {
  display: inline-block;
  padding: 0;
}

.product_share ul li.facebook a, .product_share ul li.facebook a span i {
  color: #3b5998;
}

.product_share ul li.twitter a, .product_share ul li.twitter a span i {
  color: #1da1f3;
}

.product_share ul li.instagram a, .product_share ul li.instagram a span i {
  color: #e56969;
}

.product_share ul li.gplus a, .product_share ul li.gplus a span i {
  color: #e04a39;
}

.product_share ul li:hover a > i {
  opacity: 0;
}

.product_share ul li:hover a span {
  opacity: 1;
}

.ingredient_slider_flex {
  display: flex;
}

.ingredient_slider_main {
  width: 687px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .ingredient_slider_main {
    width: 50%;
  }
  .ingredient_slider_detail {
    padding-left: 50px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .ingredient_slider_flex {
    flex-wrap: wrap;
  }
  .ingredient_slider_main {
    width: 100%;
  }
  .product_share ul li a span {
    letter-spacing: normal;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .ingredient_slider_flex {
    flex-wrap: wrap;
  }
  .ingredient_slider_main {
    width: 100%;
  }
}

@media only screen and (max-width: 575px) {
  .ingredient_slider_flex {
    flex-wrap: wrap;
  }
  .ingredient_slider_main {
    width: 100%;
  }
  .page_header_list li {
    padding: 0px 0px;
    border-right: 0px solid #7f7f7f;
    padding-right: 10px;
  }
  .product_share ul li:hover a > i {
    opacity: 0.5;
  }
}

/*--------------------------------------------------------------
  ## Ingredient4
  --------------------------------------------------------------*/
.ingredients_key_area {
  padding: 110px 0 0px;
}

.ingredients_key_area .section_title_four {
  text-align: center;
  margin-bottom: 59px;
}

.ingredients_key_features_all {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.ingredients_key_single_feature {
  width: 27.4%;
  background: #fff;
  border: 1px solid #efefef;
  padding: 45px;
  -webkit-box-shadow: 22px 32px 70px rgba(0, 0, 0, 0.1);
  box-shadow: 22px 32px 70px rgba(0, 0, 0, 0.1);
  justify-content: space-between;
  margin: 34px 0;
  position: relative;
}

.ingredients_key_single_feature:nth-child(even) {
  margin-left: 100px;
}

.ingredients_key_single_feature:nth-child(even) span {
  left: auto;
  right: -25px;
}

.ingredients_key_single_feature:nth-child(odd) {
  margin-right: 100px;
}

.ingredients_key_single_feature h3 {
  font-size: 18px;
  letter-spacing: 2.9px;
  font-weight: 600;
  color: #121212;
  font-family: "Poppins", sans-serif;
  margin-bottom: 16px;
}

.ingredients_key_single_feature p {
  font-size: 16px;
  color: #6e6e6e;
  line-height: 24px;
}

.ingredients_key_single_feature.active h3 {
  color: #45D638;
}

.ingredients_key_single_feature.active p {
  color: #202020;
}

.ingredients_key_single_feature.active span {
  transform: scale(1);
}

.ingredients_key_single_feature span {
  position: absolute;
  left: 0;
  top: 50%;
  width: 50px;
  height: 50px;
  background: #45D638;
  text-align: center;
  left: -25px;
  line-height: 50px;
  color: #fff;
  border-radius: 50%;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  font-size: 26px;
  margin-top: -25px;
}

.ingredients_key_featurs {
  position: relative;
}

.ingredients_key_featurs_menu {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 230px;
  z-index: 3;
}

.ingredients_key_featurs_menu .ingredients_key_featurs_img {
  position: absolute;
  left: 18px;
  top: -50%;
  z-index: -1;
}

.ingredients_key_featurs_menu ul {
  padding: 0;
  margin: 0;
}

.ingredients_key_featurs_menu ul li:nth-child(2n) {
  float: right;
  width: 50%;
  text-align: right;
}

.ingredients_key_featurs_menu ul li:nth-child(2n-1) {
  float: left;
  width: 50%;
  text-align: left;
}

.ingredients_key_featurs_menu ul li {
  margin: 17px 0;
}

.ingredients_key_featurs_menu ul li a {
  width: 42px;
  height: 42px;
  padding: 0;
  line-height: 0;
  text-align: center;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 50%;
  position: relative;
  z-index: 1;
  border: 4px solid #fff;
}

.ingredients_key_featurs_menu ul li a.active {
  background: #45D638;
}

.ingredients_key_featurs_menu ul li a.active i {
  opacity: 1;
}

.ingredients_key_featurs_menu ul li a i {
  line-height: 34px;
  opacity: 0;
  color: #fff;
}

.ingredients_key_featurs_menu ul li:nth-child(1) a i {
  transform: rotate(-50deg);
}

.ingredients_key_featurs_menu ul li:nth-child(3) a i {
  transform: rotate(-90deg);
}

.ingredients_key_featurs_menu ul li:nth-child(5) a i {
  transform: rotate(-140deg);
}

.ingredients_key_featurs_menu ul li:nth-child(2) a i {
  transform: rotate(50deg);
}

.ingredients_key_featurs_menu ul li:nth-child(4) a i {
  transform: rotate(90deg);
}

.ingredients_key_featurs_menu ul li:nth-child(6) a i {
  transform: rotate(140deg);
}

.ingredients_key_single_feature:nth-child(1),
.ingredients_key_single_feature:nth-child(5) {
  margin-left: 50px;
}

.ingredients_key_single_feature:nth-child(2),
.ingredients_key_single_feature:nth-child(6) {
  margin-right: 50px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .ingredients_key_single_feature {
    padding: 30px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .ingredients_key_single_feature:nth-child(2n+1) {
    margin-right: 0;
  }
  .ingredients_key_single_feature {
    width: 34%;
  }
  .ingredients_key_featurs_menu {
    width: 153px;
  }
  .ingredients_key_featurs_menu .ingredients_key_featurs_img {
    left: 0;
    top: -20%;
  }
  .ingredients_key_single_feature {
    padding: 30px;
  }
  .ingredients_key_single_feature h3 {
    font-size: 16px;
  }
  .ingredients_key_single_feature p {
    font-size: 14px;
  }
}

@media only screen and (max-width: 767px) {
  .ingredients_key_featurs_menu {
    display: none;
  }
  .ingredients_key_single_feature:nth-child(2n+1) {
    margin-right: 0;
  }
  .ingredients_key_single_feature:nth-child(1),
  .ingredients_key_single_feature:nth-child(5) {
    margin-left: 0px;
  }
  .ingredients_key_single_feature {
    width: 100%;
  }
  .ingredients_key_single_feature:nth-child(2n) {
    margin-left: 0;
  }
  .ingredients_key_single_feature:nth-child(2), .ingredients_key_single_feature:nth-child(6) {
    margin-right: 0px;
  }
  .ingredients_key_single_feature {
    width: 100%;
    padding: 30px;
  }
  .ingredients_key_single_feature:hover h3 {
    color: #45D638;
  }
  .ingredients_key_single_feature:hover span {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
  .ingredients_key_single_feature:nth-child(2n+1) {
    margin-left: 0px;
  }
  .ingredients_key_area .section_title_four {
    text-align: left;
  }
  .ingredients_key_area .section_title_four h2 {
    padding: 14px 26px;
  }
  .ingredients_key_single_feature:nth-child(2n) {
    margin-right: 0;
    margin-left: 0px;
  }
  .ingredients_key_single_feature:nth-child(even) span {
    left: -25px;
    right: auto;
  }
  .ingredients_key_single_feature h3 {
    font-size: 18px;
    letter-spacing: normal;
  }
  .ingredients_key_single_feature p {
    font-size: 14px;
  }
  .ingredients_key_area .section_title_four h2 {
    font-size: 24px;
  }
  .ingredients_key_area .section_title_four {
    margin-bottom: 0;
  }
  .ingredients_key_single_feature {
    margin: 20px 0;
  }
  .ingredients_key_area {
    padding: 80px 0 0px;
  }
  .ingredients_key_single_feature:last-child {
    margin-bottom: 0;
  }
}

/*--------------------------------------------------------------
  ## Related product
  --------------------------------------------------------------*/
.related_product_slider.owl-carousel .owl-item img {
  width: auto;
  max-width: 100%;
  margin: 0 auto;
}

.related_product_slider.owl-carousel .owl-stage-outer {
  padding: 10px 0;
  margin-left: -1px;
}

.sn_related_product {
  padding: 30px;
  border: 1px solid #f5f5f5;
  transition: 0.5s;
  padding-bottom: 25px;
}

.sn_related_product:hover {
  border: 1px solid transparent;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.35);
}

.related_product_slider .sn_related_product:hover {
  box-shadow: none;
  border: 1px solid rgba(58, 216, 46, 0.25);
}

.sn_pd_detail a {
  margin-bottom: 0px;
  padding: 0;
}

.sn_pd_rating {
  margin-bottom: 8px;
}

.sn_pd_rating a {
  padding: 0;
  margin-right: 3.6px;
}

.sn_pd_rating a i {
  font-size: 14px;
  color: #D01BD0;
}

.sn_pd_rating a:last-child i {
  color: #b2b2b2;
}

.sn_pd_detail h5 a {
  padding: 0;
  font-size: 13px;
  font-weight: 400;
  text-transform: capitalize;
  color: #000;
  transition: 0.3s;
  line-height: normal;
  font-family: 'Poppins', sans-serif;
}

.sn_pd_detail ins {
  font-size: 16px;
  color: #5830B4;
  font-weight: 700;
}

.sn_pd_detail del {
  font-size: 13px;
  color: #ff8b8b;
}

.related_product_title h1 {
  font-size: 36px;
  color: #121212;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 66px;
}

.related_product {
  padding: 135px 0 130px;
}

.related_product_slider .owl-nav {
  position: absolute;
  right: 0;
  top: -100px;
}

.related_product_slider .owl-nav button {
  margin-left: 7px;
  transition: 0.3s;
}

.related_product_slider .owl-nav button i {
  color: #7474BA;
}

.related_product_slider .owl-nav button:hover i {
  color: #5830B4;
}

.related_product p, .related_product h1, .related_product h2, .related_product h3, .related_product h4, .related_product h5, .related_product h6, .related_product a {
  font-family: "Roboto", sans-serif;
}

.sn_pd_img {
  margin-bottom: 24px;
}

.sn_pd_img a {
  padding: 0;
  margin: 0 auto;
  display: block;
  text-align: center;
}

@media (min-width: 768px) and (max-width: 991px) {
  .related_product {
    padding: 90px 0 65px;
  }
  .related_product_slider .owl-nav {
    top: -91px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .related_product_slider .owl-nav {
    top: -87px;
  }
}

/*--------------------------------------------------------------
  ## Related product2
  --------------------------------------------------------------*/
.related_product_two p, .related_product_two h1, .related_product_two h2, .related_product_two h3, .related_product_two h4, .related_product_two h5, .related_product_two h6, .related_product_two a {
  font-family: "Roboto", sans-serif;
}

.related_product_two .sn_pd_rating a i {
  color: #D01BD0;
  font-size: 14px;
}

.related_product_two .sn_pd_detail a h5 {
  font-size: 14px;
  font-weight: 400;
  text-transform: initial;
}

.related_product_two .related_product {
  padding: 110px 0 80px;
}

/*--------------------------------------------------------------
  ## Count Up
  --------------------------------------------------------------*/
.count_up {
  background: #3db5e6;
  background: -moz-linear-gradient(right, #3db5e6 0%, #0086c3 100%);
  background: -webkit-linear-gradient(right, #3db5e6 0%, #0086c3 100%);
  background: linear-gradient(to left, #3db5e6 0%, #0086c3 100%);
  padding: 52px 0;
}

.single_count_up {
  background: rgba(255, 255, 255, 0.05);
  text-align: center;
  padding: 26px;
  padding-bottom: 20px;
}

.single_count_inner {
  display: inline-block;
  text-align: left;
}

.countup_icon {
  position: relative;
  display: inline-block;
}

.countup_icon i {
  font-size: 22px;
  color: #fff;
  line-height: 24px;
}

.counter {
  color: #fff;
  font-size: 36px;
  font-weight: 300;
  letter-spacing: 4.3px;
  margin-bottom: 5px;
}

.countup_desc {
  padding-left: 36px;
}

.countup_desc span {
  font-size: 14px;
  color: #121212;
  font-weight: 500;
  opacity: 0.8;
  letter-spacing: 1.7px;
  line-height: 30px;
}

.countup_icon {
  margin-bottom: 16px;
}

.countup_icon::after {
  position: absolute;
  left: calc(100% + 30px);
  top: 10px;
  width: 1px;
  opacity: 0.2;
  height: 66px;
  content: "";
  background: #ffff;
  -webkit-transform: rotate(-45deg) translateY(-50%);
  -ms-transform: rotate(-45deg) translateY(-50%);
  transform: rotate(45deg);
  z-index: 9;
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: top;
}

@media (min-width: 768px) and (max-width: 991px) {
  .single_count_up {
    margin-bottom: 30px;
  }
  #pick-three .col-lg-4.col-md-6:last-child .single_count_up {
    margin-bottom: 0;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .single_count_up {
    margin-bottom: 20px;
  }
}

@media (max-width: 575px) {
  .count_up {
    padding: 52px 0 24px;
  }
}

/*--------------------------------------------------------------
  ## Map
  --------------------------------------------------------------*/
.map_hovarable {
  position: relative;
  height: 107px;
  transition: all 0.3s linear;
}

.map_hovarable .map {
  height: 100%;
}

.map_click_me {
  position: absolute;
  z-index: 9;
  width: 100%;
  text-align: center;
  box-shadow: 0 -38px 38px #ecf9ff inset;
  width: 100%;
  height: 77px;
  background: #fff;
  text-align: center;
  left: 0;
  top: 0;
  cursor: pointer;
}

.map_click_me:hover i {
  transform: rotate(180deg);
}

.map_click_me p {
  line-height: 30px;
  letter-spacing: 1.4px;
  font-size: 12px;
  color: #a5aab0;
  margin-bottom: 0;
}

.map_click_me i {
  font-size: 29px;
  color: #3db5e6;
  transition: 0.3s;
}

.map_hovarable.slideup {
  height: 500px;
}

/*--------------------------------------------------------------
  ## Map Two
  --------------------------------------------------------------*/
.map_two_area {
  padding-top: 140px;
}

.map_two_area .map_two_area_top {
  padding-bottom: 112px;
}

.map_two_area .contact_details {
  padding-left: 43px;
}

.map_two_area .contact_details .section_title_four {
  margin-left: -115px;
  margin-bottom: 34px;
}

.map_two_area .contact_details .section_title_four h2 {
  letter-spacing: 8px;
}

.map_two_area .map {
  height: 95%;
}

.map_two_area .contact_address {
  margin-bottom: 22px;
}

.map_two_area .contact_address h3 {
  font-size: 24px;
  text-transform: capitalize;
  font-weight: 500;
  color: #45D638;
  font-family: "Poppins", sans-serif;
  letter-spacing: 2.9px;
  margin-bottom: 48px;
}

.map_two_area .contact_address a {
  font-size: 20px;
  font-weight: 300;
  color: #8e8f8f;
  padding: 0;
  margin-bottom: 30px;
  display: inline-block;
  width: 100%;
  transition: 0.3s;
}

.map_two_area .contact_address a:hover {
  color: #45D638;
}

.map_two_area .contact_address a i {
  font-size: 18px;
  color: #45D638;
  width: 35px;
  height: 35px;
  display: inline-block;
  vertical-align: top;
  line-height: 54px;
}

.map_two_area .contact_address a span {
  display: inline-block;
  width: calc(100% - 40px);
  vertical-align: top;
}

.map_two_area .contact_address_img {
  margin-bottom: -41%;
  position: relative;
  z-index: 9;
  box-shadow: 22px 32px 70px rgba(0, 0, 0, 0.2);
  margin-left: -30px;
  position: relative;
}

.map_two_area .contact_address_img img {
  width: 100%;
}

.map_two_area .contact_address_img:after {
  position: absolute;
  content: "";
  left: 35.5%;
  top: -58%;
  width: 24px;
  height: 197%;
  background: #45D638;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(44deg);
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  z-index: -1;
}

.map_two_area_bottom {
  position: relative;
  padding: 140px 0 140px;
}

.map_two_area_bottom:before {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background: url("../../media/images/home6/pattern-contact.png") repeat center;
  top: 0;
  z-index: -1;
}

.map_two_area_bottom:after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(153, 153, 153, 0.05);
  z-index: -1;
}

.map_two_area_bottom .section_title_four {
  margin-bottom: 37px;
}

.map_two_area_bottom .map_two_area_input {
  width: 100%;
  position: relative;
  margin-bottom: 20px;
}

.map_two_area_bottom .map_two_area_input input {
  height: 60px;
  border: 1px solid #ededed;
  border-radius: 0;
  width: 100%;
  padding: 0 30px 0 70px;
  background: #fff;
  transition: 0.3s;
  font-size: 20px;
  font-weight: 300;
}

.map_two_area_bottom .map_two_area_input input::placeholder {
  color: #c2c2c2;
  font-weight: 300;
  font-size: 16px;
}

.map_two_area_bottom .map_two_area_input input:focus {
  color: #45D638;
  border: 1px solid #45D638;
}

.map_two_area_bottom .map_two_area_input.active label {
  border-right: 1px solid #45D638;
}

.map_two_area_bottom .map_two_area_input.active label i {
  color: #45D638;
}

.map_two_area_bottom .map_two_area_input label {
  position: absolute;
  left: 0;
  top: 17px;
  padding: 0 16px;
  border-right: 1px solid #ddd;
  margin-bottom: 0;
  color: #c2c2c2;
  font-size: 14px;
}

.map_two_area_bottom .map_two_area_all_input {
  display: inline-block;
  width: calc(50% - 4px);
  vertical-align: top;
  padding-right: 27px;
  margin-bottom: 60px;
}

.map_two_area_bottom .map_two_area_textarea {
  width: 50%;
  display: inline-block;
  vertical-align: top;
  padding-left: 27px;
}

.map_two_area_bottom .map_two_area_textarea textarea {
  height: 220px;
  background: #fff;
  border: 1px solid #ededed;
  resize: none;
  padding: 25px 27px;
  border-radius: 0;
}

.map_two_area_bottom .map_two_area_textarea textarea::placeholder {
  color: #c2c2c2;
  font-weight: 300;
}

.map_two_area_bottom .map_two_area_textarea textarea:focus {
  border: 1px solid #45D638;
}

.map_two_area_bottom .map_two_area_submit input {
  width: 130px;
  height: 60px;
  background: transparent;
  border-radius: 0;
  border: 2px solid #45D638;
  font-size: 14px;
  color: #45D638;
  font-weight: 700;
  text-transform: uppercase;
  padding: 0;
  line-height: 58px;
}

.map_two_area_bottom .map_two_area_submit input:hover {
  color: #fff;
  background: #45D638;
}

@media (min-width: 768px) and (max-width: 991px) {
  .map_two_area {
    padding-top: 100px;
  }
  .map_two_area .map_two_area_top {
    padding-bottom: 80px;
  }
  .map_two_area_bottom {
    padding: 100px 0;
  }
}

@media only screen and (max-width: 767px) {
  .contact_address_img:after {
    display: none;
  }
  .map_two_area .map {
    height: 300px;
  }
  .map_two_area .contact_details .section_title_four {
    margin-left: 17px;
    margin-bottom: 31px;
    margin-top: -37px;
  }
  .map_two_area .contact_details {
    padding-left: 0;
  }
  .map_two_area .contact_details .section_title_four h2 {
    padding: 16px;
  }
  .map_two_area .contact_address_img {
    margin-left: 0;
  }
  .map_two_area_bottom .map_two_area_all_input {
    width: 100%;
  }
  .map_two_area_bottom .map_two_area_textarea {
    width: 100%;
  }
  .map_two_area_bottom .map_two_area_textarea {
    padding-left: 0;
  }
  .map_two_area_bottom .map_two_area_all_input {
    padding-right: 0;
  }
  .map_two_area .contact_address_img {
    margin-bottom: 0;
  }
  .map_two_area_bottom .section_title_four {
    margin-bottom: 60px;
    margin-top: 0;
  }
  .map_two_area_bottom::before {
    width: 100%;
  }
  .map_two_area_bottom .map_two_area_all_input {
    margin-bottom: 0;
  }
  .map_two_area_bottom .map_two_area_textarea {
    margin-bottom: 30px;
  }
  .map_two_area {
    padding-top: 80px;
  }
  .map_two_area .map_two_area_top {
    padding-bottom: 80px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .map_two_area_bottom {
    padding: 80px 0;
  }
}

@media (max-width: 575px) {
  .map_two_area_bottom {
    padding: 80px 0;
  }
}

/*--------------------------------------------------------------
  ## Map
  --------------------------------------------------------------*/
.blog_hm_five_slider .owl-stage-outer {
  overflow: visible;
}

.blog_hm_five_slider .owl-item {
  opacity: 0;
  transition: opacity 100ms;
}

.blog_hm_five_slider .owl-item.active {
  opacity: 1;
}

.blog_hm_five_slider .owl-nav {
  position: absolute;
  right: -35px;
  top: calc(50% - 72px);
  width: 64px;
}

.blog_hm_five_slider .owl-nav button {
  width: 64px;
  height: 64px;
  background: #3db5e6 !important;
  text-align: center;
  line-height: 64px;
  display: block;
  border-radius: 50%;
  margin-bottom: 10px;
}

.blog_hm_five_slider .owl-nav button:hover i {
  font-size: 20px;
}

.blog_hm_five_slider .owl-nav button i {
  color: #fff;
  font-size: 16px;
  line-height: 64px;
  transition: 0.3s;
}

.blog_hm_five_post {
  padding: 25px 19px;
  box-shadow: 18px 18px 17px #dedede;
  background: #fff;
}

.blog_hm_five_post .blog_hm_five_title {
  width: 80%;
  margin-bottom: 20px;
}

.blog_hm_five_post .blog_hm_five_title h4 a {
  padding: 0;
  color: #424e5a;
  letter-spacing: -0.2px;
  line-height: 26px;
  text-transform: initial;
  font-weight: 500;
  font-size: 16px;
  transition: 0.3s;
}

.blog_hm_five_post .blog_hm_five_title h4 a:hover {
  color: #3db5e6;
}

.blog_hm_five_post .blog_hm_five_thumb {
  margin-bottom: 20px;
}

.blog_hm_five_post .blog_hm_five_meta a {
  font-size: 12px;
  color: #a5aab0;
  letter-spacing: 1.4px;
  line-height: 30px;
  padding: 0;
  width: 100%;
  position: relative;
}

.blog_hm_five_post .blog_hm_five_meta a:hover {
  color: #3db5e6;
}

.blog_hm_five_post .blog_hm_five_meta a i {
  font-size: 16px;
  color: #3db5e6;
  position: absolute;
  right: 0;
  top: 9px;
}

.Blog_home_five_slider_part {
  width: 78%;
  float: left;
}

.Blog_home_five_slider_part .owl-stage-outer {
  position: relative;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  padding: 50px;
  padding-right: 0;
  width: 110%;
  margin-left: -50px;
}

.Blog_home_five_btn_part {
  position: absolute;
  left: 80%;
  bottom: 50px;
  z-index: 1;
}

.Blog_home_five_btn_part a {
  padding: 0;
  padding-left: 72px;
  padding-bottom: 25px;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.88;
  letter-spacing: 1.9px;
  text-align: left;
  color: #3db5e6;
  text-transform: uppercase;
}

.Blog_home_five_btn_part a:hover i {
  padding-left: 15px;
}

.Blog_home_five_btn_part a i {
  color: #121212;
  padding-left: 9px;
  font-size: 23px;
  vertical-align: middle;
  margin-top: -3px;
  transition: 0.3s;
}

.Blog_home_five {
  overflow: hidden;
  padding: 44px 0 62px;
}

.Blog_home_five .vigo_container_two {
  position: relative;
}

.faq3_area {
  padding-bottom: 0;
}

.faq3_area .vigo_container_two {
  background: url(../../media/images/home3/girl.png) no-repeat scroll right 109px bottom/contain;
}

.faq3_area .faq3_left_area {
  width: 54%;
}

.faq3_area .faq3_left_area .faq3_accordion {
  box-shadow: 18px 18px 38px #dedede;
  background: #fff;
}

.faq3_area .faq3_left_area .question span {
  font-size: 16px;
  text-transform: capitalize;
  color: #424e5a;
  font-weight: 500;
  padding: 22px 24px;
  -webkit-transition: all 2s linear;
  -o-transition: all 2s linear;
  transition: all 2s linear;
  letter-spacing: normal;
  text-transform: inherit;
}

.faq3_area .faq3_left_area .question span:after {
  font-weight: 900;
  content: "\f056";
  color: #3db5e6;
  font-size: 14px;
  transition: 0.3s;
}

.faq3_area .faq3_left_area .question span.collapsed.collapsed::after {
  content: "\f055";
  color: #dddddd;
}

.faq3_area .faq3_left_area .collapse .ans_body:after {
  height: calc(100% - 50px);
}

.faq3_area .faq3_left_area .ans_body {
  font-size: 16px;
  color: #434f5b;
  font-weight: 300;
  line-height: 25px;
  padding-left: 47px;
  position: relative;
  letter-spacing: 0.4px;
}

.faq3_area .faq3_left_area .ans_body:after {
  position: absolute;
  content: "";
  left: 25px;
  width: 4px;
  background: #3db5e6;
  top: 25px;
  height: 0;
  transition: 0.3s linear;
}

.call_to_action3 .call_to_action_area_two {
  background: #3db5e6;
  background: -webkit-gradient(linear, right top, left top, from(#3db5e6), to(#0086c3));
  background: -o-linear-gradient(right, #3db5e6 0%, #0086c3 100%);
  background: linear-gradient(to left, #3db5e6 0%, #0086c3 100%);
  position: relative;
  padding: 50px 0px;
}

.call_to_action3 .call_to_action_hello {
  padding: 0px 79px 0 92px;
  border: 0px solid rgba(242, 168, 26, 0.6);
}

.how_it_work_tab {
  position: relative;
  padding: 170px 0 116px;
}

.how_it_work_tab .tab-content {
  margin: 0;
  padding: 0;
  width: 275px;
  height: 275px;
  text-align: center;
  border: 10px solid #ddd;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: relative;
  left: auto;
  top: 58px;
  float: right;
  right: 60px;
}

.how_it_work_tab .tab-content img {
  padding-left: 15px;
}

.how_it_work_tab .tab-content .icon_menu {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}

.how_it_work_tab .tab-content .icon_menu li {
  position: absolute;
  width: 80px;
  height: 80px;
  background: #ebebeb;
  line-height: 80px;
  border-radius: 50%;
  transform: rotate(-45deg);
  z-index: 9;
}

.how_it_work_tab .tab-content .icon_menu li.active {
  background: #3db5e6;
  background: -webkit-gradient(linear, right top, left top, from(#3db5e6), to(#0086c3));
  background: -o-linear-gradient(right, #3db5e6 0%, #0086c3 100%);
  background: linear-gradient(to left, #3db5e6 0%, #0086c3 100%);
  transition: 0.5s;
}

.how_it_work_tab .tab-content .icon_menu li i {
  color: #fff;
  font-size: 22px;
}

.how_it_work_tab .tab-content .icon_menu li:nth-child(3) {
  right: -45px;
  top: calc(50% - 40px);
}

.how_it_work_tab .tab-content .icon_menu li:first-child {
  left: -45px;
  top: calc(50% - 40px);
}

.how_it_work_tab .tab-content .icon_menu li:nth-child(2) {
  left: calc(50% - 40px);
  top: -40px;
}

.how_it_work_tab .tab-content .icon_menu li:nth-child(4) {
  left: calc(50% - 40px);
  top: calc(100% - 30px);
}

.how_it_work_tab .tab-content > .active {
  display: block;
}

.how_it_work_tab .tab-pane {
  transform: rotate(-45deg);
  text-align: center;
  line-height: 275px;
  position: relative;
}

.how_it_work_tab .tab-pane.fade {
  transition: 0.5s linear;
  transform: rotate(-45deg) scale(0);
}

.how_it_work_tab .tab-pane.fade.show {
  transform: rotate(-45deg) scale(1);
}

.how_it_work_tab_menu .section_title_three {
  margin-bottom: 27px;
}

.how_it_work_tab_menu a {
  transition: 0.5s ease;
  font-size: 16px;
  font-weight: 400;
  color: #424e5a;
  padding: 0;
  margin-bottom: 15px;
}

.how_it_work_tab_menu a:last-child {
  margin-bottom: 0;
}

.how_it_work_tab_menu a span {
  font-size: 28px;
  font-weight: 300;
  margin-right: 0;
  vertical-align: middle;
}

.how_it_work_tab_menu a P {
  display: inline-block;
  width: calc(100% - 35px);
  vertical-align: middle;
  margin-bottom: 0;
  padding-left: 14px;
}

.how_it_work_tab_menu a.active {
  color: #3db5e6;
}

.combine_area {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.combine_bg_right {
  position: absolute;
  right: 0;
  top: 23%;
  z-index: -1;
}

.combine_bg_left {
  position: absolute;
  top: 25%;
  left: 0;
  z-index: -1;
}

@media (min-width: 1200px) and (max-width: 1499px) {
  .how_it_work_tab .tab-content {
    top: 73px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .how_it_work_tab .tab-content {
    width: 270px;
    height: 270px;
    left: 10%;
  }
  .how_it_work_tab .tab-pane {
    line-height: 270px;
  }
  .section_title_three h2 {
    font-size: 33px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .Blog_home_five_btn_part a {
    padding-left: 0;
  }
  .faq3_area .faq3_left_area {
    width: 100%;
    background: #fff;
  }
  .how_it_work_tab .tab-content {
    transform: translateX(-50%) rotate(45deg);
    left: 50%;
  }
  .how_it_work_tab .tab-content {
    width: 236px;
    height: 236px;
    top: 35%;
    left: 50%;
    transform: translate(-50%) rotate(45deg);
  }
  .how_it_work_tab .tab-content .icon_menu li {
    width: 70px;
    height: 70px;
    line-height: 70px;
  }
  .how_it_work_tab .tab-pane {
    line-height: 236px;
  }
  .how_it_work_tab {
    overflow: hidden;
  }
  .how_it_work_tab.section_padding_two {
    padding: 160px 0 100px;
  }
  .section_title_three p {
    width: 100%;
  }
  .blog_hm_five_post .blog_hm_five_title {
    width: 88%;
  }
  .blog_hm_five_slider .owl-nav button {
    width: 50px;
    height: 50px;
  }
  .blog_hm_five_slider .owl-nav button i {
    line-height: 50px;
  }
  .blog_hm_five_post {
    box-shadow: -2px 5px 10px #dedede;
  }
  .Blog_home_five_btn_part a {
    font-size: 13px;
  }
  .Blog_home_five_btn_part a i {
    padding-left: 6px;
    font-size: 17px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .supplement_at_a_glance.section_padding_two {
    position: relative;
    z-index: 1;
  }
  .faq3_area .vigo_container_two {
    background: url(../../media/images/home3/girl.png) no-repeat scroll right 109px bottom/auto;
  }
  .faq3_area .faq3_left_area .faq3_accordion {
    background: #fff;
  }
  .Blog_home_five_btn_part a {
    padding-left: 25px;
  }
  .blog_hm_five_post {
    box-shadow: 5px 5px 7px #dedede;
  }
  .blog_hm_five_post .blog_hm_five_title {
    width: 90%;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .how_it_work_tab .tab-content {
    -webkit-transform: translateX(-50%) rotate(45deg);
    -ms-transform: translateX(-50%) rotate(45deg);
    transform: translateX(-50%) rotate(45deg);
    position: relative;
    left: 50%;
    top: auto;
    bottom: 0;
    width: 300px;
    height: 300px;
  }
  .how_it_work_tab .tab-pane {
    line-height: 300px;
  }
  .faq3_area .faq3_left_area {
    width: 100%;
  }
  .faq3_area.section_padding_two {
    padding-bottom: 0;
  }
  .how_it_work_tab_menu {
    margin-bottom: 100px;
  }
  .how_it_work_tab.section_padding_two {
    padding-bottom: 130px;
    padding-top: 130px;
  }
  .Blog_home_five.section_padding_two {
    padding-top: 0;
  }
  .faq3_area .vigo_container_two {
    background: #fff;
  }
  .Blog_home_five_btn_part a:hover i {
    padding-left: 10px;
  }
  .Blog_home_five_slider_part .owl-stage-outer {
    width: 128%;
  }
  .Blog_home_five_slider_part {
    width: 100%;
  }
  .Blog_home_five_btn_part a {
    padding-left: 0;
  }
  .Blog_home_five_btn_part {
    position: static;
  }
  .blog_hm_five_post .blog_hm_five_content {
    width: 100%;
  }
  .blog_hm_five_slider .owl-nav button {
    width: 38px;
    height: 38px;
    line-height: 38px;
    border-radius: 0;
    margin-bottom: 3px;
  }
  .blog_hm_five_slider .owl-nav button i {
    line-height: 38px;
  }
  .blog_hm_five_slider .owl-nav {
    right: -19px;
    width: auto;
    top: calc(50% - 115px);
  }
  .how_it_work_tab.section_padding_two {
    padding-bottom: 141px;
  }
  #accordion {
    margin-bottom: 30px;
  }
  .blog_hm_five_post {
    -webkit-box-shadow: 4px -2px 17px #dedede;
    box-shadow: 4px -2px 17px #dedede;
  }
  .Blog_home_five.section_padding_two {
    padding-top: 30px;
    padding-bottom: 36px;
  }
}

@media only screen and (max-width: 575px) {
  .faq3_area .vigo_container_two {
    background: #fff;
  }
  .how_it_work_tab .tab-content {
    width: 220px;
    height: 220px;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    margin-top: 50px;
  }
  .how_it_work_tab .tab-pane {
    line-height: 200px;
  }
  .how_it_work_tab .tab-content .icon_menu li {
    width: 60px;
    height: 60px;
    line-height: 68px;
  }
  .call_to_action3 .call_to_action_hello {
    padding: 0px 15px 0 15px;
  }
  .faq3_area .faq3_left_area {
    width: 100%;
  }
  .faq3_area .faq3_left_area .faq3_accordion {
    background: #fff;
  }
  .Blog_home_five_slider_part {
    width: 100%;
    padding-bottom: 0px;
  }
  .blog_hm_five_post .blog_hm_five_content {
    width: 100%;
  }
  .single_count_up {
    margin-bottom: 30px;
  }
  .how_it_work_tab.section_padding_two {
    padding: 115px 0 185px;
    overflow: hidden;
  }
  .Blog_home_five.section_padding_two {
    padding: 0 0 72px;
  }
  .blog_hm_five_slider .owl-nav button {
    width: 50px;
    height: 50px;
  }
  .blog_hm_five_slider .owl-nav button i {
    line-height: 50px;
  }
  .blog_hm_five_post .blog_hm_five_title {
    width: 90%;
  }
  .Blog_home_five_btn_part {
    display: none;
  }
  .Blog_home_five_slider_part .owl-stage-outer {
    width: 125%;
  }
  .Blog_home_five_slider_part .owl-stage-outer {
    width: 135%;
    padding-top: 80px;
  }
  .banner_area .owl-prev:hover {
    box-shadow: none;
  }
  .banner_area .owl-next:hover {
    box-shadow: none;
  }
  .blog_hm_five_post {
    -webkit-box-shadow: 2px 9px 17px #dedede;
    box-shadow: 2px 9px 17px #dedede;
  }
  .blog_hm_five_slider .owl-nav {
    position: static;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: auto;
    margin-top: 0;
  }
  .blog_hm_five_slider .owl-nav button {
    width: 30px;
    height: 30px;
    margin-bottom: 0;
    margin-right: 4px;
    border-radius: 0;
  }
  .blog_hm_five_slider .owl-nav button i {
    line-height: 30px;
  }
}

/*--------------------------------------------------------------
  ## Map
  --------------------------------------------------------------*/
.combine_area_two {
  position: relative;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.combine_area_two .parallax-all {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 50%;
  z-index: -9;
}

.combine_area_two .combine_two_left {
  position: fixed;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.combine_area_two .combine_two_right {
  position: fixed;
  top: 24%;
  right: 0;
  transform: translateY(-30%);
}

@media (min-width: 992px) and (max-width: 1200px) {
  .combine_area_two .combine_two_right {
    top: 20%;
  }
}

/*--------------------------------------------------------------
  ## Extra
  --------------------------------------------------------------*/
/*--------------------------------------------------------------
  ##  Sign Up
  --------------------------------------------------------------*/
.sign-up-area {
  background: url(../../media/images/popup/background.jpg) no-repeat scroll 0 0/cover;
  padding-top: 137px;
  padding-bottom: 140px;
}

.sign-up-inner {
  max-width: 798px;
  margin: 0 auto;
  padding: 70px 40px;
  background: #fff;
  border: 6px solid rgba(0, 0, 0, 0.03);
  box-shadow: 0px 44px 62px rgba(0, 0, 0, 0.14);
}

.sign-up-section-title {
  text-align: center;
  margin-bottom: 40px;
}

.sign-up-section-title h4 {
  font-size: 24px;
  color: #121212;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  letter-spacing: 1.20px;
  position: relative;
  padding-bottom: 20px;
  margin-bottom: 20px;
  display: inline-block;
}

.sign-up-section-title h4:after {
  position: absolute;
  content: "";
  left: 50%;
  bottom: 0;
  width: 10px;
  height: 2px;
  background: #45D638;
  margin-left: -5px;
}

.sign-up-section-title span {
  font-size: 20px;
  color: #121212;
  font-weight: 300;
  text-transform: capitalize;
  display: block;
}

.sign-up-single-input {
  width: calc(50% - 15px);
  margin-bottom: 30px;
}

.sign-up-single-input input {
  width: 100%;
  height: 50px;
  border: 1px solid #ddd;
  border-radius: 0px;
  padding: 0 20px;
}

.sign-up-single-input input:focus {
  border: 1px solid #45D638;
}

.sign-up-single-input label {
  display: block;
  font-size: 12px;
  color: #121212;
  font-weight: 400;
  margin-bottom: 0;
  line-height: normal;
  margin-bottom: 10px;
  letter-spacing: 1.2px;
  text-transform: uppercase;
}

.sign-up-form form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.sign-up-single-button {
  width: 210px;
}

.sign-up-single-button input {
  width: 100%;
  height: 52px;
  background: transparent;
  border: 2px solid #45D638;
  color: #45D638;
  border-radius: 0px;
  line-height: 46px;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 0;
  line-height: 50px;
  cursor: pointer;
  transition: 0.3s;
}

.sign-up-single-button input:hover {
  color: #fff;
  background: #45D638;
}

.sign-up-single-checkbox {
  width: calc(100% - 210px);
  padding-left: 30px;
  font-size: 14px;
  color: #9f9f9f;
  display: block;
  margin-bottom: 0;
}

.sign-up-single-checkbox label {
  font-size: 14px;
  color: #9f9f9f;
  display: block;
  margin-bottom: 0;
}

.sign-up-single-checkbox a {
  padding: 0;
  font-size: 14px;
  color: #a3a3a3;
  font-weight: 700;
}

.sign-up-single-checkbox input[type="checkbox"] {
  border: none;
  margin-right: 5px;
  vertical-align: middle;
}

.sign-up-form {
  margin-bottom: 36px;
}

.sign-up-social p {
  font-size: 14px;
  color: #222;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 18px;
}

.sign-up-social-item {
  display: flex;
  justify-content: space-between;
}

.sign-up-social-item a {
  padding: 0;
  border: 2px solid #ebebeb;
  border-radius: 0;
  width: 220px;
  height: 52px;
  transition: 0.3s;
}

.sign-up-social-item a i {
  width: 50px;
  height: 50px;
  line-height: 46px;
  text-align: center;
  border-right: 2px solid #ededed;
  font-size: 14px;
  color: #969696;
  vertical-align: middle;
  transition: 0.3s;
}

.sign-up-social-item a span {
  height: 50px;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  line-height: 50px;
  width: calc(100% - 54px);
  display: inline-block;
  color: #969696;
  vertical-align: middle;
  transition: 0.3s;
}

.sign-up-social-item a:hover {
  border: 2px solid #3473d2;
}

.sign-up-social-item a:hover i {
  color: #3473d2;
  border-right: 2px solid #3473d2;
}

.sign-up-social-item a:hover span {
  color: #3473d2;
}

/*--------------------------------------------------------------
  ##  Sign In
  --------------------------------------------------------------*/
.sign-in-area .sign-in-inner {
  max-width: 422px;
  margin: 0 auto;
  background: #fff;
  padding: 75px 40px;
  box-shadow: 0px 44px 62px rgba(0, 0, 0, 0.14);
  border: 6px solid rgba(0, 0, 0, 0.03);
}

.sign-in-area .sign-up-single-input {
  width: 100%;
  margin-bottom: 30px;
}

.sign-in-area .sign-up-social-item {
  flex-wrap: wrap;
}

.sign-in-area .sign-up-social-item a {
  width: 100%;
  margin-bottom: 10px;
}

.sign-in-area .sign-up-single-button {
  width: 100px;
}

/*--------------------------------------------------------------
  ##  Reset Pass
  --------------------------------------------------------------*/
.reset-pass-area .reset-pass-inner {
  max-width: 422px;
  margin: 0 auto;
  background: #fff;
  padding: 70px 40px;
  box-shadow: 0px 44px 62px rgba(0, 0, 0, 0.14);
  border: 6px solid rgba(0, 0, 0, 0.03);
}

.reset-pass-area .reset-pass-inner .sign-up-form {
  margin-bottom: 0;
}

.reset-pass-area .sign-up-single-input {
  width: 100%;
  margin-bottom: 30px;
}

.sign-up-magic-link {
  max-width: 422px;
  margin: 0 auto;
  background: #fff;
  padding: 130px 40px;
  box-shadow: 0px 44px 62px rgba(0, 0, 0, 0.14);
  border: 6px solid rgba(0, 0, 0, 0.03);
  margin-top: 108px;
  font-size: 14px;
  color: #121212;
  font-weight: 300;
  text-align: center;
}

/*--------------------------------------------------------------
  ##  Popup light
  --------------------------------------------------------------*/
#loading-subscribe1 .modal-dialog {
  width: 941px;
}

.loading-subscribe.loading-subscribe-white {
  min-width: 941px;
  background: #fff;
  margin: 0 auto;
  box-shadow: 22px 32px 70px rgba(0, 0, 0, 0.1);
  padding: 45px;
  position: relative;
}

.loading-subscribe-inner {
  border: 7px solid rgba(0, 0, 0, 0.03);
  padding: 65px 55px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.loading-subscribe-img {
  width: 356px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.loading-subscribe-detail {
  width: 60%;
}

.loading-subscribe-top h1 {
  font-size: 42.2px;
  font-weight: 400;
  color: #026782;
  text-transform: capitalize;
  margin: 0;
  margin-bottom: 30px;
}

.loading-subscribe-top h1 span {
  color: #45D638;
  font-weight: 600;
  font-style: italic;
}

.loading-subscribe-top p {
  font-size: 20px;
  color: #6f6f6f;
  margin-bottom: 30px;
}

.loading-subscribe-top p span {
  font-weight: 700;
  color: #121212;
}

.loading-subscribe-input {
  width: 100%;
  margin-bottom: 30px;
}

.loading-subscribe-input input {
  width: 100%;
  height: 66px;
  border: 2px solid #026782;
  padding: 0 20px;
  border-radius: 0;
  color: rgba(57, 57, 57, 0.6);
}

.loading-subscribe-input input:focus {
  border: 2px solid #45D638;
}

.loading-subscribe-submit button {
  border: none;
  height: 66px;
  border-radius: 0px;
  background: #3ad82e;
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  letter-spacing: 2.2px;
  text-transform: uppercase;
  cursor: pointer;
  font-family: "Roboto", sans-serif;
  line-height: 69px;
  width: 253px;
  padding: 0;
}

.loading-subscribe-submit button i {
  transition: 0.3s;
  transform: rotate(180deg);
  vertical-align: middle;
  font-size: 30px;
}

.loading-subscribe-submit button:hover i {
  margin-left: 10px;
}

.modal-dialog {
  max-width: -moz-max-content;
  padding: 0;
  border: none;
}

.modal-backdrop.show {
  opacity: .75;
}

.loading-subscribe {
  position: relative;
}

.loading-subscribe .remove {
  position: absolute;
  right: 0;
  top: -30px;
  cursor: pointer;
  z-index: -1;
}

.loading-subscribe .remove button {
  background: transparent;
  border: none;
  padding: 0;
}

.loading-subscribe .remove button:focus {
  outline: none;
}

.loading-subscribe .remove i {
  font-size: 20px;
  color: #fff;
}

/*--------------------------------------------------------------
  ##  Popup dark
  --------------------------------------------------------------*/
.loading-subscribe.loading-subscribe-dark {
  max-width: 941px;
  margin: 0 auto;
  background: url(../../media/images/popup/Dark.jpg) no-repeat scroll 0 0/cover;
}

.loading-subscribe.loading-subscribe-dark .loading-subscribe-top h1 {
  color: #fff;
}

.loading-subscribe.loading-subscribe-dark .loading-subscribe-top h1 span {
  color: #3db5e6;
}

.loading-subscribe.loading-subscribe-dark p span {
  font-weight: 500;
  color: #3db5e6;
}

.loading-subscribe.loading-subscribe-dark .loading-subscribe-input input {
  width: 100%;
  height: 66px;
  border: 2px solid rgba(255, 255, 255, 0.6);
  padding: 0 20px;
  border-radius: 0;
  color: rgba(255, 255, 255, 0.6);
  background: transparent;
}

.loading-subscribe.loading-subscribe-dark .loading-subscribe-input input:focus {
  border: 2px solid #3db5e6;
}

.loading-subscribe.loading-subscribe-dark .loading-subscribe-submit button {
  background: #3db5e6;
}

/*--------------------------------------------------------------
  ##  Cookies
  --------------------------------------------------------------*/
.megamenu-cookies {
  position: fixed;
  width: 273px;
  height: auto;
  background: #fff;
  bottom: 0;
  border: 12px solid #ebebeb;
  padding: 36px 15px 30px;
  border-radius: 10px;
  left: 7.4%;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: left bottom;
  -ms-transform-origin: left bottom;
  transform-origin: left bottom;
  -webkit-box-shadow: 22px 32px 70px rgba(0, 0, 0, 0.13);
  box-shadow: 22px 32px 70px rgba(0, 0, 0, 0.13);
  font-size: 14px;
  font-weight: 400;
  color: #121212;
  line-height: 25px;
  z-index: 99;
  opacity: 0;
}

.megamenu-cookies a {
  padding: 0;
  color: #45D638;
}

.megamenu-cookies a.agree {
  background: #026782;
  height: 54px;
  display: inline-block;
  line-height: 54px;
  width: 100%;
  text-align: center;
  margin-top: 10px;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  letter-spacing: 1.2px;
  transition: 0.5s;
}

.megamenu-cookies a.agree:hover {
  letter-spacing: 4px;
}

.megamenu-cookies.seen {
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55) 3s;
  -o-transition: all 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55) 3s;
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55) 3s;
}

.megamenu-cookies.seen .remove {
  cursor: pointer;
  position: absolute;
  top: 7px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .loading-subscribe.loading-subscribe-white {
    min-width: 550px;
    padding: 15px;
  }
  .modal-dialog {
    min-width: 550px;
  }
  #loading-subscribe1 .modal-dialog {
    width: 550px;
  }
  .loading-subscribe-inner {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 20px;
    height: 350px;
    overflow-y: scroll;
  }
  .loading-subscribe-img {
    position: static;
    top: 0;
    transform: translateY(0);
    margin: 0 auto;
  }
  .loading-subscribe-detail {
    width: 100%;
    margin-top: 30px;
  }
  .loading-subscribe.loading-subscribe-dark {
    max-width: 550px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .sign-up-inner {
    max-width: 80%;
  }
  .footer_five_area .footer_four_bottom_right a:first-child {
    padding-left: 0;
  }
  .loading-subscribe.loading-subscribe-dark {
    max-width: 550px;
  }
  .loading-subscribe.loading-subscribe-white {
    max-width: 100%;
    padding: 15px;
  }
  .modal-open .modal {
    overflow-y: scroll;
    width: 90%;
    margin: 0 auto;
  }
  .loading-subscribe-inner {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 20px;
    height: 350px;
    overflow-y: scroll;
    background: #fff;
  }
  .loading-subscribe.loading-subscribe-dark .loading-subscribe-inner {
    background: #222;
  }
  .loading-subscribe-detail {
    padding-left: 80px;
    padding-right: 80px;
  }
  #loading-subscribe1.modal.show .modal-dialog {
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .loading-subscribe.loading-subscribe-white {
    margin: 0;
  }
  .loading-subscribe-img {
    position: static;
    left: 0;
    top: 0;
    transform: translateY(0);
    width: 100%;
    text-align: center;
  }
  .loading-subscribe-inner {
    flex-wrap: wrap;
    padding: 20px;
  }
  .loading-subscribe-detail {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 80px;
  }
  #loading-subscribe1.modal.show .modal-dialog {
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .loading-subscribe.loading-subscribe-white {
    margin: 0;
  }
  .megamenu-cookies {
    left: 4%;
  }
  .loading-subscribe.loading-subscribe-white {
    min-width: 550px;
    padding: 15px;
  }
  .modal-dialog {
    min-width: 550px;
  }
  #loading-subscribe1 .modal-dialog {
    width: 550px;
  }
  .sign-up-area {
    padding-top: 97px;
    padding-bottom: 100px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .sign-up-social-item {
    flex-wrap: wrap;
  }
  .loading-subscribe.loading-subscribe-dark {
    max-width: 550px;
  }
  .sign-up-social-item a {
    margin-bottom: 10px;
  }
  .sign-up-inner {
    max-width: 90%;
  }
  .sign-up-area {
    padding-top: 87px;
    padding-bottom: 90px;
  }
  .loading-subscribe-detail {
    padding-bottom: 80px;
  }
  #loading-subscribe1 .modal-dialog {
    width: 500px;
  }
  .loading-subscribe.loading-subscribe-white {
    max-width: 100%;
    padding: 15px;
  }
  .modal-open .modal {
    overflow-y: scroll;
    width: 90%;
    margin: 0 auto;
  }
  .loading-subscribe-inner {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 20px;
    height: 350px;
    overflow-y: scroll;
    background: #fff;
  }
  .loading-subscribe.loading-subscribe-dark .loading-subscribe-inner {
    background: #222;
  }
  #loading-subscribe1.modal.show .modal-dialog {
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .loading-subscribe.loading-subscribe-white {
    margin: 0;
  }
  .megamenu-cookies {
    left: 5%;
  }
  .loading-subscribe.loading-subscribe-white {
    min-width: 500px;
    padding: 15px;
  }
  .modal-dialog {
    min-width: auto;
  }
  .loading-subscribe-img {
    position: static;
    left: 0;
    top: 0;
    transform: translateY(0);
    width: 100%;
    text-align: center;
  }
  .loading-subscribe-inner {
    flex-wrap: wrap;
    padding: 20px;
  }
  .loading-subscribe-detail {
    width: 100%;
    padding-top: 50px;
  }
  .sign-up-social-item a {
    width: 51%;
  }
}

@media only screen and (max-width: 575px) {
  /*--------------------------------------------------------------
  ##  Sign Up
  --------------------------------------------------------------*/
  .sign-up-inner {
    max-width: 100%;
  }
  .sign-up-single-input {
    width: 100%;
  }
  .sign-up-single-button {
    width: 100%;
    margin-bottom: 30px;
  }
  .sign-up-single-checkbox {
    width: 100%;
    padding-left: 0;
  }
  .sign-up-social-item {
    flex-wrap: wrap;
  }
  .sign-up-social-item a {
    margin-bottom: 10px;
  }
  .loading-subscribe.loading-subscribe-white {
    min-width: 100%;
    padding: 15px;
  }
  #loading-subscribe1 .modal-dialog {
    width: 100%;
  }
  .modal-dialog {
    min-width: 100%;
  }
  .megamenu-cookies {
    left: 4%;
  }
  .footer_five_area .footer_five_top .footer_five_top_right label::after {
    -webkit-transform: skewX(0deg);
    -ms-transform: skewX(0deg);
    transform: skewX(0deg);
  }
  .modal-open .modal {
    overflow-y: scroll;
    width: 90%;
    margin: 0 auto;
  }
  .loading-subscribe-inner {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 20px;
    height: 350px;
    overflow-y: scroll;
    background: #fff;
  }
  #loading-subscribe1.modal.show .modal-dialog {
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .loading-subscribe.loading-subscribe-white {
    margin: 0;
  }
  .loading-subscribe-img {
    position: static;
    left: 0;
    top: 0;
    transform: translateY(0);
    width: 100%;
    text-align: center;
  }
  .loading-subscribe-inner {
    flex-wrap: wrap;
    padding: 20px;
  }
  .loading-subscribe-detail {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 80px;
  }
  .loading-subscribe-dark .loading-subscribe-inner {
    background: #121212;
  }
  .sign-up-area {
    padding-top: 87px;
    padding-bottom: 87px;
  }
  .sign-up-inner {
    padding: 70px 30px;
  }
  .footer_five_area .footer_five_bottom .backtotop {
    position: static;
    width: 100%;
    text-align: center;
  }
  .sign-in-area .sign-in-inner {
    margin: 0 15px;
    max-width: 100%;
  }
}

@media only screen and (max-width: 479px) {
  .loading-subscribe-top h1 {
    font-size: 30px;
  }
  .loading-subscribe-top p {
    font-size: 16px;
  }
  .loading-subscribe-submit button {
    padding: 0 30px;
    width: 100%;
    font-size: 16px;
  }
  .sign-up-section-title h4 {
    margin-bottom: 10px;
  }
  .sign-up-section-title span {
    font-size: 16px;
  }
  .sign-up-area {
    padding-top: 87px;
    padding-bottom: 87px;
  }
  .sign-up-magic-link {
    margin-top: 30px;
  }
}

/*--------------------------------------------------------------
  ##  Responsive
  --------------------------------------------------------------*/
/*--------------------------------------------------------------
  ##  Responsive
  --------------------------------------------------------------*/
@media (min-width: 1200px) and (max-width: 1500px) {
  /* --- Product area --- */
  .single-product .product-content {
    width: 54%;
  }
  .single-product {
    padding: 5px 20px 5px 20px;
  }
  /* --- Top Feature area--- */
  .single_top_feature {
    color: #fff;
  }
  /* --- Top Feature area two --- */
  .single_feature_two {
    padding: 39px 15px 20px;
  }
  .single_feature_two h5 {
    font-size: 19px;
  }
  .single_feature_two {
    font-size: 16px;
  }
  .single_feature_two p {
    font-size: 16px;
    line-height: 26px;
  }
  /* --- Ingredient page  --- */
  .ingredient_content .mini-des {
    padding-bottom: 20px;
    margin-bottom: 30px;
  }
  .ingridient_list {
    margin-top: 40px;
  }
  .side_bar .widget:last-child {
    margin-bottom: 0;
  }
  .banner_two_area .banner_left {
    padding-left: 20px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  /* --- Top Feature Area --- */
  .single_top_feature .tf_icon {
    float: none;
    width: 100%;
    text-align: left;
  }
  .single_top_feature .tf_content {
    width: 100%;
    float: none;
    text-align: left;
    margin-top: 7px;
  }
  .single_top_feature {
    margin-top: 0px;
  }
  .single_top_feature .tf_content p {
    font-size: 14px;
  }
  .tf_content p {
    font-size: 16px;
  }
  .single_top_feature span {
    font-size: 25px;
  }
  .single_top_feature .tf_content h5 {
    font-size: 18px;
  }
  /* --- Product Area --- */
  .single-product .product-content {
    width: 55%;
  }
  .single-product {
    padding: 5px 0px 5px 20px;
  }
  .carousel-sin-product .carousel-product-image {
    margin-top: -226px;
    width: 92%;
  }
  .carousel-sin-product {
    padding: 5px 20px 5px 20px;
  }
  .carousel-sin-product .carousel-product-image {
    margin-top: -172px;
  }
  .carousel-sin-product .carousel-product-content {
    padding-top: 20px;
    width: 100%;
  }
  .ingredient_content h5 {
    font-size: 26px;
  }
  /* --- Features  Area --- */
  .single-features p {
    font-size: 16px;
  }
  .single-features {
    padding: 50px 40px 25px;
  }
  .services_area .padding_right {
    padding-right: 0;
  }
  .services_area .section_title p {
    font-size: 14px;
    line-height: 25px;
  }
  .section_title h2 {
    font-size: 26px;
  }
  .services_area .section_title {
    margin-bottom: 36px;
  }
  /* --- Section Padding --- */
  .products_area {
    padding: 100px 0 70px;
  }
  /* ---Blog page--- */
  .side_bar {
    padding-left: 0;
  }
  .widget_single_post .wid_post_content .post_att {
    font-size: 13px;
  }
  .widget_single_post .wid_post_content h6 a {
    font-size: 15px;
    font-weight: 600;
    text-transform: capitalize;
  }
  .widget {
    padding-right: 0;
  }
  .widget_newsletter .sidebar_newsletter {
    padding: 30px 15px;
  }
  .widget_tags ul li a {
    font-size: 15px;
    padding: 0px 11px;
  }
  .search-field {
    width: 80%;
  }
  .post_details p {
    font-size: 15px;
  }
  .sin_post blockquote {
    padding: 10px 50px;
  }
  .single_feature_two {
    font-size: 14px;
    line-height: 24px;
    padding: 39px 20px 20px;
  }
  .single_feature_two h5 {
    font-size: 15px;
  }
  .single_feature_two span {
    font-size: 30px;
    top: -12px;
  }
  .about_image .image_one {
    height: 370px;
    padding-top: 24px;
    margin-right: 0;
  }
  .about_image .image_two {
    height: 400px;
    padding-top: 36px;
  }
  .about_area .section_title h2 {
    font-size: 24px;
  }
  .about_area .section_title .svg {
    display: none;
  }
  .about_sub p {
    font-size: 14px;
    line-height: 24px;
  }
  .about_area .section_title > span {
    margin-bottom: 7px;
  }
  .carousel-sin-product .carousel-product-content p {
    font-size: 14px;
    line-height: 26px;
    padding-bottom: 58px;
  }
  /* ---Service area two--- */
  .single_service_two h6 {
    color: #383838;
    font-size: 16px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
  }
  .single_service_two p {
    font-size: 14px;
    line-height: 26px;
  }
  .single-servie h6 {
    font-size: 16px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
  }
  .single-servie p {
    font-size: 14px;
    line-height: 26px;
  }
  .services_area_bg .section_title {
    margin-bottom: 25px;
  }
  /* ---Feature three area-- */
  .single_feature_3 {
    padding: 60px 30px;
  }
  .single_feature_3 p {
    font-size: 14px;
    line-height: 26px;
  }
  .single_feature_3 h6 {
    margin-bottom: 18px;
    font-size: 16px;
  }
  .single_feature_3 a {
    font-size: 14px;
  }
  /* ---Ingredient page-- */
  .ingredient-img {
    padding: 95px 18px;
  }
  .ingredient_content .mini-des {
    padding-bottom: 30px;
    margin-bottom: 35px;
  }
  .ingridient_list h6 {
    margin-bottom: 38px;
  }
  .ingridient_list {
    margin-top: 40px;
  }
  .ingredient_content .mini-des ul li {
    font-size: 14px;
    margin-bottom: 3px;
  }
  .ingredient_content > p {
    font-size: 14px;
  }
  .ingridient_list table tr td {
    padding: 6px 0;
  }
  .ingridient_list table tr {
    font-size: 14px;
  }
  .ingredient_content h5 {
    margin-bottom: 40px;
  }
  .testimonial_pro_image {
    top: 160px;
  }
  .side_bar .widget {
    padding: 30px 15px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* --- Top Feature Area --- */
  .single_top_feature .tf_icon {
    float: none;
    width: 100%;
    text-align: center;
  }
  .footer_bottom_two .copyright span {
    margin-top: 0;
  }
  .single_top_feature .tf_content {
    width: 100%;
    float: none;
    text-align: center;
    margin-top: 7px;
  }
  .single_top_feature {
    margin-top: 27px;
  }
  #footer .widget.widget_about {
    padding-right: 0;
  }
  .tf_content p {
    font-size: 16px;
  }
  .single_top_feature span {
    font-size: 25px;
  }
  .single_top_feature .tf_content h5 {
    font-size: 18px;
  }
  .tf_image {
    text-align: center;
  }
  .top_feature_area {
    padding: 35px 0;
  }
  /* --- Product Area --- */
  .single-product {
    padding: 15px;
    width: 49%;
    float: left;
    margin: 3px;
    margin-bottom: 6px;
    height: 267px;
  }
  .single-product .product-content {
    width: 59%;
    float: left;
    padding-top: 0px;
  }
  .product-image {
    width: 40%;
    overflow: hidden;
  }
  .single-features {
    padding: 30px 10px;
  }
  .single-features p {
    font-size: 14px;
    line-height: 26px;
  }
  .single-features h5 {
    margin-top: 18px;
    margin-bottom: 27px;
    font-size: 18px;
  }
  .single-features h5::after {
    top: 30px;
  }
  .single-product .product-content h3 {
    margin-bottom: 10px;
  }
  /* --- Service Area --- */
  .services_area .image-box-half {
    display: none;
  }
  /* --- Teatimonial  Area --- */
  .single-testimonial, .single-testimonial-two {
    padding: 0 25px;
  }
  /* --- Portfolio  Area --- */
  .sin-portfolio .portfolio_details h5 {
    font-size: 16px;
  }
  /* --- Section padding --- */
  .testimonial_area {
    padding: 80px 0 69px;
  }
  .testimonial_area_two {
    padding: 80px 0;
  }
  .products_area {
    padding: 80px 0;
  }
  .services_area .section_title {
    margin-bottom: 24px;
  }
  .load_more_portfolio .btn_wrapper {
    margin: 54px auto 0;
  }
  /* --- Blog page --- */
  .widget_single_post .post_thumb {
    width: auto;
  }
  .side_bar {
    max-width: 75%;
    margin: 100px auto 0;
    padding-left: 0px;
  }
  .page_content {
    padding: 90px 0 5px;
  }
  .post_details p {
    font-size: 15px;
  }
  .sin_post blockquote {
    padding: 10px 50px;
  }
  /* --- Feature area two --- */
  .single_feature_two {
    margin-bottom: 45px;
  }
  .top_feature_area_two {
    padding: 60px 0 10px;
  }
  #feature {
    padding-bottom: 0.8rem;
  }
  #portfolio, #ingredient, #work_process {
    padding-bottom: 5rem;
  }
  .section_padding_less {
    padding: 5rem 0;
  }
  #blog {
    padding-bottom: 3rem;
  }
  .single_feature_two h5 {
    font-size: 18px;
  }
  /* --- About area --- */
  .about_image .image_one {
    height: 470px;
    padding-top: 88px;
  }
  .about_image .image_two {
    height: 528px;
    padding-top: 116px;
  }
  .about_image .image_one, .about_image .image_two {
    height: auto;
    padding-top: 0;
  }
  .about_area .section_title .svg {
    display: none;
  }
  .about_sub {
    padding-bottom: 30px;
  }
  /* ---Service area two--- */
  .single_service_two h6 {
    color: #383838;
    font-size: 16px;
  }
  .single_service_two p {
    font-size: 14px;
    line-height: 26px;
  }
  /* ---Service area with background--- */
  .services_area_bg::before, .services_area_bg::after {
    display: none;
  }
  /* ---Feature three area-- */
  .single_feature_3 {
    padding: 40px 15px;
  }
  .single_feature_3 p {
    font-size: 14px;
    line-height: 26px;
  }
  .single_feature_3 h6 {
    margin-bottom: 18px;
    font-size: 16px;
  }
  .single_feature_3 a {
    font-size: 14px;
  }
  .gredient_tab {
    display: none;
  }
  .ingredient-img {
    margin: 0 auto;
    width: 52%;
    padding: 25px 0;
    margin-bottom: 50px;
    height: auto;
  }
  .ingredient_content .mini-des {
    padding-bottom: 20px;
    margin-bottom: 30px;
  }
  .ingridient_list {
    margin-top: 40px;
  }
  .ingredient_content .mini-des ul li {
    font-size: 14px;
    margin-bottom: 3px;
  }
  .ingredient_content > p {
    font-size: 14px;
  }
  .ingridient_list table tr td {
    padding: 6px 0;
  }
  .ingridient_list table tr {
    font-size: 14px;
  }
  .ingredient_content h5 {
    margin-bottom: 40px;
  }
  .ingredient_content h5 {
    display: none;
  }
  .testimonial_pro_image {
    top: 45%;
    width: 400px;
  }
  .call_to_action_right {
    margin-top: 15px;
  }
  .breadcrumb_area {
    padding-top: 110px;
  }
  .page_header h1 {
    line-height: normal;
    font-size: 2rem;
    padding-bottom: 15px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .single_top_feature .tf_icon {
    float: none;
    width: 100%;
    text-align: center;
  }
  .top_feature_area_two {
    padding-bottom: 20px;
  }
  .single_pkg_bottom {
    padding: 2rem;
  }
  .single_top_feature .tf_content {
    width: 100%;
    float: none;
    text-align: center;
    margin-top: 7px;
  }
  .single_top_feature {
    margin-top: 0;
    margin-bottom: 30px;
  }
  .top_feature_border {
    padding-bottom: 0;
  }
  .tf_content p {
    font-size: 14px;
    line-height: 25px;
  }
  .single_top_feature span {
    font-size: 25px;
  }
  .single_top_feature .tf_content h5 {
    font-size: 18px;
  }
  .tf_image {
    text-align: center;
  }
  .top_feature_area {
    padding: 35px 0;
  }
  .tf_image {
    text-align: center;
    padding: 0 16%;
    margin-top: 10px;
  }
  .single-product .product-content {
    width: 55%;
  }
  .carousel-sin-product .carousel-product-image {
    margin-top: -252px;
    width: 59%;
  }
  /* --- Features Area --- */
  .single-features {
    width: 75%;
    margin: 0 auto;
    margin-top: 30px;
  }
  .single-features:hover {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  /* --- Service Area --- */
  .services_area .image-box-half {
    width: 50%;
    margin: 0 auto;
    margin-bottom: 50px;
  }
  .single-servie p {
    font-size: 14px;
    line-height: 26px;
  }
  /* --- Testimonial  Area --- */
  .single-testimonial {
    padding: 0 30px;
  }
  .single-testimonial p {
    line-height: 36px;
    font-size: 18px;
  }
  /* --- Testimonial  Area Two--- */
  .single-testimonial-two {
    padding: 0 30px;
  }
  .single-testimonial-two p {
    line-height: 36px;
    font-size: 18px;
  }
  /* --- Portfolio  Area --- */
  .filter-button li {
    margin-bottom: 10px;
  }
  /* --- Portfolio  Area --- */
  .sin-portfolio .portfolio_details h5 {
    font-size: 16px;
  }
  /* --- Section padding --- */
  .testimonial_area {
    padding: 80px 0 68px;
  }
  .testimonial_area_two {
    padding: 80px 0 68px;
  }
  .products_area {
    padding: 80px 0;
  }
  .services_area .section_title {
    margin-bottom: 24px;
  }
  .load_more_portfolio .btn_wrapper {
    margin: 54px auto 0;
  }
  .features_area .section_title {
    margin-bottom: 5px;
  }
  /* --- Blog page --- */
  .pagination ul li {
    margin-right: 15px;
  }
  .side_bar {
    padding-left: 0;
  }
  .side_bar {
    width: 86%;
    margin: 50px auto 0;
  }
  .post_details p {
    font-size: 15px;
  }
  .sin_post blockquote {
    padding: 10px 50px;
  }
  .comment-content p {
    font-size: 15px;
  }
  /* --- Feature area two --- */
  .single_feature_two {
    margin-bottom: 60px;
  }
  .single_feature_two h5 {
    font-size: 18px;
  }
  .single_feature_two span {
    font-size: 32px;
    top: -30px;
  }
  .single_feature_two {
    padding: 39px 15px 20px;
  }
  /* --- About area --- */
  .about_image .image_one {
    height: 470px;
    padding-top: 88px;
  }
  .about_image .image_two {
    height: 528px;
    padding-top: 116px;
  }
  .about_image .image_one, .about_image .image_two {
    height: auto;
    padding: 30px 0;
  }
  .about_area .section_title .svg {
    display: none;
  }
  .about_sub {
    padding-bottom: 30px;
  }
  /* ---Service area two--- */
  .single_service_two h6 {
    color: #383838;
    font-size: 1rem;
  }
  .single_service_two p {
    font-size: 14px;
    line-height: 26px;
  }
  /* ---Service area with background--- */
  .services_area_bg::before, .services_area_bg::after {
    display: none;
  }
  .pl-60 {
    padding-left: 15px;
  }
  .single_feature_3 {
    margin-bottom: 30px;
  }
  .single_feature_3 {
    width: 75%;
    margin: 0 auto 30px;
  }
  /* ---Ingredient page--- */
  .gredient_tab {
    display: none;
  }
  .ingredient-img {
    margin: 0 auto;
    width: 52%;
    padding: 25px 0;
    margin-bottom: 50px;
    height: auto;
  }
  .ingredient_content .mini-des {
    padding-bottom: 20px;
    margin-bottom: 30px;
  }
  .ingridient_list {
    margin-top: 40px;
  }
  .ingredient_content .mini-des ul li {
    font-size: 14px;
    margin-bottom: 3px;
  }
  .ingredient_content > p {
    font-size: 14px;
  }
  .ingridient_list table tr td {
    padding: 6px 0;
  }
  .ingridient_list table tr {
    font-size: 14px;
  }
  .ingredient_content h5 {
    margin-bottom: 40px;
  }
  .ingredient_content h5 {
    display: none;
  }
  .slick_slider_two .slick-next.slick-arrow {
    top: 225px;
  }
  .slick_slider_two .slick-prev.slick-arrow {
    top: 225px;
  }
  .breadcrumb_area {
    padding-bottom: 0px;
    padding-top: 98px;
  }
  .page_header h1 {
    font-size: 2rem;
    padding-bottom: 5px;
  }
  .banner_left .banner_left_cell h2 {
    padding-top: 66px;
  }
  #footer .widget.widget_about_two {
    padding-right: 0;
  }
}

@media (max-width: 575px) {
  .single_top_feature .tf_icon {
    float: none;
    width: 100%;
    text-align: center;
  }
  .widget_single_post .wid_post_content {
    width: 72%;
    padding-left: 15px;
    padding-top: 0;
  }
  .single_top_feature .tf_content {
    width: 100%;
    float: none;
    text-align: center;
    margin-top: 7px;
  }
  .comment-body .reply {
    top: 60px;
  }
  .single_top_feature {
    margin-bottom: 30px;
  }
  .tf_content p {
    font-size: 14px;
    line-height: 25px;
  }
  .single_top_feature span {
    font-size: 25px;
  }
  .single_top_feature .tf_content h5 {
    font-size: 18px;
  }
  .tf_image {
    text-align: center;
  }
  .top_feature_area {
    padding: 35px 0;
  }
  .tf_image {
    text-align: center;
    padding: 0 16%;
    margin-top: 10px;
    margin-bottom: 25px;
  }
  .single-product {
    padding: 0;
  }
  .single-product .product-content {
    width: 100%;
    padding-top: 20px;
    text-align: center;
  }
  .single-product .product-content > span {
    margin-bottom: 11px;
  }
  .single-product .btn_wrapper {
    margin: 0 auto;
    margin-top: 6px;
  }
  .product-image {
    text-align: center;
  }
  .carousel-sin-product {
    padding: 0 20px;
    margin-bottom: 0;
  }
  .carousel-sin-product .carousel-product-content {
    padding-top: 20px;
    width: 100%;
  }
  .carousel-sin-product .carousel-product-image {
    margin-top: 20px;
    width: 74%;
  }
  .carousel-sin-product .carousel-product-content p {
    font-size: 16px;
    line-height: 26px;
  }
  .carousel-sin-product .carousel-product-content > span {
    font-size: 15px;
  }
  /* --- Features Area --- */
  .single-features {
    margin-top: 30px;
  }
  .single-features:hover {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  .single-features p {
    font-size: 15px;
    line-height: 28px;
  }
  /* --- Service Area --- */
  .image-box-half {
    margin-bottom: 30px;
  }
  .services_area .section_title {
    text-align: left;
    margin-bottom: 50px;
  }
  .services_area .padding_right {
    padding-right: 0;
  }
  .single-servie {
    margin-top: 0;
    text-align: left;
  }
  /* --- Testimonial Area --- */
  .single-testimonial {
    padding: 0;
  }
  .single-testimonial p {
    line-height: 33px;
    font-size: 16px;
  }
  .single-testimonial .tes-name {
    margin: 15px auto;
  }
  /* --- Testimonial  Area Two--- */
  .single-testimonial-two {
    padding: 0;
  }
  .single-testimonial-two p {
    line-height: 33px;
    font-size: 16px;
  }
  .testimonial_pro_image {
    top: 260px;
    width: 300px;
  }
  .single-testimonial-two p {
    margin-bottom: 27px;
  }
  .single-testimonial-two .tes-name {
    margin: 16px auto 40px;
  }
  /* --- Portfolio Area --- */
  .sin-portfolio .portfolio_details {
    padding-left: 12px;
  }
  .sin-portfolio .portfolio_details h5 {
    font-size: 15px;
  }
  .filter-button li {
    margin-bottom: 20px;
  }
  .filter-button li:hover {
    border: 0px solid #2acb35;
  }
  /* --- Portfolio  Area --- */
  .sin-portfolio .portfolio_details h5 {
    font-size: 16px;
  }
  /* --- Section Global Responsive --- */
  .testimonial_area {
    padding: 80px 0 69px;
  }
  .testimonial_area_two {
    padding: 80px 0;
  }
  .products_area {
    padding: 80px 0;
  }
  .services_area .section_title {
    margin-bottom: 63px;
  }
  .load_more_portfolio .btn_wrapper {
    margin: 54px auto 0;
  }
  .features_area .section_title {
    margin-bottom: 5px;
  }
  .page_content {
    padding: 72px 0;
  }
  /* --- Blog page--- */
  .sin_post h3 {
    font-size: 20px;
  }
  .post_meta a i {
    display: none;
  }
  .post_meta p a {
    font-size: 12px;
  }
  .post_meta p a {
    font-size: 12px;
    padding: 0 12px 0 0;
  }
  .post_meta p a:nth-child(2)::after {
    display: none;
  }
  .post_meta p a:first-child::after {
    display: none;
  }
  .pagination ul li:last-child {
    padding: 5px 0px;
  }
  .pagination ul li:first-child {
    padding: 5px 0px;
  }
  .pagination ul li {
    margin-right: 0px;
  }
  .side_bar {
    padding-left: 0;
    margin-top: 35px;
  }
  .widget_single_post .wid_post_content h6 a {
    font-size: 15px;
    font-weight: 600;
    text-transform: capitalize;
  }
  .widget {
    padding-right: 0;
  }
  .widget_single_post .post_thumb {
    width: 70px;
    margin-bottom: 10px;
  }
  .side_bar .widget {
    padding: 30px 15px;
  }
  .widget_newsletter .sidebar_newsletter {
    padding: 40px 12px;
  }
  .post_meta {
    margin-bottom: 10px;
  }
  .post_details p {
    font-size: 15px;
  }
  .sin_post blockquote {
    padding: 0;
    margin: 0;
  }
  .comment-meta {
    display: inline-block;
    width: 100%;
  }
  .comment-content .reply i {
    display: none;
  }
  .children {
    padding-left: 0;
  }
  .comment-content .reply i {
    display: none;
  }
  .sin-portfolio .portfolio_details {
    width: 96%;
  }
  .single-product .product-content h3 {
    margin-bottom: 7px;
  }
  .vigo_container_one, .vigo_container_two, .container {
    max-width: 450px;
  }
  /* --- Feature area two--- */
  .single_feature_two {
    margin-top: 45px;
  }
  .single_feature_two span {
    font-size: 38px;
    top: -18px;
  }
  .top_feature_area_two {
    padding: 0px 0 80px;
  }
  /* --- About area --- */
  .about_image .image_one {
    height: 470px;
    padding-top: 88px;
  }
  .about_image .image_two {
    height: 528px;
    padding-top: 116px;
  }
  .about_image .image_one, .about_image .image_two {
    height: auto;
    padding: 15px 0;
  }
  .about_area .section_title .svg {
    display: none;
  }
  .about_sub {
    padding-bottom: 30px;
  }
  .about_sub p {
    font-size: 14px;
    line-height: 24px;
  }
  /* ---Service area two--- */
  .single_service_two h6 {
    color: #383838;
    font-size: 16px;
  }
  .single_service_two p {
    font-size: 14px;
    line-height: 26px;
  }
  .single_service_two {
    margin-bottom: 30px;
  }
  .service_two.section_padding {
    padding: 60px 0 25px;
  }
  /* ---Service area with background--- */
  .services_area_bg.services_bg_two::before, .services_area_bg.services_bg_two::after {
    background: #f8f8f8;
    width: 100%;
  }
  .pl-60 {
    padding-left: 15px;
  }
  .single_feature_3 {
    margin-bottom: 30px;
  }
  /* ---Ingredient page--- */
  .gredient_tab {
    display: none;
  }
  .ingredient-img {
    margin: 0 auto;
    width: 52%;
    padding: 25px 0;
    margin-bottom: 50px;
    height: auto;
  }
  .ingredient_content .mini-des {
    padding-bottom: 20px;
    margin-bottom: 30px;
  }
  .ingridient_list {
    margin-top: 40px;
  }
  .ingredient_content .mini-des ul li {
    font-size: 14px;
    margin-bottom: 3px;
  }
  .ingredient_content > p {
    font-size: 14px;
  }
  .ingridient_list table tr td {
    padding: 6px 0;
  }
  .ingridient_list table tr {
    font-size: 14px;
  }
  .ingredient_content h5 {
    margin-bottom: 40px;
  }
  .ingredient_content h5 {
    display: none;
  }
  .slick_slider_two .slick-next.slick-arrow {
    top: 0;
  }
  .slick_slider_two .slick-prev.slick-arrow {
    top: 0;
  }
  .ingredient_content_heading h5 {
    margin-bottom: 25px;
    height: 59px;
  }
  .ingredient_content_heading h5::after {
    display: none;
  }
  .ingridient_list table {
    margin: 0;
  }
  .breadcrumb_area {
    padding-bottom: 0px;
    padding-top: 93px;
  }
}

@media (min-width: 1500px) and (max-width: 1699px) {
  .home_five_single_service {
    width: 31.89%;
  }
}

@media (min-width: 1200px) and (max-width: 1499px) {
  .vigo_container_one, .vigo_container_two {
    max-width: 1170px;
  }
  .main_menu > ul > li > a {
    padding: 0.8rem 1rem 0.6rem;
  }
  .header_nav .btn_wrapper {
    margin: 2.5rem 0 0;
    height: 2.2rem;
  }
  .main_menu {
    padding: 2rem 0;
  }
  .banner_area .owl-dots {
    display: none;
  }
  .megamenu .column:last-child {
    padding-right: 0px;
  }
  .widget .widget_payment span {
    display: block;
  }
  .services_area.section_padding {
    padding-bottom: 2.7rem;
  }
  .banner_left .banner_left_cell h1 {
    font-size: 2rem;
  }
  .header_nav .btn_wrapper {
    width: 8rem;
  }
  .promote_sub ul li span {
    font-size: 0.89rem;
  }
  .promote_right {
    position: absolute;
    bottom: -70px;
    width: 66%;
  }
  .promote_sub {
    padding: 2rem;
    margin-left: 15px;
  }
  .promote_sub h2 {
    font-size: 1.5rem;
  }
  .single_pkg_bottom p {
    font-size: 0.9rem;
  }
  .footer_top_two .widget.widget_about_two {
    padding-right: 0;
  }
  .widget_instafeed .widget_insta_list ul li {
    width: 82px;
    height: 82px;
  }
  .widget_recent_post .widget_post_list li a span {
    margin-right: 7px;
  }
  .footer_top_two .widget {
    font-size: 0.9rem;
  }
  .supplement_service_img {
    -webkit-transform: translateY(-25%);
    -ms-transform: translateY(-25%);
    transform: translateY(-25%);
  }
  .promote_area.section_padding {
    padding-bottom: 120px;
  }
  .advertise_text_left {
    width: 65%;
  }
  .advertise_text_right {
    margin-top: 30px;
  }
  .banner_left .banner_left_cell p {
    margin-bottom: 3rem;
    font-size: 20px;
    line-height: 30px;
  }
  .main_menu {
    padding: 1.3rem 0;
  }
  .banner_two_area .banner_left .banner_left_cell h1 {
    font-size: 55px;
    line-height: 57px;
    margin-bottom: 10px;
  }
  .banner_two_area .banner_left .banner_left_cell p {
    margin-bottom: 30px;
  }
  .why_use_area {
    background-size: contain;
    background-repeat: no-repeat;
  }
  .tesm_three_content {
    background-position: center center;
  }
  .quote_mark {
    left: calc(50% - 32px);
  }
  .feature_benifit_area .card.active {
    padding: 30px 20px;
  }
  .sn_pd_two_offer {
    right: 32px;
    top: 98px;
  }
  .call_to_action_left_two {
    margin-right: 30px;
  }
  .sn_pd_two_slide {
    padding: 30px 20px;
  }
  .services_bg_two .pl-60 {
    padding-left: 30px;
  }
  .promote_sub {
    left: 0;
  }
  .backtotop.backtop_two {
    right: 0;
  }
  .promote_area.promote_area_one.section_padding {
    text-align: center;
  }
  .promote_area.promote_area_one .promote_sub {
    left: 0;
    text-align: left;
    margin-bottom: 50px;
  }
  .banner_area.banner_three .banner_left .banner_left_cell h1 {
    font-size: 55px;
    line-height: 55px;
  }
  .home_five_single_service {
    width: 31.2%;
  }
  .home_five_single_service_right h3 {
    font-size: 18px;
    letter-spacing: 1.5px;
  }
  .home_five_single_service_right p {
    margin-bottom: 0;
  }
  .footer_five_area .widget.widget5 .widget5_about {
    padding-right: 26px;
    margin-top: 0;
  }
  .home_five_single_service_inner img {
    padding-top: 28px;
  }
  .home_five_single_service_img {
    left: -35px;
  }
  .home_five_single_service:last-child .home_five_single_service_img {
    right: -33px;
  }
  .home5_offer_center h2 {
    font-size: 53px;
  }
  .home5_recent_slider .owl-nav {
    width: 100%;
    left: 0;
  }
  .home5_video_left {
    width: 40%;
    padding-right: 100px;
  }
  .home5_video_right {
    width: 59%;
  }
  .banner_static .banner_static_left_cell h1 span {
    font-size: 68px !important;
  }
  .home5_video_total .section_title_four {
    margin-left: -35px;
    z-index: 9;
  }
  .home5_offer_social {
    padding-right: 0;
  }
  .home5_offer_center h2::after {
    left: 12px;
    top: -90%;
  }
  .home5-most-sold .sn_related_product {
    padding: 30px 10px;
  }
  .footer_five_area .widget5_social a {
    width: 30px;
    height: 30px;
    line-height: 26px;
  }
  .main_menu > ul {
    padding-left: 0;
  }
  .sn_bnr_slide {
    padding: 0 30px;
  }
  .banner_area {
    height: 75vh;
  }
  .banner_right, .banner_left {
    padding-top: 125px;
    height: 75vh;
  }
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .vigo_container_one, .vigo_container_two {
    max-width: 1140px;
  }
  .home5_offer_left {
    left: -164px;
  }
  .home5_offer_center h2::after {
    left: 15px;
    top: -92%;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .vigo_container_one,
  .vigo_container_two {
    max-width: 960px;
  }
  .banner_right, .banner_left {
    padding-top: 99px;
  }
  .banner_area {
    height: 100vh;
  }
  .banner_right, .banner_left {
    height: 100vh;
  }
  #example-one {
    padding-left: 0;
  }
  .main_menu > ul > li > a {
    font-size: 0.9rem;
    padding: 0.7rem 0.5rem 0.5rem;
  }
  .btn_wrapper {
    width: 8rem;
  }
  .sn_post_detail p a {
    padding: 0 0.2rem;
  }
  .promote_area_one.promote_area.section_padding {
    text-align: center;
  }
  .promote_area_one .promote_sub {
    width: 65%;
    text-align: left;
    margin-bottom: 50px;
  }
  .sn_post_detail p a:last-child {
    margin-left: 0;
  }
  .btn_one {
    font-size: 0.9rem;
  }
  .widget {
    padding-right: 0;
    margin-bottom: 0;
  }
  .widget .widget_payment span {
    display: block;
  }
  .footer_top_two .widget {
    margin-bottom: 40px;
  }
  .ingredient_slider_one .slick-prev, .ingredient_slider_one .slick-next {
    top: 86%;
  }
  .footer_top_two.section_padding {
    padding-bottom: 3.8rem;
  }
  .widget.widget_store_links,
  .widget.widget_instafeed {
    padding-left: 60px;
  }
  .contact_content {
    padding-left: 30px;
  }
  .product_detail_desc {
    top: 5rem;
    padding: 3rem 0 0 1.7rem;
  }
  .product_detail_slider {
    width: calc(50% + 70px);
  }
  .question {
    padding: 1rem;
  }
  .question span {
    line-height: 1.3rem;
  }
  .ans_body {
    padding: 1rem;
  }
  .faq_right_bg {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .btn_wrapper {
    width: 9rem;
  }
  .header_nav .btn_wrapper {
    width: 7rem;
  }
  .pricing_head h6 {
    font-size: 1rem;
  }
  .about_section {
    padding: 0;
  }
  .banner_left .banner_left_cell h1 {
    font-size: 2rem;
  }
  .banner_right {
    position: static;
  }
  .banner_left {
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    margin-top: 10px;
  }
  #feature {
    padding-bottom: 3rem;
  }
  .widget_instafeed .widget_insta_list ul li {
    width: 105px;
    height: 105px;
  }
  .supp_ser_list,
  .supplement_service_area .section_title {
    width: 75%;
    margin: 0 auto 40px;
  }
  .supp_ser_list {
    margin: 0 auto 80px;
  }
  .promote_area.section_padding {
    padding-bottom: 110px;
  }
  .package_plan_area.section_padding {
    padding-bottom: 60px;
  }
  .single_pkg_plan {
    max-width: 339px;
    margin: 0 auto;
  }
  .single_pkg_plan {
    margin-bottom: 60px;
  }
  .advertise_text_left {
    width: 74%;
  }
  .advertise_text_right {
    margin-top: 31px;
  }
  .advertise_area .row.background_white {
    padding: 30px 0;
  }
  .banner_two_area .banner_left .banner_left_cell h1 {
    font-size: 55px;
    line-height: 60px;
  }
  .tesm_three_content {
    background-position: center center;
  }
  .quote_mark {
    top: -55px;
    left: calc(50% - 31px);
  }
  .hdr_area_two .btn_one {
    line-height: 38px;
  }
  .hdr_area_two .btn_one, .hdr_area_two .btn_two {
    width: 125px;
  }
  .hdr_area_two .main_menu li:hover .sub-menu {
    padding-left: 26px;
  }
  .why_use_area {
    background-size: contain;
    background-repeat: no-repeat;
  }
  .banner_two_area .banner_left .banner_left_cell p {
    margin-bottom: 50px;
  }
  .quote_mark {
    top: -58px;
  }
  .banner_two_area .quote_mark {
    height: calc(100vh - 147px);
  }
  .sn_pd_two_slide {
    padding: 20px;
  }
  .sn_pd_two_detail h4 {
    display: inline;
  }
  .sn_pd_two_offer {
    right: 12%;
    top: 26%;
  }
  .widget.widget_three .widget_nwsfeed_list a {
    width: 43px;
    height: 43px;
  }
  .banner_two_area .banner_left {
    height: calc(100vh - 147px);
  }
  .client_talk {
    display: block;
  }
  .client_talk p {
    margin-bottom: 10px;
    margin-right: 0;
  }
  .single_promote .btn_wrapper {
    margin: 0 auto;
  }
  .single_pkg_bottom .btn_wrapper {
    margin: 0 auto;
  }
  .section_title_two h2 {
    font-size: 28px;
    line-height: 36px;
  }
  .form_group .btn_wrapper {
    margin-top: 0;
    margin: 0 auto;
  }
  .error_content_area .btn_wrapper {
    margin: 0 auto;
  }
  .error_content_area .btn_one,
  .error_content_area .btn_two {
    padding: 0 21px;
  }
  .banner_area .btn_wrapper {
    margin: 0 auto;
  }
  .quickview-wrapper .ingredient_slider_two .ingredient-img {
    padding: 50px;
  }
  .modal.quickview-wrapper::before {
    width: calc(100% - 138px);
  }
  .hdr_area_two .main_menu > ul > li > a {
    padding: 0 8px;
  }
  .call_to_action_area_two::before {
    display: none;
  }
  .backtotop.backtop_two {
    right: 0;
    bottom: 66px;
  }
  .single_service_two {
    padding: 0px 0px;
  }
  .services_bg_two .pl-60 {
    padding-left: 30px;
  }
  .ingredeint_section_three .ingredient_content h5 {
    font-size: 26px;
  }
  .call_to_action_left {
    width: 82%;
  }
  .pl-60 {
    padding-left: 30px;
  }
  .services_area .section_title h2 {
    line-height: 30px;
  }
  .banner_area.banner_three .banner_left .banner_left_cell h1 {
    font-size: 53px;
    line-height: 58px;
  }
  .banner_area.banner_three .banner_left .banner_left_cell p {
    margin-bottom: 5rem;
  }
  .supplement_service_area_two.section_padding_two {
    padding-bottom: 90px;
  }
  .banner_two_area .owl-prev {
    top: calc(50% - 40px);
    left: -15px;
    width: 40px;
    height: 80px;
    border-top-right-radius: 80px;
    border-bottom-right-radius: 80px;
  }
  .banner_two_area .owl-next {
    top: calc(50% - 40px);
    width: 40px;
    height: 80px;
    border-top-left-radius: 80px;
    border-bottom-left-radius: 80px;
  }
  .banner_two_area .owl-next i {
    margin-right: 5px;
  }
  .banner_two_area .owl-prev i {
    margin-left: 5px;
  }
  .home_five_single_service {
    width: 55%;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 50px;
  }
  .hdr_area_four .main_menu > ul > li > a {
    padding: 0 7px;
  }
  .hdr_area_four .main_menu > ul > li > a {
    font-size: 12px;
  }
  .banner_static .banner_static_left_cell h1 span {
    font-size: 54px !important;
  }
  .banner_static .banner_static_left_cell h1 {
    line-height: 60px !important;
  }
  .banner_static_download p {
    font-size: 12px !important;
    margin-bottom: 10px !important;
  }
  .banner_static .banner_static_left_cell h1::after {
    left: 0;
    top: -50%;
    height: 250px;
  }
  .hdr_area_four .main_menu > ul > li > a::after {
    left: 0;
  }
  .home5_offer_social {
    padding-right: 0;
  }
  .home5_offer_center h2 {
    font-size: 36px;
  }
  .home5_offer_center h2::after {
    left: 0;
    top: -62px;
  }
  .home5_offer_right span {
    left: auto;
    right: 40px;
  }
  .home5_video_right {
    width: 54%;
  }
  .home5_video_left {
    width: 45%;
    padding-right: 0;
  }
  .home5_recent_slider .owl-nav {
    left: 0;
    width: 100%;
  }
  .home5_video_total .section_title_four {
    z-index: 9;
  }
  .sn_pd_detail a {
    display: block;
  }
  .home5_pricing_single {
    margin-bottom: 50px;
  }
  .footer_five_area .footer_five_top {
    display: block;
  }
  .footer_five_area .footer_five_middle_flex {
    flex-flow: wrap;
  }
  .footer_five_area .widget.widget5 {
    width: 33.33%;
    margin-bottom: 50px;
  }
  .footer_five_area .widget.widget5:last-child {
    width: 63%;
  }
  .footer_five_area .footer_five_middle {
    padding: 95px 0 15px;
  }
  .footer_five_top_left {
    text-align: center;
    margin-bottom: 20px;
  }
  .loading-subscribe.loading-subscribe-white {
    max-width: 700px;
    min-width: auto;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  body {
    font-size: 0.9rem;
  }
  .vigo_container_one,
  .vigo_container_two {
    max-width: 720px;
  }
  h2 {
    font-size: 1.2rem;
  }
  h4 {
    font-size: 1rem;
  }
  h6 {
    font-size: 1rem;
  }
  .btn_wrapper {
    width: 9rem;
    height: 3rem;
  }
  .single_promote .btn_wrapper {
    margin: 0 auto;
  }
  .single_pkg_bottom .btn_wrapper {
    margin: 0 auto;
  }
  .section_padding {
    padding: 5rem 0;
  }
  .section_title {
    margin-bottom: 2rem;
  }
  .footer_top_two.section_padding {
    padding-bottom: 2rem;
  }
  .btn_one {
    line-height: 2rem;
  }
  .widget .widget_form form button {
    width: 44px;
  }
  .widget .widget_payment a i {
    font-size: 1.6rem;
  }
  .footer_top .widget {
    padding-right: 0;
    margin-bottom: 0;
  }
  .contact_content {
    padding-left: 30px;
  }
  .section_title h2 {
    line-height: 36px;
    font-size: 24px;
  }
  .pl-60 {
    padding-left: 0;
  }
  .video_area_two .about_section {
    padding: 0;
  }
  .form_group.half {
    width: 100%;
    padding: 0;
  }
  .error_content_area.section_padding {
    padding: 80px 0;
  }
  .error_content_area.error_content_two.section_padding {
    padding: 112px 0 90px;
  }
  .sn_post_detail p a:last-child {
    margin-left: 0;
  }
  .sn_post_detail p a {
    padding: 0 0.5rem;
  }
  .contact_page_form {
    padding-bottom: 80px;
  }
  .contact_content_two .contact_page_form {
    padding-bottom: 120px;
  }
  .product_detail_desc {
    padding: 1rem 0 0 1rem;
    height: 100%;
    top: 2rem;
  }
  .backtotop.backtotop_three {
    bottom: -2px;
    right: -12px;
  }
  .product_detail_slider {
    width: calc(50% + 30px);
  }
  .question {
    padding: 1rem;
  }
  .call_to_action_left {
    width: 78%;
  }
  .question span {
    line-height: 1.4rem;
  }
  .ans_body {
    padding: 1rem;
  }
  .faq_right_bg {
    text-align: right;
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .single_process h6 {
    font-size: 0.9rem;
  }
  .about_section {
    padding: 0;
  }
  .banner_right {
    position: static;
  }
  .home5_banner .btn_two {
    box-shadow: none;
  }
  .promote_area.section_padding {
    padding-bottom: 80px;
    padding-top: 80px;
  }
  .package_plan_area.section_padding {
    padding-bottom: 0;
  }
  .promote_right {
    margin-top: 50px;
  }
  .advertise_text_left {
    width: 62%;
  }
  .advertise_area .row.background_white {
    padding: 30px 0;
  }
  .advertise_text_right {
    margin-top: 21px;
  }
  .services_area_bg .image-box {
    display: none;
  }
  .service_two.section_padding {
    padding-bottom: 0;
  }
  .banner_two_area .banner_left .banner_left_cell h1 {
    font-size: 41px;
    line-height: 48px;
  }
  .banner_right_cell .bnr_secondary_img {
    margin-top: 0;
  }
  .tesm_three_content {
    background-position: center;
  }
  .client_talk {
    display: block;
    padding: 0 15px;
  }
  .client_talk p {
    margin-bottom: 10px;
  }
  .quote_mark {
    left: calc(50% - 31px);
  }
  .sn_pd_two_slide {
    padding: 20px 30px;
  }
  .sn_pd_two_offer {
    right: 10%;
    top: 24%;
  }
  .sn_pd_two_detail {
    display: flex;
  }
  .sn_pd_two_slide {
    padding: 20px;
  }
  .widget.widget_three {
    width: 33.33%;
  }
  .widget.widget_three:last-child {
    width: 40%;
  }
  .widget.widget_three {
    margin-bottom: 30px;
  }
  .accordion_btn {
    margin-bottom: 50px;
  }
  .why_use_area {
    overflow: hidden;
  }
  .banner_two_area .banner_left .banner_left_cell p {
    margin-bottom: 60px;
    font-size: 18px;
  }
  .ingredient_slider_two .ingredient-img {
    margin: 0;
    width: 100%;
  }
  .ingredient_slider_detail {
    padding-left: 0;
    margin-top: 80px;
  }
  .related_product_title h1 {
    font-size: 27px;
    margin-bottom: 63px;
  }
  .sn_review_input input,
  .sn_review_input textarea {
    width: 100%;
  }
  .form_group .btn_wrapper {
    margin-top: 0;
    margin: 0 auto;
  }
  .error_content_area .btn_wrapper {
    margin: 0 auto;
  }
  .error_content_area .btn_one,
  .error_content_area .btn_two {
    padding: 0 21px;
  }
  .banner_area .btn_wrapper {
    margin: 0 auto;
  }
  .client_talk p {
    font-size: 20px;
    margin-right: 0;
  }
  .section_title_two p {
    font-size: 18px;
  }
  .section_title_two {
    margin-bottom: 80px;
  }
  .product_two_slider .owl-nav {
    top: -60px;
  }
  .quickview-wrapper .col-xl-7,
  .quickview-wrapper .col-xl-5 {
    max-width: auto !important;
    width: 100% !important;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .single_service_two {
    padding: 0px 0px;
  }
  .services_bg_two .pl-60 {
    padding-left: 0;
  }
  .work_process_area_two .single_process h6 {
    font-size: 14px;
  }
  .ingredient_content {
    margin-top: 35px;
  }
  .ingredeint_section_one .ingredient-img img {
    top: 0;
    -webkit-transform: translateY(0%);
    -ms-transform: translateY(0%);
    transform: translateY(0%);
    padding: 25px;
  }
  .banner_area.banner_three .banner_left .banner_left_cell h1 {
    font-size: 36px;
    line-height: 36px;
    margin-top: 0px;
  }
  .banner_right_cell {
    padding-top: 0;
  }
  .banner_area.banner_three .banner_left .banner_left_cell p {
    margin-bottom: 2rem;
  }
  #product_detail .product_detail_desc {
    height: auto;
  }
  .faq_right_bg {
    display: none;
  }
  #contact {
    padding-bottom: 5rem;
  }
  .footer_three_area {
    padding: 113px 0 88px;
  }
  .breadcrumb_area_two .page_header h1 {
    font-size: 44px;
    line-height: 46px;
  }
  .banner_three .sn_bnr_slide {
    padding-top: 46px;
  }
  .home_five_single_service {
    width: 76%;
    margin-bottom: 50px;
    left: 50%;
    right: auto;
    transform: translateX(-50%);
  }
  .home5_offer_center {
    width: 90%;
    padding-bottom: 56px;
    padding-left: 0;
    padding-top: 56px;
  }
  .home5_offer_right {
    display: none;
  }
  .home5_offer_left {
    display: none;
  }
  .home5_video_left {
    width: 100%;
    padding-right: 0;
    margin-bottom: 50px;
  }
  .home5_video_right {
    width: 100%;
  }
  .home5_pricing_single {
    margin-bottom: 50px;
  }
  .footer_five_area .footer_five_middle_flex {
    flex-wrap: wrap;
  }
  .footer_five_area .widget.widget5 {
    width: 33.33%;
    margin-bottom: 50px;
  }
  .footer_five_area .widget.widget5:last-child {
    width: 66%;
  }
  .footer_five_area .footer_five_bottom {
    flex-wrap: wrap-reverse;
    text-align: center;
  }
  .footer_five_area .footer_five_top {
    display: block;
  }
  .footer_five_top_left {
    margin-bottom: 0;
  }
  .footer_five_area .footer_five_top .footer_five_top_right label::after {
    display: none;
  }
  .footer_five_area .footer_five_top .footer_five_top_right label {
    display: none;
  }
  .footer_five_area .footer_five_top .footer_five_top_right input {
    margin-left: 0;
    width: 80%;
  }
  .footer_five_area .widget.widget5 .widget5_about {
    padding-right: 30px;
    margin-top: 0;
  }
  .footer_five_area .footer_five_bottom_flex {
    flex-wrap: wrap;
  }
  .sn_pd_detail a {
    display: block;
  }
  .footer_five_area .footer_five_middle {
    padding: 95px 0 13px;
  }
  .banner_static .banner_static_left_cell h1 span {
    font-size: 40px !important;
  }
  .banner_static .banner_static_left_cell h1 {
    line-height: 60px !important;
  }
  .banner_static .banner_static_left_cell h1::after {
    left: 0;
    top: -50%;
    height: 200px;
  }
  .home_five_service {
    padding: 100px 0 45px;
  }
  .home5_recent {
    padding-bottom: 100px;
    padding-top: 5px;
  }
  .home5_recent.home5_feature {
    padding-top: 5px;
  }
  .home5_offer_detail {
    padding: 70px 0 77px;
  }
  .home5_offer {
    padding: 0px 0 95px;
  }
  .home5_video_total .section_title_four {
    margin-left: -35px;
  }
  .home5_video {
    padding-bottom: 100px;
  }
  .home5_pricing {
    padding-bottom: 78px;
  }
  .call_to_action_green {
    padding: 100px 0;
  }
  .footer_five_area .footer_five_top {
    padding: 50px 0;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  html {
    font-size: 16px;
  }
  h2 {
    font-size: 1.5rem;
  }
  .footer_top_two.section_padding {
    padding-bottom: 2rem;
  }
  .blog_area.section_padding_less {
    padding-bottom: 1.5rem;
  }
  .section_title {
    margin-bottom: 2.45rem;
  }
  .section_padding {
    padding: 5rem 0;
  }
  .section_padding_less {
    padding: 5rem 0;
  }
  .call_to_action_left p {
    width: 100%;
  }
  #portfolio, #ingredient, #work_process {
    padding-bottom: 5rem;
    padding-top: 5rem;
  }
  #feature {
    padding-bottom: 1.2rem;
  }
  .vigo_container_one,
  .vigo_container_two {
    max-width: 540px;
  }
  .widget {
    margin-bottom: 2.5rem;
    padding-right: 0;
  }
  .banner_right_cell .bnr_secondary_img {
    margin-top: 0;
  }
  .banner_area .owl-dots {
    display: none;
  }
  .banner_three .sn_bnr_slide {
    padding-top: 46px;
  }
  .footer_top {
    padding: 5rem 4rem;
  }
  .footer_top .col-xl-4:last-child .widget {
    margin-bottom: 0;
  }
  .contact_area.section_padding {
    padding-bottom: 20.67rem;
  }
  .map_main {
    top: calc(100% - 16.67rem);
    width: 100%;
    height: 16.67rem;
    padding-right: 0;
  }
  .contact_content {
    padding-left: 0;
  }
  .all_post {
    padding: 0 3rem;
  }
  .single_post {
    margin-bottom: 3rem;
  }
  .product_detail_slider {
    width: 100%;
  }
  .product_detail_desc {
    position: static;
    width: 100%;
  }
  #accordion {
    margin-bottom: 0;
  }
  .process_icon {
    width: 4.89rem;
    height: 4.89rem;
  }
  .process_icon span {
    font-size: 2rem;
    line-height: 4.89rem;
  }
  .single_price {
    margin-bottom: 2.45rem;
  }
  .pricing_table_area.section_padding {
    padding: 4rem 0 1.65rem;
  }
  .about_section {
    margin-top: 2.45rem;
  }
  .banner_left .banner_left_cell p {
    font-size: 17px;
  }
  .banner_left {
    padding-right: 0;
    height: auto;
  }
  .banner_right {
    height: auto;
  }
  .banner_left .btn_one, .banner_left .btn_two {
    width: 128px;
  }
  .banner_right_cell {
    padding-top: 0;
  }
  .banner_left .banner_left_cell h1 {
    font-size: 1.3rem;
  }
  .advertise_area .row.background_white {
    padding: 30px 0;
  }
  .advertise_text_left {
    width: 60%;
  }
  .advertise_text_right {
    margin-top: 30px;
  }
  .product_review_tab .tab-content {
    padding: 50px 40px;
  }
  .sn_review_input input {
    width: 100%;
  }
  .sn_review_input textarea {
    width: 100%;
  }
  .review_form h5 span {
    display: block;
    margin-top: 10px;
  }
  .advertise_img {
    margin-bottom: 30px;
  }
  .widget.widget_instafeed .widget_insta_list ul li {
    width: 72px;
    height: 72px;
  }
  .feature_three.section_padding {
    padding-bottom: 3rem;
  }
  .services_area_bg .image-box {
    display: none;
  }
  .service_two.section_padding {
    padding-bottom: 0;
  }
  .single_service_two {
    margin-bottom: 50px;
  }
  .single_service_two span {
    margin-bottom: 36px;
  }
  .service_two.section_padding .col-md-4:last-child .single_service_two {
    margin-bottom: 0;
  }
  .single_process h6 {
    font-size: 0.75rem;
  }
  .widget.widget_three {
    width: 33.33%;
    margin-bottom: 15px;
  }
  .widget.widget_three:last-child {
    width: 50%;
  }
  .accordion_btn {
    margin-bottom: 0px;
  }
  .sn_pd_two_slide {
    padding: 25px 10px;
  }
  .banner_two_area .banner_left .banner_left_cell p {
    margin-bottom: 25px;
  }
  .banner_two_area .banner_left .banner_left_cell h1 {
    line-height: 34px;
    font-size: 34px;
  }
  .banner_two_area .banner_left .banner_left_cell p {
    font-size: 18px;
    line-height: 28px;
  }
  .bnr_right_extra {
    width: 100px;
    height: 100px;
  }
  .banner_two_area {
    height: 100vh;
  }
  .tesm_three_content {
    margin-top: 0;
  }
  .btn_one, .btn_two {
    padding: 0 13px;
  }
  .client_talk p {
    margin-bottom: 10px;
    margin-right: 0;
    font-size: 20px;
    line-height: 26px;
  }
  .client_talk {
    display: block;
  }
  .tesm_three_content {
    margin-top: -76px;
    background-position: center;
    padding: 0 30px;
  }
  .quote_mark {
    left: calc(50% - 30px);
    top: -55px;
  }
  .tesm_three_content {
    background-position: center;
  }
  .why_use_area .vigo_container_one {
    width: 100%;
  }
  .why_use_area {
    overflow: hidden;
  }
  .banner_two_area {
    height: auto;
  }
  .why_use_area {
    padding: 75px 0 60px;
  }
  .section_title_two p {
    font-size: 18px;
  }
  .feature_benifit_area {
    padding-bottom: 80px;
  }
  .video_three_area_bg {
    padding: 80px 0;
  }
  .video_three_area_bg {
    padding: 72px 0 80px;
  }
  .video_three_area .section_title_two {
    margin-bottom: 76px;
  }
  .call_to_action_left_two {
    margin-right: 10px;
  }
  .section_title_two {
    margin-bottom: 75px;
  }
  .product_two_slider .owl-nav {
    top: -50px;
  }
  .footer_three_area {
    padding: 80px 0;
  }
  .footer_three_area {
    padding: 80px 0 50px;
  }
  .product_two_area {
    padding: 72px 0 55px;
  }
  .ingredient_slider_one .slick-prev,
  .ingredient_slider_one .slick-next {
    top: 430px;
  }
  .ingredient_slider_two .ingredient-img {
    width: 100%;
    padding-bottom: 0;
    margin-bottom: 0;
  }
  .product_review_tab .nav li a {
    font-size: 14px;
    letter-spacing: normal;
  }
  .ingredient_slider_detail {
    padding-left: 0;
    margin-top: 50px;
  }
  .related_product {
    padding: 80px 0 70px;
  }
  .related_product_title h1 {
    font-size: 24px;
    margin-bottom: 60px;
  }
  .why_use_area .carousel {
    display: none;
  }
  .carousel_screenshot {
    display: block;
  }
  .banner_two_area .btn_one, .banner_two_area .btn_two {
    width: 130px;
  }
  .banner_two_area .banner_right {
    position: relative;
    height: 93vh !important;
  }
  .sn_pd_two_offer {
    right: 18px;
    top: 100px;
  }
  .call_to_action_hello {
    display: block;
    text-align: center;
  }
  .call_to_action_left_two {
    margin-right: 0;
    text-align: center;
    margin-bottom: 30px;
  }
  .services_bg_two .pl-60 {
    padding-left: 15px;
  }
  .work_process_area_two .single_process h6 {
    font-size: 11px;
  }
  .promote_area_two .single_promote ul {
    padding: 0 15px;
  }
  .ingredeint2_section {
    padding: 80px 0;
  }
  .page_content_two.section_padding_two {
    padding: 80px 0;
  }
  .video_area_two .about_section {
    margin-top: 0;
    margin-bottom: 60px;
  }
  .banner_area.banner_three .banner_left .banner_left_cell h1 {
    font-size: 30px;
    line-height: 30px;
    margin-top: 0;
  }
  .banner_area.banner_three .banner_left .banner_left_cell p {
    display: none;
  }
  .banner_area.banner_three .btn_two {
    width: 110px;
  }
  .banner_area.banner_three .banner_right {
    height: 100vh !important;
  }
  .product_detail_area.section_padding {
    padding-bottom: 5rem;
  }
  .breadcrumb_area_two .page_header h1 {
    font-size: 44px;
    line-height: 45px;
  }
  .banner_right_cell img {
    margin-top: 0;
  }
  .sn_bnr_slide {
    padding: 0px 50px;
  }
  .banner_area {
    height: 600px;
    padding: 165px 0 80px;
  }
  .banner_left .banner_left_cell p {
    display: none !important;
  }
  .banner_right {
    margin-left: -87px;
  }
  .sn_bnr_slide {
    height: 370px;
  }
  .footer_four_area .footer_four_bottom_right ul li:first-child a {
    padding-left: 0;
  }
  .home5_recent {
    padding-top: 5px;
  }
  .section_title_four h2 {
    font-size: 44px;
    box-shadow: -1px -1px 5px rgba(0, 0, 0, 0.05);
  }
  .home_five_service {
    padding: 80px 0 25px;
  }
  .home5_offer_social {
    padding-top: 0;
    padding-right: 0;
  }
  .home5_recent {
    padding-bottom: 75px;
  }
  .home5_offer_detail {
    padding: 45px 0 0;
  }
  .home5_offer {
    padding: 0px 0 75px;
  }
  .home5_recent.home5_feature {
    padding-bottom: 80px;
  }
  .home5_video_total .section_title_four {
    margin-left: -80px;
  }
  .home5_video {
    padding-bottom: 80px;
  }
  .home5_pricing {
    padding-bottom: 80px;
  }
  .footer_five_area .footer_five_top {
    padding-top: 75px;
  }
}

@media (min-width: 991px) {
  #mobile-nav-wrap {
    display: none;
  }
  .sn_pd_slide {
    padding: 0px 70px;
  }
}

@media (max-width: 1200px) {
  .video_popup {
    height: auto;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .footer_top .widget {
    padding-right: 0;
    margin-bottom: 0;
  }
  .sn_contact_feature {
    padding: 0;
  }
  .promote_right {
    position: static;
    width: 100%;
  }
  .supplement_service_img {
    text-align: center;
  }
  .supp_icon {
    margin-top: 15px;
  }
  #mobile-nav-wrap {
    display: block;
  }
  .top_toolbar,
  .header_nav {
    display: none;
  }
  .hdr_area_two .mobile_toolbar {
    background: #5830B4;
  }
  .hdr_area_three .mobile_toolbar {
    background: #333;
  }
  .hdr_area_two .mobile_toolbar .header_login a {
    color: #fff;
  }
  .hdr_area_two #mobile-nav-wrap {
    background: #D01BD0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  }
  .headroom--not-top.hdr_area_two #mobile-nav-wrap {
    background: #D01BD0;
  }
  #mobile-nav-wrap .bottom_nav_two #mobile-logo {
    padding: 0;
    line-height: 60px;
  }
  .mobile-menu-inner-two .mobile_accor_togo {
    background: #45D638;
  }
  .close-menu span::after {
    background: #121212;
  }
  .close-menu span::before {
    background: #121212;
  }
  .mobile-menu-inner-two .close-menu span::after {
    background: #fff;
  }
  .mobile-menu-inner-two .close-menu span::before {
    background: #fff;
  }
  .hdr_area_two .phone_number a {
    color: #fff;
  }
  .phone_number span {
    color: #fff;
  }
  .toggle-inner i {
    color: #fff;
  }
  .mobile-menu-inner {
    width: 33%;
  }
}

@media (max-width: 991px) {
  .ingredeint_section.section_padding {
    padding: 4.7rem 0 3.7rem;
  }
  .banner_two_area .banner_left .banner_left_cell h1 {
    margin-top: 0;
  }
  .sn_pd_slide {
    padding: 0px 70px;
  }
  .single_price {
    margin-bottom: 40px;
  }
  .video_popup a {
    left: calc(50% - 45px);
    top: calc(50% - 45px);
    width: 90px;
    height: 90px;
  }
  .video_popup a i {
    font-size: 2.72rem;
  }
  .video_two_area {
    height: 70vh;
  }
  .image-box-half {
    display: none;
  }
  .mobile-menu-inner {
    width: 50%;
  }
  .sn_contact_feature {
    width: 32.8%;
    margin-bottom: 50px;
  }
  .footer_bottom_two .copyright,
  .footer_bottom_two .widget_payment {
    text-align: center;
  }
  .footer_top_two .widget_title {
    margin-bottom: 2.22rem;
  }
  .footer_top_two .widget {
    margin-bottom: 40px;
  }
  .promote_sub {
    padding: 4.45rem;
    width: 100%;
    margin: 0 auto;
    left: 0;
  }
  .promote_right {
    position: static;
    bottom: 0;
    width: 100%;
  }
  .single_promote ul li {
    font-size: 0.7rem;
  }
  .single_pkg_plan {
    margin-bottom: 1.8rem;
  }
  .supp_ser_list {
    padding-right: 0;
    margin-bottom: 50px;
  }
  .widget.widget_about_two {
    padding-right: 0;
  }
  .banner_left .banner_left_cell h2 {
    font-size: 1rem;
  }
  .bnr_title_img {
    margin-bottom: 12px;
  }
  .supplement_service_area.section_padding {
    padding: 5rem 0 4.5rem;
  }
  .hdr_area_two .phone_number a {
    color: #fff;
  }
  .hdr_area_two .phone_number span {
    color: #fff;
  }
  .hdr_area_two .toggle-inner i {
    color: #fff;
  }
  .headroom--not-top.hdr_area_two .toggle-inner i {
    color: #fff;
  }
  .banner_two_area .banner_slider {
    padding-top: 0;
  }
  .breadcrumb_area_two .page_header {
    padding-top: 98px;
  }
  .breadcrumb_area_two .breadcrumb_inner {
    height: 300px;
  }
  .backtotop.backtop_two {
    bottom: 20px;
    right: 20px;
  }
  .supplement_service_area_two .supplement_service_img {
    display: none;
  }
  .supplement_service_area_two .supp_ser_list {
    margin-bottom: 0;
  }
  .call_to_action_area_two::before,
  .call_to_action_area_two::after {
    display: none;
  }
  .accordion_btn {
    padding-left: 0;
  }
  .bottom_nav_two .toggle-inner i {
    padding-top: 0;
    line-height: 60px;
  }
  .services_area_bg.services_bg_two {
    background: #f8f8f8;
  }
  .tab-content {
    margin-left: 0;
  }
  .feature_benifit_area #accordion {
    margin-bottom: 0;
  }
  .feature_benifit_area .accordion_btn {
    margin-bottom: 65px;
  }
  .modal.quickview-wrapper {
    overflow-y: scroll;
    height: 400px;
    width: 400px;
  }
  .quickview-wrapper .ingredient_slider_one.slick-slider {
    height: auto;
  }
  .quickview-wrapper .ingredient_slider_main {
    margin-bottom: 0;
  }
  .modal.quickview-wrapper::before {
    display: none;
  }
  .quickview-wrapper .ingredient_slider_two .ingredient-img {
    padding-bottom: 0;
    padding-left: 0px;
    padding-top: 0;
    padding-right: 25px;
  }
  .quickview-wrapper {
    width: 100%;
  }
  .quickview-wrapper .ingredient_slider_detail {
    margin-left: 20px;
  }
  .mask-overlay2 {
    z-index: 999;
    background: rgba(0, 0, 0, 0.8);
  }
  .quickview-wrapper .ingredient_slider_one .slick-prev,
  .quickview-wrapper .ingredient_slider_one .slick-next {
    display: none !important;
  }
  .modal.quickview-wrapper {
    background: #fff;
  }
  .promote_area_two .promote_sub {
    top: 0;
    transform: translateX(-50%);
    width: auto;
    left: 50%;
  }
  .services_area_bg.section_padding {
    padding-bottom: 1rem;
    background: #f8f8f8;
  }
  .service_two.section_padding {
    padding-bottom: 80px;
  }
  .services_area_bg::before, .services_area_bg::after {
    background: #f8f8f8;
    width: 100%;
  }
  .breadcrumb_area {
    height: 280px;
  }
  .banner_left .banner_left_cell h1 {
    font-size: 25px;
  }
  .banner_left {
    padding-right: 5px;
  }
}

@media (max-width: 767px) {
  .call_to_action_right {
    float: none;
    margin-top: 30px;
  }
  .banner_left {
    padding-top: 50px;
  }
  .banner_right {
    padding-top: 0;
  }
  .home5_banner .btn_two {
    -webkit-box-shadow: 18px 18px 38px #dedede;
    box-shadow: none;
  }
  .call_to_action_area,
  .call_to_action_left {
    text-align: center;
  }
  .footer_top_two .widget.widget_about_two {
    padding-right: 0;
  }
  .promote_sub {
    padding: 1rem;
    width: 100%;
  }
  .promote_right {
    position: static;
    width: 100%;
  }
  .promote_sub {
    padding: 1rem;
    width: 100%;
    margin-bottom: 50px;
  }
  .contact_page_form {
    padding-bottom: 4.8rem;
  }
  .single_promote {
    margin-bottom: 50px;
  }
  .promote_sub h2 {
    font-size: 1.2rem;
  }
  .promote_area.section_padding {
    padding: 80px 0 30px;
  }
  .package_plan_area.section_padding {
    padding-bottom: 50px;
  }
  .single_pkg_plan {
    margin-bottom: 30px;
  }
  .error_cont_img {
    margin-bottom: 50px;
  }
  .error_cont_desc h1 {
    font-size: 110px;
    line-height: 110px;
  }
  .error_cont_desc p {
    font-size: 1rem;
  }
  .widget_recent_post .widget_post_list li a span {
    margin-right: 5px;
  }
  .video_two_area {
    height: 60vh;
  }
  .supp_icon {
    margin-top: 15px;
  }
  .services_area_bg.section_padding {
    padding-bottom: 1.5rem;
  }
  .footer_bottom_two .copyright span {
    margin-top: 0;
  }
  .sn_contact_content p {
    font-size: 0.9rem;
  }
  .sn_contact_content a {
    font-size: 0.9rem;
  }
  .breadcrumb_area_two .page_header {
    padding-top: 98px;
  }
  .breadcrumb_area_two .breadcrumb_inner {
    height: 300px;
  }
  .section_title_two h2 {
    font-size: 28px;
    line-height: 33px;
  }
  .promote_area_two.section_padding_two {
    padding-bottom: 30px;
  }
  .testimonial_hm_two .section_padding_two {
    padding: 80px 0;
  }
  .package_plan_two.section_padding_two {
    padding-bottom: 30px;
  }
  .service_three.section_padding_two {
    padding-bottom: 30px;
  }
  .feature_four.section_padding_two {
    padding-bottom: 50px;
  }
  .banner_two_area .owl-prev {
    width: 35px;
    height: 60px;
    border-top-right-radius: 60px;
    border-bottom-right-radius: 60px;
  }
  .banner_two_area .owl-next {
    width: 35px;
    height: 60px;
    border-top-left-radius: 60px;
    border-bottom-left-radius: 60px;
  }
  .banner_two_area .owl-prev i {
    margin-right: -10px;
  }
  .banner_two_area .owl-next i {
    margin-left: -10px;
  }
  .ingredeint_section_one .ingredient-img img {
    top: 0;
    -webkit-transform: translateY(0%);
    -ms-transform: translateY(0%);
    transform: translateY(0%);
    padding: 25px;
  }
  .map_main {
    padding-left: 0;
  }
  .contact_content {
    padding-left: 15px;
  }
  #contact {
    padding-bottom: 80px;
  }
  #footer .footer_top .widget {
    margin-bottom: 40px;
  }
  #footer .footer_top .widget.widget_subscribe {
    margin-bottom: 0px;
  }
  .footer_three_area {
    padding: 80px 0 65px;
  }
  .section_padding_two {
    padding: 80px 0;
  }
  .services_area_bg.services_bg_two {
    padding-bottom: 15px;
  }
  .error_content_area.section_padding {
    padding: 75px 0;
  }
  .error_cont_desc .btn_wrapper {
    height: auto;
  }
  .banner_static .banner_static_left_cell h1 {
    font-size: 50px;
    line-height: 50px;
  }
  .banner_static_right {
    display: none;
  }
  .banner_static .banner_static_left_cell h1::after {
    left: 44px;
    top: -33%;
    height: 150px;
  }
  .home_five_single_service {
    width: 100%;
    margin-bottom: 50px;
  }
  .home5_offer_left {
    position: static;
  }
  .home5_offer_right {
    position: relative;
    text-align: right;
  }
  .home5_offer_inner {
    margin-left: 0;
    padding: 19px;
  }
  .home5_offer_center {
    padding-left: 30px;
    width: 100%;
    padding-right: 0px;
  }
  .home5_offer_center h2 {
    font-size: 42px;
  }
  .home5_offer_right span {
    left: 50%;
  }
  .home5_offer_center h2::after {
    left: 8px;
    top: -123%;
  }
  .home5_offer_detail .home5_offer_detail_bg {
    display: none;
  }
  .section_title_four h2 {
    font-size: 44px;
  }
  .home5_video_left {
    width: 100%;
    margin-bottom: 30px;
    padding-right: 0;
  }
  .home5_video_right {
    width: 100%;
  }
  .home5_pricing_single {
    margin-bottom: 50px;
  }
  .footer_five_area .footer_five_middle {
    flex-flow: wrap;
  }
  .footer_five_area .widget.widget5 {
    width: 50%;
    margin-bottom: 50px;
  }
  .footer_five_area .widget.widget5:last-child {
    width: 100%;
    margin-bottom: 0;
  }
  .sn_pd_detail a {
    display: block;
  }
  .footer_five_area .footer_five_bottom {
    flex-flow: wrap-reverse;
    text-align: center;
  }
  .footer_four_bottom_left {
    width: 100%;
    margin-top: 16px;
  }
  .home5_video_right::after {
    top: -35px;
  }
  .footer_five_area .footer_five_top_flex {
    flex-wrap: wrap;
  }
  .footer_five_area .footer_five_top .footer_five_top_right label {
    margin-bottom: 30px;
  }
  .footer_five_area .footer_five_top .footer_five_top_right label {
    line-height: 24px;
  }
}

@media (max-width: 575px) {
  .banner_right {
    height: auto;
  }
  html {
    font-size: 16px;
  }
  h2 {
    font-size: 1.5rem;
  }
  .banner_area.banner_three .banner_left .banner_left_cell p {
    font-size: 18px;
  }
  .promote_sub h2 {
    font-size: 1.1rem;
  }
  .services_area .section_title h2 {
    line-height: 35px;
  }
  .footer_five_top_right {
    width: 100%;
  }
  .section_title h2 {
    line-height: 35px;
    font-size: 25px;
  }
  .section_title {
    margin-bottom: 2.45rem;
  }
  .ingredient_content_heading h5 {
    font-size: 1.1rem;
  }
  .section_padding {
    padding: 5rem 0;
  }
  .ingredeint_section.section_padding {
    padding-bottom: 4rem;
  }
  .section_padding_less {
    padding: 5rem 0;
  }
  .widget_payment span {
    display: block;
  }
  .top_toolbar {
    display: none;
  }
  .header_nav {
    display: none;
  }
  .services_area .section_title svg {
    display: none;
  }
  .widget {
    margin-bottom: 2.5rem;
    padding-right: 0;
  }
  .footer_top .col-xl-4:last-child .widget {
    margin-bottom: 0;
  }
  .map_main {
    position: static;
    width: 100%;
    height: 400px;
    padding-right: 0;
  }
  .contact_content {
    padding-top: 2.45rem;
  }
  .form_group.half {
    width: 100%;
    padding: 0;
  }
  .form_group.half:nth-child(2) input[required] + label {
    left: 0.84rem;
  }
  .sn_post_detail p a {
    font-size: 13px;
    padding: 0 0.4rem;
  }
  .sn_post_detail p a:last-child {
    margin-left: 0;
  }
  .product_detail_desc {
    position: static;
    width: 100%;
    padding: 1rem 0 0 1rem;
  }
  .product_detail_slider {
    width: 100%;
  }
  .product_detail_desc .btn_wrapper {
    margin-top: 30px;
    margin-left: -3px;
  }
  .question {
    padding: 1rem;
  }
  .question span {
    line-height: 1.5;
  }
  .question span:after, .question span:before {
    display: none;
  }
  #accordion {
    margin-bottom: 0;
  }
  .ans_body {
    padding: 1rem;
  }
  .all_processes .single_process {
    width: 100%;
  }
  .all_processes svg {
    display: none;
  }
  .all_processes .single_process {
    margin-bottom: 2.5rem;
  }
  .all_processes .single_process:last-child {
    margin-bottom: 0rem;
  }
  .single_price {
    margin-bottom: 2.45rem;
  }
  .about_section {
    padding: 0;
    margin-top: 2.45rem;
  }
  .video_two_area {
    height: 55vh;
  }
  .video_popup {
    margin-bottom: 0;
  }
  .video_popup:after {
    display: none;
  }
  .video_popup a {
    left: calc(50% - 35px);
    top: calc(50% - 35px);
    width: 70px;
    height: 70px;
  }
  .video_popup a i {
    font-size: 2.5rem;
  }
  .banner_left {
    padding-right: 15px;
    padding-left: 15px;
    height: auto;
    padding-top: 50px;
  }
  .banner_right {
    padding-top: 0;
  }
  .banner_left .banner_left_cell h1 {
    font-size: 21px;
  }
  .banner_right {
    display: block;
  }
  .banner_left .banner_left_cell h2 {
    padding-top: 0;
  }
  .banner_right_cell .bnr_secondary_img {
    margin-top: 0;
  }
  .banner_right_cell {
    padding-top: 0;
  }
  .banner_slider {
    height: 480px;
    padding-top: 113px;
  }
  .banner_left .banner_left_cell h1 span {
    margin-top: 8px;
    font-size: 21px;
  }
  .banner_left .banner_left_cell h1 {
    font-size: 18px;
  }
  .banner_area {
    height: auto;
  }
  .slr_btn_wrapper .btn_two {
    width: 120px;
    font-size: 14px;
  }
  .sn_contact_feature {
    width: 100%;
    margin-bottom: 2.77rem;
  }
  .contact_page_form {
    padding-bottom: 5rem;
  }
  .page_header h1 {
    font-size: 1.89rem;
  }
  .widget_instafeed .widget_insta_list ul li {
    width: 5.5rem;
    height: 5.5rem;
  }
  .footer_top_two .widget_title {
    margin-bottom: 2rem;
  }
  .footer_top_two .widget {
    margin-bottom: 50px;
    width: 87%;
  }
  .footer_area .widget {
    margin-bottom: 50px;
  }
  .advertise_area .row.background_white {
    padding: 30px 10px;
  }
  .advertise_img {
    margin-bottom: 30px;
  }
  .advertise_text_left {
    margin-bottom: 30px;
  }
  .advertise_text_right {
    float: left;
  }
  .footer_top_two.section_padding {
    padding-bottom: 1.2rem;
  }
  #feature {
    padding-bottom: 0.8rem;
  }
  .video_popup_two h3 {
    font-size: 24px;
  }
  #portfolio, #ingredient, #work_process {
    padding-bottom: 5rem;
    padding-top: 5rem;
  }
  .testimonial_area_two .section_title {
    margin-bottom: 2rem;
  }
  #blog {
    padding-bottom: 3rem;
  }
  .services_area_bg .image-box {
    margin-bottom: 50px;
  }
  .padding_right {
    padding-right: 0;
  }
  .services_area_bg.section_padding {
    padding-bottom: 1rem;
    padding-top: 3.5rem;
  }
  .feature_three.section_padding {
    padding-bottom: 3.2rem;
  }
  .single_service_two {
    margin-bottom: 50px;
  }
  .service_two.section_padding {
    padding: 75px 0 30px;
  }
  .single_service_two span {
    margin-bottom: 35px;
  }
  .advertise_area {
    padding-bottom: 0;
  }
  .supplement_service_area.section_padding {
    padding: 5rem 0;
  }
  .widget.widget_three {
    width: 50%;
    margin-bottom: 30px;
  }
  .widget.widget_three:last-child {
    width: 67%;
  }
  .call_to_action_left_two h2 {
    font-size: 30px;
  }
  .call_to_action_area_two::before {
    background: transparent;
  }
  .sn_pd_two_offer {
    right: 42px;
    top: 96px;
  }
  .accordion_btn {
    padding-bottom: 30px;
  }
  .sn_pd_two_slide {
    padding: 10px 10px;
  }
  .tesm_three_content {
    background-position: center;
  }
  .banner_two_area {
    height: auto;
  }
  .banner_two_area .banner_left .banner_left_cell h1 {
    font-size: 40px;
    line-height: 48px;
  }
  .carousel {
    display: none;
  }
  .carousel_screenshot {
    display: block;
  }
  .client_talk {
    display: block;
  }
  .client_talk p {
    font-size: 16px;
    margin-bottom: 10px;
  }
  .quote_mark {
    left: calc(50% - 31px);
  }
  .banner_two_area .banner_right {
    margin-bottom: 0px;
    display: none;
  }
  .banner_two_area .banner_slider {
    padding-top: 0;
    padding-bottom: 0;
  }
  .call_to_action_area_two::after {
    background: none;
  }
  .why_use_area {
    padding: 72px 0 60px;
  }
  .section_title_two {
    margin-bottom: 72px;
  }
  .video_three_area .section_title_two {
    margin-bottom: 72px;
  }
  .client_talk p {
    padding: 0 15px;
    margin-right: 0;
  }
  .feature_benifit_area #accordion {
    margin-bottom: 0;
  }
  .feature_benifit_area {
    padding-bottom: 80px;
  }
  .video_three_area_bg {
    padding: 70px 0 80px;
  }
  .product_two_area {
    padding: 80px 0;
  }
  .product_two_area {
    padding: 72px 0 54px;
  }
  .footer_three_area {
    padding: 80px 0;
  }
  .product_two_slider .owl-nav {
    top: -60px;
  }
  .ingredient_slider_one .slick-slide {
    width: 100px;
    height: 120px;
  }
  .ingredient_slider_two .ingredient-img {
    border: 0px solid #fff;
    background: #fff;
    width: 100%;
    padding: 19px 0;
    margin: 0;
  }
  .ingredient_slider_one .slick-prev,
  .ingredient_slider_one .slick-next {
    top: 273px;
  }
  .ingredient_slider_main {
    margin-bottom: 50px;
  }
  .ingredient_slider_detail {
    padding-left: 0;
  }
  .product_review_tab .nav li {
    width: 50%;
    margin-top: -1px;
  }
  .product_review_tab .tab-content {
    padding: 20px;
  }
  .ingredient_slider_detail .product_quantity li {
    width: 92px;
    margin-right: 2px;
  }
  .ingredient_slider_detail .product_ratting span {
    font-size: 13px;
    margin-right: 5px;
  }
  .related_product_title h1 {
    font-size: 20px;
  }
  .related_product_slider .owl-nav {
    right: -3px;
    top: -54px;
  }
  .related_product {
    padding: 80px 0px 70px;
  }
  .sn_review_right {
    width: 100%;
    padding-left: 0;
    margin-top: 20px;
  }
  .sn_review_input textarea {
    width: 100%;
  }
  .sn_review_input input {
    width: 100%;
  }
  .backtotop.backtop_two {
    bottom: 10px;
    right: 10px;
  }
  .breadcrumb_area_two .page_header {
    padding-top: 88px;
  }
  .breadcrumb_area_two .breadcrumb_inner {
    height: 280px;
  }
  .section_padding_two {
    padding: 72px 0;
  }
  .faq3_area.section_padding_two {
    padding: 74px 0 0;
  }
  .section_title_two p {
    font-size: 15px;
  }
  .supplement_service_area_two .supplement_service_img {
    display: none;
  }
  .supplement_service_area_two .supp_ser_list {
    margin-bottom: 0;
  }
  .testimonial_hm_two .section_padding_two {
    padding: 72px 0;
  }
  .portfolio_area_two .accordion_btn {
    padding: 0;
  }
  .call_to_action_left_two p {
    font-size: 16px;
  }
  .section_title_two h2 {
    font-size: 28px;
    line-height: 36px;
  }
  .call_to_action_left_two {
    margin-right: 0;
    text-align: center;
    margin-bottom: 30px;
  }
  .call_to_action_left_two h2 {
    font-size: 28px;
    letter-spacing: 4px;
  }
  .feature_four.section_padding_two {
    padding-bottom: 50px;
  }
  .service_three.section_padding_two {
    padding-bottom: 30px;
  }
  .banner_left .banner_left_cell p {
    display: none !important;
  }
  .banner_two_area {
    height: 100vh;
  }
  .banner_two_area .sn_bnr_slide {
    height: 100vh;
  }
  .banner_two_area .banner_left .banner_left_cell p {
    display: none !important;
  }
  .banner_two_area .btn_one, .banner_two_area .btn_two {
    width: 120px;
  }
  .banner_two_area .banner_left .banner_left_cell h1 {
    margin-bottom: 40px;
  }
  .banner_area {
    height: 480px;
  }
  .modal.quickview-wrapper {
    width: 400px;
  }
  .call_to_action_hello {
    display: block;
    text-align: center;
  }
  .sn_pd_two_offer {
    right: 26px;
    top: 90px;
  }
  .product_two_slider {
    max-width: 280px;
    margin: 0 auto;
  }
  .services_bg_two .pl-60 {
    padding-left: 15px;
  }
  .single_feature_3 {
    padding: 45px 15px;
  }
  .single_service_two {
    padding: 0px 15px;
  }
  .related_product_title h1 {
    margin-bottom: 30px;
  }
  .ingredeint_section_three .ingredient-img {
    padding: 0 25px;
  }
  .ingredeint_section_three .ingredient_content {
    margin-top: 50px;
  }
  .ingredeint_section_three .slick_slider_two .slick-prev.slick-arrow {
    top: 0;
  }
  .ingredeint_section_three .slick_slider_two .slick-next.slick-arrow {
    top: 0;
  }
  .page_content_two.section_padding_two {
    padding: 80px 0;
  }
  .video_area_two .about_section {
    margin-top: 0;
    margin-bottom: 30px;
  }
  .call_to_action_left p {
    width: 100%;
  }
  .banner_area.banner_three .banner_right {
    height: auto;
  }
  .banner_area.banner_three .banner_left .banner_left_cell h1 {
    font-size: 36px;
    line-height: 40px;
  }
  .banner_area.banner_three .banner_left {
    height: 100vh;
  }
  #pricing {
    padding-bottom: 40px;
  }
  #work_process.work_process_area {
    padding-bottom: 75px;
  }
  .product_detail_area.section_padding {
    padding: 80px 0;
  }
  .share-wrap .product-share-link a {
    margin-left: 10px;
  }
  .share-wrap {
    padding: 16px 8px 16px;
  }
  .question span {
    padding: 0;
    font-size: 14px;
  }
  .faq_area #accordion {
    margin-bottom: 30px;
  }
  .product_detail_desc .btn_wrapper {
    margin-top: 0px;
  }
  .product-cart .btn_two {
    width: 118px;
  }
  #contact {
    padding-bottom: 80px;
  }
  .sin-portfolio .portfolio_details span {
    letter-spacing: 0;
  }
  #faq {
    padding-bottom: 60px;
  }
  .banner_two_area .banner_left .banner_left_cell h1 {
    margin-top: 0;
  }
  .widget.widget_three .widget_logo {
    margin-top: 0;
  }
  .widget.widget_three:last-child {
    margin-bottom: 0;
  }
  .breadcrumb_area_two .page_header h1 {
    font-size: 36px;
    letter-spacing: 4px;
  }
  .sin_post_tag {
    margin-bottom: 60px;
  }
  .comments-title {
    margin-bottom: 73px;
  }
  .comment-list {
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .comment-reply-title {
    margin-bottom: 80px;
  }
  .sin_post_tag ul li {
    margin-left: 15px;
  }
  .filter-button li {
    padding: 0 5px;
  }
  #ingredient .video-pop-inner {
    top: calc(50% - 35px);
    left: calc(50% - 35px);
  }
  #footer .widget.widget_about_two {
    padding-right: 0;
  }
  .top_feature_border {
    padding: 30px 10px;
  }
  .home_five_single_service {
    width: 100%;
    margin-bottom: 50px;
  }
  .home5_offer_center {
    width: 100%;
    padding-left: 0;
  }
  .home5_offer_left {
    position: static;
  }
  .home5_offer_center h2 {
    font-size: 30px;
  }
  .home5_video_total {
    padding: 68px 20px 72px;
  }
  .section_title_four h2 {
    font-size: 39px;
  }
  .home5_video_left {
    width: 100%;
    padding-right: 0;
  }
  .home5_video_right {
    width: 100%;
  }
  .home5_pricing_single {
    margin-bottom: 50px;
  }
  .footer_five_area .footer_five_top {
    display: block;
  }
  .footer_five_top_left {
    margin-bottom: 30px;
  }
  .footer_five_area .footer_five_top .footer_five_top_right label {
    line-height: normal;
    padding-right: 0px;
    margin-bottom: 30px;
    padding-top: 6px;
  }
  .home5_offer_center {
    padding-bottom: 0px;
    margin-bottom: 30px;
  }
  .home_five_service {
    padding: 80px 0 25px;
  }
  .home5_offer_inner {
    padding: 19px;
  }
  .home5_offer_center h2::after {
    left: 11px;
    top: -55px;
    height: 130px;
  }
  .footer_five_area .footer_five_middle_flex {
    flex-wrap: wrap;
    flex-direction: row;
  }
  .footer_five_area .widget.widget5 {
    width: 50%;
  }
  .footer_five_area .widget.widget5:last-child {
    width: 100%;
  }
  .footer_five_area .widget.widget5 {
    margin-bottom: 50px;
  }
  .footer_five_area .widget.widget5 .widget5_about {
    padding-right: 20px;
  }
  .footer_five_area .footer_five_bottom_flex {
    flex-wrap: wrap-reverse;
    text-align: center;
  }
  .footer_four_bottom_left {
    width: 100%;
    margin-top: 16px;
  }
  .sn_pd_detail a {
    display: block;
  }
  .home5_video_right::after {
    left: 55%;
    top: 0;
    height: 128%;
  }
  .home5_offer_detail .home5_offer_detail_bg {
    display: none;
  }
  .home5_offer_inner {
    margin-left: 0;
  }
  .home5_offer_right {
    position: relative;
  }
  .home5_recent_slider .owl-nav {
    left: 0;
    width: 100%;
  }
  .section_title_four h2 {
    padding: 16px 20px;
    box-shadow: -1px -1px 5px rgba(0, 0, 0, 0.05);
  }
  .home5_recent {
    padding-bottom: 80px;
    padding-top: 5px;
  }
  .home5_offer {
    padding: 0px 0 0px;
  }
  .home5_video {
    padding-bottom: 80px;
  }
  .home5_pricing {
    padding-bottom: 62px;
  }
  .call_to_action_green {
    padding: 80px 0 80px;
  }
  .footer_five_area .footer_five_top {
    padding-top: 74px;
  }
  .tp-banner-container {
    padding-top: 145px;
  }
  .footer_five_area .footer_five_bottom {
    padding: 0;
  }
}

@media only screen and (max-width: 575px) {
  .home5_offer_center h2 {
    font-size: 24px;
  }
  .tp-banner-container {
    padding-top: 167px;
  }
  .backtotop.backtotop_three {
    right: 0;
    margin-bottom: 0;
  }
  .banner_right {
    margin-left: 0;
  }
  .footer_five_area .footer_four_bottom_right {
    margin-right: 0;
  }
  .footer_five_top_right label {
    display: none;
  }
}

@media (min-width: 400px) and (max-width: 575px) {
  .ingredient_slider_one .slick-prev,
  .ingredient_slider_one .slick-next {
    top: 386px;
  }
  .ingredient_slider_two .ingredient-img {
    padding: 17px 0;
  }
  .mobile-menu-inner {
    width: 70%;
  }
}

@media (min-width: 320px) and (max-width: 479px) {
  .mobile-menu-inner {
    width: 100%;
  }
  .login-right {
    width: 100%;
    padding-left: 0;
  }
  .login-left {
    display: none;
  }
  .modal.show .modal-dialog {
    -webkit-transform: translateY(-32%);
    -ms-transform: translateY(-32%);
    transform: translateY(-32%);
  }
  .single_supp_ser {
    display: -webkit-box;
    display: -ms-flexbox;
    display: block;
    margin-bottom: 42px;
    padding-left: 0;
  }
  .supp_content {
    padding-left: 0;
    width: 100%;
  }
  .supp_icon {
    margin-bottom: 50px;
    margin-left: 15px;
  }
  .footer_top_two .widget {
    width: 100%;
  }
  .widget.widget_three {
    width: 100%;
    margin-bottom: 50px;
  }
  .widget.widget_three:last-child {
    margin-bottom: 0;
  }
  .footer_three_area {
    padding: 80px 0;
  }
  .call_to_action_area_two::before {
    background: transparent;
  }
  .call_to_action_hello {
    height: auto;
    padding: 50px 0;
    display: block;
  }
  .call_to_action_left_two {
    margin-bottom: 15px;
  }
  .product_two_area {
    padding: 80px 0 56px;
  }
  .section_title_two h2 {
    font-size: 25px;
  }
  .video_three_area_bg {
    padding: 80px 0;
  }
  .why_use_area {
    padding: 80px 0;
  }
  .feature_benifit_area .card.active {
    padding: 15px;
  }
  .feature_benifit_area .card {
    padding: 0 20px;
  }
  #accordion {
    margin-bottom: 0;
  }
  .feature_benifit_area {
    padding-bottom: 80px;
  }
  .section_title_two {
    margin-bottom: 80px;
  }
  .carousel {
    overflow: hidden;
  }
  .accordion_btn {
    padding-bottom: 0px;
  }
  .banner_two_area .banner_left .banner_left_cell h1 {
    font-size: 35px;
    line-height: 35px;
  }
  .banner_two_area .banner_right {
    height: auto;
  }
  .banner_two_area .banner_slider {
    padding-top: 0px;
    padding-bottom: 0;
  }
  .banner_two_area .banner_left {
    position: relative;
  }
  .widget.widget_three:last-child {
    width: 100%;
  }
  .call_to_action_hello {
    text-align: center;
  }
  .call_to_action_left_two {
    text-align: center;
  }
  .banner_two_area {
    height: 100vh;
  }
  .banner_two_area .sn_bnr_slide {
    height: 100vh;
  }
  .banner_two_area .owl-prev {
    width: 30px;
  }
  .banner_two_area .owl-next {
    width: 30px;
  }
  .modal.quickview-wrapper {
    width: 100%;
  }
  .product_two_slider {
    max-width: 280px;
    margin: 0 auto;
  }
  .portfolio_area_two .sin-portfolio .portfolio_details span {
    font-size: 12px;
    letter-spacing: 0;
  }
  .portfolio_area_two .sin-portfolio .portfolio_details h5,
  .portfolio_area_two .sin-portfolio .portfolio_details h5 > span {
    font-size: 13px;
    letter-spacing: 0;
  }
  .portfolio_area_two .sin-portfolio .portfolio_details {
    -webkit-transform: translate(-50%, 50%);
    -ms-transform: translate(-50%, 50%);
    transform: translate(-50%, 50%);
  }
  .page_content_two.section_padding_two {
    padding: 80px 0;
  }
  .footer_three_area {
    padding: 80px 0;
  }
  .sin-portfolio .portfolio_details h5,
  .sin-portfolio .portfolio_details h5 > span {
    font-size: 14px;
    letter-spacing: 0;
  }
  .banner_two_area .banner_left .banner_left_cell h1 {
    margin-top: 0;
  }
  .client_talk p {
    font-size: 15px;
  }
  .breadcrumb_area_two .page_header h1 {
    font-size: 29px;
    letter-spacing: 3px;
  }
  .footer_five_area .widget.widget5 {
    width: 100%;
  }
  .home_five_single_service_img {
    display: none;
  }
  .home_five_single_service_right {
    width: 100%;
  }
  .home_five_single_service:last-child .home_five_single_service_right {
    width: 100%;
  }
  .home_five_single_service_right {
    padding-top: 0;
    margin-bottom: 50px;
  }
  .home_five_single_service_inner {
    height: auto;
  }
  .home5_recent_slide {
    padding: 37px 15px 13px;
  }
  .home5_recent_slider .owl-nav {
    left: 0;
    width: 100%;
  }
  .home5_recent_slider .owl-nav .owl-prev,
  .home5_recent_slider .owl-nav .owl-next {
    width: auto;
    height: auto;
    line-height: auto;
    text-align: center;
    border: 2px solid transparent !important;
    background: transparent !important;
  }
  .home5_recent_slider .owl-nav .owl-prev:hover,
  .home5_recent_slider .owl-nav .owl-next:hover {
    border: 2px solid transparent !important;
  }
  .footer_five_area .footer_four_bottom_right a {
    padding: 0px 10px;
  }
  .footer_four_bottom_left {
    margin-top: 17px;
  }
  .section_title_four h2 {
    font-size: 24px;
  }
  .footer_five_area .footer_five_top .footer_five_top_right label {
    height: auto;
  }
  .footer_five_area .footer_five_top .footer_five_top_right label::after {
    display: none;
  }
  .footer_five_area .footer_five_top .footer_five_top_right label span {
    font-size: 12px;
  }
  .footer_five_area .footer_five_top .footer_five_top_right button {
    width: 40px;
  }
  .footer_five_area .footer_five_top .footer_five_top_right input {
    width: calc(100% - 42px);
  }
  .call_to_action_green .call_to_action_hello {
    padding: 0 15px;
  }
  .home5_video_right::after {
    left: 25%;
  }
  .home_five_single_service::after {
    top: 0;
    height: 100%;
  }
  .home_five_single_service:hover:before {
    height: 100%;
    top: 0;
  }
  .home_five_single_service {
    margin-right: 0;
  }
  .home5_pricing_single {
    padding-left: 30px;
    padding-right: 30px;
  }
  .tp-banner-container {
    padding-top: 197px;
  }
}

@media (max-width: 450px) {
  .banner_slider {
    padding-top: 150px;
  }
  .banner_right_cell {
    width: 50%;
  }
  .owl-carousel .owl-item img {
    width: 248px;
  }
}
