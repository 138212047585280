.top_feature_area_two{
	padding-bottom: 116px;
	padding-top: 136px;

	p,h1,h2,h3,h4,h5,h6,a {
		font-family: $font_primary;
	}
}

.single_feature_two{
	text-align: center;
	padding: 39px 34px 20px;
	position: relative;
	border-radius: 3px;

	span {
	    font-size: 40px;
	    position: absolute;
	    top: -25px;
	    left: 50%;
	    -webkit-transform: translateX(-50%);
	    -ms-transform: translateX(-50%);
	    transform: translateX(-50%);
	    width: 70px;
	    line-height: 50px;
	    border: 1px solid #fff;
	    border-radius: 5px;
	}

	h5 {
		@include transition(all 0.3s);
		color: $box_heading;
	}

}



