.home5_recent {
	background: transparent;
	position: relative;
	overflow: hidden;
	padding-bottom: 140px;
	padding-top: 190px;

	&:after {
		position: absolute;
		top: 47px;
		content: "";
		left: 0;
		width: 100%;
		height: calc(100% - 251px);
		background: #f5f5f5;
		z-index: -1;
	}

	.section_title_four {
		margin-bottom: 30px;
		position: absolute;
		left: 15px;
		top: -198px;
	}
}

.home5_recent_slide {
	position: relative;

	.off {
		position: absolute;
		right: 43px;
		color: #ff8b8b;
		top: 23px;
	}
}

.home5_recent_slider {

	&:hover {

		.owl-nav {
			opacity: 1;
		}
	}

	.owl-stage-outer {
		overflow: visible;
	}

	&.owl-carousel .owl-item img {
		display: inline-block;
		width: auto;
		max-width: 100%;
	}

	&.owl-carousel .owl-item {
		opacity: 0;

		&.active {
			opacity:1;
		}
	}

	.owl-nav {
		position: absolute;
		top: 127px;
		left: -40px;
		width: calc(100% + 80px);
		opacity: 0;
		transition: all 0.5s linear;

		.owl-prev, .owl-next {
			width: 80px;
			height: 80px;
			line-height: 80px;
			text-align: center;
			border: 2px solid #dadada !important;
			border-radius: 0;
			background: #fff !important;
			transition: all 0.3s ease;

			i {
				font-size: 30px;
				color: #dadada;
				transition: all 0.3s ease;
				line-height: 80px;
			}

			&:hover {
				border: 2px solid #000 !important;

				i {
					color: $home5_black;
				}
			}
		}

		.owl-next {
			float: right;
		}
	}
}

.section_title_four {
	margin-bottom: 30px;

	h2 {
		font-size: 60px;
		color: $home6_primary2;
		font-weight: 600;
		display: inline-block;
		background: #fff;
		padding: 31px 26px;
		margin-bottom: 0;
		line-height: 43px;
		letter-spacing: 1.5px;
	}
}

.home5_recent_appreciate {
	margin-bottom: 8px;

	a {
		padding: 0;
		font-size: 14px;
		margin-right: 3px;

		i {
			color: $home6_primary3;
		}
	}
}

.home5_recent_view_cart {
	overflow: hidden;

	a {
		padding: 0;
		padding: 0;
		font-size: 12px;
		color: $text_inner74;
		letter-spacing:2px;
		vertical-align: middle;

		&:last-child {
			float: right;
			padding-right: 13px;
			margin-top: 2px;

			i {
				font-size: 14px;
				margin-top: -4px;
			}
		}

		i {
			font-size: 16px;
			margin-right: 10px;
			vertical-align: middle;
			color: #7474BA;
		}

		&:hover {

			i {
				color: $home6_primary;
			}

			color: #000;
		}
	}
}

.home5_recent_slide {
	background: #fff;
	padding: 37px 39px;
	box-shadow: 0px 5px 18px rgba(0, 0, 0, 0.06);
	padding-bottom: 20px;

	.home5_recent_thumb {
		margin-bottom: 20px;
		position: relative;
		text-align: center;

		span {
			position: absolute;
			bottom: 0;
			left: 57px;
			width: 50px;
			height: 50px;
			border-radius: 50%;
			background: $home6_primary;
			line-height: 50px;
			color: #fff;
			text-align: center;
		}
	}
}

.home5_recent_title h3 {
	font-size: 16px;
	color: #000;
	font-weight: 500;
	padding-bottom: 22px;
	margin-bottom: 15px;
	border-bottom: 1px solid #ddd;
	text-transform: capitalize;
	letter-spacing: 0.3px;

	a {
		font-size: 16px;
		color: #000;
		font-weight: 500;
		padding: 0;
	}

	&:hover {
		a {
			color: $home6_primary;
		}
	}
}
@media (min-width: 992px) and (max-width: 1199px) {
	.section_title_four {
		padding-top: 5px;
		h2 {
			box-shadow: 0px -1px 5px rgba(0,0,0,0.05);
		}
	}

	.home5_recent_slide {
		padding: 37px 30px;
	}
	.home5_recent_view_cart a {
		font-size: 10px;
	}
	.home5_recent_view_cart a i {
		margin-right: 0;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.home5_recent_view_cart a {
	    font-size: 10px;
	}
	.home5_recent_slider .owl-nav {
		left: -39px;
		width: calc(100% + 78px);
	}
}

@media (min-width: 576px) and (max-width: 767px) {
	.home5_recent_slider {
		width: 80% !important;
		margin: 0 auto;
	}
	.home5_recent_slider .owl-nav {
		left: -51px;
		width: calc(100% + 102px);
		opacity: 1;
	}
	.home5_recent_slide .home5_recent_thumb span {
		left: 124px;
	}
}

@media only screen and (max-width: 575px) {
	.home5_recent_slider .owl-nav {
		opacity: 1;
	}
	.home5_recent_view_cart a i {
		margin-right: 0;
	}
}