.faq_custom_single_post_more_text {
  max-height: 0;
  overflow: hidden;
  @include transition(all ease 0.5s);

  .show-more & {
    max-height: 1000px;
  }
}

.faq_custom_area {
	padding: 140px 0 33px;

	.section_title_four {
		margin-bottom: 100px;

		h2 {
			padding: 0;
			letter-spacing: 7.5px;
		}
	}
}

.faq_custom_single_post_link {
	display: block;
	text-align: center;
	border: 1px solid #ccc;
	padding: 3px;
	background-color: #ddd;
	color: $home5_black;
	position: absolute;
	top: 87px;
	height: calc(100% - 80px);
	width: 1px;
	border: none;
	background: transparent;
	border-radius: 0;
	cursor: pointer;
	transition: all ease 0.5s;

	&:after {
		position: absolute;
		content: "";
		left: 6px;
		top: 0;
		width: 1px;
		height: calc(100% + 15px);
		background: #e1e1e1;
		z-index: -1;
		-webkit-transition: all ease 0.5s;
		-o-transition: all ease 0.5s;
		transition: all ease 0.5s;
		padding: 0;
	}


	&:hover {
		background-color: transparent;

		&:before {
			color: $home6_primary;
		}
	}

	&:before {
		content: "\f055";
		font-family: "Font Awesome 5 Free";
		font-weight: 900;
		position: absolute;
		bottom: -25px;
		font-size: 14px;
		left: -1px;
		border-radius: 50%;
		z-index: 9;
		color: $home5_black;
		width: 15px;
		height: 15px;
		line-height: 15px;
		transition: 0.3s;
		transition: all ease 0.5s;

	    .show-more & {
	      content: "\f056";
	      color: $home6_primary;
	    }
  	}

  	&.no-more {
  		&:before {
  			background: $home5_black;
			content: "";
			width: 15px;
			height: 15px;
  		}
  	}
}

.faq_custom_single_post:nth-child(2n) .faq_custom_single_post_img {
	padding-right: 0;
	padding-left: 154px;
}

.faq_custom_single_post:nth-child(2n) .faq_custom_single_post_container {
	padding-left: 11px;
}

.faq_custom_single_post {
	position: relative;
	margin-bottom: 124px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: space-between;

	&:nth-child(even) {
		flex-direction: row-reverse;

		.faq_custom_single_post_img img {
			transform: rotate(180deg);
		}
	}

	&:last-child {
		margin-bottom: 0;
	}
}

.faq_custom_single_post_content {
	width: 60%;
}
.faq_custom_single_post_img {
	padding-right: 154px;

	img {
		padding-right: 19px;
		padding-top: 8px;
	}
}

.faq_custom_single_post_container {

	h4 {
		font-size: 24px;
		font-weight: 500;
		font-style: normal;
		font-stretch: normal;
		line-height: 31px;
		letter-spacing: 0.6px;
		text-align: left;
		color: $home5_black;
		font-family: "Poppins", sans-serif;
		text-transform: inherit;
		transition: 0.5s;
		margin-bottom: 43px;
	}

	&.show-more h4 {
        color: $home6_primary3;
    }

	p{
		font-size: 20px;
		font-weight: 300;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.5;
		letter-spacing: 0.5px;
		text-align: left;
		color: $text_inner45;
		padding-left: 30px;
	}
}

.faq_custom_single_post_inner {
	position: relative;
}

@media (min-width: 1200px) and (max-width: 1499px) {
	.faq_custom_single_post_img {
	    padding-right: 50px;
	}
	.faq_custom_single_post:nth-child(2n) .faq_custom_single_post_img {
	    padding-left: 50px;
	}
}

@media (min-width: 992px) and (max-width: 1199px) {
	.faq_custom_single_post:nth-child(2n) .faq_custom_single_post_img {
		padding-left: 0;
	}

	.faq_custom_single_post_img {
		padding-right: 0;
	}
	.faq_custom_area .section_title_four h2 {
		box-shadow: none;
	}
	.faq_custom_single_post_container h4 {
		font-size: 22px;
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	.faq_custom_single_post:nth-child(2n) .faq_custom_single_post_img {
		padding-left: 0;
	}

	.faq_custom_single_post_img {
		padding-right: 0;
	}

	.faq_custom_single_post_link {
		top: 140px;
		height: calc(100% - 140px);
	}
	.faq_custom_area .section_title_four h2 {
		box-shadow: none;
	}
	.faq_custom_area {
		padding: 90px 0 26px;
	}
	.faq_custom_area .section_title_four {
		margin-bottom: 65px;
	}
}

@media only screen and (max-width: 767px) {
	.faq_custom_single_post {
		flex-wrap: wrap;
	}
	.faq_custom_area .section_title_four h2 {
		box-shadow: none;
	}
	.faq_custom_single_post_content {
		width: 100%;
	}

	.faq_custom_single_post_container h4 {
		font-size: 20px;
	}
	.faq_custom_single_post_img {
		display: none;
	}
	.faq_custom_single_post_link {
		top: 80px;
		height: calc(100% - 80px);
	}
	.faq_custom_area {
		padding: 90px 0 33px;
	}
	.faq_custom_area .section_title_four h2 {
		font-size: 30px;
	}
	.faq_custom_area .section_title_four {
		margin-bottom: 40px;
	}
	.faq_custom_area {
		padding: 75px 0 25px;
	}
	.faq_custom_single_post:nth-child(2n) {
		margin-left: -10px;
	}
	.faq_custom_single_post {
		margin-bottom: 80px;
	}
}

@media only screen and (max-width: 575px) {
	.faq_custom_single_post_link {
		top: 127px;
		height: calc(100% - 127px);
	}
	.faq_custom_area .section_title_four h2 {
		letter-spacing: normal;
	}
	.faq_custom_area .section_title_four {
		margin-bottom: 60px;
	}
}

@media (min-width: 1200px) and (max-width: 1499px) {
	.faq_custom_single_post_content {
		width: 68%;
	}
}