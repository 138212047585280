.supplement_benefit_item {
	text-align: center;
	width: 33.33%;
	background: #f1f1f1;
	margin-right: 1px;
	transition: 0.5s;
	position: relative;
	z-index: 1;

	&:last-child {
		margin-right: 0;
	}

	i {
		width: 45px;
		height: 45px;
		background: #fff;
		line-height: 45px;
		border-radius: 50%;
		font-size: 16px;
		color: $home5_primary;
		margin-bottom: 32px;
	}

	p {
		font-size: 16px;
		font-weight: 500;
		margin-bottom: 0;
		line-height: 26px;
		color: #424e5a;
	}

	&:hover {
		p {
			color: #fff;
		}

		&:before {
			opacity: 1;
		}
	}
}

.supplement_benefit_all_items {
	display: flex;
}

.supplement_benefit {
	position: relative;
	z-index: 1;
}

.supplement_benefit_item {
    color: #000;
    display: inline-block;
    text-decoration: none;
    overflow: hidden;
    vertical-align: top;
    -webkit-perspective: inherit;
    -moz-perspective: inherit;
    -ms-perspective: inherit;
    perspective: inherit;
    -webkit-perspective-origin: 50% 50%;
    -moz-perspective-origin: 50% 50%;
    -ms-perspective-origin: 50% 50%;
    perspective-origin: 50% 50%;

    &:hover .supplement_benefit_inner {
		background: #f1f1f1;
		-webkit-transform: translate3d( 0px, 0px, -30px ) rotateX( 90deg );
		-moz-transform: translate3d( 0px, 0px, -30px ) rotateX( 90deg );
		-ms-transform: translate3d( 0px, 0px, -30px ) rotateX( 90deg );
		transform: translate3d( 0px, 0px, -30px ) rotateX( 90deg );
    }

    .supplement_benefit_inner {
		padding: 47px 90px 43px !important;
		display: block;
		position: relative;
		padding: 0 3px;
		-webkit-transition: all 0.8s ease;
		-moz-transition: all 0.8s ease;
		-ms-transition: all 0.8s ease;
		transition: all 0.8s ease;
		-webkit-transform-origin: 50% 0%;
		-moz-transform-origin: 50% 0%;
		-ms-transform-origin: 50% 0%;
		transform-origin: 50% 0%;
		-webkit-transform-style: preserve-3d;
		-moz-transform-style: preserve-3d;
		-ms-transform-style: preserve-3d;
		transform-style: preserve-3d;
		z-index: 1;

      	.after {
			content: "";
			z-index: -1;
			display: block;
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			padding: 0 3px;
			color: #fff;
			background: -o-linear-gradient(top, #3db5e6 0%, #0086c3 100%);
			background: -webkit-gradient(linear, right top, left top, from(#3db5e6), to(#0086c3));
			background: linear-gradient(to bottom, #3db5e6 0%, #0086c3 100%);
			-webkit-transform-origin: 50% 0%;
			-moz-transform-origin: 50% 0%;
			-ms-transform-origin: 50% 0%;
			transform-origin: 50% 0%;
			-webkit-transform: translate3d( 0px, 105%, 0px ) rotateX( -90deg );
			-moz-transform: translate3d( 0px, 105%, 0px ) rotateX( -90deg );
			-ms-transform: translate3d( 0px, 105%, 0px ) rotateX( -90deg );
			transform: translate3d( 0px, 105%, 0px ) rotateX( -90deg );
			padding: 47px 90px 43px !important;
      	}
    }
}

@media (min-width: 1200px) and (max-width: 1500px) {
	.supplement_benefit_item .supplement_benefit_inner {
		padding: 47px 55px 43px !important;

		.after {
			padding: 47px 55px 43px !important;
		}
	}
}

@media (min-width: 992px) and (max-width: 1199px) {
	.supplement_benefit_item .supplement_benefit_inner {
		padding: 47px 25px 43px !important;

		.after {
			padding: 47px 25px 43px !important;
		}
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	.supplement_benefit_item {
		width: calc(50% - 1px);
		margin-bottom: 1px;
	}
	.supplement_benefit_all_items {
		flex-wrap: wrap;
	}

	.supplement_benefit_item .supplement_benefit_inner {
		padding: 47px 82px 43px !important;
	}

	.supplement_benefit_item .supplement_benefit_inner .after {
		padding: 47px 82px 43px !important;
	}
}

@media only screen and (max-width: 767px) {
	.supplement_benefit_item {
		width: calc(50% - 1px);
		margin-bottom: 1px;
	}
	.supplement_benefit_all_items {
		flex-wrap: wrap;
	}

	.supplement_benefit_item .supplement_benefit_inner {
		padding: 47px 35px 43px !important;
	}

	.supplement_benefit_item .supplement_benefit_inner .after {
		padding: 47px 35px 43px !important;
	}
}

@media only screen and (max-width: 575px) {
	.supplement_benefit_item .supplement_benefit_inner {
		padding: 47px 26px 43px !important;
	}

	.supplement_benefit_item .supplement_benefit_inner .after {
		padding: 47px 26px 43px !important;
	}
	.supplement_benefit_item {
		margin-bottom: 1px;
	}

	.supplement_benefit {
		padding-top: 0;
	}
}

@media only screen and (max-width: 480px) {
	.supplement_benefit_item {
		width: 100%;
		margin-bottom: 30px;
	}

	.supplement_benefit_all_items {
		flex-wrap: wrap;
	}
}