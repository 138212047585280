.supplement_benefit_two {
	padding-bottom: 140px;

	.supplement_benefit_item {
		transition: all 0.5s linear;

		i {
			color: $home6_primary;
		}

		&:hover {
			.supplement_benefit_inner {
				background: $home6_primary;
				background: #f1f1f1;
			}
		}

		.supplement_benefit_inner {

	      	.after {
				background: -o-linear-gradient(right, #32c027 0%, #3ad82e 100%);
				background: -webkit-gradient(linear, right top, left top, from(#32c027), to(#3ad82e));
				background: linear-gradient(to left, #32c027 0%, #3ad82e 100%);
	      	}
	    }
	}
}


@media only screen and (max-width: 767px) {
	.supplement_benefit_two {
		padding-bottom: 94px;
		padding-top: 0;
	}
}


@media (min-width: 576px) and (max-width: 767px) {
	.supplement_benefit_two {
		padding-bottom: 126px;
		padding-top: 0;
	}
}