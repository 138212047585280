/*--------------------------------------------------------------
  ##  Responsive
  --------------------------------------------------------------*/
@media (min-width: 1200px) and (max-width: 1500px) {
  /* --- Product area --- */
  .single-product .product-content {
    width: 54%;
  }
  .single-product {
    padding: 5px 20px 5px 20px;
  }
  /* --- Top Feature area--- */
  .single_top_feature {
    color: #fff;
  }
  /* --- Top Feature area two --- */
  .single_feature_two {
    padding: 39px 15px 20px;
  }
  .single_feature_two h5 {
    font-size: 19px;
  }
  .single_feature_two {
    font-size: 16px;
  }
  .single_feature_two p {
    font-size: 16px;
    line-height: 26px;
  }
  /* --- Ingredient page  --- */
  .ingredient_content .mini-des {
    padding-bottom: 20px;
    margin-bottom: 30px;
  }
  .ingridient_list {
    margin-top: 40px;
  }
  .side_bar .widget:last-child {
		margin-bottom: 0;
	}
	.banner_two_area .banner_left {
		padding-left: 20px;
	}
}

@media (min-width: 992px) and (max-width: 1199px) {
  /* --- Top Feature Area --- */
  .single_top_feature .tf_icon {
    float: none;
    width: 100%;
    text-align: left;
  }
  .single_top_feature .tf_content {
    width: 100%;
    float: none;
    text-align: left;
    margin-top: 7px;
  }
  .single_top_feature {
    margin-top: 0px;
  }
  .single_top_feature .tf_content p {
		font-size: 14px;
	}
  .tf_content p {
    font-size: 16px;
  }
  .single_top_feature span {
    font-size: 25px;
  }
  .single_top_feature .tf_content h5 {
    font-size: 18px;
  }
  /* --- Product Area --- */
  .single-product .product-content {
    width: 55%;
  }
  .single-product {
    padding: 5px 0px 5px 20px;
  }
  .carousel-sin-product .carousel-product-image {
    margin-top: -226px;
    width: 92%;
  }
  .carousel-sin-product {
    padding: 5px 20px 5px 20px;
  }
  .carousel-sin-product .carousel-product-image {
    margin-top: -172px;
  }
  .carousel-sin-product .carousel-product-content {
    padding-top: 20px;
    width: 100%;
  }
  .ingredient_content h5 {
		font-size: 26px;
	}
  /* --- Features  Area --- */
  .single-features p {
    font-size: 16px;
  }
  .single-features {
    padding: 50px 40px 25px;
  }
  .services_area .padding_right {
    padding-right: 0;
  }
  .services_area .section_title p {
    font-size: 14px;
    line-height: 25px;
  }
  .section_title h2 {
    font-size: 26px;
  }
  .services_area .section_title {
    margin-bottom: 36px;
  }
  /* --- Section Padding --- */
  .products_area {
    padding: 100px 0 70px;
  }
  /* ---Blog page--- */
  .side_bar {
    padding-left: 0;
  }
  .widget_single_post .wid_post_content .post_att {
    font-size: 13px;
  }
  .widget_single_post .wid_post_content h6 a {
    font-size: 15px;
    font-weight: 600;
    text-transform: capitalize;
  }
  .widget {
    padding-right: 0;
  }
  .widget_newsletter .sidebar_newsletter {
    padding: 30px 15px;
  }
  .widget_tags ul li a {
    font-size: 15px;
    padding: 0px 11px;
  }
  .search-field {
    width: 80%;
  }
  .post_details p {
    font-size: 15px;
  }
  .sin_post blockquote {
    padding: 10px 50px;
  }
  .single_feature_two {
    font-size: 14px;
    line-height: 24px;
    padding: 39px 20px 20px;
  }
  .single_feature_two h5 {
    font-size: 15px;
  }
  .single_feature_two span {
    font-size: 30px;
    top: -12px;
  }
  .about_image .image_one {
    height: 370px;
    padding-top: 24px;
    margin-right: 0;
  }
  .about_image .image_two {
    height: 400px;
    padding-top: 36px;
  }
  .about_area .section_title h2 {
    font-size: 24px;
  }
  .about_area .section_title .svg {
    display: none;
  }
  .about_sub p {
    font-size: 14px;
    line-height: 24px;
  }
  .about_area .section_title > span {
    margin-bottom: 7px;
  }
  .carousel-sin-product .carousel-product-content p {
    font-size: 14px;
    line-height: 26px;
    padding-bottom: 58px;
  }
  /* ---Service area two--- */
  .single_service_two h6 {
    color: #383838;
    font-size: 16px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
  }
  .single_service_two p {
    font-size: 14px;
    line-height: 26px;
  }
  .single-servie h6 {
    font-size: 16px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
  }
  .single-servie p {
    font-size: 14px;
    line-height: 26px;
  }
  .services_area_bg .section_title {
    margin-bottom: 25px;
  }
  /* ---Feature three area-- */
  .single_feature_3 {
    padding: 60px 30px;
  }
  .single_feature_3 p {
    font-size: 14px;
    line-height: 26px;
  }
  .single_feature_3 h6 {
    margin-bottom: 18px;
    font-size: 16px;
  }
  .single_feature_3 a {
    font-size: 14px;
  }
  /* ---Ingredient page-- */
  .ingredient-img {
    padding: 95px 18px;
  }
  .ingredient_content .mini-des {
		padding-bottom: 30px;
		margin-bottom: 35px;
	}
	.ingridient_list h6 {
		margin-bottom: 38px;
	}
  .ingridient_list {
    margin-top: 40px;
  }
  .ingredient_content .mini-des ul li {
    font-size: 14px;
    margin-bottom: 3px;
  }
  .ingredient_content > p {
    font-size: 14px;
  }
  .ingridient_list table tr td {
    padding: 6px 0;
  }
  .ingridient_list table tr {
    font-size: 14px;
  }
  .ingredient_content h5 {
    margin-bottom: 40px;
  }
  .testimonial_pro_image {
    top: 160px;
  }
  .side_bar .widget {
		padding: 30px 15px;
	}
}

@media (min-width: 768px) and (max-width: 991px) {
  /* --- Top Feature Area --- */
  .single_top_feature .tf_icon {
    float: none;
    width: 100%;
    text-align: center;
  }
  .footer_bottom_two .copyright span {
		margin-top: 0;
	}
  .single_top_feature .tf_content {
    width: 100%;
    float: none;
    text-align: center;
    margin-top: 7px;
  }
  .single_top_feature {
    margin-top: 27px;
  }
  #footer .widget.widget_about {
		padding-right: 0;
	}
  .tf_content p {
    font-size: 16px;
  }
  .single_top_feature span {
    font-size: 25px;
  }
  .single_top_feature .tf_content h5 {
    font-size: 18px;
  }
  .tf_image {
    text-align: center;
  }
  .top_feature_area {
    padding: 35px 0;
  }
  /* --- Product Area --- */
  .single-product {
    padding: 15px;
    width: 49%;
    float: left;
    margin: 3px;
    margin-bottom: 6px;
    height: 267px;
  }
  .single-product .product-content {
    width: 59%;
    float: left;
    padding-top: 0px;
  }
  .product-image {
    width: 40%;
    overflow: hidden;
  }
  .single-features {
    padding: 30px 10px;
  }
  .single-features p {
    font-size: 14px;
    line-height: 26px;
  }
  .single-features h5 {
    margin-top: 18px;
    margin-bottom: 27px;
    font-size: 18px;
  }
  .single-features h5::after {
    top: 30px;
  }
  .single-product .product-content h3 {
    margin-bottom: 10px;
  }
  /* --- Service Area --- */
  .services_area .image-box-half {
    display: none;
  }
  /* --- Teatimonial  Area --- */
  .single-testimonial, .single-testimonial-two {
    padding: 0 25px;
  }
  /* --- Portfolio  Area --- */
  .sin-portfolio .portfolio_details h5 {
    font-size: 16px;
  }
  /* --- Section padding --- */
  .testimonial_area {
    padding: 80px 0 69px;
  }
  .testimonial_area_two {
    padding: 80px 0;
  }
  .products_area {
    padding: 80px 0;
  }
  .services_area .section_title {
    margin-bottom: 24px;
  }
  .load_more_portfolio .btn_wrapper {
    margin: 54px auto 0;
  }
  /* --- Blog page --- */
  .widget_single_post .post_thumb {
    width: auto;
  }
  .side_bar {
		max-width: 75%;
		margin: 100px auto 0;
		padding-left: 0px;
	}
  .page_content {
    padding: 90px 0 5px;
  }
  .post_details p {
    font-size: 15px;
  }
  .sin_post blockquote {
    padding: 10px 50px;
  }
  /* --- Feature area two --- */
  .single_feature_two {
    margin-bottom: 45px;
  }
  .top_feature_area_two {
    padding: 60px 0 10px;
  }
  #feature {
    padding-bottom: 0.8rem;
  }
  #portfolio, #ingredient, #work_process {
    padding-bottom: 5rem;
  }
  .section_padding_less {
    padding: 5rem 0;
  }
  #blog {
    padding-bottom: 3rem;
  }
  .single_feature_two h5 {
    font-size: 18px;
  }
  /* --- About area --- */
  .about_image .image_one {
    height: 470px;
    padding-top: 88px;
  }
  .about_image .image_two {
    height: 528px;
    padding-top: 116px;
  }
  .about_image .image_one, .about_image .image_two {
    height: auto;
    padding-top: 0;
  }
  .about_area .section_title .svg {
    display: none;
  }
  .about_sub {
    padding-bottom: 30px;
  }
  /* ---Service area two--- */
  .single_service_two h6 {
    color: #383838;
    font-size: 16px;
  }
  .single_service_two p {
    font-size: 14px;
    line-height: 26px;
  }
  /* ---Service area with background--- */
  .services_area_bg::before, .services_area_bg::after {
    display: none;
  }
  /* ---Feature three area-- */
  .single_feature_3 {
    padding: 40px 15px;
  }
  .single_feature_3 p {
    font-size: 14px;
    line-height: 26px;
  }
  .single_feature_3 h6 {
    margin-bottom: 18px;
    font-size: 16px;
  }
  .single_feature_3 a {
    font-size: 14px;
  }
  .gredient_tab {
    display: none;
  }
  .ingredient-img {
    margin: 0 auto;
    width: 52%;
    padding: 25px 0;
    margin-bottom: 50px;
    height: auto;
  }
  .ingredient_content .mini-des {
    padding-bottom: 20px;
    margin-bottom: 30px;
  }
  .ingridient_list {
    margin-top: 40px;
  }
  .ingredient_content .mini-des ul li {
    font-size: 14px;
    margin-bottom: 3px;
  }
  .ingredient_content > p {
    font-size: 14px;
  }
  .ingridient_list table tr td {
    padding: 6px 0;
  }
  .ingridient_list table tr {
    font-size: 14px;
  }
  .ingredient_content h5 {
    margin-bottom: 40px;
  }
  .ingredient_content h5 {
    display: none;
  }
  .testimonial_pro_image {
    top: 45%;
    width: 400px;
  }
  .call_to_action_right {
    margin-top: 15px;
  }
  .breadcrumb_area {
    padding-top: 110px;
  }
  .page_header h1 {
    line-height: normal;
    font-size: 2rem;
    padding-bottom: 15px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .single_top_feature .tf_icon {
    float: none;
    width: 100%;
    text-align: center;
  }
  .top_feature_area_two {
		padding-bottom: 20px;
	}
  .single_pkg_bottom {
		padding: 2rem;
	}
  .single_top_feature .tf_content {
    width: 100%;
    float: none;
    text-align: center;
    margin-top: 7px;
  }
  .single_top_feature {
		margin-top: 0;
		margin-bottom: 30px;
	}
	.top_feature_border {
		padding-bottom: 0;
	}
  .tf_content p {
    font-size: 14px;
    line-height: 25px;
  }
  .single_top_feature span {
    font-size: 25px;
  }
  .single_top_feature .tf_content h5 {
    font-size: 18px;
  }
  .tf_image {
    text-align: center;
  }
  .top_feature_area {
    padding: 35px 0;
  }
  .tf_image {
    text-align: center;
    padding: 0 16%;
    margin-top: 10px;
  }
  .single-product .product-content {
    width: 55%;
  }
  .carousel-sin-product .carousel-product-image {
    margin-top: -252px;
    width: 59%;
  }
  /* --- Features Area --- */
  .single-features {
    width: 75%;
    margin: 0 auto;
    margin-top: 30px;
  }
  .single-features:hover {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  /* --- Service Area --- */
  .services_area .image-box-half {
    width: 50%;
    margin: 0 auto;
    margin-bottom: 50px;
  }
  .single-servie p {
    font-size: 14px;
    line-height: 26px;
  }
  /* --- Testimonial  Area --- */
  .single-testimonial {
    padding: 0 30px;
  }
  .single-testimonial p {
    line-height: 36px;
    font-size: 18px;
  }
  /* --- Testimonial  Area Two--- */
  .single-testimonial-two {
    padding: 0 30px;
  }
  .single-testimonial-two p {
    line-height: 36px;
    font-size: 18px;
  }
  /* --- Portfolio  Area --- */
  .filter-button li {
    margin-bottom: 10px;
  }
  /* --- Portfolio  Area --- */
  .sin-portfolio .portfolio_details h5 {
    font-size: 16px;
  }
  /* --- Section padding --- */
  .testimonial_area {
    padding: 80px 0 68px;
  }
  .testimonial_area_two {
    padding: 80px 0 68px;
  }
  .products_area {
    padding: 80px 0;
  }
  .services_area .section_title {
    margin-bottom: 24px;
  }
  .load_more_portfolio .btn_wrapper {
    margin: 54px auto 0;
  }
  .features_area .section_title {
    margin-bottom: 5px;
  }
  /* --- Blog page --- */
  .pagination ul li {
    margin-right: 15px;
  }
  .side_bar {
    padding-left: 0;
  }
  .side_bar {
    width: 86%;
    margin: 50px auto 0;
  }
  .post_details p {
    font-size: 15px;
  }
  .sin_post blockquote {
    padding: 10px 50px;
  }
  .comment-content p {
    font-size: 15px;
  }
  /* --- Feature area two --- */
  .single_feature_two {
    margin-bottom: 60px;
  }
  .single_feature_two h5 {
    font-size: 18px;
  }
  .single_feature_two span {
    font-size: 32px;
    top: -30px;
  }
  .single_feature_two {
		padding: 39px 15px 20px;
	}
  /* --- About area --- */
  .about_image .image_one {
    height: 470px;
    padding-top: 88px;
  }
  .about_image .image_two {
    height: 528px;
    padding-top: 116px;
  }
  .about_image .image_one, .about_image .image_two {
    height: auto;
    padding: 30px 0;
  }
  .about_area .section_title .svg {
    display: none;
  }
  .about_sub {
    padding-bottom: 30px;
  }
  /* ---Service area two--- */
  .single_service_two h6 {
    color: #383838;
    font-size: 1rem;
  }
  .single_service_two p {
    font-size: 14px;
    line-height: 26px;
  }
  /* ---Service area with background--- */
  .services_area_bg::before, .services_area_bg::after {
    display: none;
  }
  .pl-60 {
    padding-left: 15px;
  }
  .single_feature_3 {
    margin-bottom: 30px;
  }
  .single_feature_3 {
    width: 75%;
    margin: 0 auto 30px;
  }
  /* ---Ingredient page--- */
  .gredient_tab {
    display: none;
  }
  .ingredient-img {
    margin: 0 auto;
    width: 52%;
    padding: 25px 0;
    margin-bottom: 50px;
    height: auto;
  }
  .ingredient_content .mini-des {
    padding-bottom: 20px;
    margin-bottom: 30px;
  }
  .ingridient_list {
    margin-top: 40px;
  }
  .ingredient_content .mini-des ul li {
    font-size: 14px;
    margin-bottom: 3px;
  }
  .ingredient_content > p {
    font-size: 14px;
  }
  .ingridient_list table tr td {
    padding: 6px 0;
  }
  .ingridient_list table tr {
    font-size: 14px;
  }
  .ingredient_content h5 {
    margin-bottom: 40px;
  }
  .ingredient_content h5 {
    display: none;
  }
  .slick_slider_two .slick-next.slick-arrow {
    top: 225px;
  }
  .slick_slider_two .slick-prev.slick-arrow {
    top: 225px;
  }
  .breadcrumb_area {
		padding-bottom: 0px;
		padding-top: 98px;
	}

  .page_header h1 {
    font-size: 2rem;
    padding-bottom: 5px;
  }
  .banner_left .banner_left_cell h2 {
		padding-top: 66px;
	}
	#footer .widget.widget_about_two {
		padding-right: 0;
	}
}

@media (max-width: 575px) {
  .single_top_feature .tf_icon {
    float: none;
    width: 100%;
    text-align: center;
  }
  .widget_single_post .wid_post_content {
		width: 72%;
		padding-left: 15px;
		padding-top: 0;
	}
  .single_top_feature .tf_content {
    width: 100%;
    float: none;
    text-align: center;
    margin-top: 7px;
  }
  .comment-body .reply {
		top: 60px;
	}
  .single_top_feature {
    margin-bottom: 30px;
  }
  .tf_content p {
    font-size: 14px;
    line-height: 25px;
  }
  .single_top_feature span {
    font-size: 25px;
  }
  .single_top_feature .tf_content h5 {
    font-size: 18px;
  }
  .tf_image {
    text-align: center;
  }
  .top_feature_area {
    padding: 35px 0;
  }
  .tf_image {
    text-align: center;
    padding: 0 16%;
    margin-top: 10px;
    margin-bottom: 25px;
  }
  .single-product {
    padding: 0;
  }
  .single-product .product-content {
    width: 100%;
    padding-top: 20px;
    text-align: center;
  }
  .single-product .product-content > span {
    margin-bottom: 11px;
  }
  .single-product .btn_wrapper {
    margin: 0 auto;
    margin-top: 6px;
  }
  .product-image {
    text-align: center;
  }
  .carousel-sin-product {
    padding: 0 20px;
    margin-bottom: 0;
  }
  .carousel-sin-product .carousel-product-content {
    padding-top: 20px;
    width: 100%;
  }
  .carousel-sin-product .carousel-product-image {
    margin-top: 20px;
    width: 74%;
  }
  .carousel-sin-product .carousel-product-content p {
    font-size: 16px;
    line-height: 26px;
  }
  .carousel-sin-product .carousel-product-content > span {
    font-size: 15px;
  }
  /* --- Features Area --- */
  .single-features {
    margin-top: 30px;
  }
  .single-features:hover {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  .single-features p {
    font-size: 15px;
    line-height: 28px;
  }
  /* --- Service Area --- */
  .image-box-half {
    margin-bottom: 30px;
  }
  .services_area .section_title {
    text-align: left;
    margin-bottom:50px;
  }
  .services_area .padding_right {
    padding-right: 0;
  }
  .single-servie {
    margin-top: 0;
    text-align: left;
  }
  /* --- Testimonial Area --- */
  .single-testimonial {
    padding: 0;
  }
  .single-testimonial p {
    line-height: 33px;
    font-size: 16px;
  }
  .single-testimonial .tes-name {
    margin: 15px auto;
  }
  /* --- Testimonial  Area Two--- */
  .single-testimonial-two {
    padding: 0;
  }
  .single-testimonial-two p {
    line-height: 33px;
    font-size: 16px;
  }
  .testimonial_pro_image {
    top: 260px;
    width: 300px;
  }
  .single-testimonial-two p {
    margin-bottom: 27px;
  }
  .single-testimonial-two .tes-name {
    margin: 16px auto 40px;
  }
  /* --- Portfolio Area --- */
  .sin-portfolio .portfolio_details {
    padding-left: 12px;
  }
  .sin-portfolio .portfolio_details h5 {
    font-size: 15px;
  }
  .filter-button li {
    margin-bottom: 20px;
  }
  .filter-button li:hover {
    border: 0px solid #2acb35;
  }
  /* --- Portfolio  Area --- */
  .sin-portfolio .portfolio_details h5 {
    font-size: 16px;
  }
  /* --- Section Global Responsive --- */
  .testimonial_area {
    padding: 80px 0 69px;
  }
  .testimonial_area_two {
    padding: 80px 0;
  }
  .products_area {
    padding: 80px 0;
  }
  .services_area .section_title {
    margin-bottom: 63px;
  }
  .load_more_portfolio .btn_wrapper {
    margin: 54px auto 0;
  }
  .features_area .section_title {
    margin-bottom: 5px;
  }
  .page_content {
    padding: 72px 0;
  }
  /* --- Blog page--- */
  .sin_post h3 {
    font-size: 20px;
  }
  .post_meta a i {
    display: none;
  }
  .post_meta p a {
    font-size: 12px;
  }
  .post_meta p a {
    font-size: 12px;
    padding: 0 12px 0 0;
  }
  .post_meta p a:nth-child(2)::after {
    display: none;
  }
  .post_meta p a:first-child::after {
    display: none;
  }
  .pagination ul li:last-child {
    padding: 5px 0px;
  }
  .pagination ul li:first-child {
    padding: 5px 0px;
  }
  .pagination ul li {
    margin-right: 0px;
  }
  .side_bar {
    padding-left: 0;
    margin-top: 35px;
  }
  .widget_single_post .wid_post_content h6 a {
    font-size: 15px;
    font-weight: 600;
    text-transform: capitalize;
  }
  .widget {
    padding-right: 0;
  }
  .widget_single_post .post_thumb {
    width: 70px;
    margin-bottom: 10px;
  }
  .side_bar .widget {
		padding: 30px 15px;
	}
  .widget_newsletter .sidebar_newsletter {
		padding: 40px 12px;
	}

  .post_meta {
    margin-bottom: 10px;
  }
  .post_details p {
    font-size: 15px;
  }
  .sin_post blockquote {
    padding: 0;
    margin: 0;
  }
  .comment-meta {
    display: inline-block;
    width: 100%;
  }
  .comment-content .reply i {
    display: none;
  }
  .children {
    padding-left: 0;
  }
  .comment-content .reply i {
    display: none;
  }
  .sin-portfolio .portfolio_details {
		width: 96%;
	}
  .single-product .product-content h3 {
    margin-bottom: 7px;
  }
  .vigo_container_one, .vigo_container_two, .container {
    max-width: 450px;
  }
  /* --- Feature area two--- */
  .single_feature_two {
    margin-top: 45px;
  }
  .single_feature_two span {
    font-size: 38px;
    top: -18px;
  }
  .top_feature_area_two {
		padding: 0px 0 80px;
	}
  /* --- About area --- */
  .about_image .image_one {
    height: 470px;
    padding-top: 88px;
  }
  .about_image .image_two {
    height: 528px;
    padding-top: 116px;
  }
  .about_image .image_one, .about_image .image_two {
    height: auto;
    padding: 15px 0;
  }
  .about_area .section_title .svg {
    display: none;
  }
  .about_sub {
    padding-bottom: 30px;
  }
  .about_sub p {
    font-size: 14px;
    line-height: 24px;
  }
  /* ---Service area two--- */
  .single_service_two h6 {
    color: #383838;
    font-size: 16px;
  }
  .single_service_two p {
    font-size: 14px;
    line-height: 26px;
  }
  .single_service_two {
    margin-bottom: 30px;
  }
  .service_two.section_padding {
    padding: 60px 0 25px;
  }
  /* ---Service area with background--- */
  .services_area_bg.services_bg_two::before, .services_area_bg.services_bg_two::after {
		background: #f8f8f8;
		width: 100%;
	}
  .pl-60 {
    padding-left: 15px;
  }
  .single_feature_3 {
    margin-bottom: 30px;
  }
  /* ---Ingredient page--- */
  .gredient_tab {
    display: none;
  }
  .ingredient-img {
    margin: 0 auto;
    width: 52%;
    padding: 25px 0;
    margin-bottom: 50px;
    height: auto;
  }
  .ingredient_content .mini-des {
    padding-bottom: 20px;
    margin-bottom: 30px;
  }
  .ingridient_list {
    margin-top: 40px;
  }
  .ingredient_content .mini-des ul li {
    font-size: 14px;
    margin-bottom: 3px;
  }
  .ingredient_content > p {
    font-size: 14px;
  }
  .ingridient_list table tr td {
    padding: 6px 0;
  }
  .ingridient_list table tr {
    font-size: 14px;
  }
  .ingredient_content h5 {
    margin-bottom: 40px;
  }
  .ingredient_content h5 {
    display: none;
  }
  .slick_slider_two .slick-next.slick-arrow {
    top: 0;
  }
  .slick_slider_two .slick-prev.slick-arrow {
    top: 0;
  }
  .ingredient_content_heading h5 {
    margin-bottom: 25px;
    height: 59px;
  }
  .ingredient_content_heading h5::after {
    display: none;
  }
  .ingridient_list table {
    margin: 0;
  }
  .breadcrumb_area {
    padding-bottom: 0px;
    padding-top: 93px;
  }
}