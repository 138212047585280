.hdr_area_two {
	border-bottom: 1px solid rgba(255,255,255,0.1);
	background: transparent;
	box-shadow: none;
	font-family: $font_primary;

	p,h1,h2,h3,h4,h5,h6,a {
		font-family: $font_primary;
	}

	&.headroom--not-top .header_nav {
		background: #fff;
		box-shadow: 0px 0px 10px rgba(0,0,0,0.3);

		.main_menu .sub-menu a.current_page_item {
			color: $home6_primary;
		}

		.btn_three {
			color: $home6_primary;
			height: 45px;
			line-height: 42px;
			background: transparent;

			&:before {
				border: 2px solid #fff;
			}

			&:hover {
				color: #fff;
				background: $home6_primary;

				&:before {
					border: 2px solid #fff;
					opacity: 1;
				}
			}
		}
	}

	&.headroom--not-top .header_nav .main_menu {
		padding: 25px 0 23px;
	}

	.sub-menu a:hover {
		color: #cf3815;
	}

	.main_menu {
		padding: 26px 0;
	}

	.main_menu > ul > li > a {
		color: #fff;
		border-radius: 3px;
		-webkit-transition: 0.5s;
		-o-transition: 0.5s;
		transition: 0.5s;
		letter-spacing: 0.25px;
		height: 32px;
		padding: 0 18px;
		line-height: 32px;
	}

	.main_menu .sub-menu a:hover,
	.main_menu .sub-menu a.current_page_item {
		color: $home6_primary3;
	}

	.btn_one, .btn_two {
		border: 2px solid #fff;
		color: #fff;
		letter-spacing: 2.2px;
		padding: 0;
		width: 143px;
		text-align: center;
	}

	.header_logo .svg .st1,
	.header_logo .svg .st0 {
		fill: #fff;
		stroke: #fff;
	}
	.header_logo .svg{
		fill: #fff;
		stroke: #fff;
	}
}

.top_toolbar_new {
	height: 45px;
	background: #2e2c97;
	font-family: $font_primary;
	position: relative;

	.toolbar_left {
		display: flex;

		p {
		    margin-bottom: 0;
		    font-size: 14px;
		    font-weight: 400;
		    color: #fff;
		    line-height: 45px;


			span {
				color: #f24400;
				font-weight: 500;
			}
		}
	}

	.toolbar_right {
		text-align: right;
  		line-height: 44px;

		ul {
			margin-bottom: 0;

			li {
				display: inline-block;
				font-size: 14px;
				color: #fff;
				font-weight: 400;
				padding: 0 10px;
				position: relative;
				cursor: pointer;

				> i {
					font-size: 14px;
					color: #fff;
				}
				&.cart {
					padding-right: 10px;
				}

				&:last-child {
					padding-right: 0;
					padding-left: 20px;

					i {
						margin-right: 5px;
					}
				}

				&:first-child {
					padding: 0px 8px;
					&:after {
						display: none;
					}
				}

				&:after {
					position: absolute;
					left: 0;
					top: 10px;
					height: calc(100% - 20px);
					width: 1px;
					background: rgba(255, 255, 255, 0.1);
					content: "";
				}

				&.cart {
					&:before {
						position: absolute;
						content: "";
						right: 6px;
						top: 25%;
						width: 6px;
						height: 6px;
						border-radius: 50%;
						background: $home6_primary;
					}
				}

				> a {
					padding: 0;
					color: #fff;
					font-size: 14px;
					font-weight: 400;
				}

				&.search {
					position: inherit;
				}
			}
		}
	}
}

#count_down {
	display: inline-block;

	p {
		line-height: 44px;

		span {
			font-size: 20px;
			color: #fff;
			background: rgba(0, 0, 0, 0.3);
			display: inline-block;
			padding: 0px 16px;
			margin: 0 7px;
			vertical-align: middle;
			line-height: 45px;
			margin-right: 3px;
		}
	}
}

.cart_detail {
	position: absolute;
	width: 311px;
	background: #fff;
	text-align: left;
	right: 50%;
	transform: translateX(30%);
	padding: 37px;
	border-radius: 3px;
	z-index: 9;
	font-family: $font_primary;
	top: calc(100% + 3px);
	visibility: hidden;
	transition: all 0.3s ease 0s;
	-webkit-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;

	&:after {
		position: absolute;
		content: "";
		left: calc(50% - 5px);
		top: -5px;
		width: 10px;
		height: 10px;
		background: #fff;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
		border-radius: 2px;
	}

	p,h1,h2,h3,h4,h5,h6 {
		font-family: $font_primary;
	}
}
.single_cart {
	padding: 13px 0;
	border-bottom: 1px solid #ddd;

	&:first-child {
		padding-top: 0;
	}
}
.cart_left {
	width: 60px;
	background: #ebebeb;
	text-align: center;
	padding: 10px;
	vertical-align: middle;
	display: inline-block;
}
.cart_right {
	display: inline-block;
	width: calc(100% - 65px);
	vertical-align: middle;
	padding-left: 10px;

	h3 {
		font-size: 14px;
		color: #171723;
		font-weight: 500;
		margin-bottom: 0;
		text-transform: capitalize;
	}

	p {
		font-size: 24px;
		color: $home6_primary3;
		margin-bottom: 0;

		sup {
			font-size: 12px;
		}
	}
}

.cart_more {
	text-align: center;
	line-height: 14px;
	padding-top: 26px;

	a {
		font-size: 14px;
		color: $home6_primary2;
		font-weight: 700;
		text-transform:uppercase;
		padding: 0;
		letter-spacing: 1.8px;
		transition: 0.5s;

			&:hover {
				i {
					margin-left: 10px;
					color: $home6_primary;
				}
			}

		i {
			font-size: 15.02px;
			color: $home6_primary !important;
			transition: 0.3s;
		}
	}
}

.hdr_area_three {

	.cart_more {

		a {
			color: $home5_primary;

			&:hover {
				i {
					margin-left: 10px;
				}
			}

			i {
				color: $home5_primary !important;
			}
		}
	}
}

.toolbar_right li:hover .cart_detail {
	visibility: visible;
	transform: translateX(48%);
	transition: all 0.3s ease 0s;
	-webkit-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	box-shadow: 0px 0px 15px rgba(0,0,0,0.05);
}

.header_logo {
	line-height: 80px;
	margin-left: -5px;
}


.search_detail_two {
	position: absolute;
	width: 100%;
	text-align: left;
	left: 0;
	padding: 0px;
	border-radius: 3px;
	z-index: 9;
	font-family: "Roboto", sans-serif;
	top: calc(100% + 3px);
	transition: all 0.3s ease 0s;
	-webkit-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	top: 0;
	height: 100%;
	border: none;
	background: #ededed;
	opacity: 0;
	z-index: -1;

	form {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		margin: 0 15px;
		max-width: 80%;
		margin: 0 auto;
		justify-content: center;
		height: 100%;


		button {
			display: inline-block;
			border: none;
			border-radius: 0;
			color: #ededed;
			font-weight: 500;
			font-size: 16px;
			cursor: pointer;
			background: transparent;
			padding: 0;
			color: $home5_black;
		}

		input {
			display: inline-block;
			border: none;
			padding: 0 15px;
			border-radius: 0;
			width: 100%;
			background: #ededed;
			color: $home5_black;
			background: transparent;
			font-size: 16px;
		}
	}

	&.active {
		opacity: 1;
		z-index: 1;
	}
}

.search_detail_two_close {
	position: absolute;
	left: 8%;
	top: 50%;
	transform: translateY(-50%);
	font-size: 24px;
	padding-right: 12px;
	border-right: 2px solid #ddd;
	cursor: pointer;
	color: $home5_black;

	i {
		margin-top: 5px;
	}
}