/*!
  Theme Name: Vaxin-Health suppliment single product affiliate marketing html template
  Theme URI: http://themeim.com/demo/vaxin
  Author: ThemeIM
  Description: Theme Description
  Version: 1.0.0
  License: GNU General Public License v2 or later
  License URI: http://www.gnu.org/licenses/gpl-2.0.html
  Tags: affiliate landing page, body enhancement, fitness, health, html, landing page, medicine, nutrition, product, responsive, small business, sports, supplement, vitamin, weight loss
  */
/*--------------------------------------------------------------
  >>> TABLE OF CONTENTS:
  ----------------------------------------------------------------
  # Base
  # Sections
    ## Header
    ## Banner
    ## Breadcrumb
    ## Top feature
    ## Top feature 2
    ## About area
    ## Product with carousel
    ## Features
    ## Services
    ## Services With background
    ## Services 2
    ## Feature 3
    ## Testimonial
    ## Testimonial  two
    ## Portfolio
    ## Video
    ## Video 2
    ## Pricing Table
    ## Work Process
    ## Product Detail
    ## Faq
    ## Blog
    ## Contact
    ## Footer
    ## Call to action
    ## Promote
    ## Package Plan
    ## Advertise
    ## Supplement service
    ## 404 Error content
    ## Contact page
    ## Footer 2
    ## Blog page
    ## Ingredient
    ## Responsive


  --------------------------------------------------------------*/
@import "generated/icons";
@import "base/variables";
@import "base/mixins";
@import "base/base";
@import "base/color";
@import "elements/elements";

