.section_padding_two {
	padding: 116px 0;
}

.supplement_service_area_two {

	p,h1,h2,h3,h4,h5,h6,a {
		font-family: $font_primary;
	}

	.supp_content{

		h5 {
			color: #3734f0;
			font-size: 20px;
		}

		 p {
			color: #9e9c9c;
		}
	}

	.supp_icon {
		-webkit-box-shadow: 0px 2px 51px rgba(55, 52, 240, 0.25);
		box-shadow: 0px 2px 51px rgba(55, 52, 240, 0.25);
	}

	.supplement_service_img {
		-webkit-transform: translateY(-56%);
		-ms-transform: translateY(-56%);
		transform: translateY(-56%);
	}
	.supp_ser_list {
		padding-right: 0;
	}
}

.single_supp_ser:hover .supp_content h5 {
	color: #383434;
}

@media(min-width: 768px) and (max-width: 991px) {
	.faq3_area.section_padding_two {
		padding: 96px 0 0;
	}
}