p {
	margin-bottom: 0;
}
.supplement_hot_selling_group {
	padding-bottom: 220px;
	background: url(../../media/images/home6/group-bg.png);
	background-position: right top;
	background-size: inherit;
	background-repeat: no-repeat;

	.supplement_we_say_content_slide {
		height: 526px !important;
	}
}
.supplement_we_say_content {
	display: flex;
	position: relative;

	&:before {
		position: absolute;
		content: "";
		left: 0;
		top: 0;
		width: 80%;
		height: 100%;
		background: #f5f5f5;
	}

	.slider.supplement_we_say_content_for {
		width: calc(100% - 30%);
		padding-left: 30px;
		height: 100%;
		transform: translateY(80px);

		&:after {
			position: absolute;
			content: "";
			left: 24px;
			top: 0;
			width: 6px;
			height: 145px;
			background: rgba(0,0,0,0.3);
		}
	}
	.slider.supplement_we_say_content_nav {
		width: 30%;
		height: 524px;
		overflow: hidden;
		padding-left: 60px;
		margin-bottom: 0;
		padding-top: 50px;
	}
	.supplement_we_say_slide-img {
		display: flex;
		align-items: center;

		img {
			border: 4px solid #fff;
		}

		a {
			font-size: 16px;
			color: $home6_primary3;
			font-weight: 600;
			letter-spacing: 1.2px;
			padding: 0;
			padding-left: 10px;
			transition: 0.3s;
			font-family: $font_heading;

			&:hover {
				color: $home6_primary;

				span {
					color: $home5_black;
				}
			}

			span {
				display: block;
				font-size: 10px;
				font-weight: 500;
				color: $home5_black;
			}
		}
	}
	.supplement_we_say_slide {
		margin-right: 20px;
		padding-top: 25px;
		padding-bottom: 30px;

		&.slick-active.slick-current {
			border-bottom: 1px solid #fff;
		}
	}

	.slick-list {
		padding: 0 !important;
	}
}

.supplement_we_say_slide_content {
	letter-spacing: 0.25px;
	color: $text_inner45;
	font-weight: 300;
}

.supplement_what_we_say {

	.section_title_four {
		text-align: center;
		margin-bottom: 80px;
	}
}
.supplement_hot_selling {
	position: relative;

	.section_title_four {
		position: absolute;
		top: -45px;
	}
	.supplement_hot_selling_content {
		margin-right: -15px;
	}

	&:before {
		position: absolute;
		content: "";
		left: 0;
		top: 0;
		width: 51%;
		height: 100%;
		background: #f5f5f5;
	}
}
.supplement_we_say_slide-author {
	width: 85px;
	height: 85px;
}
.supplement_hot_selling_slider {

	&:hover {

		.owl-nav {
			opacity: 1;
		}
	}

	.owl-nav {
		position: absolute;
		left: 0;
		bottom: 50%;
		width: 100%;
		opacity: 0;
		transition: 0.5s;

		.owl-next {
			float: right;
			margin-right: -37px;
		}

		.owl-prev {
			margin-left: -37px;
		}

		.owl-next,
		.owl-prev {
			width: 75px;
			height: 75px;
			border: 3px solid #ededed !important;
			border-radius: 0px;
			text-align: center;
			line-height: 70px;
			background: #fff !important;
			transition: 0.3s;

			&:hover {
				border: 3px solid #000 !important;

				i {
					color: $home5_black;
				}
			}

			i {
				font-size: 30px;
				color: #dadada;
				line-height: 71px;
				transition: 0.3s;
			}
		}
	}

	.owl-item img {
		display: block;
		width: auto;
		max-width: 100%;
	}

	.owl-stage-outer {
		padding: 90px 0 97px;
		margin-right: -15px;
	}
}

.supplement_what_we_say {
	padding-top: 110px;
}

.supplement_we_say_content_slide {

	.youtube-wrapper .youtube-poster {
		height: 100%;
		border: none;
	}

	.home5_video_right {
		height: 100%;
		width: 100%;
	}

	.home5_video_right i {
		right: auto;
		left: 0;
		z-index: 9;
		bottom: 80px;
		background: $text_inner74;
	}
}

.supplement_we_say_slide-img {
	margin-bottom: 10px;
}

.supplement_hot_selling_slider .owl-item img {
	display: block;
	width: auto;
	max-width: 100%;
	// left: 50%;
	// position: relative;
	// transform: translateX(-50%);
	margin: 0 auto;
}

@media (min-width: 1200px) and (max-width: 1499px) {
	.supplement_we_say_slide_content {
		font-size: 14px;
	}
	.supplement_we_say_content .slider.supplement_we_say_content_nav {
		padding-left: 40px;
		padding-top: 40px;
	}
	.supplement_we_say_content .slider.supplement_we_say_content_nav {
		width: 100%;
	}
	.supplement_we_say_content .supplement_we_say_slide {
		margin-right: 0;
	}
}

@media (min-width: 992px) and (max-width: 1199px){
	.supplement_we_say_content .slider.supplement_we_say_content_nav {
		width: 50%;
	}
	.supplement_we_say_content .slider.supplement_we_say_content_for {
		width: 50%;
	}
	.supplement_hot_selling_slider .owl-nav .owl-next {
		margin-right: 0;
	}
	.supplement_hot_selling_slider .owl-nav .owl-prev {
		margin-left: 0;
	}
	.supplement_we_say_content_slide .youtube-wrapper .youtube-poster {
	    background-position: 84%;
	}
	.supplement_what_we_say {
		padding-top: 100px;
	}
	.supplement_what_we_say .section_title_four {
		margin-bottom: 40px;

		h2 {
			box-shadow: none;
		}
	}
	.supplement_hot_selling_group {
		padding-bottom: 140px;
	}
	.supplement_we_say_content .slider.supplement_we_say_content_for {
		transform: translateY(0);
	}
	.supplement_hot_selling::before {
		width: 100%;
	}
}

@media (min-width: 768px) and (max-width: 991px){
	.supplement_we_say_content .slider.supplement_we_say_content_for {
		width: 50%;
		-webkit-transform: translateY(0px);
		-ms-transform: translateY(0px);
		transform: translateY(0px);
	}
	.supplement_we_say_content .slider.supplement_we_say_content_nav {
		width: 100%;
		padding-left: 30px;
	}

	.supplement_we_say_content_nav.slick-dotted.slick-slider {
		margin-bottom: 0;
	}

	.supplement_we_say_content_nav .slick-list {
		padding-top: 0 !important;
	}


	.supplement_we_say_content_slide .youtube-wrapper .youtube-poster {
	    background-position: 84%;
	}

	.supplement_hot_selling_slider .owl-nav .owl-prev {
		margin-left: 0;
	}

	.supplement_hot_selling_slider .owl-nav .owl-next {
		margin-right: 0;
	}
	.supplement_hot_selling_group {
		padding-bottom: 130px;
	}
	.supplement_what_we_say {
		padding-top: 100px;
	}
	.supplement_what_we_say .section_title_four {
		text-align: left;
	}
	.supplement_hot_selling_slider .owl-stage-outer {
		margin-right: 0;
	}
	.supplement_hot_selling .supplement_hot_selling_content {
		margin-right: 0;
	}
	.supplement_what_we_say .section_title_four {
		margin-bottom: 64px;
	}
	.supplement_hot_selling::before {
		width: 100%;
	}
}

@media (min-width: 576px) and (max-width: 767px) {
	.supplement_what_we_say {
		padding-top: 80px;
	}
	.supplement_hot_selling_slider .owl-nav .owl-next {
		margin-right: 0;
	}
	.supplement_hot_selling_slider .owl-nav .owl-prev {
		margin-left: 0;
	}
	.supplement_what_we_say .section_title_four {
		text-align: left;
	}
	.supplement_hot_selling::before {
		background: transparent;
	}
	.supplement_hot_selling_group {
		padding-bottom: 130px;
	}
}

@media only screen and (max-width: 767px) {
	.supplement_hot_selling_slider {
		// max-width: 400px;
		margin: 0 auto;
	}

	.supplement_we_say_content .slider.supplement_we_say_content_nav {
		width: 100%;
		height: auto;
		padding-left: 0px;
	}

	.supplement_we_say_content::before {
		width: 100%;
	}

	.supplement_we_say_content {
		flex-wrap: wrap;
	}

	.supplement_we_say_content .slider.supplement_we_say_content_for {
		width: 100%;
		padding-left: 0;
		-webkit-transform: translateY(0);
		-ms-transform: translateY(0);
		transform: translateY(0);
	}

	.supplement_we_say_content_nav .slick-list {
		padding-top: 0 !important;
	}

	.supplement_we_say_content .slider.supplement_we_say_content_nav {
		padding-left: 30px;
		padding-right: 30px;
		margin-bottom: 0;
	}

	.supplement_hot_selling_slider .owl-stage-outer {
		padding: 90px 0 0px;
		margin-right: 0;
	}
	.supplement_hot_selling .supplement_hot_selling_content {
		margin-right: 0;
	}

	.supplement_what_we_say .section_title_four {
		margin-bottom: 40px;
	}

	.supplement_we_say_content_slide .youtube-wrapper .youtube-poster {
	    background-position: 84%;
	}
	.supplement_hot_selling_group-two .supplement_what_we_say {
	    padding-top: 40px;
	}
}


@media only screen and (max-width: 575px) {
	.supplement_hot_selling .supplement_hot_selling_content {
		margin-right: 0;
	}
	.supplement_hot_selling_slider .owl-nav .owl-next i, .supplement_hot_selling_slider .owl-nav .owl-prev i {
		line-height: 43px;
	}
	.supplement_hot_selling_slider .owl-nav .owl-next, .supplement_hot_selling_slider .owl-nav .owl-prev {
		width: 45px;
		height: 45px;
		line-height: 45px;
	}
	.supplement_hot_selling_group {
		padding-bottom: 111px;
	}

	.supplement_hot_selling_slider .owl-nav .owl-prev {
		margin-left: 0;
	}

	.supplement_hot_selling_slider .owl-nav .owl-next {
		margin-right: 0;
	}
	.supplement_hot_selling::before {
		width: auto;
	}
	.supplement_we_say_content .supplement_we_say_slide-img {
		flex-wrap: wrap;
	}
	.supplement_we_say_content .supplement_we_say_slide-img a {
		padding-left: 0;
		padding-left: 10px;
	}
	.supplement_we_say_content .supplement_we_say_slide {
		margin-right: 0;
	}
	.supplement_hot_selling_group-two .supplement_what_we_say {
		padding-top: 60px;
	}
}

@media (max-width: 575px) {

	.supplement_what_we_say {
		padding-top: 80px;
	}
	.supplement_what_we_say .section_title_four {
		text-align: left;
	}
	.supplement_hot_selling_group {
		padding-bottom: 110px;
	}
	.supplement_hot_selling_slider .owl-stage-outer {
		padding: 63px 0 0px;
	}
}

@media (max-width: 358px) {

	.supplement_we_say_content .supplement_we_say_slide-img a {
		padding-left: 0;
		margin-top: 10px;
	}
}
