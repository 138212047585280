.product_detail_area.section_padding {
    padding: 7rem 0 13rem;
}

.product_detail_cont {
    position: relative;
}

.product_detail_slider {
    width: calc(50% + 117px);
    background: $main_theme_color;
    text-align: center;
    @include box-shadow(1px, 0px, 9px, rgba(72, 72, 72, 0.2));
    border: 5px solid #fff;
    padding: 4.45rem 0 2.23rem;

    .owl-nav {
        position: absolute;
        right: 20px;
        top: 40px;

        button {
            display: inline-block;
            position: relative;
            border-right: 1px solid #f2cb47 !important;
            border-radius: 0;

            &:last-child {
                border-right: none !important;
            }

            span {
                font-size: 30px;
                color: #eff8ff;
                padding: 0px 30px;
            }
        }
    }
}

.sn_pd_slide {
    display: inline-block;
}

.product_detail_desc {
    position: absolute;
    right: 0;
    top: 5.55rem;
    width: 50%;
    z-index: 1;
    background: $secondary_theme_color;
    @include box-shadow(1px, 0px, 9px, rgba(72, 72, 72, 0.2));
    border: 5px solid #fff;
    padding: 4.45rem 3.35rem;
    height: 100%;

    h4 {
        span {
            color: $main_theme_color;
        }
    }

    .btn_wrapper {
        margin-top: 0;
    }

}

.product_ratting {
    a {
        color: $secondary_theme_color;
        padding: 0;

        i {
            font-size: 14px;
        }
    }

    span {
        color: #6a6868;
        font-size: 0.73rem;
        font-weight: 500;
    }
}

.quantity {
    display: inline-block;
    margin-right: 1.12rem;
    vertical-align: middle;

    .looking {

        &:hover {
            background: $main_theme_color;
            color: $text_white;
        }
    }

    input, .looking {
        width: 2.39rem;
        display: inline-block;
        text-align: center;
        height: 2.39rem;
        line-height: 2.39rem;
        border: 1px solid #ddd;
        vertical-align: middle;
        cursor: pointer;
        padding: 0;
        font-size:1rem;
        border-radius: 0;
    }
    .looking {
    	margin-left: -1px;
    }

    input {
    	margin-right: -4px;
    }

    span i {
        font-size: 0.8rem;
    }
}
.product_detail_desc .btn_wrapper {
	margin-top: 0;
	vertical-align: middle;
	display: inline-block;
	height: 50px;
}
.product_price {
    padding-bottom: 2rem;
    border-bottom: 7px solid $main_theme_color;
    margin-bottom: 1.67rem;

    b {
        font-weight: 600;
        color: #3c3c3c;
        text-transform: uppercase;
        vertical-align: middle;
    }
    span {
        font-size: 26px;
        color: #ff8b8b;
        font-weight: 700;
        vertical-align: middle;
    }
}

.woocommerce-product-details__short-description p {
    margin-bottom: 3rem;
}

.product-cart {
    padding-bottom: 3rem;
    border-bottom: 1px solid #ddd;
    margin-bottom: 0;
}

.share-wrap {
    padding: 3rem 0 0;
    overflow: hidden;

    span {
        display: inline-block;
    }

    .product-share-link {
        margin-bottom: 0;
        display: inline-block;
        float: right;
        padding-right: 0;

        a {
            padding: 0;
            color: #646464;
            font-size: 18px;
            margin-left: 17px;

            &:hover {
                color: $main_theme_color;
            }
        }
    }
}