.product_review_tab {

	.nav li {
		height: 62px;
		width: 25%;
		border: 1px solid #ededed;
		margin-left: -1px;
		text-align: center;
		line-height: 62px;
		font-weight: 500;

		a {
			padding: 0;
			color: $home5_black;
			display: block;
			position: relative;
			letter-spacing: 2px;

			&:after {
				position: absolute;
				left: calc(50% - 21px);
				top: 97%;
				content: "";
				width: 0;
				height: 1px;
				background: $home5_black;
				transition: 0.3s;
			}

			&.active {
				color: $main_theme_color;

				&:after {
					width: 42px;
				}
			}
		}
	}

	.tab-content {
		padding: 68px 60px;
		border: 1px solid #ededed;
		border-top: 0px;
		width: 99.6%;
		margin-left: -1px;

		#description p {
			color: #666;
			font-size: 16px;
			line-height: 30px;
			margin-bottom: 35px;
			letter-spacing: 0.38px;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}

.supplement_nutrition_fact {

	h3 {
		font-size: 24px;
		color: #666;
		font-weight: 400;
		text-transform: capitalize;
		margin-bottom: 30px;
	}

	p {
		margin-bottom: 70px;
	}
}

.shipping_info_detail h4 {
	font-size: 24px;
	color: #222;
	font-weight: 400;
	text-transform: capitalize;
	margin-bottom: 20px;
}
.single_shipping_info {
	margin-bottom: 40px;

	> span {
		font-size: 22px;
		color: #666;
		font-weight: 500;
		margin-bottom: 30px;
		display: block;
	}

	ul {
		padding-left: 0;
	}

	li {
		font-size: 16px;
		color: #666;
		line-height: 30px;
	}
}

.sn_review_left {
	width: 50px;
	height: 50px;
	border-radius: 3px;
	display: inline-block;
	vertical-align: top;
}

.sn_review_right {
	display: inline-block;
	width: calc(100% - 55px);
	vertical-align: top;
	padding-bottom: 50px;
	border-bottom: 1px solid #f5f5f5;
	margin-bottom: 50px;
	padding-left: 20px;

	a {
		padding: 0;
		font-size: 16px;
		color: #666;
		font-weight: 500;

		span {
			font-size: 12px;
			color: #9c9c9c;
		}
	}

	.sn_review_icon a {
		font-size: 14px;
		color: $main_theme_color;

		&:last-child {
			color: #b2b2b2;
		}
	}

	.sn_review_desc p {
		font-size: 16px;
		color: #666;
		line-height: 30px;
		margin-bottom: 0;
	}
}
.review_form h5 {
	font-size: 16px;
	color: $home5_black;
	font-weight: 500;
	margin-bottom: 50px;

	span {
		color: #9c9c9c;
		font-size: 14px;
		text-transform: capitalize;
	}
}
.product_rating {
	margin-bottom: 30px;

	h5 {
		font-size: 16px;
		color: #4f4f4f;
		text-transform: capitalize;
		font-weight: 400;
	}

	a {
		padding: 0;
		color: #b8b8b8;
		font-size: 16px;
	}
}
.sn_review_input {

	label {
		font-size: 16px;
		color: #666;
		font-weight: 400;
		display:block;
		border-radius: 0;
	}

	textarea {
		width: 45%;
		height: 140px;
		border: 1px solid #f5f5f5;
		resize: none;
		border-radius: none;
		margin-bottom: 40px;
		padding: 15px;
		border-radius: 0px;

		&:focus {
			border: 1px solid $home6_primary
		}
	}

	input {
		width: 45%;
		border: none;
		height: 49px;
		border: 1px solid #f5f5f5;
		border-radius: 0;
		margin-bottom: 40px;
		padding: 15px;

		&:focus {
			border: 1px solid $home6_primary
		}
	}
}

.sn_review_input label {
	transition: 0.3s;
}

.sn_review_input:hover label {
	color: #000;
}

@media (min-width: 576px) and (max-width: 767px) {
	.product_share ul li:hover a span {
		display: none;
	}

	.product_share ul li:hover a > i {
		opacity: 1;
	}
}

@media only screen and (max-width: 575px) {
	.product_review_tab .nav li a {
		font-size: 12px;
	}
	.product_share ul li:hover a span {
		display: none;
	}

	.product_share ul li:hover a > i {
		opacity: 1;
	}
}