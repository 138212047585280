.about_area {
	padding: 140px 0 140px;
	background: #fff;
}

.about_right_side {
	position: relative;
	height: 100%;
	padding-left: 15px;

	.section_title_four {
		margin-bottom: 60px;
		left: -15%;
		position: relative;
		z-index: 1;

		h2 {
			box-shadow: 0px 4px 5px rgba(0,0,0,0.1);
		}
	}

	h3 {
		font-size: 24px;
		font-weight: 500;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: 2.9px;
		text-align: left;
		color: $home6_primary;
		text-transform: capitalize;
		font-family: $font_heading;
		margin-bottom: 30px;
	}

	p {
		font-size: 20px;
		font-weight: 300;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.5;
		letter-spacing: normal;
		text-align: left;
		color: $text_inner45;
		padding-right: 0;
	}

	.about_right_img {
		position: absolute;
		bottom: -47%;
		right: 0;
		z-index: 3;
	}

}

.about_area_bottom {
	background: #f5f5f5;
	padding: 140px 0;
	position: relative;

	.section_title_four {

		h2 {
			padding: 32px 28px;
		}
	}
}

.about_area_bottom_left {
	padding-right: 100px;

	p {
		font-size: 20px;
		font-weight: 300;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.5;
		letter-spacing: normal;
		text-align: left;
		color: $text_inner45;
		margin-bottom: 54px;
	}
}

.about_area_bottom_video {

	a {
		padding: 0;
		background: $home6_primary;
		width: 78px;
		height: 80px;
		text-align: center;
		border-radius: 0;
		position: relative;

		i {
			font-size: 45px;
			color: $home5_black;
			line-height: 80px;
		}

		&:hover h4 {
			left: 110%;
		}

		h4 {
			position: absolute;
			left: calc(100% - 23px);
			bottom: 5px;
			font-size: 16px;
			font-weight: 700;
			font-style: normal;
			font-stretch: normal;
			line-height: 6.25;
			letter-spacing: normal;
			text-align: left;
			color: $home5_black;
			width: 120px;
			line-height: normal;
			margin-bottom: 0;
			font-family: $font_heading;
			transition: 0.5s;
		}
	}
}

.about_bottom_right_side {
	position: absolute;
	bottom: -140px;
	box-shadow: 22px 32px 70px rgba(0,0,0,0.2);
	z-index: 1;
	margin-left: 15px;
	margin-right: 15px;

	img {
		height: 503px;
		object-fit: cover;
	}

	&:after {
		position: absolute;
		content: "";
		left: 75px;
		top: -107px;
		height: 412px;
		width: 25px;
		background: $home6_primary;
		-webkit-transform-origin: center;
		-ms-transform-origin: center;
		transform-origin: center;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
		z-index: -1;
	}
}
.call_to_action_green {
	padding: 150px 0 130px;
}

.about_left_side{
	position: relative;
	z-index: 1;

	&:after {
		position: absolute;
		content: "";
		left: 10px;
		top: -107px;
		background: $home6_primary;
		width: 25px;
		height: 412px;
		transform-origin: center;
		transform: rotate(45deg);
		z-index: -1;
	}
}

@media (min-width: 1200px) and (max-width: 1499px) {
	.about_right_side {
		padding-top: 60px;
	}
	.about_area_bottom_left {
		padding-right: 50px;
	}

	.about_right_side .about_right_img {
		bottom: -62%;
	}
}

@media (min-width: 992px) and (max-width: 1199px) {
	.about_right_side {
		padding-top: 0;
		padding-left: 0;
	}
	.about_area_bottom_left {
		padding-right: 0;
		padding-left: 0;
	}

	.about_right_side p {
		padding-right: 0;
	}

	.about_right_side .about_right_img {
		bottom: -82%;
	}
}
@media (min-width: 576px) and (max-width: 767px) {
	.about_right_side .section_title_four {
		left: 0;
	}

	.about_bottom_right_side {
		margin-left: 0;
		margin-right: 0;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.about_right_side {
		padding-top: 0;
		padding-left: 0;
	}
	.about_area_bottom_left {
		padding-right: 0;
		padding-left: 0;
	}

	.about_right_side p {
		padding-right: 0;
	}

	.about_right_side .about_right_img .section_title_four {
		top: 30px;
	}

	.about_bottom_right_side {
		bottom: 0;
	}

	.about_right_side .section_title_four {
		margin-bottom: 30px;
	}
	.about_right_side h3 {
		margin-bottom: 15px;
	}
}

@media only screen and (max-width: 767px) {
	.about_right_side {
		padding-top: 80px;
		padding-left: 0;
	}

	.about_right_side p {
		padding-right: 3px;
	}

	.about_area_bottom_left {
		padding-right: 0;
		padding-left: 0;
		padding-top: 60px;
		padding-bottom: 60px;
	}
	.about_right_side .about_right_img .section_title_four h2 {
		padding: 15px;
		letter-spacing: 4px;
	}

	.about_area_bottom .section_title_four h2 {
		padding: 16px 28px;
	}
	.about_bottom_right_side {
		position: static;
	}
	.about_right_side .about_right_img {
		position: static;
		padding-top: 40px;
	}

	.about_right_side .about_right_img .section_title_four {
		top: auto;
		bottom: 50px;
	}

	.about_area_bottom {
		padding: 20px 0 80px;
	}
	.about_area_bottom .section_title_four {
		bottom: -200px;
	}

	.about_area {
		padding: 80px 0 80px;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.about_area {
		padding: 100px 0 80px;
	}

	.about_right_side .about_right_img {
		bottom: -76%;
	}
	.about_area_bottom_left p {
		margin-bottom: 35px;
	}
	.about_bottom_right_side img {
		height: auto;
	}

	.about_area_bottom {
		padding: 100px 0;
	}
}
@media (max-width: 575px) {
	.about_area_bottom {
		margin-bottom: 0;
	}

	.about_right_side .section_title_four {
		left: 0;
	}

	.about_bottom_right_side img {
		height: auto;
	}
}