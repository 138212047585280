.layer_two {
	right: 0;
	position: absolute;
}
.layer_one {
	position: absolute;
	left: 0;
}
.why_use_area {
	position: relative;
	background: #fff;
	padding: 120px 0 120px;
	text-align: center;
	font-family: $font_primary;
}
.section_title_two {
	margin-bottom: 115px;
	text-align: center;

	h2 {
		font-size: 36px;
		color: #3734f0;
		font-weight: 600;
		line-height: 28px;
		padding-bottom: 30px;
		margin-bottom: 0;
	}

	p {
		font-size: 20px;
		color: #4f4f4f;
		line-height: 30px;
		margin-bottom: 0;
		letter-spacing:0.5px;
	}
	img {
		margin-bottom: 45px;
	}
}

.why_use_slider {

	.owl-stage-outer {
		padding: 100px 0;
	}

	.owl-item {


		&.active {

			&.center {
			transform: scale(1.3);
			z-index: 9;
			}

			&.medium {
				transform: scale(1.1);
				z-index: 7;
			}

			&.small {
				transform: scale(0.9);
				z-index: 5;
			}
		}
	}
}


.carousel {
  position : relative;
  margin : 0 auto;
  width : 100%;
  height : auto;
  border : none;
  overflow : visible;
}


.carousel .slides {
	margin: 0;
	padding: 0;
	border: none;
	list-style: none;
	position: relative;
	overflow: visible;
	left: 50%;
	transform: translateX(-50%);
}

.carousel .slides .slideItem {
  position : absolute;
  cursor : pointer;
  overflow : visible;
  padding : 0;
  margin : 0;
  border : none;
}

.carousel .slides .slideItem a {
  display: block;
  text-decoration : none;
  margin : 0;
  padding : 0;
  border : none;
  outline : none;
  position: relative;
  transition: 0.5s;

	// &:after {
	//   	position: absolute;
	//   	left: 0;
	//   	bottom: -15px;
	//   	width: 100%;
	//   	height: 100%;
	//   	content: "";
	//   	background: url(../../media/images/banner-two/bottle-shadow.png);
	//   	background-size: contain;
	//   	background-repeat: no-repeat;
	//   	background-position: bottom left;
 //  		transition: 0.1s;
	// }

}

.carousel .slides .slideItem img {
  margin : 0;
  padding : 0;
  border : none;
  width : 100%;
  height : 100%;
}

.carousel_screenshot {
	display: none;
}







