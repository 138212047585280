.footer_four_area {
	background: url(../../media/images/home3/footer-bg.jpg) no-repeat scroll 0 0/cover;
	position: relative;
	overflow: hidden;
	padding: 68px 0;

	a {
		padding: 0;
	}

	a,h1,h2,h3,h4,h5,h6,p {
		font-family: $font_primary;
		margin: 0;
		padding: 0;
		letter-spacing: 0.25px;
	}

	ul,li {
		padding: 0;
		margin: 0;
	}

	.footer_four_top {
		border-bottom: 1px solid #2d2d2d;
		padding-bottom: 30px;
	}

	.footer_four_left {
		float: left;

		a {
			margin-top: 6px;
		}
	}

	.footer_four_right {
		float: right;

		p {
			display: inline-block;
			font-size: 14px;
			color: $text_white;
			letter-spacing: 1.5px;
			margin-right: 18px;
		}

		a {
			width: 42px;
			height: 42px;
			background: $text_white;
			text-align: center;
			line-height: 42px;
			border-radius: 50%;
			margin-right: 4px;

			&:hover {
				background: $home5_primary;

				i {
					color: $text_white;
				}
			}

			i {
				color: $home5_black;
				font-size: 14px;
				transition: 0.3s;
			}
		}
	}

	.footer_four_middle {
		padding: 41px 0 37px;
		border-bottom: 1px solid #2d2d2d;

		.widget.widget4 {
			width: 20.4%;
			float: left;
			margin-bottom: 0;
			padding-right: 0;

			.widget4_title {

				h3 {
					font-size: 20px;
					letter-spacing:2.2px;
					font-weight: 300;
					color: $home5_primary;
					border-bottom: 0;
					margin-bottom: 0;
					padding-bottom: 25px;

					&:after {
						display: none;
					}
				}
			}

			.widget4_desc {

				 a {
					display: block;
					font-size: 16px;
					color: #fff;
					font-weight: 400;
					line-height: 28px;
					letter-spacing: 0.25px;

					&:hover {
						i {
							color: $home5_primary;
						}
					}

					i {
						margin-right: 6px;
					}
				}

				.widget4_brand {

					a {
						display: inline-block;
						margin-left: 6px;
					}
				}
			}

			.widget4_address {

				a {
					font-size: 20px;
					font-weight: 700;
					color: $home5_primary;
					letter-spacing: 2.2px;
					margin-bottom: 15px;
				}

				p {
					margin-bottom: 32px;

					span {
						display: block;
						color: #fff;
						font-size: 16px;
						font-weight: 300;
						line-height: 24px;
						letter-spacing: 0.4px;
					}
				}
			}

			&:last-child {
				width: calc(59.6% - 9px);
				text-align: right;
			}
		}
	}

	.footer_four_bottom_left {
		float: left;

		&:hover {
			a {
				color: $home5_primary;
			}
		}

		p {
			font-size: 14px;
			color: #717171;
			letter-spacing: 0.7px;

			a {
				color: #fff;
			}
		}
	}

	.footer_four_bottom {
		padding-top: 35px;
	}

	.footer_four_bottom_right {
		float: right;
		padding-right:73px;
		position: relative;

		ul li {
			display: inline-block;

			a {
				font-size: 14px;
				font-weight: 500;
				color: #fff;
				padding:0 10px;
				position: relative;
				letter-spacing:0.5px;

				&:hover {
					color: $home5_primary;
				}

				&:after {
					position: absolute;
					left: 100%;
					top: 0;
					content: "/";
				}
			}

			&:last-child a:after {
				display: none;
			}
		}
	}
}

.backtotop.backtotop_three {
	position: absolute;
	right: 0;
	bottom: 0;
	cursor: pointer;
	transition: 0.3s;

	&:hover {
		bottom: 10px;
	}

	i {
		font-size: 16px;
		color: #fff;
	}
}

@media (min-width:768px) and (max-width: 991px) {
	.footer_four_area .footer_four_bottom_right {
		padding-right: 0;
	}

	.footer_four_area .footer_four_middle .widget.widget4 {
		width: 27%;
	}

	.footer_four_area .footer_four_middle .widget.widget4:last-child {
		width: 42%;
	}

	.footer_four_area .footer_four_middle .widget.widget4 .widget4_desc .widget4_brand a {
		margin-bottom: 4px;
	}
}

@media (min-width: 576px) and (max-width: 767px) {
	.footer_four_area .footer_four_middle .widget.widget4 {
		width: 50%;
	}

	.footer_four_area .footer_four_middle .widget.widget4:last-child {
		margin-top: 50px;
	}
	.footer_four_area .footer_four_middle .widget.widget4:last-child {
		text-align: left;
		width: calc(80% - 9px);
	}
	.footer_four_area .footer_four_bottom_right {
		float: left;
		padding-right: 0;
		width: 100%;
		position: relative;
	}
	.footer_four_area .footer_four_bottom {
		text-align: left;
	}
	.footer_four_area .footer_four_right p {
		display: block;
	}
	.footer_four_area {
		padding: 70px 0;
	}
	.footer_four_area .footer_four_middle {
		padding: 41px 0 80px;
	}
}

@media (min-width: 480px) and (max-width: 575px) {
	.footer_four_area .footer_four_right p {
		display: inline-block;
	}
	.footer_four_area .footer_four_right {
		float: right;
	}
}

@media only screen and (max-width: 575px) {
	.footer_four_area .footer_four_middle .widget.widget4:last-child {
		width: 100%;
		text-align: left;
		margin-bottom: 0;
	}
	.footer_four_area .footer_four_middle .widget.widget4 {
		width: 100%;
		margin-bottom: 60px;
	}
	.footer_four_area .footer_four_bottom_right {
		float: left;
		padding-right: 0;
		position: relative;
		width: 100%;
	}

	.footer_four_area .footer_four_bottom_right ul li:first-child a {
		padding-left: 0;
	}
	.footer_four_area .footer_four_right p {
		display: block;
	}

	.footer_four_area .footer_four_middle .widget.widget4 .widget4_desc .widget4_brand a {
		margin-bottom: 5px;
	}
	.footer_four_area .footer_four_middle {
		padding: 41px 0 74px;
	}
	.footer_four_area {
		padding: 74px 0 30px;
	}
	.footer_four_area .footer_four_bottom {
		padding-top: 0;
	}
}

@media (min-width: 480px) and (max-width: 575px) {
	.footer_four_area .footer_four_right {
		float: right;
		margin-top: 0px;
		width: auto;
	}
}

@media only screen and (max-width: 479px) {
	.footer_four_area .footer_four_right {
		float: left;
		margin-top: 14px;
		width: 100%;
	}

	.footer_four_area .footer_four_right p {
		margin-bottom: 10px;
	}
}