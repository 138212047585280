.product_all_collection.woocommerce {
	padding: 140px 0 130px;
}

.product .product_single_collection {
	padding-top: 70px;
	padding: 20px;
	padding-top: 70px;
	transition: all 0.5s linear;

	.woocommerce-LoopProduct-link {
		padding: 0;
		display: block;
	}

	.product-thumb {
		margin-bottom: 12px;
	}
}

.products {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.column-3 .products .product {
	width: calc(33.33% - 40px);
	margin-bottom:60px;
	-webkit-box-shadow: -2px -1px 35px rgba(0, 0, 0, 0.06);
	box-shadow: -2px -1px 35px rgba(0, 0, 0, 0.06);
	text-align: center;
	transition: 0.5s;

	&:hover {
		box-shadow: -2px -1px 50px rgba(0, 0, 0, 0.16);
	}
}

.woocommerce-loop-product__title {

	&:hover {
		span {
			background: #fff;
			-webkit-transform: translate3d( 0px, 0px, -30px ) rotateX( 90deg );
			-moz-transform: translate3d( 0px, 0px, -30px ) rotateX( 90deg );
			-ms-transform: translate3d( 0px, 0px, -30px ) rotateX( 90deg );
			transform: translate3d( 0px, 0px, -30px ) rotateX( 90deg );
		}
	}
}

.woocommerce-loop-product__title {
	color: $home5_black;
	display: block;
	text-decoration: none;
	overflow: hidden;
	vertical-align: top;
	-webkit-perspective: inherit;
	-moz-perspective: inherit;
	-ms-perspective: inherit;
	perspective: inherit;
	-webkit-perspective-origin: 50% 50%;
	-moz-perspective-origin: 50% 50%;
	-ms-perspective-origin: 50% 50%;
	perspective-origin: 50% 50%;
	font-family: $font_heading;
	line-height: normal;

    span {
		display: block;
		position: relative;
		-webkit-transition: all 0.5s ease;
		-o-transition: all 0.5s ease;
		transition: all 0.5s ease;
		-webkit-transform-origin: 50% 0%;
		-ms-transform-origin: 50% 0%;
		transform-origin: 50% 0%;
		-webkit-transform-style: preserve-3d;
		transform-style: preserve-3d;
		padding: 40px 0 50px;
		background: #fff;
		font-size: 22px;
		font-weight: 500;
		color: #000;
		line-height: 30px;

		.after {
			content: "";
			display: block;
			position: absolute;
			left: 0;
			top: 0;
			color: $home6_primary2;
			background: #fff;
			-webkit-transform-origin: 50% 0%;
			-ms-transform-origin: 50% 0%;
			transform-origin: 50% 0%;
			-webkit-transform: translate3d(0px, 105%, 0px) rotateX(-90deg);
			-ms-transform: translate3d(0px, 105%, 0px) rotateX(-90deg);
			transform: translate3d(0px, 100%, 0px) rotateX(-90deg);
			font-weight: 500;
			width: 100%;
			height: 100%;
			font-size: 16px;
			padding: 40px 50px;
			letter-spacing: 1.8px;
			line-height: 30px;
			font-family: "Roboto", sans-serif;
			line-height: 26px;

			i {
				display: block;
				font-size: 14px;
				margin-top: 10px;
				transition: 0.3s;

				&:hover {
					color: $home6_primary3;
				}
			}
		}
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
	.woocommerce-loop-product__title span .after {
		padding: 40px 0;
	}
	.column-3 .products .product {
		width: calc(33.33% - 30px);
		margin-bottom: 50px;
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	.column-3 .products .product {
		width: calc(50% - 30px);
		margin: 0 auto 60px;
	}
	.woocommerce-loop-product__title span .after {
		padding: 40px 0;
	}

	.woocommerce .blog_list_pagination {
		padding-left: 16px;
		padding-right: 16px;
	}
	.product_all_collection.woocommerce {
		padding: 100px 0 90px;
	}
}

@media (min-width: 576px) and (max-width: 767px) {
	.product_all_collection.woocommerce {
		padding: 80px 0 70px;
	}
}

@media only screen and (max-width: 767px) {
	.column-3 .products .product {
		width: 100%;
		margin: 0 auto 60px;
	}

	.woocommerce-loop-product__title span .after {
		padding: 40px;
	}
}

@media only screen and (max-width: 480px) {
	.column-3 .products .product {
		width: 100%;
		margin: 0 auto 60px;
	}

	.woocommerce-loop-product__title span .after {
		font-size: 13px;
		line-height: 22px;
	}
}