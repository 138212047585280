.sn_pd_two_slide {
	display: inline-block;
	border: 1px solid #f4f4f4;
	-webkit-box-shadow: 0px 11px 21px rgba(221, 221, 221, 0.31);
	box-shadow: 0px 11px 21px rgba(221, 221, 221, 0.31);
	margin: 0;
	padding: 44px 37px;
	width: 100%;
	position: relative;
	border-radius: 3px;
}
.product_two_slider {

	.owl-nav {
		position: absolute;
		left: calc(50% - 23px);
		top: -80px;

		.owl-prev,
		.owl-next {
			font-size: 15px !important;
			color: #afafaf !important;
			margin: 0 4px;
		}
		.owl-prev:hover,
		.owl-next:hover {
			color: #e3e3e3 !important;
		}
	}

	img {
		margin-bottom: 10px;
	}
}

.product_two_area {
	padding: 120px 0 94px;

	p,h1,h2,h3,h4,h5,h6,a {
		font-family: $font_primary;
	}

	.owl-carousel .owl-stage-outer {
		margin-left: -1px;
	}
}

.sn_pd_two_detail {

	h4 {
		font-size: 16px;
		color: $home5_black;
		font-weight: 400;
		display: inline-block;
		margin-bottom: 0;
		vertical-align: middle;
		transition: 0.3s;
	}

	a {
		padding: 0;
		color: $home5_black;

		&:last-child {
			float: right;
		}
	}

	&:hover {
		h4 {
			color: #f06b00;
		}
		a i {
			font-size: 18px;
			transition: 0.3s;
		}
	}
}
.sn_pd_two_offer {
	height: 86px;
	width: 86px;
	overflow: hidden;
	border-radius: 50%;
	position: absolute;
	right: 43px;
	top: 112px;
	text-align: center;
	background: rgba(247, 247, 247, 0.95);

	.sn_pd_two_offer_icon,
	.sn_pd_two_offer_desc {
		transform-style: preserve-3d;
		backface-visibility:hidden;
		width: 100%;
		height: 100%;
		border-radius: 50%;
		display: table;
		position: absolute;
		transition: all 1.5s ease 0s;
	}

	.sn_pd_two_offer_desc {
		transform: rotateY(0deg);
		z-index: 1;

			h3 {
				font-size: 24px;
				color: $home5_black;
				font-weight: 400;
				text-align: center;
				display: table-cell;
				vertical-align: middle;

			span {
				font-size: 14px;
				display: block;
			}
		}
	}

	&:hover {

		.sn_pd_two_offer_desc {
			-webkit-transform: rotateY(-180deg);
			transform: rotateY(-180deg);
		}

		.sn_pd_two_offer_icon {
			-webkit-transform: rotateY(0deg);
			transform: rotateY(0deg);
		}
	}

	.sn_pd_two_offer_icon {
		background: rgba(247, 247, 247, 0.3);
	    transform: rotateY(180deg);
		z-index: 0;

		i {
			font-size: 20px;
			color: $home5_black;
			display: table-cell;
			vertical-align: middle;
			font-weight: 700;
			cursor: pointer;
		}
	}
}

.quickview-wrapper.open {
	visibility: visible;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	opacity: 1;
	overflow: visible;
	z-index:99999;
}

.quickview-wrapper {
	position: fixed;
	width: 1000px;
	height: 590px;
	border-radius: 5px;
	top: 50%;
	left: 50%;
	opacity: 0;
	-webkit-transform: translate(-50%, -60%);
	-ms-transform: translate(-50%, -60%);
	transform: translate(-50%, -60%);
	visibility: hidden;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	display: inline-block;
	-webkit-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	background: transparent;
	z-index: 1050;
}
.quickview-wrapper {
	visibility: hidden;

	.share-wrap {
		padding: 0;
	}

	.ingredient_slider_btn {
		margin-bottom: 30px;
	}

	.col-xl-7,.col-xl-5 {
		padding: 0;
	}
}
.modal {
	display: block;
	visibility: hidden;
}
.mask-overlay2 {
    content: '';
    position: fixed;
    background: rgba(0, 0, 0, 0.8);
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
}
.close-qv {
	position: absolute;
	right: 18px;
	cursor: pointer;
	z-index: 99999;
	cursor: pointer;
	right: 0;
	top: -40px;

	i {
		font-size: 25px;
		color: #fff;
	}
}

.modal.quickview-wrapper.open {
	background: #fff;
}

.quickview {
	background: #fff;

	.ingredient_slider_main {
		width: 60%;
	}

	.ingredient_slider_detail {
		width: 40%;
	}
	.ingredient_slider_flex {
		width: 1000px;
	}
}
.quickview-wrapper .ingredient_slider_two.slick-slider {
	width: calc(100% - 118px);
	-webkit-box-shadow: none;
	box-shadow: none;
}
.quickview-wrapper .ingredient_slider_detail {
	margin: 20px;
	border: 1px solid #ddd;
	padding: 30px;
	margin-left: 0;
}

.quickview-wrapper .ingredient_slider_one .slick-prev,
.quickview-wrapper .ingredient_slider_one .slick-next {
	top: 105%;
}
.quickview-wrapper .ingredient_slider_one .slick-slide {
	border: 1px solid #f5f5f5;
	cursor: pointer;
	width: 118px !important;
}
.quickview-wrapper .ingredient_slider_two .ingredient-img {
	padding: 98px 100px 86px;
}
.quickview-wrapper .ingredient_slider_one.slick-slider {
	height: 541px;
	background: #fff;
}
.modal.quickview-wrapper::before {
	position: absolute;
	content: "";
	right: 0;
	top: 0;
	width: calc(100% - 120px);
	height: 100%;
	background: #fff;
	z-index: -1;
	box-shadow: 22px 32px 70px rgba(0,0,0,0.1);
}
.quickview-wrapper .product_ratting {
	margin-bottom: 60px;
}
.quickview-wrapper .product_price {
	padding-bottom: 3rem;
	margin-bottom: 0;
}
.quickview-wrapper .ingredient_slider_detail .quantity {
	margin-bottom: 70px;
}

.quickview-wrapper .ingredient_slider_detail {
	margin: 20px;
	border: 1px solid #ddd;
	padding: 30px;
	margin-left: 0;
}

.quickview-wrapper .product_ratting a i {
	color: #D01BD0;
}
.quickview-wrapper .ingredient_slider_detail .product_price p {
	color: #fff;
}
.quickview-wrapper .ingredient_slider_detail .product_price p.in-stock {
	background: #5830B4;
}
.quickview-wrapper .ingredient_slider_detail .product_price p.out-stock {
	background: #b6b6b6;
}
.quickview-wrapper .ingredient_slider_detail .product_price .price {
	margin-top: 28px;
}
.modal.show {
	visibility: visible;
}

@media only screen and (max-width: 991px) {
	.quickview .ingredient_slider_detail,
	.quickview .ingredient_slider_main {
		width: 400px;
	}
	.quickview-wrapper .ingredient_slider_one {
		width: 110px;
	}
	.quickview .ingredient_slider_flex {
		width: 400px;
		padding-top: 30px;
	}
	.quickview-wrapper .ingredient_slider_two.slick-slider {
		width: calc(100% - 112px);
	}
	.quickview-wrapper .ingredient_slider_one .slick-slide {
		width: 110px !important;
	}
	.modal.quickview-wrapper.open {
	    width: 400px;
	}

	.close-qv {
		right: 5px;
		top: 5px;

		i {
			color: #222;
		}
	}
}

@media only screen and (max-width: 575px) {
	.quickview .ingredient_slider_detail,
	.quickview .ingredient_slider_main {
		width: 100%;
	}
	.quickview .ingredient_slider_flex {
		width: 100%;
		padding-top: 30px;
	}
}

@media (min-width: 320px) and (max-width: 479px) {
	.modal.quickview-wrapper.open {
		width: 300px;
	}
}