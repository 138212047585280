.call_to_action_area_two {
	background: rgb(240,107,0);
	background: -moz-linear-gradient(right, rgba(240,107,0,1) 0%, rgba(240,158,0,1) 100%);
	background: -webkit-linear-gradient(right, rgba(240,107,0,1) 0%,rgba(240,158,0,1) 100%);
	background: linear-gradient(to left, rgba(240,107,0,1) 0%,rgba(240,158,0,1) 100%);
	position: relative;
	$font_primary: 'Roboto', sans-serif;
	padding: 40px 0px;

	p,a,h1,h2,h3,h4,h5,h6 {
		$font_primary: 'Roboto', sans-serif;
	}

	&:before {
		position: absolute;
		left:0;
		top:0;
		width: 100%;
		height: 100%;
		content: "";
		background: url(../../media/images/banner-two/garlic-pills.png) no-repeat scroll top left/contain;
	}

	&:after {
		position: absolute;
		content: "";
		right: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: url(../../media/images/banner-two/garlic-pill-reflect.png) no-repeat scroll top right/contain;
	}
}

.call_to_action_hello {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	height: auto;
	padding: 40px;
	border: 2px solid rgba(242,168,26,0.6);
}

.call_to_action_left_two {

	p {
		margin-bottom: 0;
		font-size: 20px;
		color: $home5_black;
		opacity: 0.8;
		letter-spacing: 0.2px;
	}

	h2 {
		margin-bottom: 0;
		font-size: 36px;
		color: #fff;
		font-weight: 300;
		letter-spacing: 6px;
		line-height: normal;
	}
}

.call_to_action_right_two {
	height: 46px;

	.btn_two {
		border: 2px solid #fff;
		color: #fff;
		letter-spacing: 2.2px;

		&:hover {
			color: $home5_primary;
			background: #fff;
		}

		&:before {
			background: #fff;
		}
	}
}
