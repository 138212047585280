// // DO NOT EDIT DIRECTLY!
////Generated by gulpfile.js/tasks/iconFont.js
////from gulpfile.js\tasks\iconFont\template.sass

@font-face {
  font-family: theme-build-icons;
  src: url("../fonts/theme-build-icons.eot");
  src: url("../fonts/theme-build-icons.eot?#iefix") format('embedded-opentype'), url("../fonts/theme-build-icons.woff") format('woff'), url("../fonts/theme-build-icons.ttf") format('truetype'), url("../fonts/theme-build-icons.svg#theme-build-icons") format('svg');
  font-weight: normal;
  font-style: normal; }

@mixin icon($content: '') {
  &:before {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    content: $content;
    font-family: 'theme-build-icons';
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    line-height: 1;
    speak: none;
    text-transform: none;
    @content; } }

.icon {
  @include icon; }

// Save variable
$icon-facebook: "\EA01";
// Save mixin
@mixin icon--facebook {
  @include icon($icon-facebook) {
    @content; } }
// Expose as class
.icon.-facebook:before {
  content: $icon-facebook; }

// Save variable
$icon-instagram: "\EA02";
// Save mixin
@mixin icon--instagram {
  @include icon($icon-instagram) {
    @content; } }
// Expose as class
.icon.-instagram:before {
  content: $icon-instagram; }

// Save variable
$icon-twitter: "\EA03";
// Save mixin
@mixin icon--twitter {
  @include icon($icon-twitter) {
    @content; } }
// Expose as class
.icon.-twitter:before {
  content: $icon-twitter; }

// Save variable
$icon-viget: "\EA04";
// Save mixin
@mixin icon--viget {
  @include icon($icon-viget) {
    @content; } }
// Expose as class
.icon.-viget:before {
  content: $icon-viget; }

